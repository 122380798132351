.chat {
@include mq('phone-wide', max){
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
&__tab-name{
  color: $white-dark;
  font-size: $small;
  font-family: $ubuntu-regular;
}
    &__tabs{
      display: flex;
      height: 53px;
      justify-content: space-evenly;
      align-items: center;
      @include mq('phone-wide', max){
        display: flex;
        flex-direction: column;
        background-color: #6441a5;
        border-radius: 16px;
        padding: 1px;
        height: 70px;
        width: 100%;
      }
      &.TWITCH{
        background: #17111e;
      }
      &.DISCORD{
        background: #7289da;
      }
    }
    &__sub-tab{
      width: 90%;
      height: 30px;
      border-radius: 15px;
      background-color: transparent;
      display: flex;
      justify-content: space-evenly;
    }
    &__main-tab{
      width: 100%;
      height: 40px;
      border-radius: 15px;
      background-color: $background-dark-grey;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px;
      box-sizing: border-box;
    }
    &__label{
      line-height: 36px;
      padding-left: 1em;
      width: 35%;
    }
    &__aside{
      width: 60%;
      display:flex;
    }
    &__twitch-tab{
      width: 25%;
      height: 100%;
      background-image: url('../images/main/chat/twitch-disabled.png');
      background-size: 37px 37px;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      div{
        width: 37px;
        height: 37px;
        margin: auto;
        margin-top: 9%;
        border-radius: 50%;
        transition: all .3s ease;
      &:hover{
        box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
      }
      }
      @include mq('phone-wide', max){
          background: none;
          color: $white-dark;
          font-size: $small;
          text-align: center;
          line-height: 30px;
      }
      &.active{
        background-image: url('../images/main/chat/twitch-enabled.png'); 
      }
    }
    &__youtube-tab{
      width: 25%;
      height: 100%;
      // background-image: url('../images/main/chat/youtube-disabled.png');
      background-color: black;
      background-size: 37px 37px;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      div{
        width: 37px;
        height: 37px;
        margin: auto;
        margin-top: 9%;
        border-radius: 50%;
      &:hover{
        box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
      }
      }
      @include mq('phone-wide', max){
          background: none;
          color: $white-dark;
          font-size: $small;
          text-align: center;
          line-height: 30px;
      }
      &.active{
        // background-image: url('../images/main/chat/youtube-enabled.png'); 
        background-color: red;
      }
    }
    &__discord-tab{
      cursor: pointer;
      height: 100%;
      width: 25%;
      background-image: url('../images/main/chat/discord-disabled.png');
      background-size: 37px 37px;
      background-repeat: no-repeat;
      background-position: center;
      div{
        width: 37px;
        height: 37px;
        margin: auto;
        margin-top: 9%;
        border-radius: 50%;
        transition: all .3s ease; 
      &:hover{
        box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
      }
      }
      @include mq('phone-wide', max){
          background: none;
          color: $white-dark;
          font-size: $small;
          text-align: center;
          line-height: 30px;
      }
      &.active{
        background-image: url('../images/main/chat/discord-enabled.png'); 
      }
    }
    &__activities-tab-icon{
      background-image: url('../images/main/chat/cube-white.png');
      background-repeat: no-repeat;
      background-size: 100%;
      width: 25px;
      height: 28px;
      margin-right: 5px;
    }
    &__activities-tab{
        cursor: pointer;
        width: 154px;
        height: 39px;
        border-radius: 18.5px;
        // background-image: url('../images/main/chat/cube-white.png');
        // background-size: 22px 22px;
        // background-position: center;
        // background-repeat: no-repeat;
        transition: all .3s ease; 
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid white;
        background-position-y: center;
        box-sizing: border-box;
        font-size: 18px;
        color: $white-dark;
        line-height: 37px;
        text-align: center;
        background-position-x: 27px;
        &:hover{
          box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
        }
        @include mq('phone-wide', max){
          width: 30px;
          border: none;
          height: 30px;
          background-position-x: center;               
          padding-left: 0;
        }
        &.TWITCH{
          background-color: #6441a5;
      }
        &.DISCORD{
          background-color: #7c8ce4;
       }
    }

    &__chat-tab{
      cursor: pointer;
      width: 154px;
      height: 39px;
      box-sizing: border-box;
      background-color: #1b1b1b;
      border-radius: 18.5px;
      border: 4px solid #ffc837;
      background-image: url('../images/main/activities/chat.png');
      background-size: 26px;
      background-position: center;
      background-repeat: no-repeat;
      @include mq('phone-wide', max){
        width: 30px;
        border: none;
        background-color: transparent;
        height: 30px;
        color: transparent;
      }
  }
    height: 560px;
    width: 350px;
    border-radius: 6px;  
    // overflow: hidden;
    background: #000000;
   
  }
  