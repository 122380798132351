@import '../../assets/style/partials/variables.scss';

.textarea{
    display: block;
    width: 100%;
    border: none;
    min-height: 92px;
    border-bottom: 1px solid $gray-77;
    outline: none;
    padding: 10px 0;
    padding-bottom: 1.5rem;
    resize: none;
    color: $gray-77;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    &::placeholder{
        color: $gray-77;
        font-size: 14px;
    }
    &__wrap{
        position: relative;
    }
    &__left-addon{
        position: absolute;
        right: 0;
        margin-top: -4rem;
        color: $gray-77;
    }
}
