
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

$breakpoints: (
    "phone":        400px,
    "phone-wide":   480px,
    "phablet":      560px,
    "tablet-small": 640px,
    "tablet":       768px,
    "tablet-wide":  1024px,
    "desktop":      1248px,
    "desktop-wide": 1440px
);

@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-device-width: $width) {
            @content;
        }
    //     @media only screen and (-moz-min-device-pixel-ratio: 2), 
    //    only screen and (-o-min-device-pixel-ratio: 2/1), 
    //    only screen and (-webkit-min-device-pixel-ratio: 2), 
    //    only screen and (min-device-pixel-ratio: 2) {
    //     @content;
    }
}

@mixin font-source($family: false, $size: false, $colour: false, $weight: false,  $lh: false) {
    @if $family {font-family: $family; }
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
}

// FONTS

$montserrat: 'Montserrat', sans-serif;

// DEPRECATED
$ubuntu-regular: Ubuntu-Regular, sans-serif;
$ubuntu-bold: Ubuntu-Bold, sans-serif;
$ubuntu-medium: Ubuntu-Medium, sans-serif;

//COLOR

$white: #fff;
$semi-white: #F2F4FF;
$gray: #656B6F;
$blue: #2094fa;
$blue-lighter: rgba(32, 128, 250, 0.8);
$red: #FA114F;
$purple: #787AFF;
$purple-dark: #9300ff;
$purple-dark-bg: #0e011f;
$win: #04de71;
$first: #787aff;
$lose: #ff3b30;
$second: #ff9500;
$losedark: #2b0a08;
$background: #1b1b1b;
$border: #e5ebf1;
$input-back: #222224;
$input-border: #e5ebf1;

$red-fill: #2B0A08;
$blue-fill: #05192B;
$main-header-fill: #282828;
$main-footer-fill: #282828;
$faq-table-fill: #1B1B1B;
$additional-header-fill: #1B1B1B;
$footer-fill: #282828;
$dark-background: #1B1B1B;
$active-faq: #1b1b1b;


$white-stroke: #979797;
$red-stroke: #FF3B30;
$blue-stroke: #5AC8FA;
$grey: #9d9d9d;

//  NEW COLORS 
$gold-dark: #ff8008;
$gold-light: #ffc837;
$gold-light-hover: #fad26d;
$gold-dark-hover: #faa24f;
$white-dark: #f7f7f7;
$white-darker:rgba(247, 247, 247, 0.59);
$blue-dark: #2E3945;
$cube: #2094fa;
$cube-hover: #83c1f7;
// SOCIAL
$twitch: #6441a5;
$discord: #8c9eff;


// BACKGROUNDS
$background-grey: #232323;
$background-dark-grey: #1b1b1b;

// ACTIVITIES
$blue: #4b7bec;
$win: #2d87bf;
$lose: #4069ca;

$green: #009640;

// FONT-SIZE
$smallest: 12px;
$super-small: 14px;
$ultra-small: 16px;
$small: 18px;
$medium: 20px;
$large: 48px;

//MEDIA

$desktop-container: 1280px;

$xs-max: 575px;
$sm-min: 576px;
$sm-max: 767px;
$md-min: 768px;
$md-max: 991px;
$lg-min: 992px;
$lg-max: 1199px;
$xl-min: 1200px;

$xs: "(max-width: $xs-max)";
$sm: "(min-width: $sm-min) and (max-width: $sm-max)";
$md: "(min-width: $md-min) and (max-width: $md-max)";
$lg: "(min-width: $lg-min) and (max-width: $lg-max)";
$xl: "(min-width: $xl-min)";
$xl2: "(min-width: 1439px)";
$xl3: "(min-width: 1599px)";
$k2: "(min-width: 1919px)";


// predictoria v2.0 colors
$dark-magenta: #8e0470;
$pompadour: #63014E;
$fuchsia-pink: #D23ECD;
$medium-violet-red: #4F013E;
$light-grey: #d6d6d6;
$white: #ffffff;
$gray-85: #d9d9d9;
$gray-77: #c4c4c4;
$orange: #ff891c;
$pumpkin: #f37b1f;
$orange-2: #EB7F1B;
$red:  #FF4040;
$dark: #1E1E1E;
$dark-1: #121212;
$gray: #F2F2F2;
$normal-green: #00DF74;
$gray-3: #777777;
$orange-3: #F39C12;