@import '../../../assets/style/partials/variables.scss';

.row-layout{
    position: relative;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
}

.column-layout{
    display: grid;
    position: relative;
    // hardcode!
    column-gap: 8px;
    row-gap: 8px;
}
.column-layout-distribution{
    &--2-cols{
        grid-template-columns: repeat(2, 1fr);
    }
    &--3-cols{
        grid-template-columns: repeat(3, 1fr);
    }
    // figure out proper naming
    &--1-3-2-3{
        grid-template-columns:  1fr 2fr;
    }
    &--2-3-1-3{
        grid-template-columns:  2fr 1fr;
    }
}

.stack-layout{
    display: flex;
    flex-direction: row;
}

.row-layout-gutter{
    &--default{
        grid-gap: 8px;
    }
}

.ds-stack-layout-gutter{
    &--default{
        >*{
            margin-right: 16px;
        } 
    }
    &--small{
        >*{
            margin-right: 16px;
        } 
    }
    &--default{
        >*{
            margin-right: 24px;
        } 
    }
    &--large{
        >*{
            margin-right: 32px;
        } 
    }
}

.ds-stack-layout-distribution{
    &--center{
        justify-content: center;
    }
    &--start{
        justify-content: flex-start;
    }
    &--end{
        justify-content: flex-end;
    }
    &--evenly{
        justify-content: space-evenly;
    }
}

.ds-stack-layout-alignment{
    &--start {
        align-items: flex-start;
    }
    &--end{
        align-items: flex-end;
    }
    &--center{
        align-items: center;
    }
}
// ?
.page-layout{
    max-width: 100%;
    margin: 0 auto;
    padding: 64px 16px;
    min-height: calc(100vh - 400px);
    background-color: $background-dark-grey;
}