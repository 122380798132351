.pagination{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 auto;
    list-style: none;
    color: $white-dark;
    padding: 20px;
    cursor: pointer;
    @include mq('phone', max){
      padding: 20px 0;
      width: 100%;
    }

    .active{
        background-color: $background-grey;
    }
  }

  .pagination li {
    outline: 0;
    display: block;
    padding: 5px;
    min-width: 20px;
    text-align: center;
    @include mq('phone-wide', max){
      min-width: 10px;
      padding: 3px 5px;
    }
  }
  .pagination li a {
    outline: 0;
}
 