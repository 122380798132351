
// @DEPRECATED
.text {
  color: $white-dark;
  font-family: $ubuntu-regular;
  &--small {
    font-size: 10px;
  }
  &_small {
    font-size: 10px;
  }

  &_medium {
    font-size: 12px;
  }

  &_default {
    font-size: 14px;
  }
}

.greyscale {
  filter: grayscale(100%);
}

.row-container {
  display: flex;

  &_center {
    justify-content: center;
  }

  &_space {
    justify-content: space-between;
  }

  &_v-center {
    align-items: center;
  }

  &_around {
    justify-content: space-around;
  }

  &_wrap {
    flex-wrap: wrap;
  }
}

.col-container {
  display: flex;
  flex-direction: column;

  &_center {
    align-items: center;
  }
}

.col-3 {
  column-count: 3;
}

.head {
  width: 100%;
  color: $white-dark;

  &_center {
    text-align: center;
  }

  &_small {
    font-size: 20px;
  }

  &_medium {
    font-size: 22px;
  }

  &_large {
    font-size: 36px;
  }
}

.button {
  text-align: center;
  height: 50px;
  border-radius: 25px;
  padding: 15px 30px;
  border: none;
  outline: none;
  color: $white-dark;
  cursor: pointer;

  &_grey {
    background-color: #4b4b4b;
  }

  &_blue {
    background-color: #2095fe;
  }

  &_gold {
    background-image: linear-gradient($gold-light, $gold-dark);
  }

  &_gold-b {
    border: 4px solid $gold-dark;
    background-color: transparent;
  }

  &_glow {
    &:hover {
      box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7;
    }
  }
}

.form {
  &__input-block {
    margin-bottom: 20px;
  }

  &__label {
    display: block;
    margin-bottom: 20px;
  }

  &__input {
    display: block;
    outline: none;
    // height: 50px;
    // min-width: 470px;
    border-radius: 25px;
    padding: 5px 10px;
    background: #4b4b4b;
    border: none;
    box-sizing: border-box;
    width: 100%;
    // var
    color: $white-dark;
  }
}

.link {
  cursor: pointer;

  &_dashed {}

  &_gold {
    color: $gold-dark;
    &:hover{
      color: $white-dark;
    }
  }

  &_underline {
    text-decoration: underline;
  }
}

.icon {


  &_exclamation {
    background-image: url('../images/main/icons/1@3x.png');
  }
  &_questionmark {
    background-image: url('../images/main/icons/questionmark.svg');
  }
  &_clock {
    background-image: url('../images/main/icons/clock.png');
  }

  &_close {
    background-image: url('../images/main/activities/close.png');
  }
}