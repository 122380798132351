.adm-new-paid-action {
  width: 70%;
  box-sizing: border-box;
}

.adm-new-paid-action__cubes,
.adm-new-paid-action__orbs {
  &::before {
    content: ' ';
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    margin-bottom: -2px;
    background-size: 100% 100%;
  }
}

.adm-new-paid-action__orbs {
  &::before {
    background-image: url('/assets/images/main/redesign/orb.png');
  }
}

.adm-new-paid-action__cubes {
  &::before {
    background-image: url('/assets/images/main/redesign/cube.png');
  }
}

.adm-new-paid-action__option-group {
  margin-right: 15px;
  input {
    margin-top: -3px;
    padding-bottom: 0;
    width: 50%
  }
}

.adm-new-paid-action__input-cube {
  background-image: url('/assets/images/main/redesign/cube.png');
  background-size: 100% 100%;
}

.adm-new-paid-action__input-orb {
  background-image: url('/assets/images/main/redesign/orb.png');
  background-size: 100% 100%;
}