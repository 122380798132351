@import '../../assets/style/partials/variables.scss';


.landing{
p{
    line-height: 1.6;
}

&__circle{
    width: 65px;
    height: 65px;
    object-fit: none;
}
}

.landing-desktop{
    // @PAY ATTENTION
    background-image: url('/assets/images/main/landing-background.png');
    background-size: 100% 2790px;
    background-position-y: -127px;
    &__section-1{
        min-height: 500px;
    }
    &__section-2{
        min-height: 750px;
    }
    &__section-3{
        min-height: 780px;
    }
    p{
        line-height: 1.5;
    }
    &__task-card-2{
        top: -50px;
        left: 0px;
    }
    &__task-card-3{
        bottom: 50px;
        right: 50px;
    }
}


.task-card{
    width: 293px;
    position: absolute;
    background: $dark-1;
    // OVERRIDE
    p{
        line-height: 1;
    }
    // PAY ATTENTION
    // CONSIDER PADDINGS IN BUTTON
    &__btn{
        padding-left: 4px;
        padding-right: 4px;  
        text-transform: uppercase;  
    }
  
}

// @TODO
.color--accent{
    color: $normal-green;
}
.color--disabled{
    color: $gray-3;
}

.landing-mobile{
    &__img{
        width: 100%;
    }
}