@import '../../assets/style/partials/variables.scss';

.button-theme-purple{
    &._button{
        color: $white;
        background-color: $dark-magenta;
        box-shadow: 0 3px 0 $pompadour;
    }
    &.view--ghost{
        border: 1px solid $white;
    }
}