.terms{
  .tabContent-paragraph{
    font-size: 20px;
  }
  .tabContent-header{
    font-size: 36;
    font-weight: bold;
  }
  img{
    width: 300px;
    margin: 20px auto;
    display: block;
  }
  &__buy-orbs-wrapper{
    display: inline-block;
    height: 40px;
    width: 100px;
  }
  .become-streamer__button{
    display: inline-block!important;
    width: 120px!important;
    margin: 0;
    margin-left: 10px;
  }
}
.streamer-help{
  img{
    margin: 20px auto;
    display: block;
  }
}
.faqPage {
  background-color: $background;
  // background-image: url('/assets/images/main/redesign/background-dark1.png');
  background-size: 30%;
  min-height: calc(100vh - 285px);
  .about {
    background-color: transparent;
  }
  .aboutContent {
    padding-bottom: 0;
  }
}
.faqContainer {
  margin-top: -10px;
}

.faqMenuBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 105px;
}

.faqMenu {
  display: inline-block;
  width: 28%;
  box-sizing: border-box;
  height: fit-content;
  border-radius: 6px;
  border: solid 2px #e5ebf1;
  background-color: $footer-fill;

  .faqMenu-item {
    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      margin-top: 15px;
    }
    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      margin-bottom: 15px;
    }
    cursor: pointer;
    background-color: $footer-fill;
    display: block;
    &.activeItem {
			font-family: $ubuntu-bold;
			background-color: $active-faq;	
      background-color: $active-faq;
      a.activeItem {
				display: block;
				font-family: $ubuntu-bold;
				background-color: $active-faq;				
      }
    }
    &:hover {
      background-color: $active-faq;
      a {
        font-family: $ubuntu-bold;
      }
    }
    a {
      padding: 15px 0 15px 30px;
      display: block;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      font-family: $ubuntu-regular;
      font-size: 20px;
      letter-spacing: 0.19px;
    }
  }
}

.activeItem {
	display: block;
	font-family: $ubuntu-bold;
	background-color: $active-faq;
	box-sizing: border-box;		
}

.faqContent {
  width: 80%;
  .tabContent {
    display: block;
    &.activeTab {
      display: block;
    }
    padding-left: 40px;
  }
  .tabContent-block:first-child .tabContent-header {
    padding-top: 0;
  }
  .tabContent-header {
    font-family: $ubuntu-bold;
    color: $white!important;
    font-size: 34px;
    letter-spacing: 0.3px;
    padding-bottom: 28px;
    padding-top: 28px;
    .blockExpandButton {
      float: right;
      display: none;
    }
  }
  .tabContent-paragraph {
    font-family: $ubuntu-regular;
    font-size: 24px;
    letter-spacing: 0.3px;

    color: #FFFFFF!important;

    a {
      text-decoration: underline;
    }

    button {
      width: initial;
      height: initial;
      padding: initial;
      font-size: 24px;
      text-decoration: underline;
    }
  }
}
@media (min-width: 1550px) {
  .faqPage {
    min-height: calc(100vh - 251px);
  }
}

@media (max-width: 1024px) {
  .faqPage {
    padding: 0 30px;
    .aboutContent-header {
      padding: 50px 0 48px;
    } 
    .aboutContent {
      margin: auto;
    }
  }
  .faqMenu .faqMenu-item a {
    font-size: 14px;
  }
  .faqContent .tabContent-header {
    font-size: 16px;
    padding: 15px 0;
    font-family: ubuntu, arial, sans-serif;
    font-weight: normal;
    .blockExpandButton {
      display: block;
    }
  }
  .faqContent .tabContent-paragraph {
    font-size: 12px;
    padding: 0 0 15px;
  }
  .faqContent .tabContent-block {
    border-bottom: 2px solid white;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .faqPage {
    padding: 0 20px;
  }
  .faqMenu .faqMenu-item a {
    font-size: 14px;
  }
  .faqContent .tabContent-header {
    font-size: 16px;
  }
  .faqContent .tabContent-paragraph {
    font-size: 12px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .faqPage {
    padding-top: 24px;
  }
  .faqContainer {
    margin-top: 0;
  }
  .faqMenuBlock {
    display: block;
    padding-bottom: 40px;
  }
  .faqMenu {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  .faqContent {
    width: 100%;
  }
  .faqContent .tabContent {
    padding-left: 0;
  }
  .faqPage .aboutContent-header {
    display: none;
  }
  .faqMenu .faqMenu-item a {
    font-size: 18px;
  }
}