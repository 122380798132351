.referals{
    @include mq('phone-wide', max){
        padding-bottom: 15px;
    }
    a{
        &:hover{
            color: $gold-dark;
        }
    }
    table{
        background-color: $background-grey;
    }
    p, div{
        color: $white-dark;
    }
    &__list-container{
        padding: 15px;
        @include mq('phone-wide', max){
            padding: 0 15px;
        }
    }
    &__button{
        color: $white-dark;
        padding: 20px;
        cursor: pointer;
        opacity: 0.6;
        @include mq('phone-wide', max){
            padding: 10px 0;
        }
        &_active{
            opacity: 1;
        }
    }
    &__username{
        text-align: center;
        width: calc(50% / 2);
    }
    &__date{
        text-align: center;
        width: calc(50% / 2);
    }
    &__invite-container{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
        & > * {
            padding: 0 10%;
        }
        @include mq('phone-wide', max){
            padding: 15px;
            & > * {
                padding: 0; // Reset of paddingX: 10%
            }
        }
    }
    &__share-container{
        display: flex;
        justify-content: center;
    }
    &__switch{
        display: flex;
        width: 40%;
        justify-content: space-around;
        @include mq('phone-wide', max){
            width: calc(100% - 30px); // minus padding
            padding: 10px 15px;
            justify-content: space-between;
        }
        @include mq('phone', max){
            & > * {
                flex-basis: calc(100% / 3 - 20px);
            }
        }  
    }
   &__row{
    color: #f7f7f7;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 4px solid #484848;
    height: 80px;
   }
    &__link{
        width: 60%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include mq('phone-wide', max){
            width: 100%;
        }
    }
    &__link-input{
        display: block;
        width: 100%;
        height: 30px;
        background-color: $background-grey;
        border: 1px solid $background-dark-grey;
        outline: none;
        padding: 10px;
        color: $white-dark;
        margin-right: 15px;
        @include mq('phone-wide', max){
            flex-basis: 65%;
        }
        @include mq('phone', max){
            margin-right: 10px;      
        }
    }
    &__copy-button{
        display: block;
        width: 120px;
        height: 40px;
        cursor: pointer;
        background-color: green;
        border: none;
        color: $white-dark;
        border-radius: 5px;
        @include mq('phone', max){
            width: 85px;      
        } 
    }
    &__label{
        margin-bottom: 20px;
    }
    .fb-share-button{
        width: 300px;
        height: 30px;
    }
    &__fb{
        background-image: url('../images/main/main-social/fb.svg');
        width: 45px;
        height: 45px;
    }
    table{
        width: 100%;
    }
    &__header{
        color: #f7f7f7;
        height: 30px;
        display: -webkit-box;
        display: flex;
        justify-content: space-around;
        -webkit-box-align: center;
        align-items: center;
        tr{
            width: 100%;
            display: flex;
            justify-content: space-around;
        }
        th {
            width: calc(100% / 2);
            text-align: center;
            height: 100%;
            line-height: 30px;
            opacity: 0.5;
            font-size: 25px;
            font-weight: 300;
            cursor: pointer;
            @include mq('phone-wide', max){
                font-size: 10px;
            }
        }
    }
}