.social{
    display: flex;
    height: 30px;
    align-items: center;
    padding: 0 20px;
    &__button{
        width: 25px;
        height: 25px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 10px;
    }
    &__discord{
        background-image: url('../images/main/header/discord.png');
    }
    &__twitter{
        background-image: url('../images/main/header/twitter.png');
    }
    &__facebook{
        background-image: url('../images/main/header/facebook.png');
    }
    &__instagram{
        background-image: url('../images/main/header/instagram.png');
    }
}