.editor{
	position: relative;
	width: 100%;
	background-color: transparent;
  margin: 0 auto;
  overflow: auto;
  &__window{
    width: 100%;
    height: 600px;
  }
  &__key{
    width: 30%;
  }
  &__save{
    width: 20%;
    margin: 20px auto;
    text-align: center;
  }
	.key{
	position: sticky;
	top: 0px;
	right: 0px;
	width: 100px;
	height: 30px;
	margin-left: 90%;
	background-color: lightgrey;
	color: white;
	border: 1px solid #2094fa;
  }
  .row{
    display: flex;
    justify-content: space-between;
    margin: 1em;
  }
  .text-area{
    width: 70%;
  }
}
.RichEditor-root {
  background: transparent;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}