@import '../../../assets/style/partials/variables.scss';
.bq-card{
    max-width: 320px;
    min-width: 180px;
    &__badge{
        background-color: #8e0470;
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
        padding: 2px 16px 2px 22px;
        margin-left: -16px;
    }
}