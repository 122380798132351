.admin-stats {
  &__filter-wrap {
    border: 2px solid white;
    border-radius: 5px;
    padding: 5px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__filter-item-wrap {
    color: white;
    display: flex;
    flex-direction: column;
  }

  &__table-wrap {
    color: white;
  }

  &__table {
    width: 100%;
    border: 2px solid white;
    borderRadius: 5px;
  }
  
  &__table-item {
    border-right: 2px solid white;
    border-top: 2px solid white;
    padding: 4px;
  }

  &__table-item-last {
    border-top: 2px solid white;
    padding: 4px;
  }

  &__table-control-top {
    border-bottom: none;
    margin-bottom: none;
  }

  &__table-control-bottom {
    border-top: none;
    margin-bottom: none;
  }

  &__table-control-item {
    display: flex;
    flex-direction: row;
  }

  &__bank-title {
    font-weight: 900;
    background: black;
    border-radius: 5px;
    padding: 5px
  }

  &__pagination-wrap {
    display: flex
  }
  
  &__pagination-button {
    background: black;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;

    &:hover {
      cursor: pointer;
    }
  }
  
  &__current-page {
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    background: white;
    color: black;
  }

  &__pagination-field-wrap {
    width: 30px;
    display: flex;
    align-items: center;
    margin: 5px;
  }

  &__pagination-field {
    width: 30px;
  }

  &__search-button {
    padding: 10px;
    border: 1px solid white;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
    }
  }
}

