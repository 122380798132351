@import '../../../assets/style/partials/variables.scss';

.panel {
  position: relative;
  padding: 16px;
}

.panel-appearance {
  background-color: transparent;
  background-size: 4px;
  &_white {
    background-color: $background-dark-grey;
  }
} 
.panel-b-radius {
  &_default {
    border-radius: 16px;
  }
  &_top-left {
    border-radius: 16px 0 0 0;
  }
  &_top-right {
    border-radius: 0 16px 0 0;
  }
  &_top {
    border-radius: 16px 16px 0 0;
  }
  &_bottom-left {
    border-radius: 0 0 0 16px;
  }
  &_bottom-right {
    border-radius: 0 0 16px 0;
  }
  &_bottom {
    border-radius: 0 0 16px 16px;
  }
  &--none {
    border-radius: 0;
  }
}
