.about {
  padding: 0;
  margin: 0;
  background: transparent;
}
.about.aboutUs {
  min-height: calc(100vh - 285px);
  padding-bottom: 105px;
  box-sizing: border-box;
}

.aboutTeam {
  padding-top: 12px;
  background-color: black;
  width: 1060px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 70px;
}

.aboutTeamCol {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 33%;
  padding: 24px;
  box-sizing: border-box;
}

.aboutTeam-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.aboutTeamContent-name {
  font-size: 14px;
  font-family: $ubuntu-bold;
  text-transform: uppercase;
  color: $white;
  padding-bottom: 10px;
}

.aboutTeamContent-post {
  font-family: $ubuntu-regular;
  font-size: 16px;
  color: $white;
  opacity: 0.8;
}

.aboutContent {
  @include mq('phone-wide', max){
    padding: 80px 15px;
  }
  .tabContent-header {
    text-align: center;
    font-family: $ubuntu-bold;
    color: $white!important;
    font-size: 34px;
    letter-spacing: 0.3px;
    padding-bottom: 28px;
    padding-top: 28px;
    @include mq('phone', max){
      font-size: 24px;
      padding: 15px 0;
    }
    .blockExpandButton {
      float: right;
      display: none;
    }
  }
  .tabContent-paragraph {
    // text-align: center;
    text-align: justify;
    font-family: $ubuntu-regular;
    font-size: 24px;
    letter-spacing: 0.3px;
    padding-bottom: 0.5em;
    color: #FFFFFF!important;
    @include mq('phone-wide', max){
      font-size: 20px;
    }
    @include mq('phone', max){
      font-size: 16px;
    }
    a {
      text-decoration: underline;
    }
  
    button {
      width: initial;
      height: initial;
      padding: initial;
      font-size: 24px;
      text-decoration: underline;
    }
  }
  max-width: 800px;
  width: calc(100% - 192px);
  margin: 0 auto;
  padding-bottom: 80px;
  &:after {
    display: block;
    content: '';
    height: 2px;
    background-color: $blue;
    width: 200px;
    margin: 0 auto;
  }
}
.aboutContent.aboutContent_faq {
  &:after {
    display: none;
  }
}

.aboutContentDescription {
  width: 100%;
}

.aboutContent-header {
  font-family: $ubuntu-bold;
  font-size: 64px;
  letter-spacing: 0.91px;
  text-transform: uppercase;
  color: $white;
  text-align: center;
  padding: 105px 0 36px 0;
}

.aboutContent-description {
  font-family: $ubuntu-regular;
  color: $white;
  padding-bottom: 24px;
  font-size: 24px;
  line-height: 1.4;
  text-align: left;
}
@media (min-width: 1550px) {
  .about.aboutUs {
    min-height: calc(100vh - 251px);
  }
}




@media (min-width: 768px) and (max-width: 1024px),
  (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  //CSS
  .aboutContent-header {
    font-size: 32px;
  }
  .aboutContent-description {
    font-size: 20px;
    text-align: center;
  }
  .aboutContent-header {
    padding: 70px 0 30px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  //CSS
  .aboutContent {
    width: calc(100% - 40px);
  }
  .aboutContent-header {
    font-size: 20px;
  }
  .aboutContent-description {
    font-size: 14px;
    text-align: center;
  }
  .aboutContent-header {
    padding: 30px 0 20px;
  }
  .about.aboutUs {
    padding-bottom: 0;
  }

  .about {
    width: 100%;
    overflow-x: scroll;
  }
  .about > div {
    width: 1200px;
  }
  .activity-admin__toggler {
    display: none;
  }
  // .admin__sidebar {
  //   display: none;
  // }
  // .admin__content {
  //   position: relative;
  // }
  // .admin__sidebar-mob {
  //   display: block;
  // }
}