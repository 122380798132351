.profile-main{
    &__tasks-feed{
        flex-basis: 375px; 
    }
    &__news-feed{
        flex-grow: 1;
        
    }
}


.news-feed{
    background: rosybrown;
}