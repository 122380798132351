$flag-icons: ('en', 'ru', 'kr', 'ch', 'es');

.b-flag {
  width: 25px;
  height: 20px;
  display: inline-block;
  background: {
    position: 50% 50%;
    repeat: no-repeat;
    color: transparent;
    size: 100%;
  }
  vertical-align: middle;
  font-style: normal;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  margin-right: 0.4rem;

  @each $flag in $flag-icons {
    &_#{$flag} {
      background-image: url('../images/flags/' +$flag+ '.svg');
    }
  }
}
