.transactions{
    background-color: $background-dark-grey;
    &__table{
        padding: 43px;
        @include mq('phone-wide', max){
            padding: 0 15px;
        }
    }
    table{
        width: 100%;
    }
    &__header{
        color: $white-dark;
        height: 30px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        tr{
            width: 100%;
            display: flex;
            justify-content: space-around;
        }
        th {
            width: calc(100% / 5);
            text-align: center;
            height: 100%;
            line-height: 30px;
            opacity: 0.5;
            font-size: 25px;
            font-weight: 300;
            cursor: pointer;
            @include mq('phone-wide', max){
                font-size: 10px;
            }
        }
        th:first-child(){
            width: 50px;
        }
    }
    &__circle{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #2094fa;
        &.gold{
            background-image: linear-gradient($gold-light, $gold-dark);
        }
    }
    &__label{
        color: $white-dark;
        line-height: 30px;
        display: flex;
        align-items: center;
    }
    &__checkbox{
        position: static!important;
        margin-left: 10px;
    }
    &__row{
        color: $white-dark;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-bottom: 4px solid #484848;
        height: 80px;
        td {
            width: calc(100% / 5);
            text-align: center;
            @include mq('phone-wide', max){
                font-size: 10px;
                padding: 5px;
            }
        }
      td:first-child(){
          width: 50px;
      }
    }
   
    &__row{
        background-color: $background-dark-grey;
    }
    &__row:nth-child(2n){
            // background-color: $background-grey;
        }
   
}