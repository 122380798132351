@import '../../assets/style/partials/variables.scss';

.header-mobile{
    // @TODO: make defaults!
    position: relative;
    background-color: $dark-magenta;
    // @TODO pay attention uncontrolled opacities
    border-bottom: 3px solid  rgba(255, 255, 255, 0.19);
    &__auth{
        >*:not(:last-child){
            margin-right: 2rem;
        }
    }
}

.header-desktop{
    max-height: 104px;
}

.navigation-desktop{
    a{
        margin-right: 3rem;
    }
}

.hamburger{
    width: 25px;
    height: 15px;
    &__line{
        height: 3px;
        width: 100%;
        background-color: $white;
    }
}

.menu-dropdown{
    li, a{
        cursor: pointer;
    }
    width: 100vw;
    height: 100vh;
}

.header-menu{
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    width: 450px;
}