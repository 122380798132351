



.modalDesc {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

}

.modalImgOverlay {
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  // background-color: black
}

.modalDesc-content {
  position: absolute;
  top: 0;
  left: 40px;
  right: 35px;
  .formLogo {
    padding-top: 80px;
    height: 30px;
    width: auto;
    padding-bottom: 30px;
  }
  h3 {
    font-family: $ubuntu-bold;
    font-size: 36px;
    letter-spacing: 0.1px;
    text-align: left;
    color: #f2f4ff;
    padding-bottom: 20px;
  }
  p {
    font-family: $ubuntu-regular;
    font-size: 24px;
    letter-spacing: 0.1px;
    text-align: left;
    color: #f2f4ff;
  }
}

.modalDesc-img, .modalDesc-form {
  border: 1px solid #f2f4ff;
  width: 50%;
  box-sizing: border-box;
  @include mq('phone-wide', max){
    width: 100%;
    padding-top: 20px;
  }
}

.modalDesc-img {
  position: relative;
  .modalDesc-back {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.custom-modal .closeButton {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 24px;
  right: 24px;
  border: none;
  cursor: pointer;
  z-index: 9999;
}


footer {
  background-color: $main-footer-fill;
  color: white;
  padding: 16px;
}

@media (max-width: 1550px) {
  footer {
    padding-bottom: 20px;
  }
}

.footerContent {
  display: flex;
  justify-content: space-around;
  align-items: start;
  &.footerContent_additional {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .footer-content {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
}

.predictoria {
  margin: 0;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.57px;
}

.socialText {
  font-size: 14px;
  letter-spacing: 0.228571px;
  color: $white;
  mix-blend-mode: normal;
  opacity: 0.8;
  padding: 5px 0;
}

.footer-social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  a {
    cursor: pointer;
    display: flex;
  }
  .socialImg {
    display: block;
    height: 40px;
    width: auto;
    padding: 5px;
  }
}

.contact-us {
  padding-top: 32px;
  opacity: 0.5;
}

.footer-social_additional {
  display: flex;
  .socialImg {
    padding-right: 18px;
    padding-left: 18px;
    padding-top: 20px;
    height: 20px;
    width: auto;
  }
}

.modalForm-links {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  button {
    border: 0;
    color: white;
    outline: 0;
    display: block;
    height: 100%;
    width: 100%;
    background-color: transparent;
    font-size: 20px;
    font-family: $ubuntu-bold;
    padding: 20px;
    text-align: center;
    // transition: opacity 0.3s ease-in;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.5;
    cursor: pointer;
    &.activeFormLink {
      background-color: transparent;
      opacity: 1;
    }
    &:hover {
      background-color: transparent;
      opacity: 1;
    }
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;

  }
}

.formTab {
  display: none;
  flex-direction: column;

  &.formTab_active {
    display: flex;
  }
}



//FORM MODAL

.formContainerForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.inputBlock {
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  .checkBox {
    display: flex;
    width: 340px;
    margin: 0 auto;
  }
  .authInput {

    box-sizing: border-box;
    outline: none;
    padding: 20px 61px;
    display: block;
    -webkit-appearance: none;
    background-color: $input-back;
    border: 2px solid $input-border;
    border-radius: 30px;
    font-family: $ubuntu-regular;
    font-size: 13px;
    letter-spacing: 0.2px;
    text-align: left;
    color: #ffffff;
    opacity: 0.6;
    width: 360px;
    margin: 0 auto 10px auto;
    //transition: all 0.3s ease-in;
    &.authInput_modal {
      width: 450px;
      margin: 20px auto;
    }
    &.authSendInput_modal {
      width: 200px;
      font-size: 12px;
      margin-top: 20px;
    }
    &.authInput_error {
      transition: all 0.3s ease-in;
      background-color: $losedark;
      border: 2px solid $lose;
    }
    &:active {
      opacity: 1;
    }
    &:focus {
      opacity: 1;
    }
  }
}

.inputBlock-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-75%);
  left: 20px;
}

.inputBlock-img {
  display: block;
  height: auto;
  width: auto;
}

.authInput[type="submit"] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 60px;
  padding: 0;
  cursor: pointer;
  background-color: $blue;
  margin-top: 30px;
  border: $blue;
  opacity: 1;
  text-align: center;
  font-family: $ubuntu-bold;
  font-size: 20px;
  height: 60px;
  transition: background-color 0.3s ease-in;

  &:hover {
    transition: background-color 0.3s ease-in;
    background-color: $blue-lighter;
  }
}


// /* Base for label styling */
// [type="checkbox"]:not(:checked),
// [type="checkbox"]:checked {
//   position: absolute;
//   outline: none;
//   left: -9999px;
// }
// [type="checkbox"]:not(:checked) + label,
// [type="checkbox"]:checked + label {
//   position: relative;
//   padding-top: 5px;
//   padding-left: 30px;
//   font-size: 13px;
//   outline: none;
//   cursor: pointer;
//   font-family: $ubuntu-regular;
//   color: white;
//   opacity: 0.5;
//   strong {
//     color: $blue;
//     opacity: 1;
//   }
// }

// /* checkbox aspect */
// [type="checkbox"]:not(:checked) + label:before,
// [type="checkbox"]:checked + label:before {
//   content: '';
//   outline: none;
//   position: absolute;
//   left: 0; 
//   top: 9px;
//   width: 20px;
//   height: 20px;
//   background-color: rgba(0, 0, 0, 0.3);
//   border: solid 2px rgba(255, 255, 255, 0.6);
//   border-radius: 6px;
//   box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
// }
// /* checked mark aspect */
// [type="checkbox"]:not(:checked) + label:after,
// [type="checkbox"]:checked + label:after {
//   content: '✔';
//   outline: none;
//   position: absolute;
//   top: .9em;
//   left: .4em;
//   font-size: 13px;
//   background-color: rgba(0, 0, 0, 0.3);
//   //border: solid 2px rgba(255, 255, 255, 0.6);
//   opacity: 0.5;
//   //transition: all .2s;
//   font-family: $ubuntu-regular;
// }
// /* checked mark aspect changes */
// [type="checkbox"]:not(:checked) + label:after {
//   opacity: 0;
//   transform: scale(0);
// }
// [type="checkbox"]:checked + label:after {
//   opacity: 1;
//   transform: scale(1);
// }
// /* disabled checkbox */
// [type="checkbox"]:disabled:not(:checked) + label:before,
// [type="checkbox"]:disabled:checked + label:before {
//   box-shadow: none;
//   border-color: #bbb;
//   background-color: #ddd;
// }
// [type="checkbox"]:disabled:checked + label:after {
//   color: #999;
// }
// [type="checkbox"]:disabled + label {
//   color: #aaa;
// }
// /* accessibility */
// [type="checkbox"]:checked:focus + label:before,
// [type="checkbox"]:not(:checked):focus + label:before {
//   border: 2px solid rgba(255, 255, 255, 0.6);
// }

/* hover style just for information */
// label:hover:before {
//   border: 2px solid rgba(255, 255, 255, 0.6) !important;
// }

.socialContainer-desc {
  display: block;
  font-family: $ubuntu-regular;
  font-size: 13px;
  letter-spacing: 0.2px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.socialContainer {
  width: 100%;
  margin-bottom: 40px;
}

.socialContainerList {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .socialContainer-img {
    padding: 5px;
    display: block;
    height: 45px;
    width: auto;
    border-radius: 10px;
    transition: filter 0.2s ease;
    opacity: 0.8;
    &:hover {
      // transition: filter 0.3s ease-in;
      // filter: brightness(80%);
      opacity: 1;
    }
  }
}

.forgotPass {
  padding-bottom: 20px;
  display: block;
  margin: 0 auto;
  font-size: 10px;
  letter-spacing: 0.1px;
  text-align: center;
}

#in .inputBlock.submit {
  margin-top: 40px;
}
#up .socialContainer-desc {
  margin-top: 31px;
}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px //812px iphone X //823px pixel 2x l
*/

@media (min-width: 481px) and (max-width: 823px), (min-width: 320px) and (max-width: 480px) {
  .inputBlock .authInput {
    width: calc(100vw - 44px);
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  //CSS
  .registerBlock {
    .custom-modal {
      top: 50%;
      height: 100%;

      &.modal-animate {
        animation: none;
      }

      .modal-content {
        height: initial;
      }
    }
  }
  .aboutContent-header {
    font-size: 20px;
  }
  .aboutContent-description {
    font-size: 14px;
  }
  footer {
    padding: 24px 30px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  //CSS

  .predictoria {
    font-size: 16px;
  }
  .socialText {
    font-size: 12px;
  }
  .aboutContent {
    width: calc(100% - 40px);
  }
  .aboutContent-header {
    padding: 30px 0 20px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  header .registerBlock .custom-modal {
    width: calc(100% - 40px);
    top: 20px;
  }
  .footerContent {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
  .footer-social,
  .footer-content {
    margin: auto;
  }
  .inputBlock {
    width: 100%;
  }
  .inputBlock .checkBox {
    width: calc(100% - 20px);
    margin: auto;
  }
  .inputBlock .authInput {
    padding: 13px 25px 13px 50px;
    width: calc(100% - 20px);
    font-size: 12px;
  }
  .authInput[type="submit"] {
    height: 44px;
    line-height: 44px;
    font-size: 12px;
    padding: 0;
    margin-top: 54px;
  }
  #up .authInput[type="submit"] {
    margin-top: 57px;
  }
  .authInput[name="referal"] {
    font-size: 10px;
  }
  .socialContainer-desc {
    margin-top: 0;
    font-size: 10px;
    margin-bottom: 5px;
  }
  #up .socialContainer-desc {
    margin-top: 0;
  }
  .socialContainer {
    margin-bottom: 20px;
  }
  .forgotPass {
    padding-bottom: 11px;
    font-size: 12px;
  }
  .inputBlock-icon {
    left: 35px;
  }
  header .registerBlock .custom-modal.modal-animate {
    height: auto;
  }
  .custom-modal .closeButton {
    display: block;
  }
}

.footer{
  @include mq('phone-wide', max){
    padding: 30px 15px;
  }
  &__links{
    display: flex;
    justify-content: center;
    @include mq('phone-wide', max){
      flex-wrap: wrap;
    }
  }
  &__col{
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    max-width: calc(100% /6);
    min-width: 150px;
    @include mq('phone-wide', max){
      margin-bottom: 30px;
      max-width: 100%;
      padding: 0;
    }
    @include mq('phone', max){
      padding: 0 10px;
    }
  }
  &__col-body{
    display: flex;
    flex-direction: column;
    // text-align: center;
    a{
      padding: 5px 0px;
      &:hover{
        color: #ff8008;
      }
    }
  }
  &__label{
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.57px;
    padding-bottom: 5px;
  }
  &__col-head{
    // text-align: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.57px;
    padding-bottom: 5px;
  }
}