.icon{
    cursor: pointer;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-sizes{
    &--default{
        width: 17px;
        height: 17px;
    }
    &--medium{
        width: 30px;
        height: 30px;
    }
}

.icon-names{
    &--normal-orb{
        background-image: url('./resources/icon-normal-orb.svg');
    }
    &--normal-cube{
        background-image: url('./resources/icon-normal-cube.svg');
    }
    &--medium-steam-grey{
        background-image: url('./resources/icon-medium-steam-grey.svg');
    }
    &--medium-twitch-grey{
        background-image: url('./resources/icon-medium-twitch-grey.svg');
    }
    &--medium-fb-grey{
        background-image: url('./resources/icon-medium-fb-grey.svg');
    }
    &--medium-discord-grey{
        background-image: url('./resources/icon-medium-discord-grey.svg');
    }
}