.adm-new-challenge-container {
  flex-wrap: wrap;
  justify-content: center;
}

.adm-new-challenge {
  width: calc(100% - 2rem);
  height: auto;
  // margin: 15px; // Debug
}

.adm-new-challenge__option-group {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;

  input {
    margin-top: -3px;
    padding-bottom: 0;
  }
}

.adm-new-challenge__input-group {
  flex-basis: 45%;
}

.adm-new-challenge__orbs {
  margin: 0 3px;

  &::before {
    content: ' ';
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 3px;
    margin-bottom: -2px;
    background-size: 100% 100%;
  }
}

.adm-new-challenge__orbs {
  &::before {
    background-image: url('/assets/images/main/redesign/orb.png');
  }
}

.adm-new-challenge__icon {
  width: 15px;
  min-width: 15px;
  height: 15px;
}

.adm-new-challenge__input-time {
  background-color: $accent;
  background-size: 100% 100%;
}

.adm-new-challenge__input-orb {
  background-image: url('/assets/images/main/redesign/orb.png');
  background-size: 100% 100%;
}

.adm-new-challenge__content {
  p {
    margin-top: 15px;
  }
}

// Temp
.adm-new-challenge-state2 {
  .adm-new-challenge__btn--status {
    display: flex;
    justify-content: center;

    span {
      padding: 0 3px;
    }
  }
}

.adm-new-challenge {
  &__input-time-container {
    border-bottom: 3px solid #ffad25;
    opacity: 0.5;
    display: flex;
    align-items: flex-end;
    transition: opacity 0.5s ease-in;

    &:focus-within {
      opacity: 1;
    }
  }

  &__current-balance {
    height: 25px;
    text-align: center;
    line-height: 25px;
    font-size: 14px;
    border: 3px solid #ffad25;
    border-radius: 25px;
    display: flex;
    justify-content: space-around;
  }
}

.bank-input {
  border: none;
  width: 30%;
}

.time-input {
  border: none;
  padding: 0px 2px;

  &_h {
    text-align: right;
  }

  &_m {
    text-align: left;
  }
}

.adm-new-challenge__head-input-group{
  position: relative;
  display: flex;
  width: 45%;
}

.adm-new-challenge__time{
  display: flex;
  align-items: flex-end;
}