.gold{
    .profilePage{
        background-image: url(../images/main/redesign/background-dark1.png); 
    }
    .main__container_wide{
        background-color: transparent;
    }
    .container{
        background-color: transparent;
    }
    .twitchSlider{
        background-image: url(../images/main/redesign/background-dark1.png); 
    }
    .help-center__main-content{
        background-image: url(../images/main/redesign/background-dark1.png); 
    }
    .help-center .category-view{
        background-image: url(../images/main/redesign/background-dark1.png); 
    }
    .help-center__article{
        background-image: url(../images/main/redesign/background-dark1.png); 
    }
    .help-center__article *{
        background-color: transparent!important;
    }
    .help-center__category-icon{
        background-color: rgba(1, 1, 1, 0.5);
    }
}

.default{
    .profilePage{
        background-image: none;
    }
}
