.containerWrapper {

   
  
 &_absolute{
   top: 0px;
   width: 100%;
 }
}

.leaderboard {
  .b-dropdown__wrapper{
    top: 20px;
    left: -10px;
    background-color: transparent;
    width: 120px;
    display: flex;
    align-items: center;
  }
  .b-dropdown__content{
    background-color: transparent;
    align-items: center;
    width: 120px;

  }
  .b-dropdown__arrow svg{
    visibility: hidden!important;
  }
  .b-dropdown__trigger{
    width: 150px;
  }
  &__avatar{
    position: relative;
    top: 15px;
    width: 40px;
    height: 40px;
    border: 3px solid white;
    border-radius: 50%;
    margin: 0 20px;
    @include mq('phone-wide', max){
      // width: 25px;
      // height: 25px;
      // margin: 0 10px;
      display: none;
    }
  }
  .react-gravatar{
    position: relative;
    top: 15px;
    width: 50px;
    height: 50px;
    border: 3px solid white;
    border-radius: 50%;
    margin: 0 20px;
  }
  &__table{
    padding: 40px;
    background-color: #1b1b1b;
  }
  &__head
  {
    background-color: #1b1b1b;
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: lighter;
    line-height: 0.96;
    letter-spacing: normal;
    text-align: center;
    color: #cccaca;
      th{
        height: 40px;
        text-align: left;
      }
    
  }
  &__body{
    background-color: #1b1b1b;
    tr{
      
    td, th{
        font-family: Ubuntu;
        font-size: 25px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 80px;
        letter-spacing: normal;
        color: #f7f7f7;
        text-align: left;
        border-bottom: 3px solid #484848;
        text-align: left;
        vertical-align: center;
    }
    
  }
  tr:last-child{
    td {
      border: none;
    }
  }
}
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
  
   &__section{
      background:linear-gradient(to bottom, rgba(27,27,27,0) 20%, rgb(27, 27, 27) 90%), url('../images/main/leaderboard/pirate-dark-min.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 280px 80px;
      padding-bottom: 120px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      @include mq('phone-wide', max){
        padding: 80px 20px 0px;
        background-size: cover;
        flex-direction: column;
        align-items: center;
        height: 180px;
      }
    }
  &__header{
    width: 100%;
  }
  &__page-title{
    font-family: Ubuntu;
    font-size: 84px;
    font-weight: bold;
    letter-spacing: normal;
    text-align: left;
    color: #f7f7f7;
    @include mq('phone-wide', max){
      font-size: 40px;
      text-align: center;
    }
  }
  &__page-desc{
    font-family: Ubuntu;
    font-size: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #f7f7f7;
    @include mq('phone-wide', max){
      text-align: center;
    }
  }
  &__trigger{
    display: block;
    width: 150px;
    font-family: Ubuntu;
    font-size: 34px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.71;
    letter-spacing: normal;
    text-align: left;
    color: #f7f7f7;
    @include mq('phone-wide', max){
      text-align: center;
    }
  }
  &__table{
    @include mq('phone-wide', max){
      padding: 0 15px;
    }
  }
  &__trigger::after{
    display: block;
    position: absolute;
    width: 100%;
    border-bottom: 4px solid gold;
    margin: 10px 0 5px 0;
    content: " ";
    @include mq('phone-wide', max){
      display: none;
    }
  }
  &__option{
    cursor: pointer;
    width: 120px;
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.71;
    letter-spacing: normal;
    text-align: left;
    color: #d1cdcd;
    padding: 10px 0;
    @include mq('phone-wide', max){
      text-align: center;
    }
  }
    .leaderboardUsers {
        width: 100%;
        margin-right: 20px;

        table {
            width: 100%;
            thead {
            }

            tbody {
            } 
            .userPosition {
                width: 40px;
                height: 40px;
                display: block;
                margin: 0 auto;
                line-height: 40px;
                border-radius: 20px;
                background-color: rgba(255, 255, 255, 0.1);
                text-align: center;
            }
            .userName {
              text-align: left;
              line-height: 70px;
              margin-left: 15px;
              display: inline-block;
            }
        }
    }

    .sortByGames {
        width: 442px;

        .gameSelector {
          button {
            position: relative;

            margin-right: 10px;
            margin-bottom: 10px;
            outline: none;
            cursor: pointer;
            border: 0;

            font-family: $ubuntu-regular;
            font-size: 24px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.3px;
            text-align: center;

            &:nth-child(3n) { 
              margin-right: 0px;
            }
            .hover {
              position: absolute;
              top:0;
              left: 0;
              z-index: 2;
              width: 100%;
              height: 100%;
              opacity: 0.5;
              background: black;
            }

            -webkit-transition: .3s;
            transition: opacity .3s;

            &.active .hover, &:hover .hover {
              opacity: 0;
            }

            span {
              position: absolute;
              top: 110px;
              left: 0;
              z-index: 1;
              width: 100%;

              font-family: $ubuntu-regular;
              font-size: 20px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 0.3px;
              text-align: center;
              color: #ffffff;
              
              &.hidden {
                display: none;
              }
            }

            img {
              width: 134px;
              height: 178px;
              float: left;
            }
          }
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape),
  (min-width: 768px) and (max-width: 1024px) {
    .leaderboard {
      padding-left: 30px;
      padding-right: 30px;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
  .containerWrapper {
    // padding: 20px;
  }
  .leaderboard {
    // flex-direction: column-reverse;
    .predictionParagraph{
      font-size: 20px;                                                                                      
    }
  }
  .leaderboard .leaderboardUsers table thead th {
    font-size: 10px;
  }
  .leaderboard .leaderboardUsers table td {
    font-size: 14px;
    padding: 15px 5px;
    text-align: center;
  }
  .leaderboard .sortByGames {
    width: 100%;
  }
  .leaderboard .leaderboardUsers {
    width: 100%;
    margin-right: 0;
  }
  .leaderboard .leaderboardUsers table .userName {
    line-height: 14px;
    margin-left: 0;
  }
  .leaderboard .leaderboardUsers table .userPosition {
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
}