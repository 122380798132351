$accent: #ffad25;
$danger: #d40707;
$blue: #4492ee;
$shadow: #f7f7f7;

.adm-new-controls-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.adm-new-control-card {
  width: calc(100% / 4 - 55px); // Debug
  border: 5px solid rgba($color: $accent, $alpha: 0.25);
  color: $white-dark;
  margin: 0 0.5rem 1rem 0.5rem;

  &:hover {
    .adm-new-control-card__actions {
      display: block !important;
    }
  }

  &--active {
    border: 5px solid rgba($color: $accent, $alpha: 1);
  }

  &__desc {
    margin: 20px 0;
    word-break: break-all;
  }

  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }

  &__option {}

  &__close {
    cursor: pointer;

    &::before,
    &::after {
      background-color: $accent;
    }
  }

  &__content {
    font-size: 13px;

    &>input:last-child {
      margin-top: 5px;
    }

    &>textarea:focus {
      outline: none;
      opacity: 1;
    }
  }

  &__description {
    width: 100%;
    margin-top: 10px;
    border: none;
    resize: none;
    color: #fff;
    background-color: transparent;
    margin-bottom: 10px;
    border-bottom: 3px solid $accent;
    opacity: 0.5;
    transition: opacity 0.3s ease-in;
    overflow: hidden;
  }

  &__input-group {
    display: flex;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;

    i {
      width: 15px;
      min-width: 15px;
      height: 15px;
      margin-right: 5px;
      cursor: pointer;
    }
  }

  &__input {
    width: 100%;
    padding: 5px 0px;
    color: #fff;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid $accent;
    opacity: 0.5;
    transition: opacity 0.3s ease-in;
    margin: 0 !important;

    &:focus {
      outline: none;
      opacity: 1;
    }
  }

  &__actions {
    margin-top: 15px;
    display: none;
  }

  &__btn-group {
    display: flex;
    justify-content: space-between;

    &:last-child {
      margin-top: 15px;
    }

    &>button:last-child {
      margin-top: 0;
    }
  }

  &__btn {
    display: block;
    width: 100%;
    padding: 5px;
    color: #fff;
    font-weight: 600;
    border: none;
    outline: none;
    text-align: center;
    border-radius: 25px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 10px $shadow, 0 0 10px $shadow;
    }

    &:last-child {
      margin-top: 15px;
    }

    &--accent {
      background-color: $accent;
      border: 2px solid $accent;
    }

    &--blue {
      background-color: $blue;
      border: 2px solid $blue;
    }

    &--create {
      margin-top: -5px;
    }

    &--red {
      background-color: $danger;
      border: 2px solid $danger;
    }

    &--bordered {
      background-color: transparent;
    }
  }
}

// Close button with pure CSS. Source: https://codepen.io/brissmyr/pen/egidw
.purecss-close {
  width: 12px;
  height: 12px;
  position: relative;
  //opacity: 0.3;
}

.purecss-close:hover {
  //opacity: 1;
}

.purecss-close:before,
.purecss-close:after {
  content: ' ';
  position: absolute;
  right: 0.4em;
  height: 12px;
  width: 1.7px;
}

.purecss-close:before {
  transform: rotate(45deg);
}

.purecss-close:after {
  transform: rotate(-45deg);
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}