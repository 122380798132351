@import '../../assets/style/partials/variables.scss';

.button-theme-white{
    &._button{
        color: $dark-magenta;
        background-color: $white;
        box-shadow:  0 3px 0 $fuchsia-pink;
    }
    &.view--ghost{
        border: 1px solid $white;
        background: none;
    }
}