.profile-stream{
  width: 80%;
  padding: 20px 0;
  margin: 0px auto;
    @include mq('phone-wide', max){
      width: 100%;
    }
}

.profileContainer {
  width: 720px;
  margin: 0 auto;
}

.profilePage {
  min-height: calc(100vh - 285px);
  box-sizing: border-box;
  background-color: $background;
  padding: 70px 0 40px 0;
  // overflow: auto;
}

.profilePageBlock {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  &_wide {
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }
}

.overlayEditPhoto {
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-family: $ubuntu-regular;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-align: left;
    color: #ffffff;
  }
}

.profilePageBlock-avatar {
  cursor: pointer;
  margin-right: 50px;
  position: relative;
  overflow: hidden;
  height: 160px;
  width: 160px;
  border-radius: 50%;
  border: solid 2px #f2f4ff;
  transition: all 0.3s ease;
  box-sizing: border-box;
  &:hover {
    border: 2px solid #46474F;
    .overlayEditPhoto {
      transition: all 0.3s ease;
      opacity: 1;
      visibility: visible;
      top: -1px;
      left: -1px;
      width: 101%;
      height: 101%;
    }
  }
  img {
    position: absolute;
    top: -9999px;
    right: -9999px;
    bottom: -9999px;
    left: -9999px;
    margin: auto;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}



.profilePageInputBlock {
  display: flex;
  flex-direction: column;
  &:first-child {
    .profilePageInput-label {
      margin-top: 0;
    }
  }
}

.profilePageInput-label {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-family: $ubuntu-regular;
  font-size: 14px;
  letter-spacing: 0.2px;
  text-align: left;
  color: #ffffff;
}

.profileInput {
  font-family: $ubuntu-regular;
  margin-top: 10px;
  -webkit-appearance: none;
  width: 425px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: $input-back;
  border: solid 2px $input-border;
  padding: 12px;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  color: $white;
  transition: all .3s ease;
  &_narrow {
    width: 300px;
  }
  &_pass {
    opacity: 0.6;
  }
  &:hover {
    transition: all .3s ease;
    border: solid 2px white;
  }
}
select.profileInput.profileInput_narrow {
  background: url('//predictoria.com/assets/favicon-white.png') 95% / 10% no-repeat #222224;
}

.connectedServicesSocial {
  display: flex;
 @include mq('phone-wide', max){
  flex-direction: column;
  height: 120px;
  justify-content: space-around;
  align-items: center;
 }
}

.connectedServices-title {
  padding-top: 20px;
  padding-bottom: 5px;
  font-family: $ubuntu-regular;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: left;
  color: #ffffff;
}

.depositMenuList-desc {
  padding: 0 19px;
}

.connectedServicesSocial-img {
    width: 45px;
    height: 45px;
    margin-right: 10px;
    border-radius: 6px;
    cursor: pointer;
}
.last {
  margin-right: 0px;
}

.pre-btn {
  cursor: pointer;
  outline: none;
  margin-top: 20px;
  border: none;
  border-radius: 20px;
  background-color: $win;
  transition: all .3s ease;
  margin-bottom: 20px;
  &:hover{
    box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
  }
  &.pre-btn_gold{
    background-image: linear-gradient( rgb(255, 196, 0), rgb(245, 133, 28));
  }
  &.pre-btn_red {
    background-color: $lose;
    &:hover {
      background-color: $red;
    }
  }

  &.pre-btn_blue {
    background-color: $blue;
    &:hover {
      background-color: rgba(32, 148, 250, 0.8);
    }
  }
  &.pre-btn_promo {
    width: 70px;
    border: 2px solid $blue;
    background-color: $blue-fill;
    text-align: center;
    cursor: text;
    box-sizing: unset;
    color: $white-dark;
    &:hover {
      background-color: rgba(5, 25, 43, 0.8);
    }
  }
  &.pre-btn_modal {
    text-align: center;
  }
  &.disabled {
    background-color: rgb(90, 90, 90);
    cursor: default;
    color: gray;
    &:hover {
      background-color: rgb(90, 90, 90);
    }
  }
  &:active {
    transition: all .3s ease;
    //background-color: rgba(4, 222, 113, 0.8);
  }
  &:hover {
    transition: all .3s ease;
    background-color: rgba(4, 222, 113, 0.8);

  }
}
@media (min-width: 1550px) {
  .profilePage {
    min-height: calc(100vh - 251px);
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .profile-page{
    padding-top: 80px;
  }
  .profilePageBlock-avatar {
    height: 140px;
    width: 140px;
  }
  .profileContainer {
    width: 100%;
  }
  .profileInput {
    width: 100%;
    font-size: 14px;
    margin-top: 7px;
    padding: 10px 12px;
  }
  .profilePageInput-label {
    font-size: 12px;
    margin-top: 15px;
  }
  .connectedServices-title {
    font-size: 12px;
  }
  .profilePageBlock {
    display: block;
    //padding-top: 30px;
  }
  .profilePage {
    padding: 30px 15px 0px 15px;
  }
  .profilePageBlock-avatar {
    margin: 0 auto 25px;
  }
  .addServices .pre-btn.pre-btn_blue {
    // width: 100%;
    margin: 20px auto;
  }
}