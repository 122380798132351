@import '../../assets/style/partials/variables.scss';
._form-field{
    height: 39px;
    padding: 10px 22px 10px 10px;
    font-size: 14px;
}

.input-views{
    &--ghost{
        background: none;
        border-bottom: 1px solid $gray-77;
        color: $gray-77;
        &::placeholder{
            color: $gray-77;
        }
    }
    &--default{
        background: rgba(0, 0, 0, 0.35);
        border-radius: 10px;
        &::placeholder{
            color: $gray-77;
        }
    }
    // this is stupidity. reduce varinants of inputs!!!!!!!! 
    &--grey{
        background: rgba(196, 196, 196, 0.25);
        border-radius: 5px;
        height: 25px;
        padding: 4px 7px;
    }
    // copypasted from figma. 
    // COMPONENT INPUTS NEEDS REVVIES WITH  DESIGNER. TOO MENY VARIANTS. UNCONTROLLED PARAMS
    &--pink{
        height: 28px;
        background: #FFFFFF;
        border: 1px solid #8E0470;
        border-radius: 5px;
        padding: 4px 7px;
    }
}