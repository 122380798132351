.custom-modal {
    transition: all 2s ease;
    z-index: 5;
  }
  
  .overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow-y: hidden;
  }
  
  .modal-animate {
    animation-name: modalSlideIn;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  }
  
  .modal-disable {
    animation: modalSlideOut 0.3s forwards;
  }
  
  @keyframes modalSlideIn {
    from {transform: translateY(-200%); opacity: 0;}
    to {transform: translateY(0%); opacity: 1;}
  }
  
  @keyframes modalSlideOut {
    from {transform: translateY(0%); opacity: 1;}
    to {transform: translateY(-200%); opacity: 0;}
  }