.profilePageForm{
    width: 100%;
    input{
        width: 100%;
        font-size: 16px;
    }
}
.profileContainer{
    width: 80%;
    @include mq('phone-wide', max){
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
.profilePage{
    &__save{
        display: flex;
    }
    &__become-streamer{
        width: 140px;
        margin: 0;
        margin-left: 20px;
    }
    &__change-lang{
        padding: 20px 0;
    }
    &__cashbox{
        padding: 20px 0px 0px;
    }
    &__ask-partner{
        padding: 20px 0;
    }
    &__allow-donation{
        display: inline;
        width: 18px!important;
        height: 18px!important;
        margin-left: 10px;
        position: relative!important;
        top: 2px;
        left: 0!important;
    }
    &__cashbox-button-group{
        display: flex;
    }
    &__cashbox-button{
        margin: 10px 0px;
        width: 100px;
        display: inline-block;
        margin-right: 10px;
        font-size: 16px;
        padding: 0 5px;
        color: #f7f7f7;
        font-family: Ubuntu-Medium, sans-serif;
        height: 47px;
        line-height: 47px;
        text-align: center;
        @include mq('phone-wide', max){
            width: calc(100% / 3);
        }
    }
    &__label{
        color: $white-dark;
        font-size: $super-small;
        font-family: $ubuntu-regular;
    }
    &__switcher{
        padding: 10px 0 5px;
    }
    .become-streamer__button{
        margin: 0;
        width: 150px;
        display: block;
        font-size: $ultra-small;
        padding: 0 5px;
    }
    .buy-orbs__button{
        margin: 0;
        width: 100px;
        display: block;
        font-size: $ultra-small;
        padding: 0 5px;
        margin-left: 10px;
        @include mq('phone-wide', max){
            margin-left: 0;
        }
    }
    .profileSaveBtn{
        height: 37px;
        width: 120px;
        color: $white-dark;
    }
    &__buy-orbs{
        width: 130px;
        margin: 0;
        margin-left: 20px;
        @include mq('phone-wide', max){
            margin: 0;
            width: auto;
        }
    }
    background-image: url('/assets/images/main/redesign/4.png');
    .profilePageBlock-info{
        width: 600px;
        @include mq('phone-wide', max){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }
    .profilePageForm{
        width: 300px;
        @include mq('phone-wide', max){
            width: 100%;
            margin: 0 auto;
        }
    }
    .addServices{
        @include mq('phone-wide', max){
            width: 90%;
            margin: 0 auto;
        }
    }
   
}

// 
.profile-page{
    &__donations{
        padding: 0 30px;
        @include mq('phone-wide', max){
            padding: 0 15px;
        }
        table{
            margin-top: 30px;
            @include mq('phone-wide', max){
                margin-top: 15px;
            }
        }
    }
    &__tabs{
        display: flex;
        color: $white-dark;
        height: 30px;
        justify-content: space-around;
        align-items: center;
        background-color: $background-dark-grey;
        position: absolute;
        width: 100%;
        left: 0;
        @include mq('phone-wide', max){
            flex-wrap: wrap;
            height: auto;
            padding: 0 15px;
        }
    }
    &__tab{
        width: calc(100% / 3);
        height: 30px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        display: block;
        font-size: 18px;
        font-family: $ubuntu-medium;
        background-color: $background-dark-grey;
        @include mq('phone-wide', max){
            width: calc(100% / 2);
            height: auto;
            line-height: normal;
            padding: 5px 0;
            margin-top: 10px;
        }
        &:hover{
            background-color: $background-grey!important;
        }
    }

}
.ask-modal{
    &__head{
        height: 60px;
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid white;
        text-align: center;
    }
}
.cashbox-modal{
    width: 500px;
    background-color: $background-grey;
    margin: 180px auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    border: 1px solid $white-dark;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  
    }
    @include mq('phone-wide', max){
        width: 90%;
        margin: 150px auto;
    }
    &__row{
        display: flex;
        margin-top: 10px;
        justify-content: space-around;
        @include mq('phone-wide', max){
            flex-direction: column;
            margin-top: 10px;
            height: 120px;
        }
    }
    &__inline{
        width: 40%;
        @include mq('phone-wide', max){
            width: 80%;
        }
    }
    &__currency-select{
        width: 100%;
        padding: 2px 10px;
        @include mq('phone-wide', max){
            width: 40%;
            height: 30px;
            padding: 2px 10px;
        }
    }
    &__label{
        margin: 0;
        color: $white-dark;
    }
    &__wrapper{
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        top: 0px;
        left: 0px;
        z-index: 1;
    }
    &__button{
        padding: 5px 10px;
        color: $white-dark;
        font-family: $ubuntu-medium;
        font-size: $small;
        display: block;
        margin: 20px auto;
        width: auto!important;
        @include mq('phone-wide', max){
            margin: 0 auto 20px;
            width: 100px;
            display: block;
        }
    }
    &__form{
        padding: 20px 0;
    }
    &__confirm{
        // height: 40px;
    }
 
    &__block{
        border-top: 1px solid white;
        display: flex;
        flex-direction: column;
        height: 110px;
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid white;
        justify-content: space-around;
        a{
            text-decoration: underline;
        }
        i{

        }
        &__form{
            padding: 20px 0;
        }
        &__head{
            height: 40px;
            @include mq('phone-wide', max){
                height: 40px;
            }
        }
    }
}

.profileBlock__plus-mob{
    display: none;
}

.partnership-modal{
    margin: auto;
    background-color: transparent;
    border: none;
}
.transactions__header th {
    white-space: nowrap;
}

@media (min-width: 320px) and (max-width: 480px) {
    .profile-page__donations {
        padding: 0;
    }
    .profile-page__donations::-webkit-scrollbar { 
        display: none;
    }
    .profile-page__table-cnt {
        width: 100%;
        overflow-x: scroll;
    }
    .profile-page__table-cnt table {
        width: 445px!important;
    }
    .profileBlock__plus-mob{
        display: block;
    }
    
  }