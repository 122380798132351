.register{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 200px;
    @include mq('phone', max){
        width: 120px;
    }
&__login-button{
    cursor: pointer;
    background: none;
    font-size: $ultra-small;
    color: $white-dark;
    font-family: $ubuntu-medium;
    border: none;
    &:hover{
        color: $gold-dark;
    }
    @include mq('phone', max){
        font-size: 10px;
        width: 40px;
    }
}
&__register-button{
    font-size: $ultra-small;
    color: $white-dark;
    font-family: $ubuntu-medium;
    width: 120px;
    height: 47px;
    @include mq('phone', max){
        width: 80px;
        font-size: 10px;
        height: 25px;
    }
}
}