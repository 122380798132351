@import '../../../assets/style/partials/variables.scss';

.ds-button{
    border-radius: 30px;
    padding: 4px 10px;
    border: none;
    outline: none;
    color: $white-dark;
    cursor: pointer;
    text-align: center;
    &--disabled{
        opacity: 0.6;
        background-color: $grey;
        
        cursor: auto;
        &:hover{
            box-shadow: none!important;
        }
    }
    &--selected{

    }
    &--loading{

    }
    &--fit{
        width: 100%;
    }
}

.ds-button-appearance{
    &--primary{
        background-color: $green;
    }
    &--secondary{
        background-color: $blue;
    }
    &--danger{
        background-color: $red;
    }
    
    &--accent{
        background-image: linear-gradient(#ffc837, #ff8008);
        &:hover{
            box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7;
        }
    }
    &--ghost-special{
        background-color: transparent;
        border: 4px solid $gold-dark;
        padding: 8px 16px;
        &:active{
            background-color: $gold-dark;
        }
    }
}