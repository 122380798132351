.stream-moderation{
    a{
        &:hover{
            color: $gold-dark;
        }
    }
    input[type="checkbox"]{
        position: static;
    }
    width: 90%;
    margin: 0 auto;
    @include mq('phone-wide', max){
        width: 95%;
    }
    table{
        width: 100%;
    }
    &__input{
        height: auto!important;
    }
    &__header{
        color: $white-dark;
        height: 30px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        tr{
            width: 100%;
            display: flex;
            justify-content: space-around;
        }
        th {
            width: calc(100% / 5);
            text-align: center;
            height: 100%;
            line-height: 30px;
            opacity: 0.5;
            font-size: 25px;
            font-weight: 300;
            cursor: pointer;
            @include mq('phone-wide', max){
                font-size: 10px;
                line-height: 15px;
            }
        }
        th:first-child(){
            width: 50px;
        }
    }
    &__row{
        color: $white-dark;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-bottom: 4px solid #484848;
        height: 80px;
        td {
            width: calc(100% / 5);
            text-align: center;
            @include mq('phone-wide', max){
                font-size: 10px;
                padding: 5px;
            }
        }
      td:first-child(){
          width: 50px;
      }
    }
   
    &__row{
        background-color: $background-dark-grey;
    }
    &__row:nth-child(2n){
            // background-color: $background-grey;
        }
   
    &__stream-list{
        margin: 0 auto;
    }
    &__stream{
        color: $white-dark;
        display: block;
        padding: 10px;
    }
    &__moderators{
        @include mq('phone-wide', max){
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    &__save-btn{
        padding: 7px 25px;
        color: $white-dark;
        position: relative;
        right: -90%;
        @include mq('phone-wide', max){
            margin-right: 7px;
            position: static;
            align-self: flex-end;
        }
    }
    &__label{
        color: $white-dark;
        font-family: $ubuntu-regular;
        font-size: $smallest;
    }

    &__header{
        font-size: $small;
        font-family: $ubuntu-regular;
        padding: 20px 10px;
        @include mq('phone-wide', max){
            padding: 10px 0;
        }
    }
}