@import "variables.scss";

.main{
  &__container{
    &_wide{
      align-items: center;
      padding: 60px 20px;
      background-color: $background-grey;
    }
  }
}
.predictionHeader{
  display:flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
.predictionParagraph {
  color: $white;
  font-family: $ubuntu-medium;
  font-size: 30px;
  letter-spacing: 0.4px;
  padding: 9px 0;
  &_small {
    font-size: 24px;
    font-family: $ubuntu-bold;
  }
  &_smaller {
    font-family: $ubuntu-regular;
    padding-bottom: 40px;
    color: $semi-white;
  }
  &_big {
    font-size: 48px;
    color: $semi-white;
    font-family: $ubuntu-bold;
  }
}

.predictionParagraphWrapper {
  overflow: hidden;
}
.predictionParagraph.featured {
  margin-bottom: -13.5px;
}

.predictionParagraphWrapper .predictionParagraph {
  float: left;
}
.liveStream {
  margin-bottom: 42px;
}
.liveStream.live {
  margin-bottom: 33px;
}

.chatButton {
  position: absolute;
  bottom: 0;
  right: 0;
  float: right;
  display: block;
  margin: 0px 10px 10px 0;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #fff;
  font-size: 24px;
}

.twitchSlider {
  position: relative;
  // background-image: url('../images/main/redesign/background-dark1.png');
  background-size: 30%;
  // padding-top: 40px;
  // padding-bottom: 25px;
  // opacity: 0.8;
  // margin-right: -10px;
  @include mq('phone-wide', max){
    background-image: none;
    background-color: $background-dark-grey;

  }
}

.twitchStream {
  height: 100px;
  overflow: hidden;
}

.prediction {
  margin-bottom: 50px;
  padding-top: 32px;
}

.prediction-stream {
  height: 610px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  // height: 440px;  
  //align-items: flex-start;
  @include mq('phone-wide', max){
    height: auto!important;
    margin-bottom: 40px;
  }
  .predictionColStream {
    width: calc(100% - 350px);
    height: 100%;
  }
  .predictionColPred {
    position: relative;
    width: 350px;
    height: 100%;
  }
  .predictionTwitchStream {
    position: relative; // overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 440px;
    border-radius: 6px;
    background-color: $background-grey;
    /*margin: 10px;*/
    margin-left: 0;
    box-sizing: border-box;
    html {
      overflow: hidden;
    }
  }
  .predictionMainWindow {
    // overflow-y: auto;
    height: 100%;
  }
  .predictionMainWindowWrapper {
    position: relative;
    // height: 440px;
    /*margin: 10px; // padding: 0 20px;*/
    margin-right: 0;
    // max-height: 560px;
    height: 100%;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: #000000;
  }
  .predictionMainWindowEmpty {
    height: calc(100% - 44px);
    margin: 20px;
    padding: 0 50px;
    font-family: $ubuntu-regular;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
    text-align: center;
    color: #ffffff;
    border-radius: 6px;
    background-color: #1b1b1b;
    border: solid 2px #ffffff;
    display: -webkit-inline-box;
    line-height: 500px;
    span {
      line-height: 28px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.mainFakePrediction {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-user-select: none;
  -moz-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue {
  font-family: $ubuntu-regular;
  /*margin: 5px;*/
  margin-top: 8px;
  padding: 11px 32px;
  font-size: 12px;
  line-height: 14px;
  color: $white;
  border: 2px solid $blue;
  background-color: $blue;
  box-sizing: border-box;
  border-radius: 20px;
  &_dark {
    background-color: $blue-fill;
    border: 2px solid $blue;
  }
}

.interaction {
  position: relative;
  background: #4c6077;
  .containerWrapper {
    padding: 0;
  }
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%; // background-color: rgba(0, 0, 0, 0.5);
}

.interactionBackground {
  display: block;
  width: 100%;
}

.interactionContent {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.linkWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  a.btn-blue_dark:hover{
    transition: all .3s ease;
    box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7;
  }
}

.info {}

.infoBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  .infoBlockImg,
  .infoBlockContent {
    width: 50%;
    box-sizing: border-box;
    background-color: #1B1B1B;
  }
  .infoBlockImg-img {
    display: block;
    width: 100%;
    height: auto;
  }
  .infoBlockContent {
    padding: 40px;
    display: flex;
    flex-direction: column;
    background-color: $dark-background;
    justify-content: center;
    align-items: flex-start;
  }
  .infoblockContent-icon {
    display: none;
  }
}

.infoBlockContent-header,
.infoBlockContent-paragraph {
  color: $white;
  a {
    text-decoration: underline;
  }
}

.infoBlockContent-paragraph {
  opacity: 0.8;
  font-family: $ubuntu-regular;
  font-size: 18px;
}

.infoBlockContent-header {
  padding-bottom: 21px;
  font-size: 36px;
  font-family: $ubuntu-bold;
}

.predictoria-header {
  font-family: $ubuntu-bold;
  font-size: 52px;
  padding-top: 112px;
  padding-bottom: 75px;
  color: $white-dark;
  text-align: center;
  letter-spacing: -2px;
}

.AdvantagesContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.AdvantagesContent-item {
  width: 33%;
  padding: 0 7%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 57px;
}

.AdvantagesContentIcon-img {
  height: 32px;
  width: auto;
  display: block;
  padding-bottom: 21px;
}

.AdvantagesContentTitle-header {
  font-family: $ubuntu-bold;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  color: $white-dark;
  opacity: 0.8;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.AdvantagesContentContent-paragraph {
  font-family: $ubuntu-regular;
  line-height: 27px;
  font-size: 18px;
  text-align: center;
  color: $white-dark;
  opacity: 0.95;
}

.AdvantagesContent {
  margin-bottom: 0;
}

.streamSlider,
.streamList {
  width: calc(100% - 95px);
  position: relative;
  // left: 50px; wtf?
  max-width: 1300px;
  margin: 0 auto;
  a {
    display: flex;
    cursor: pointer; // border: 2px solid transparent;
    position: relative;
    box-sizing: border-box;
    transition: all 0.2s ease;
    display: block;
    width: 142px;
    height: 90px;
  
  }
  .activestreamItemList {
    .streamItem-overlay {
      background: transparent;
    }
    // transition: all 0.2s ease;
  }
  .slick-next:before,
  .slick-prev:before {
    content: none;
  }
  .slick-next,
  .slick-prev {
    font-size: initial;
    line-height: initial;
    top: 0;
    width: initial;
    height: initial;
    -webkit-transform: none;
    -ms-transform: none;
  }
  .slick-prev {
    // left: -50px;
    margin-left: 0;
  }
  .slick-next {
    // right: -45px;
    margin-right: 0;
  }
  .streamItem {
    &_empty{
      visibility: hidden;
      opacity: 0;
    }
    &:hover {
      .streamItem-overlay {
        background: transparent;
        .streamSlider__info{
          transition: all 0.5s;
          opacity: 1;
          transform: translateY(-20px);

        }
      }
      .streamItem-amount {
        // left: 23px;
        // bottom: 3px;
        // transition: all 0.0s ease;
      }
      transition: all 0.2s ease; // border: 2px solid white;
    }
    width: 160px !important;
    height: 90px;
    margin: 0 5px;
    box-sizing: border-box;
    background: black;
    overflow: hidden;
    .streamItem-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.streamItem-Btn {
  width: 39px !important;
  height: 86px !important;
  margin: 0 5px;
  display: flex !important;
  align-items: center;
  text-align: center;
  &.slick-disabled {
    opacity: .25;
    cursor: default;
  }
  cursor: pointer;
  .streamItem-img {
    margin: 0 auto;
    opacity: 1;
    cursor: pointer;
  }
}
.gamesSlider {
  height: 160px;
  overflow: hidden;
}
#gamesSlider {

  &.streamSlider {
    width: calc(100% - 100px);
    left: 55px;
  }
  .streamItem {
    position: relative;
    width: 120px;
    height: 160px;
    // margin: 0 5px;

    span {
      position: absolute;
      top: 100px;
      left: 0;
      z-index: 1;
      width: 100%;
      
      font-family: $ubuntu-regular;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      text-align: center;
      color: #ffffff;
      &.hidden {
        display: none;
      }
    }
  }
  .streamItem-Btn {
    height: 156px !important;
  }
  .streamItem-overlay {
    background: none;
  }
  .slick-prev {
    // left: -55px;
  }
  .slick-next {
    // right: -45px;
  }
}

.slick-disabled {
  .streamItem-img {
    opacity: 0.3;
    cursor: default;
  }
}

.streamItem-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  
  // div{
  //   opacity: 0;
  //   top: 20px;
  // }
  // &:hover {
  //   width: calc(100% + -4px);
  //   height: calc(100% + -4px);
  // }
}

.streamItem-amount {
  cursor: pointer;
  position: absolute;
  bottom: 9px;
  left: 25px;
  font-size: 14px;
  color: white;
  &:before {
    position: absolute;
    content: '';
    display: block;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    left: -15px;
    height: 10px;
    width: 10px;
    background: $red;
  }
}

.streamList {
  // width: calc(100% + 28px);
  // left: -13.5px;
  overflow: hidden;
  .streamItem {
    width: 31%!important;
    height: 170px;
    margin: 13.5px;
    float: left;
  }
  .streamItem-amount {
    font-size: 20px;
    bottom: 10px;
    left: 35px;
    letter-spacing: 0.3px;
  }
  .streamItem-amount:before {
    width: 20px;
    height: 20px;
    background: 0;
    left: -25px;
    content: url("/assets/images/yellow-cup.svg");
  }
  
}

// FEEDBACK MODAL

// PREDICTION BLOCK
.predictionMainWindow {
  padding-top: 0px !important;
}

.predictionContainer {
  position: relative;
  margin: 0 20px;
  margin-top: 10px;
}

.predictionContainer:first-child {
  margin-top: 20px;
}

.predictionContainer:last-child {
  margin-bottom: 20px;
}

.predictionContainer button,
.predictionContainer input[type="button"] {
  -webkit-appearance: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  opacity: 1;
}

.predictionContainer button:hover,
.predictionContainer input[type="button"]:hover {
  opacity: 0.85;
}

.predictionContainer button:focus,
.predictionContainer input[type="button"]:focus {
  outline: 0;
}

.predictionContainerBlock {
  border: 2px solid #ffffff;
  border-radius: 6px;
  background-color: #1B1B1B;
  overflow: hidden; // margin-bottom: 10px;
}

.predictionContainerBlock__top {
  background-color: #282828;
  padding: 20px;
}

.predictionContainerBlock__top__headerRow {
  overflow: hidden;
}

.predictionContainerBlock__top__headerRow_timer {
  overflow: hidden;
  float: left;
}

.predictionContainerBlock__top__headerRow_timer_time {
  font-size: 12px;
  color: #000000;
  float: left;
  letter-spacing: 0.14px;
  line-height: 23px;
  border-radius: 13px;
  background-color: #ffffff;
  padding: 0 13px;
}

.predictionContainerBlock__top__headerRow_timer_desc {
  font-size: 12px;
  margin-left: 5px;
  float: left;
  line-height: 25px;
  color: #ffffff;
  letter-spacing: 0.14px;
}

.predictionContainerBlock__top__headerRow_type {
  float: right;
  color: #ffffff;
  line-height: 25px;
  border: 1px solid;
  border-radius: 13px;
  padding: 0 13px;
  font-size: 12px;
  font-weight: 100;
  cursor: pointer;
}

.predictionContainerBlock__top__headerRow_type:after {
  width: 11px;
  height: 11px;
  object-fit: contain;
  margin: 0 0 0 5px;
}

.predictionContainerBlock__top__headerRow_type.live_event {
  border-color: #FF3B30;
  background-color: #2B0A08;
}

.predictionContainerBlock__top__headerRow_type.live_event:after {
  content: url("../images/oval.svg");
  background-color: #ff3b30;
  background-color: var(--lose);
}

.predictionContainerBlock__top__headerRow_type.next_event {
  background-color: #0E1E26;
  border-color: #5AC8FA;
}

.predictionContainerBlock__top__headerRow_type.next_event:after {
  content: url("../images/arrow.svg");
  width: 11px;
  height: 7px;
  object-fit: contain;
}

.predictionContainerBlock__top__headerRow_type.waiting_for_result {
  background-color: #242004;
  border-color: #ffe620;
}

.predictionContainerBlock__top__headerRow_type.waiting_for_result:after {
  content: url("../images/timer.svg");
}

.predictionContainerBlock__top__headerRow_type.challenge_event {
  border-color: green;
  background-color: #1c3c24;
}

.predictionContainerBlock__top__headerRow_type.challenge_event.not_accepted {
  border-color: #ffe620;
  background-color: #7d5824;
}

.predictionContainerBlock__top__headerRow_type.challenge_event:after {
  content: '🏆'; //url("../images/oval.svg");
  // background-color: green;
}

.predictionContainerBlock__top__headerRow_type {
  &.done,
  &.win,
  &.lose {
    display: none;
  }
}

.predictionContainerBlock__top__footerRow {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.17px;
  margin: 10px 0 0 0;
  color: #ffffff;
}

.predictionContainerBlock__bottom {
  padding: 20px 15px;
  .team_one.tip span,
  .team_two.tip span {
    cursor: pointer;
  }
}

.predictionContainerBlock__bottom table {
  text-align: center;
  width: 100%;
}

.predictionContainerBlock__bottom thead {}

.predictionContainerBlock__bottom thead tr {}

.predictionContainerBlock__bottom thead th {
  font-size: 10px;
  font-weight: lighter;
  letter-spacing: 0.14px;
  color: #ffffff;
}

.predictionContainerBlock__bottom tbody {}

.predictionContainerBlock__bottom tbody tr {}

.predictionContainerBlock__bottom tbody tr td {
  line-height: 40px;
  text-align: center;
  padding: 5px;
}

.predictionContainerBlock__bottom tbody tr td>span {
  font-size: 12px;
  font-weight: lighter;
  letter-spacing: 0.2px;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 20px;
  width: 100%;
  line-height: 38px;
  display: block;
  /* padding: 12px 10px; */
  margin: 0 auto;
}

.predictionContainerBlock__bottom tbody tr td button {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.17px;
  color: #ffffff;
  border-radius: 20px;
  width: 100%;
  line-height: 38px;
  border: 1px solid;
  cursor: pointer;
}

.predictionContainerBlock__bottom tbody tr.team_one td span {}

.predictionContainerBlock__bottom tbody tr.team_one td button {
  background-color: #787AFF;
  border-color: #787Aff;
}

.predictionContainerBlock__bottom tbody tr.team_one td button:hover {
  opacity: 0.85;
}

.predictionContainerBlock__bottom tbody tr.team_one.prediction td button {
  cursor: initial;
  &:hover {
    opacity: 1;
  }
}

.predictionContainerBlock__bottom tbody tr.team_two.prediction td button {
  cursor: initial;
  &:hover {
    opacity: 1;
  }
}

.predictionContainerBlock__bottom tbody tr.team_two td span {}

.predictionContainerBlock__bottom tbody tr.team_two td button {
  background-color: #FF9500;
  border-color: #FF9500;
}

.predictionContainerBlock__bottom tbody tr.team_two td button:hover {
  opacity: 0.85;
}

.predictionContainerBlock__bottom tbody tr.prediction td span {}

.predictionContainerBlock__bottom tbody tr.prediction td button {}

.predictionContainerBlock__bottom tbody tr.win td span {
  background-color: #011f10;
  border-color: #04de71;
}

.predictionContainerBlock__bottom tbody tr.win td button {
  background-color: #04de71;
  border-color: #04de71;
}

.predictionContainerBlock__bottom tbody tr.win.first td button {
  background-color: $purple-dark;
  border-color: $purple-dark;
}
.predictionContainerBlock__bottom tbody tr.win.first td span {
  background-color: $purple-dark-bg;
  border-color: $purple-dark;
}

.predictionContainerBlock__bottom tbody tr.lose td span {
  background-color: #2b0a08;
  border-color: #ff3b30;
}

.predictionContainerBlock__bottom tbody tr.lose td button {
  background-color: #ff3b30;
  border-color: #ff3b30;
}

.predictionContainerBlock__bottom tbody tr.disabled td span {
  opacity: 0.3;
}

.predictionContainerBlock__bottom tbody tr.disabled td button {
  background-color: #f2f4ff;
  border-color: #f2f4ff;
  color: #000000;
  opacity: 0.3;
  cursor: auto;
}

.predictionContainerBlock__bottom tbody tr.disabled td button:hover {
  opacity: 0.3;
}

/** set prediction value block **/

.predictionContainerAdd {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #1b1b1b;
  overflow: hidden;
}

.predictionContainerAdd__back {
  cursor: pointer;
  position: relative;
  width: 42px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #1b1b1b;
  border: solid 2px #f2f4ff;
  margin-right: 10px;
  float: left;
  overflow: hidden;
  .predictionContainerAdd__back-img {
    position: absolute;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    right: 0;
    left: 0;
    margin: auto;
  }
}

.predictionContainerAdd__body {
  height: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #1b1b1b;
  border: solid 2px #f2f4ff;
  overflow: hidden;
}

.predictionContainerAdd__body__header {
  padding: 20px;
  background-color: #282828;
}

.predictionContainerAdd__body__header h3 {
  font-family: $ubuntu-regular;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #ffffff;
}

.predictionContainerAdd__body__header h5 {
  font-family: $ubuntu-regular;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  margin-top: 8px;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: left;
  color: #ffffff;
}

.predictionContainerAdd__body__footer {
  padding: 40px 20px 20px;
  &.challenge {
    padding-top: 20px;
  }
}

.predictionContainerAdd__body__footer__controlls {
  overflow: hidden;
}

.predictionContainerAdd__body__footer__controlls input {
  font-family: $ubuntu-regular;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
  text-align: center;
  width: calc(33% - 10px);
  float: left;
  color: #ffffff;

  -webkit-appearance: none;
}

.predictionContainerAdd__body__footer__controlls__slider {}

.predictionContainerAdd__body__footer__controlls__value {
  line-height: 36px;
  border-radius: 20px;
  border: solid 2px #f2f4ff;
  background-color: #1b1b1b;
  margin-right: 10px;
}

.predictionContainerAdd__body__footer__controlls__value:focus {
  outline: 0;
}

.predictionContainerAdd__body__footer__controlls__place {
  font-weight: bold;
  border-radius: 23px;
  background-color: #2094fa;
  border: 2px solid #2094fa;
  line-height: 36px;
  cursor: pointer;
  -webkit-appearance: none;
}

.predictionContainerAdd__body__footer__buttons {
  overflow: hidden;
  margin-top: 10px;
}

.predictionContainerAdd__body__footer__buttons__button {
  font-family: $ubuntu-regular;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  width: calc(25% - 10px);
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  float: left;
  line-height: 40px;
  cursor: pointer;
  border: none;
  margin-right: 10px;
}

.predictionContainerAdd__body__footer__buttons__button:last-child {
  margin-right: 0px;
}

/* The slider itself */

.predictionContainerAdd__body__footer__controlls__slider {
  
  -webkit-appearance: none;
  appearance: none;
  width: calc(33% - 10px);
  height: 4px;
  border-radius: 2px;
  background-color: #ffffff;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin: 18px 10px 18px 0px;
}

/* Mouse-over effects */

.predictionContainerAdd__body__footer__controlls__slider:hover {
  opacity: 1;
  /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */

.predictionContainerAdd__body__footer__controlls__slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  background-color: #2d87bf;
  border: solid 2px $white-dark;
  cursor: pointer;
  /* Cursor on hover */
  border-radius: 50%;
}

.predictionContainerAdd__body__footer__controlls__slider::-moz-range-thumb {
  width: 13px;
  height: 13px;
  box-sizing: border-box;
  background-color: #2d87bf;
  border: solid 2px $white-dark;
  border-radius: 50%;
  cursor: pointer;
  /* Cursor on hover */
}

/** set prediction value block END **/

[data-simplebar] {
  position: relative;
  z-index: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  /* Trigger native scrolling for mobile, if not supported, plugin is used. */
}

// SIMPLEBAR CSS
[data-simplebar="init"] {
  display: flex;
}

.simplebar-scroll-content {
  overflow-x: hidden;
  overflow-y: scroll;
  min-width: 100%;
  box-sizing: content-box;
}

.simplebar-content {
  overflow-x: scroll;
  overflow-y: hidden;
  box-sizing: border-box;
  min-height: 100%;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 11px;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  border-radius: 7px;
  min-height: 10px;
  width: 7px;
  opacity: 0;
  transition: opacity 0.2s linear;
  background: black;
  background-clip: padding-box;
}

.simplebar-track:hover .simplebar-scrollbar {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0 linear;
}

.simplebar-track .simplebar-scrollbar.visible {
  opacity: 0.5;
}

.simplebar-track.horizontal {
  left: 0;
  width: auto;
  height: 11px;
}

.simplebar-track.vertical {
  top: 0;
}

.horizontal.simplebar-track .simplebar-scrollbar {
  right: auto;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

.hoverOverlayPrediction {
  position: absolute;
  width: calc(100% - 4px);
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in;
  z-index: 1000;
  border: 2px solid white;
  border-radius: 6px;
  &.hoverOverlayPrediction_active {
    visibility: visible;
    opacity: 1;
  }
  p {
    padding: 20px;
    text-align: justify;
  }
  .hideButtonTip {
    cursor: pointer;
    font-size: 16px;
    line-height: 1;
    position: absolute;
    top: 25px;
    left: 25px;
    color: white;
  }
}

.hoverButtonOverlay {
  position: absolute;
  padding: 12px 12px; // top: -90%;
  left: 0;
  right: 0;
  bottom: calc(100% + 2px);
  height: auto;
  max-width: 200%;
  width: auto;
  border-radius: 6px;
  margin: auto;
  background: #282828;
  color: white;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  letter-spacing: 0.1px;
  box-shadow: 0px 0px 10px black;
  p {
    font-size: 12px;
    line-height: 1;
  }
}

.hoverTipWrapper {
  position: relative; // & > div {
  //   z-index: auto;
  //   visibility: hidden;
  //   opacity: 0;
  //   transition: all 0.3s ease;
  // }
  button+div {
    z-index: auto;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
  }
  button:hover {
    cursor: pointer !important;
    &+div {
      visibility: visible;
      opacity: 1;
    }
  }
}

#g-recaptcha {
  margin: 10px auto 20px;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  //CSS
}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  //CSS
}

@media (min-width: 1025px) and (max-width: 1439px) {
  .streamList {
    // width: 103%;
    // left: -1%;
  }
  .streamList .streamItem {
    margin: 1%;
    width: 31% !important;
  }
  .predictionParagraph.featured {
    margin-bottom: -6px;
  }
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape),
  (min-width: 768px) and (max-width: 1024px) {
  .twitchSlider {
    // padding-left: 30px;
    // padding-right: 30px;
    padding-bottom: 1px;
  }
  .predictionParagraph {
    font-size: 24px;
    letter-spacing: 0.3px;
    padding-top: 0;
  }
  .predictionParagraph.featured {
    margin-bottom: -6px;
  }
  .prediction {
    margin-bottom: 20px;
  }
 
  .prediction-stream .predictionColStream {
    width: 61.5%;
  }
  .prediction-stream .predictionMainWindowEmpty {
    width: calc(100% - 20px);
    box-sizing: border-box;
    margin: 10px;
    padding: 0 20px;
    height: calc(100% - 20px);
  }
  .streamList {
    width: 101%;
    left: -0.5%;
  }
  .streamList .streamItem {
    margin: 0.5%;
    width: 32.3% !important;
    height: 130px;
  }
  .interactionBackground {
    height: 300px;
  }
  .interactionContent {
    padding-left: 30px;
  }
  .infoBlockContent-paragraph {
    font-size: 12px;
    line-height: 23px;
  }
  .interaction .predictionParagraph {
    font-size: 12px;
  }
  .predictionParagraph_small {
    font-size: 13px;
  }
  .interaction .predictionParagraph_big,
  .infoBlockContent-header,
  .predictoria-header {
    font-size: 24px;
  }
  .predictoria-header {
    padding-top: 60px;
    padding-bottom: 50px;
  }
  .AdvantagesContentTitle-header {
    font-size: 10px;
  }
  .AdvantagesContentContent-paragraph {
    font-size: 14px;
    line-height: 26px;
  }
  .AdvantagesContent-item {
    margin-bottom: 40px;
  }
  .AdvantagesContent-item {
    padding: 0 4%;
  }
  .AdvantagesContent {
    padding-bottom: 50px;
  }
  .feedbackModal-create {
    margin-top: -5px;
  }
}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px //812px iphone X //823px pixel 2x l
*/

@media (min-width: 481px) and (max-width: 823px) {
  .prediction {
    .prediction-stream {
      .predictionColStream {
        .predictionTwitchStream {
          &>div {
            /*height: 110vh !important;*/
          }
        }
      }
    }
  }
}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  //CSS
  .prediction {
    .prediction-stream {
      .predictionColStream {
        // display: none;
        .predictionTwitchStream {
          // display: none;
          &>div {
            // height: 27vh !important;
          }
        }
      }
    }
  }
}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px //812px iphone X //823px pixel 2x l
*/

@media (min-width: 481px) and (max-width: 767px),
(min-width: 320px) and (max-width: 480px) {
  //CSS
  .twitchSlider {
    padding: 0 3px;
    .slider-mobile-hidder {
      display: none !important;
    }
    .predictionParagraph {
      font-family: $ubuntu-regular;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      padding: 10px 0;
    }
    .prediction {
      padding-top: 10px;
      margin-bottom: 0;
      .prediction-stream {
        flex-direction: column;
        .predictionColStream {
          width: 100%;
          .predictionTwitchStream {
            margin: 0;
            padding: 0; // & > div {
            //     // height: 110vh !important;
            // }
            .chatButton {
              top: -35px;
              bottom: auto;
              font-family: $ubuntu-regular;
              font-size: 12px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: 0.75;
              letter-spacing: normal;
              margin: 0;
              padding: 7px 10px;
              border-radius: 8px;
              border: solid 1px #ffffff;
            }
          }
        }
        .predictionColPred {
          width: 100%;
          .feedbackModal-create,
          .predictionParagraph {
            display: none;
          }
          .predictionMainWindowWrapper {
            margin: 0;
            background-color: initial;
            max-height: initial;
            height: initial;
            .predictionContainer {
              margin-left: 0;
              margin-right: 0;
            }
            .predictionContainer:first-child {
              margin-top: 0;
            }
            .predictionContainer.predictionContainerBlock.btn-blue {
              width: 100% !important;
            }
          }
          .predictionMainWindowEmpty {
            height: calc(100% - 4px);
            margin: 0px;
            padding: 0 45px;
            width: 100%;
            display: block;
            box-sizing: border-box;
            button {
              margin: 0;
            }
          }
        }
      }
    }
    
    .streamList {
      left: 0;
      width: 100%;
      .streamItem {
        width: 100% !important;
        margin: 0;
        margin-bottom: 10px;
        &:nth-child(n+4) {
          // display: none; 
        }
        &:first-child {
          .streamItem-img {
            width: 100%;
          }
        }
      }
    }
  }
  .interaction {
    height: 50vh;
    overflow: hidden;
    img {
      width: initial;
      height: 100%;
    }
    .interactionContent {
      width: calc(100% - 40px);
      padding: 0 20px;
      .predictionParagraph {
        &.predictionParagraph_small {
          font-family: $ubuntu-regular;
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.1px;
          color: #f2f4ff;
          &.predictionParagraph_smaller {
            font-family: $ubuntu-regular;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.1px;
            color: #f2f4ff;
          }
        }
        &.predictionParagraph_big {
          font-family: $ubuntu-regular;
          font-size: 20px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #f2f4ff;
        }
      }
    }
  }
  .info {
    .infoBlock {
      flex-direction: column;
      .infoBlockImg,
      .infoBlockContent {
        width: 100%;
      }
    }
  }
  .Advantages {
    .Advantages-header {
      display: none;
    }
    .AdvantagesContent {
      padding: 40px 20px;
      flex-direction: column;
      .AdvantagesContent-item {
        width: 100%;
        margin-bottom: 20px;
        padding: 0 20px;
        &:nth-child(n+3) {
          display: none;
        }
      }
    }
  }
  .predictionParagraph.featured {
    margin-bottom: 0;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .predictionParagraph.featured {
    margin-bottom: 0;
  }
.twitchSlider {
    padding: 10px 3px 0px;
    .slider-mobile-hidder {
      display: none !important;
    }
    .twitchStream {
      height: 60px;
    }
    .predictionColStream .predictionParagraph{
      width: calc(100% - 90px);
    }
    .streamItem-Btn {
      height: 60px !important;
      width: 28px !important;
      box-sizing: border-box;
    }
    .streamSlider .streamItem {
      // width: 106px !important;
      // height: 60px;
      // margin: 0;
      // margin-right: 5px;
    }
    #gamesSlider {
      height: 100px;
    }
    #gamesSlider .streamItem-Btn {
      height: 100px !important;
    }
    #gamesSlider .streamItem {
      height: 100px;
      width: 75px;
      margin: 0;
      margin-right: 5px;      
      box-sizing: border-box;
    }
    #gamesSlider .slick-prev,
    .streamSlider .slick-prev {
      // left: -33px;
    }
    #gamesSlider .slick-next,
    .streamSlider .slick-next {
      // right: -33px;
    }
    #gamesSlider.streamSlider,
    .streamSlider {
      left: 33px;
      width: calc(100% - 66px);
    }
    .streamItem-Btn .streamItem-img {
      transform: scale(0.6);
    }
    .streamSlider .streamItem-amount:before {
      height: 6.7px;
      width: 6.7px;
      left: -11px;
    }
    .streamSlider .streamItem-amount {
      bottom: 5px;
      left: 18px;
      font-size: 9.3px;
    }
    .prediction-stream .predictionMainWindowEmpty span {
      font-size: 16px;
      line-height: 16px;
    }
    .predictionContainer.predictionContainerBlock.btn-blue {
      padding-left: 20px;
      padding-right: 20px;
    }
    .liveStream {
      margin-bottom: 10px;
      .streamList {
        .streamItem {
          height: 159px;
          @include mq('phone-wide', max){
            width: calc(100% - 30px) !important;
            height: auto;
            padding: 0 15px;
            margin: 15px auto;
            float: none;
          }
        }
      }
    }
    .twitchSlider {
      padding-bottom: 1px;
    }
  }  
  .interaction {
    height: calc(50vh + 80px);
  }
  .infoBlock:first-child .infoBlockContent {
    background-color:#282828;
  }
  .info {  
    .infoBlock .infoBlockContent {
      text-align: center;
      padding: 40px 20px;
    }
    .infoBlockContent-header {
      font-size: 20px;
      width: 100%;
      padding-bottom: 10px;
    }
    .infoBlockContent-paragraph {
      font-size: 16px;
    }
    .infoBlockImg {
      display: none;
    }
    .infoblockContent-icon {
      display: inline-block;
      margin: 0 auto 20px;
    }
  }
  .AdvantagesContentTitle-header {
    font-size: 16px;
    padding-bottom: 16px;
    letter-spacing: normal;
  }
  .AdvantagesContentIcon-img {
    padding-bottom: 12px;
  }
  .AdvantagesContentContent-paragraph {
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: normal;
  }
  .Advantages .AdvantagesContent {
    padding-bottom: 22px;
  }
  .Advantages .AdvantagesContent .AdvantagesContent-item {
    padding: 0 7%;
  }
  .footer-social, .footer-content {
    margin-bottom: 4px;
  }
}

.adminPage{
  background-color: transparent;
  background-image: url('/assets/images/main/redesign/background-dark1.png');
  &__navigation{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
}

.streamItem-live {
  width: 37px;
  height: 20px;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #ffffff;
  background: #ff3b30;
  border-radius: 3px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}