.depositMenu{

.nameBlock{
    height: 40px;
    background-color: $background-grey;
}
.depositMenuList-item{
    background-color: $background-dark-grey;
    border: none;
    height: calc(100% - 40px);
    padding: 0;
    .depositMenuList-btn{
        line-height: 30px;
        height: 30px;
        width: 120px;
        margin: 10px;
    }
}
}
.deposit{
&__wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0px;
    left: 0px;
    z-index: 1;
}
}