// Icons from directory ""
%svgIcon {
	background-repeat: no-repeat
}

.svgIcon-button-arrow-right{
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
}
.svgIcon-Facebook,
.svgIcon-Facebook\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 0;
}
.svgIcon-Facebook-dims {
	width: 8px;
	height: 16px;
}
.svgIcon-Instagram,
.svgIcon-Instagram\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -16px;
}
.svgIcon-Instagram-dims {
	width: 16px;
	height: 16px;
}
.svgIcon-Oval,
.svgIcon-Oval\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -32px;
}
.svgIcon-Oval-dims {
	width: 25px;
	height: 25px;
}
.svgIcon-Twitter,
.svgIcon-Twitter\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -57px;
}
.svgIcon-Twitter-dims {
	width: 19px;
	height: 14px;
}
.svgIcon-Youtube,
.svgIcon-Youtube\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -71px;
}
.svgIcon-Youtube-dims {
	width: 40px;
	height: 40px;
}
.svgIcon-arrow-down,
.svgIcon-arrow-down\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -111px;
}
.svgIcon-arrow-down-dims {
	width: 8px;
	height: 5px;
}
.svgIcon-arrow,
.svgIcon-arrow\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -116px;
}
.svgIcon-arrow-dims {
	width: 13px;
  height: 11px;
  margin: 0 auto;
}
.svgIcon-button-arrow-left,
.svgIcon-button-arrow-left\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: -18px -164px;
}
.svgIcon-button-arrow-left-dims {
	width: 10px;
  height: 16px;
  margin: 0 auto;
}
.svgIcon-button-arrow-right,
.svgIcon-button-arrow-right\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
  background-position: -17px -254px;
}
.svgIcon-button-arrow-right-dims {
	width: 10px;
	height: 16px;
}
.svgIcon-chat_icon,
.svgIcon-chat_icon\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -307px;
}
.svgIcon-chat_icon-dims {
	width: 25px;
	height: 16px;
}
.svgIcon-chat_icon_active,
.svgIcon-chat_icon_active\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -323px;
}
.svgIcon-chat_icon_active-dims {
	width: 25px;
	height: 16px;
}
.svgIcon-clock,
.svgIcon-clock\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -339px;
}
.svgIcon-clock-dims {
	width: 40px;
	height: 40px;
}
.svgIcon-cubes,
.svgIcon-cubes\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -379px;
}
.svgIcon-cubes-dims {
	width: 20px;
	height: 20px;
}
.svgIcon-edit-profile,
.svgIcon-edit-profile\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -399px;
}
.svgIcon-edit-profile-dims {
	width: 30px;
	height: 30px;
}
.svgIcon-email-icon,
.svgIcon-email-icon\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -429px;
}
.svgIcon-email-icon-dims {
	width: 19px;
	height: 13px;
}
.svgIcon-fb,
.svgIcon-fb\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -441.65px;
}
.svgIcon-fb-dims {
	width: 45px;
	height: 45px;
}
.svgIcon-google,
.svgIcon-google\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -487px;
}
.svgIcon-google-dims {
	width: 45px;
	height: 45px;
}
.svgIcon-green,
.svgIcon-green\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -532px;
}
.svgIcon-green-dims {
	width: 40px;
	height: 40px;
}
.svgIcon-icon1,
.svgIcon-icon1\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -572px;
	background-size: auto;
}
.svgIcon-icon1-dims {
	width: 32px;
	height: 32px;
}
.svgIcon-icon2,
.svgIcon-icon2\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -604px;
	background-size: auto;
}
.svgIcon-icon2-dims {
	width: 32px;
	height: 32px;
}
.svgIcon-icon2_,
.svgIcon-icon2_\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -636px;
	background-size: auto;
}
.svgIcon-icon2_-dims {
	width: 28px;
	height: 32px;
}
.svgIcon-icon3,
.svgIcon-icon3\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -668px;
	background-size: auto;
}
.svgIcon-icon3-dims {
	width: 26px;
	height: 32px;
}
.svgIcon-icon4,
.svgIcon-icon4\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -700px;
	background-size: auto;
}
.svgIcon-icon4-dims {
	width: 28px;
	height: 32px;
}
.svgIcon-icon5,
.svgIcon-icon5\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -732px;
	background-size: auto;
}
.svgIcon-icon5-dims {
	width: 38px;
	height: 32px;
}
.svgIcon-icon5_,
.svgIcon-icon5_\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -764px;
	background-size: auto;
}
.svgIcon-icon5_-dims {
	width: 32px;
	height: 32px;
}
.svgIcon-icon6,
.svgIcon-icon6\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -796px;
	background-size: auto;
}
.svgIcon-icon6-dims {
	width: 32px;
	height: 28px;
}
.svgIcon-inst,
.svgIcon-inst\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -824px;
}
.svgIcon-inst-dims {
	width: 40px;
	height: 40px;
}
.svgIcon-loading-spin,
.svgIcon-loading-spin\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -864px;
}
.svgIcon-loading-spin-dims {
	width: 32px;
	height: 32px;
}
.svgIcon-log-out,
.svgIcon-log-out\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -896px;
}
.svgIcon-log-out-dims {
	width: 30px;
	height: 30px;
}
.svgIcon-logo,
.svgIcon-logo\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -926px;
}
.svgIcon-logo-dims {
	width: 182px;
	height: 30px;
}
.svgIcon-oval,
.svgIcon-oval\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -956px;
}
.svgIcon-oval-dims {
	width: 11px;
	height: 11px;
}
.svgIcon-password-icon,
.svgIcon-password-icon\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -967px;
}
.svgIcon-password-icon-dims {
	width: 15px;
	height: 19px;
}
.svgIcon-promocode,
.svgIcon-promocode\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -986px;
}
.svgIcon-promocode-dims {
	width: 40px;
	height: 40px;
}
.svgIcon-recharge-balance,
.svgIcon-recharge-balance\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -1026px;
}
.svgIcon-recharge-balance-dims {
	width: 30px;
	height: 30px;
}
.svgIcon-red,
.svgIcon-red\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -1056px;
}
.svgIcon-red-dims {
	width: 40px;
	height: 40px;
}
.svgIcon-referral,
.svgIcon-referral\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -1096px;
}
.svgIcon-referral-dims {
	width: 18px;
	height: 20px;
}
.svgIcon-ringer,
.svgIcon-ringer\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -1116px;
}
.svgIcon-ringer-dims {
	width: 13px;
	height: 15px;
}
.svgIcon-share,
.svgIcon-share\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -1131px;
}
.svgIcon-share-dims {
	width: 40px;
	height: 40px;
}
.svgIcon-steam,
.svgIcon-steam\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -1171px;
}
.svgIcon-steam-dims {
	width: 45px;
	height: 45px;
}
.svgIcon-support,
.svgIcon-support\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -1216px;
}
.svgIcon-support-dims {
	width: 30px;
	height: 30px;
}
.svgIcon-timer,
.svgIcon-timer\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -1246px;
}
.svgIcon-timer-dims {
	width: 11px;
	height: 11px;
}
.svgIcon-twitter,
.svgIcon-twitter\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -1257px;
}
.svgIcon-twitter-dims {
	width: 45px;
	height: 45px;
}
.svgIcon-vk,
.svgIcon-vk\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -1302px;
}
.svgIcon-vk-dims {
	width: 45px;
	height: 45px;
}
.svgIcon-yellow,
.svgIcon-yellow\:regular {
	@extend %svgIcon;
	background-image: url('../images/sprite.svg');
	background-position: 0 -1347px;
}
.svgIcon-yellow-dims {
	width: 40px;
	height: 40px;
}