.activities{
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    background-color: #1b1b1b;
    height: 100%;

    @include mq('phone-wide', max){
      padding: 0 15px;
    }
    
    &__tab-name{
     color: $white-dark;
     font-size: $small;
     font-family: $ubuntu-regular;
    }
&__dropdown{
  width: 97px; 
  position: relative;
  display: flex;
  justify-content: center;
}
  .b-dropdown{
    margin: 0;
  }
&__selector{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 30px;
  justify-content: space-around;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  // justify-content: space-evenly;
  width: 97%;
  div{
    &:hover{
      color: $gold-dark;
    }
  }
}
&__selector-btn{
  font-family: $ubuntu-regular;
  font-size: 16px;
  color: $white-dark;
  // padding: 0 20px;
  line-height: 30px;
  opacity: 0.75;
  cursor: pointer;
  &_selected{
    background: linear-gradient($gold-light, $gold-dark);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
    font-weight: bolder;
  }
}
&__tabs{
    height: 53px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #232323;
    justify-content: center;
    @include mq('phone-wide', max){
      display: flex;
      border-radius: 16px;
      padding: 0 10px;
      height: 40px;
      width: 100%;
      border: 1px solid $gold-dark;
      justify-content: space-between;
    }
}
&__activities-tab{
  width: 70%;
  height: 37px;
  border-radius: 18.5px;
  // background-image: url('../images/main/chat/cube-white.png');
  // background-size: 22px 22px;
  // background-position: center;
  // background-repeat: no-repeat;
  border: 1px solid white;
 
  @include mq('phone-wide', max){
    width: 30px;
    border: none;
    height: 30px;
  
  }
  &.TWITCH{
    background-color: #6441a5;
}
  &.DISCORD{
    background-color: #7c8ce4;
 }
}
&__chat-tab{
    cursor: pointer;
    width: 154px;
    height: 39px;
    box-sizing: border-box;
    background-color: #1b1b1b;
    border-radius: 18.5px;
    border: 4px solid #ffc837;
    background-image: url('../images/main/redesign/chat.png');
    background-size: 26px;
    background-position-y: center;
    background-position-x: 30px;
    background-repeat: no-repeat;
    font-size: 18px;
    color: $white-dark;
    font-family: $ubuntu-regular;
    text-align: center;
    line-height: 30px;
    padding-left: 15px;
    transition: all .3s ease;
    &:hover{
      box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
    }
    @include mq('phone-wide', max){
      width: 30px;
      border: none;
      background-color: transparent;
      height: 30px;
      color: transparent;
      background-position-x: center;
      
    }
}
&__empty{
    width: 40px;
    height: 40px;
}
&__create{
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-image: linear-gradient($gold-light, $gold-dark);
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 28px;
    line-height: 35px;
    font-size: 40px;
    color: #232323;
    text-align: center;
}
&__trigger{
    display: block;
    font-family: $ubuntu-medium;
    font-size: $smallest;
    line-height: 0.71;
    letter-spacing: normal;
    text-align: right;
    color: #f7f7f7;
  }
  &__trigger::after{
    display: block;
    position: absolute;
    width: 30%;
    right: 0px;
    border-bottom: 4px solid gold;
    margin: 3px 0 5px 0;
    content: " ";
  }
  &__option{
    text-align: right;
    width: 100%;  
    color: $white-dark;
    cursor: pointer;
  }
.b-dropdown__wrapper{
    top: 15px;
    left: -7px;
    background-color: transparent;
    background-color: $background-grey;
    width: 115px;
    display: flex;
    align-items: center;
  }
  .b-dropdown__content{
    background-color: transparent;
    align-items: center;
    width: 115px;

  }
  .b-dropdown__arrow svg{
    visibility: hidden!important;
  }
  .b-dropdown__trigger{
    width: 60px;
  }
}

.activities-list{
  &__be-first{
    margin-top: 20px;
    color: $white-dark;
    font-size: 14px;
    font-weight: bold;
    padding: 7px 25px;
    background-image: linear-gradient(#ffc837, #ff8008);
  }
  &__no-challenges{
    color: $white-dark;
    width: 100%;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    strong{
      cursor: pointer;
      &:hover{
        color: $gold-dark;
      }
    }
  }
}