@import '../../assets/style/partials/variables.scss';

.help-center{
    color: $white-dark;
    &__head{
        height: 200px;
        background-image: url('../../assets/images/main/redesign/background-dark1.png');
        border-bottom: 4px solid #F79415;
        background-size: 80%;
        @include mq('phone-wide', max){
            height: auto;
        }
    }
    &__header{
        font-size: 64px;
        a:hover{
            color: $gold-dark;
        }
    }
    &__row{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;

        @include mq('phone-wide', max){
            flex-direction: column;
            align-items: center!important;
        }
    }
    &__section{
        display: flex;
        justify-content: center;
    }
    &__search{
        display: flex;
        @include mq('phone-wide', max){
            width: 95%;
            position: relative;
        }
    }
    &__search-input{
        width: 438px;
        height: 41px;
        background: #C4C4C4;
        border-radius: 10px;
        outline: none;
        padding: 0 10px;
        border: none;
        font-size: 18px;
        @include mq('phone-wide', max){
            margin: 30px auto;
            font-size: 14px;
        }
    }
   
    &__close-search-results{
        cursor: pointer;
        position: absolute;
        width: 35px;
        height: 35px;
        right: 50px;
        opacity: 0.75;
        background-image: url(/assets/close-icon.png);
        background-size: 100%;
        transition: all 0.3s;
        &:hover{
            opacity: 1;
        }
        @include mq('phone-wide', max){
        width: 20px;
        height: 20px;
        }
    }
    &__main-content{
        background-color: $background-dark-grey;
    }
    &__search-icon{
        right: 30px;
        width: 20px;
        height: 20px;
        background-image: url(../../assets/images/main/help-center/search-icon.png);
        background-size: cover;
        display: inline;
        position: relative;
        top: 10px;
        cursor: pointer;
        @include mq('phone-wide', max){
            top: 40px;
            width: 20px;
            position: absolute;
            right: 15px;
            }
    }
    &__categories{
        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #F79415;
        width:80%;
    }
    
    &__popular-articles{
        margin-top: 30px;
        width: 80%;
    }
    &__search-results{
        padding-top: 30px;
        width: 100%;
        background: $background-dark-grey;
    }
    &__category-name{
        font-size: 18px;
        text-align: center;
        margin-top: 30px;
        &:hover{
           color: $gold-dark;
        }
    }
    &__top{
        align-items: flex-start;
    }
    &__subheader{
        font-size: 36px;
        text-align: center;
    }
    &__articles-list{
        margin-top: 30px;
    }
    &__3-cols{
        // column-count: 3;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include mq('phone-wide','max'){
            flex-direction: column;
        }
        a{
            display: block;
            width: calc(33% - 100px);
            font-size: 18px;
            margin-bottom: 30px;
            margin-right: 50px;
            margin-left: 50px;
            &:hover{
                color: $gold-dark;
            }
            @include mq('phone-wide','max'){
                width: 80%;
                margin: 0 auto;
                margin-bottom: 30px;

            }
        }
    }
    &__articles{
        width: 80%;
        margin: auto;
        margin-top: 30px;
        a{
            &::before{
                content: "";
                width: 15px;
                height: 15px;
                display: inline-block;
                border-radius: 50%;
                background-color: #F79415;
                position: relative;
                top: 3px;
                left: -30px;
            }
        }
     
    }
    &__article-content-preview{
    }
    &__add-new-article{
        &::before{
            display: none!important;
        }   
       }
    &__article-list{
        padding: 30px;
  
    }
    &__article-content{
        margin-top: 30px;
        *{
            max-width: 100%!important;
        }
      
    }
    &__article{
        padding: 30px;
        background-color: $background-dark-grey;
    }
    &__50-container{
        width: 50%;
        @include mq('phone-wide', max){
            width: 100%;
        }
    }
    .start{
        align-items: flex-start;
    }
    &__1-col{
        // column-count: 3;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        a{
            display: block;
            font-size: 18px;
            margin-bottom: 30px;
            &:hover{
                color: $gold-dark;
            }
        }
    }
    &__category{
        width: calc(100% / 5);
        @include mq('phone-wide', max){
            width: auto;
            margin-bottom: 20px;
        }
    }
    .category-view{
        background-color: $background-dark-grey;
    }
    &__category-icon{
        width: 125px;
        height: 125px;
        margin-bottom: 30px;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 auto;
        background-color: rgba(1, 1, 1, 0.5);
        border-radius: 50%;
        &:hover{
            box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7;
        }
        &_about{
            background-image: url(../../assets/images/main/help-center/About.png);
            background-size: 50%;
            border: 2px solid #F79415;
            background-position: center;
            background-position-x: 58%;
            
            background-repeat: no-repeat;
        }
        &_faq{
            background-image: url(../../assets/images/main/help-center/FAQ.png);
        }
        &_activity-rules{
            background-image: url(../../assets/images/main/help-center/Rules.png);
        }
        &_referral-rules{
            background-image: url(../../assets/images/main/help-center/Referral.png);
        }
        &_privacy{
            background-image: url(../../assets/images/main/help-center/Privacy.png);
        }
    }
}