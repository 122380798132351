@import '../../assets/style/partials/variables.scss';

.checkbox{
    &__label:before {
        content: '';
        display: inline-block;
        margin: 0 16px -1px 0;
        background-color: $gray-85;
        border-radius: 50%;
        cursor: pointer;
        height: 15px;
        width: 15px;
      }
      &__input {
        visibility: hidden;
        position: absolute;
      }

      &__input:checked + label:before {
        background-color: #66bb6a;
        border-color: #66bb6a;
      }
      &__input:checked + label:after {
        opacity: 1;
      }
}


  
 
  
  
  
 