.ds-tag{
    background: #6e6779;
    border-radius: 1000px;
    bottom: .12em;
    color: #faf9fa;
    display: inline-block;
    font-size: 75%;
    line-height: 1;
    padding: .24rem .6em;
    position: relative;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
    margin-right: 8px;
}