.adm-new-prediction-container {
  flex-wrap: wrap;
  justify-content: center;
}

.adm-new-prediction {
  width: 100%;
  box-sizing: border-box;
  // margin: 15px; // Debug
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.adm-new-prediction__btn-group {
  position: relative;
}
.adm-new-control-card__option {
  font-size: 10px;
}
.adm-new-prediction__btn {
  &:first-child {
    position: absolute;
    width: 50%;
  }

  &:last-child {
    padding-left: 45%;
  }
}
