@import '../../assets/style/partials/variables.scss';

.stream-page{
    padding-top: 120px;
    &__right{
        flex-basis: 320px;
    }
    &__tasks{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
        grid-gap: 1rem;
        min-height: 440px;
    }
    &__main{
        flex-grow: 1;
    }
    &__stream{
        min-height: 440px;
    }
    &__activity-button{
        padding: 8px 10px;
    }
}

// consider making reusable table
.leaderboard{
    tr, th{
        height: 33px;
        border-bottom: 1px solid $gray-77;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

    }
    th:after{
        content: '';
        display: block;
        border-bottom: 2px solid $dark;
        width: 134px;
        margin-top: 9px;
    }
}