.activity_admin {
  width: 186px;
  border: 3px solid #fff;
  border-radius: 5px;
  background-color: #000;
  margin: 10px;
  display: inline-block;

  &__header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 40px;
  }

  &__row {
    display: flex;
    padding: 3px 10px;
    justify-content: space-between;

    &_controls {
      position: relative;
      width: calc(100% + 10px);
      padding: 0;
      left: -5px;
      top: 5px;
      height: 50px;
      justify-content: center;
    }
  }

  &__goal-price {
    background-color: $background-grey;
    border-radius: 7.5px;
    border: 1px solid $white-dark;
    width: 60px;
    height: 20px;
    padding: 0 5px;
    font-size: $smallest;
    font-family: $ubuntu-regular;
    color: $white-dark;
  }

  &__goal-price::placeholder {
    font-size: $smallest;
    font-family: $ubuntu-regular;
    text-align: center;
  }

  &__current-balance {
    width: 100%;
    height: 20px;
    border-radius: 9.5px;
    border: solid 1px $white-dark;
    text-align: center;
    font-size: 10px;
    color: $white-dark;
    font-family: $ubuntu-regular;
    line-height: 20px;
  }

  &__operator-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &_voting {
      flex-direction: row;
    }
  }

  &__description {
    padding: 3px 10px;
    font-family: $ubuntu-regular;
    font-size: $smallest;
    text-align: left;
    color: $white-dark;
  }

  &__overlay {
    display: flex;
    position: absolute;
    width: 50%;
    justify-content: center;
    height: 100%;
    transition: width 1s linear 1s, opacity 0s 1s;
  }

  &__button {
    cursor: pointer;
    width: 65px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    line-height: 20px;
    font-size: $smallest;
    font-family: $ubuntu-regular;

    &_vertical {
      cursor: pointer;
      flex-shrink: 1;
      width: 25%;
      color: $white-dark;
      font-size: 12px;
      font-family: $ubuntu-bold;
      padding: 3px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      transition: width 1s linear 1s, color 0.75s linear 0.25s;
      box-sizing: border-box;
      // opacity: 0.8;
    }

    &_transparent {
      color: transparent;
    }

    &_wide {
      width: 50%;
    }

    &_hidden {
      position: absolute;
    }

    &_approve {
      border: double 2px transparent;
      background-image: linear-gradient(#4a69bd, #4a69bd), radial-gradient(circle at top left, $gold-light, $gold-dark);
      background-origin: border-box;
      background-clip: content-box, border-box;
      border-radius: 13px;
      box-sizing: border-box;
      line-height: 16px;
      padding: 0 1px;
    }

    &_add-new {
      background-color: green;
      font-family: $ubuntu-bold;
      margin: 0 10px;
      width: 100px;
      display: block;
    }

    &_refuse {
      background-color: #60a3bc;
    }

    &_accept {
      background-color: green;
    }

    &_done {
      background-color: green;
    }

    &_failed {
      background-color: red;
    }

    &_close {
      background-color: #4a69bd;
    }

    &_close-pink {
      background-color: rgba(248, 177, 149, 0.8);
    }

    &_cancel-pink {
      background-color: rgba(246, 114, 128, 0.8);
    }

    &_close-create-pink {
      background-color: rgba(192, 108, 132, 0.8);
    }

    &_cancel-create-pink {
      background-color: rgba(246, 114, 128, 0.8);
    }
  }

  .time-to-accept {
    width: 58px;
    height: 20px;
    background: $background-grey;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    color: $white-dark;

    input {
      outline: none;
    }

    &__hh {
      width: 50%;
      height: 100%;
      background-color: transparent;
      border: none;
      border-right: 1px solid $white-dark;
      color: $white-dark;
      text-align: center;
      font-size: $smallest;
      font-family: $ubuntu-regular;
    }

    &__mm {
      width: 50%;
      height: 100%;
      background-color: transparent;
      border: none;
      color: $white-dark;
      text-align: center;
      font-size: $smallest;
      font-family: $ubuntu-regular;
    }
  }

  .color {
    transition: color 1s linear 2s;
  }

  .hidden {
    opacity: 0;
    color: 0;
    visibility: hidden;

    div {
      color: transparent;
    }
  }

  .hide {
    div {
      color: transparent;
    }
  }

  .wide {
    z-index: 100;
    width: 100%;

    div {
      color: $white-dark;
    }
  }

  .transparent {
    color: transparent;
  }
}

.activity-admin {
  &__toggler {
    height: 40px;
    background-color: $background-grey;
    line-height: 40px;
    margin: 20px 0px;
    cursor: pointer;
  }

  &__activity-block {
    border-left: 1px solid $white-dark;
    width: calc(100% / 4);
    padding: 0 1rem;

    &:first-child {
      border: none;
    }

    &_prd {
      width: 100% !important;
    }
  }

  &__templates {
    // padding: 20px;
    margin-top: 1.5rem;
    justify-content: center;

    &>form.adm-new-prediction {
      width: calc(100% / 4);
    }
  }

  &__header {
    margin-bottom: 1.5rem;
    font-size: 20px;
    min-height: 60px;
    // border-bottom: 1px solid $white-dark;
  }

  &__container {
    padding: 20px 15px 0 30px;
    margin: 0 auto;
    min-height: 100vh;
  }

  &__tab-content {
    margin-top: 50px;

    &>.row-container {
      justify-content: flex-start;
    }
  }

  // Only for Paid Actions, because there are only two columns
  &__tab-content.paidactions-tab .row-container {
    .activity-admin__activity-block {
      width: calc(100% / 2);
    }
  }

  // Only for Predictions
  &__tab-content.predictions-tab .row-container {
    .activity-admin__activity-block {
      width: calc(100% / 3);
    }
  }
}

.plus {
  &__container {}

  &__icon {
    cursor: pointer;
    position: relative;
    width: 30px;
    height: 30px;
    border: 3px solid $gold-dark;
    border-radius: 50%;
    margin: 10px auto;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: $gold-dark;
      transition: transform 0.25s ease-out;
    }

    &:before {
      top: 25%;
      left: 50%;
      width: 3px;
      height: 50%;
      margin-left: -2px;
    }

    &:after {
      top: 50%;
      left: 25%;
      width: 50%;
      height: 3px;
      margin-top: -1px;
    }
  }
}