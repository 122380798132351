.live-stream-slider{
    @include mq('phone-wide', max){
        display: none;
    }
}
.streamSlider{
    height: 100%;
    .slick-arrow{
    margin-top: 20px;
    }
    .slick-list{
        position: relative;
        left: 15px;
    }
    .streamItem-overlay{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 10px 0px;
        box-sizing: border-box;
        height: 100%;
    }
    &__info{
        width: 100%;
        top: 30px;
        position: relative;
        -webkit-box-pack: justify;
        justify-content: space-between;
        opacity: 0;
        width: 90%;
        /* margin: 0 auto; */
        padding: 5px 10px;
        background-color: rgba(128, 128, 128, 0.7);
        height: 20px;
    display: flex;
    }
    &__actions{
        height: 30px;
        // background: $background-dark-grey;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 20px;
        justify-content: flex-start;
    }
    &__become-streamer{
        height: 100%;
        text-align: center;
        width: 50%;
        background-image: linear-gradient($gold-light, $gold-dark);
        border-radius: 15px;
        line-height: 30px;
        color: $white-dark;
        @include mq('phone-wide', max){
            font-size: .8em;
        }
    }
    &__header{
        display: flex;
        justify-content: flex-start;
        height: 30px;
        align-items: center;
        margin-top: 20px;
        @include mq('phone-wide', max){
            margin-top: 90px;
            padding: 0 15px;
        }
    }
   &__search{
    content: ' ';
    cursor: pointer;
    display: block;
    position: relative;
    background-image: url('../images/main/header/search.png');
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
    margin-left: 20px;
   }
   &__search-block{
       width: 30%;
       height: 30px;
   }
   &__search-suggestions{
    width: 100%;
    position: relative;
    top: -5px;
    background: $white-dark;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    text-align: center;
    font-size: 12px;
    padding: 3px;
    box-sizing: border-box;
   }
  &__search-input{
      width: 40%;
      height: 30px;
      text-align: center;
      background-color: $background-dark-grey;
      border-radius: 15px;    
      border: none;
      outline: none;
      font-size: 12px;
      color: $white-dark;
      margin-left: 20px;
      border: 1px solid $white-dark;
      @include mq('phone-wide', max){
        width: 70%;
      }
    }
    &__mobile{
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__stream-name{
        width: auto;
        height: 15px;
        line-height: 15px;
        position: relative;
    }
    &__stream-spectators{
        width: auto;
        height: 15px;
        line-height: 15px;
        position: relative;
    }
    &__stream-spectators::before {
        position: absolute;
        content: '';
        display: block;
        border-radius: 50%;
        transform: translateY(-50%);
        left: -15px;
        top: 7px;
        height: 10px;
        width: 10px;
        background: $red;
      }
    &__arrow-right{
        background-image: url('../images/main/slider/button-arrow-right-small.png');
        background-size: 100% 100%;
        width: 32px;
        height: 55px;
        }
    &__arrow-left{
        background-image: url('../images/main/slider/button-arrow-left-small.png');
        background-size: 100% 100%;
        width: 32px;
        height: 55px;
    }
    &__background{
        display: flex;
        height: 150px;
        img{
            filter: blur(3px);
        }
    }
    &__image{
        width: 100%;
        height: 100%;
    }
    &__stream{
        border: 2px solid #1d61c7;
        border-radius: 2px;
        height: 220px;
         overflow: hidden;
         display: flex;
         align-items: center;
         width: 90%;
         position: relative;
         .streamItem-overlay{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 10px 0px;
            box-sizing: border-box;
            height: 100%;
            div {
                color: $white-dark;
            }
        }
        &:hover {
            .streamItem-overlay {
              background: transparent;
              .streamSlider__info{
                transition: all 0.5s;
                opacity: 1;
                transform: translateY(-20px);
      
              }
            }
        }
    }
  
    &__stream-container{
        position: absolute;
        display: flex;
        align-items: center;
        width: 95%;
        justify-content: space-between;
    }
    .slick-track{
        height: 120px;
        display: flex;
        justify-content: space-evenly;
    }
    .slick-slide{
        width: 200px!important;
        height: 100%;
        // this margin is needed to udjust first slide position
        margin-right: 25px;
        // border: 4px solid $blue-dark;
        // border-radius: 5px;
    }

    @include mq('phone-wide', max){
        width: 100%;
        left: 0px;
        .slick-slide{
            width: 50%!important;
            padding: 0px;
            height: 100%;
            border: none;
            border-radius: 0px;
        }
        &__arrow-right{
            background-image: url('../images/main/slider/button-arrow-right-small.png');
            background-size: 100% 100%;
            width: 15px;
            height: 20px;
        }
        &__arrow-left{
            background-image: url('../images/main/slider/button-arrow-left-small.png');
            background-size: 100% 100%;
            width: 15px;
            height: 20px;
        }
     }

}
.twitchStream{
    height: 120px!important;
    @include mq('phone-wide', max){
        height: 160px!important;
        position: relative;
     }
}

.no-border-radius{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

#carousel {
  height: 220px;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  width: 100%;
  }
#carousel div {
  margin: auto;
  width: 75%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  left: 0; right: 0;
  cursor: pointer;
  transition: transform 0.4s ease;
  }
  #carousel div.prev {
    box-shadow: 0 6px 10px 0 rgba(0,0,0,.3), 0 2px 2px 0 rgba(0,0,0,.2);
    transform: translate3d(-15%,0,-100px);
  }
  #carousel div.next {
    box-shadow: 0 6px 10px 0 rgba(0,0,0,.3), 0 2px 2px 0 rgba(0,0,0,.2);
    transform: translate3d(15%,0,-100px);
  }
  #carousel div.prevLeftSecond {
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.37);
    transform: translate3d(-30%,0,-200px);
  }
  #carousel div.nextRightSecond {
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.37);
    transform: translate3d(30%,0,-200px);
  }
  #carousel div img {
    width: 100%;
    transition: width 1s;
    height: 100%;
  }
  #carousel div.hideLeft {
    display: none
  }

  #carousel div.hideRight {
   display: none;
  }
 
  #carousel div.selected {
    box-shadow: 0 13px 25px 0 rgba(0,0,0,.3), 0 11px 7px 0 rgba(0,0,0,.19);
    transform: translate3d(0,0,0);
  }
 
  
  [type=radio] {
    display: none;
  }
  
  #slider {
    height: 35vw;
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
  }
  
  #slider div {
    margin: auto;
    width: 60%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    left: 0; right: 0;
    cursor: pointer;
    transition: transform 0.4s ease;
  }
  
  #s1:checked ~ #slide4, #s2:checked ~ #slide5,
  #s3:checked ~ #slide1, #s4:checked ~ #slide2,
  #s5:checked ~ #slide3 {
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.37);
    transform: translate3d(-30%,0,-200px);
  }
  
  #s1:checked ~ #slide5, #s2:checked ~ #slide1,
  #s3:checked ~ #slide2, #s4:checked ~ #slide3,
  #s5:checked ~ #slide4 {
    box-shadow: 0 6px 10px 0 rgba(0,0,0,.3), 0 2px 2px 0 rgba(0,0,0,.2);
    transform: translate3d(-15%,0,-100px);
  }
  
  #s1:checked ~ #slide1, #s2:checked ~ #slide2,
  #s3:checked ~ #slide3, #s4:checked ~ #slide4,
  #s5:checked ~ #slide5 {
    box-shadow: 0 13px 25px 0 rgba(0,0,0,.3), 0 11px 7px 0 rgba(0,0,0,.19);
    transform: translate3d(0,0,0);
  }
  
  #s1:checked ~ #slide2, #s2:checked ~ #slide3,
  #s3:checked ~ #slide4, #s4:checked ~ #slide5,
  #s5:checked ~ #slide1 {
    box-shadow: 0 6px 10px 0 rgba(0,0,0,.3), 0 2px 2px 0 rgba(0,0,0,.2);
    transform: translate3d(15%,0,-100px);
  }
  
  #s1:checked ~ #slide3, #s2:checked ~ #slide4,
  #s3:checked ~ #slide5, #s4:checked ~ #slide1,
  #s5:checked ~ #slide2 {
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.37);
    transform: translate3d(30%,0,-200px);
  }
  
  #slide1 { background: #00BCD4 }
  #slide2 { background: #4CAF50 }
  #slide3 { background: #CDDC39 }
  #slide4 { background: #FFC107 }
  #slide5 { background: #FF5722 }