.streams{
    // PAY ATTENTION
    &__search{
        position: absolute;
        top: -16px;
        padding-top: inherit;
        padding-right: inherit;
        right: 0;
    }
    &__main {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
        grid-gap: 16px;
    }
}

.stream-card{
    max-width: 480px;
    height: 169px;
    &__overlay{
        background-size: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
    }
    
}