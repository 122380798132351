@import '../../assets/style/partials/variables.scss';
// @TODO refactor. replace themes with views. remove importants

._button-base{
    cursor: pointer;
    border-radius: 10px;
    letter-spacing: 0.7px;
    display: block;
}

.view--ghost{
    border: 1px solid $white;
    background-color: transparent!important;
    box-shadow: none!important;
}
.view--link{
    border: none;
    background-color: transparent!important;
    box-shadow: none!important;
}

.button-size{
    &--large {
        height: 39px;
        padding: 12px 40px;
    }
    &--medium{

    }
    &--small{
        height: 25px;
        padding: 3px 6px;
    }
}

.button-colors{
    &--grey{
        background-color: $gray-77;
    }
    &--dark-magenta{
        background-color: $dark-magenta;
    }
}