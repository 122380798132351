.activity-create{
  background-color: #000;
  border: 3px solid #fff;
  border-radius: 5px;
  width: 200px;
  height: auto;
  box-sizing: border-box;
  margin: 5px;
  display: inline-block;
  &__delete{
    cursor: pointer;
    top: 10px;
    position: absolute;
    right: 10px;
    width: 15px;
    height: 15px;
    opacity: 0.75;
    background-image: url(/assets/close-icon.png);
    background-repeat: no-repeat;
    background-size: 100%;
    transition: all 0.3s;
    background-color: rgba(35, 35, 35, 1);
    &:hover{
        opacity: 1;
    }
    @include mq('phone-wide', max){
    width: 20px;
    height: 20px;
    }
}
  
  textarea{
    resize: none;
  }
  &_voting{
    width: 200px;
  }
  &__header{
    height: 30px;
    width: 100%;
    display: flex;
    padding: 5px 10px; 
    justify-content: space-between;
    box-sizing: border-box;
    &_prediction{
      height: 120%;
      min-height: 30px;
    }
  }
  &__paid-action-mode{
    cursor: pointer;
    font-size: 10px;
    color: $white-dark;
    background-color: $background-grey;
    border-radius: 15px;
    height: 20px;
    line-height: 20px;
    padding: 0px 10px;  
  }
  // &__paid-action-mode::after{
  //       display: block;
  //       position: absolute;
  //       width: 30%;
  //       right: 0px;
  //       border-bottom: 4px solid blue;
  //       margin: 3px 0 5px 0;
  //       content: " ";
  // }
  .active::after{
    border-bottom: 3px solid blue;
    display: block;
    content: ' ';
    width: 100%;
    height: 1px;
  }
    &__stream-name{
        height: 100%;
        font-size: $smallest;
        font-family: $ubuntu-medium;
        line-height: 100%;
        letter-spacing: normal;
        text-align: left;
        color: #f7f7f7;
    }
    &__activity-name{
      margin-left: 10px;
    }
    &__trigger{
        display: block;
        width: 75px;
        font-family: $ubuntu-medium;
        font-size: $smallest;
        line-height: 10px;
        letter-spacing: normal;
        text-align: right;
        color: #f7f7f7;
        margin-bottom: 10px;
      }
    
      &__trigger::after{
        display: block;
        position: absolute;
        width: 30%;
        right: 5px;
        border-bottom: 4px solid gold;
        margin: 3px 0 5px 0;
        content: " ";
      }
      &__row{
        display: flex;
        justify-content: space-around;
        padding: 5px;
        text-align: center;
        align-items: center;
        &_voting{
          justify-content: center;
          align-items: center;
        }
        &_wrap{
          flex-wrap: wrap;
        }
        &_between{
          justify-content: space-between;
        }
      }
      &__option{
        cursor: pointer;
        width: 120px;
        font-family: $ubuntu-medium;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: normal;
        text-align: left;
        color: #d1cdcd;
        padding: 3px 0;
      }
      &__description{
        line-height: 15px;
        font-size: $super-small;
        color: $white-dark;
        background-color: $background-grey;
        // height: 60px;
        overflow: hidden;
        outline: none;
        border-radius: 10px;
        width: 100%; 
        padding: 5px;
        text-align: center;
        &_voting{
          width: 100%;
        }
      }
      &__settings{
        height: 40px;
      }
      &__mode{
        cursor: pointer;
        font-size: 10px;
        color: $white-dark;
        text-align: right;
        position: relative;
        width: 100%;
        padding-right: 5px;
        bottom: 10px; 
        // ))
      }
      &__aside{
        width: 80px;
        display: flex;
        justify-content: space-between;
      }
      &__orbs{
        font-size: $smallest;
        cursor: pointer;
      }
      &__cubes{
        font-size: $smallest;
        cursor: pointer;
      }
      &__label{
        padding: 0 10px;
        width: 100%;
        text-align: left;
        font-size: $smallest;
        &_inline{
          width: auto;
          padding: 0 5px;
        }
      }
      &__outcome{
        width: 95%;
        background-color: $background-grey;
        border-radius: 10px;
        color: $white-dark;
        text-align: center;
        overflow: hidden;
        outline: none;
        font-size: 14px;
        padding: 5px;
        line-height: 15px;
        &_voting{
          margin: 5px 0;
        }
        &_hover:hover{
          border: 1px solid green;
          cursor: pointer;
        }
      }
  
      &__button{
        cursor: pointer;
        width: 62px;
        background-color: $background-grey;
        height: 20px;
        border-radius: 10px;
        color: $white-dark;
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        &_medium{
          width: 80px;
        }
      }
      .b-dropdown__wrapper{
        top: 5px;
        background-color: transparent;
        background-color: $background-grey;
        display: flex;
        align-items: center;
      }
      .b-dropdown__content{
        background-color: transparent;
        align-items: center;
        width: 140px;
    
      }
      .b-dropdown__arrow svg{
        visibility: hidden!important;
      }
      .b-dropdown__trigger{
        width: 100px;
      }
      .activity-create__trigger{
        width: 75px;
      }
      .time-to-accept{
        width: 65px;
        height: 25px;
        background: $background-grey;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        color: $white-dark;
        input {
          outline: none;
        }
        &__hh{
            width: 50%;
            height: 100%;
            background-color: transparent;
            border: none;
            border-right: 1px solid #fff;
            color: $white-dark;
            text-align: center;
            font-size: $smallest;
        }
        &__mm{
          width: 50%;
          height: 100%;
          background-color: transparent;
          border: none;
          color: $white-dark;
          text-align: center;
          font-size: $smallest;
        }
      }
}