@import './partials/variables.scss';
// base classes 

.pointer{
  cursor: pointer;
}

.disabled{
  filter: grayscale(100%);
  pointer-events: none;
  opacity: 0.5;
}

html{
  font-size: 10px;
}

.h1{ 
  font-size: 4.2rem;
  line-height: 4.6rem;
}
.h2{ font-size: 1.5rem }
.h3{ font-size: 1.25rem }
.h4{ font-size: 1rem }
.h5{ font-size: .875rem }
.h6{ font-size: .75rem }

// typography 
.t1{
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.t2{
  font-size: 1.6rem;
  line-height: 2.2rem;
}

.t3{
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.t4{
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.t5{
  font-size: 1rem;
  line-height: 1.2rem;
}

.t6{
  font-size: 0.8rem;
  line-height: 1rem;
}


.font-family-inherit{ font-family:inherit }
.font-size-inherit{ font-size:inherit }
.text-decoration-none{ text-decoration:none }

.bold{ font-weight: bold; font-weight: bold }
.regular{ font-weight:normal }
.italic{ font-style:italic }
.caps{ text-transform:uppercase; letter-spacing: .2em; }

.left-align{ text-align:left }
.center{ text-align:center }
.right-align{ text-align:right }
.justify{ text-align:justify }

.nowrap{ white-space:nowrap }
.break-word{ word-wrap:break-word }

.line-height-1{ line-height: 1 }
.line-height-2{ line-height: 1.125 }
.line-height-3{ line-height: 1.25 }
.line-height-4{ line-height: 1.5 }

.list-style-none{ list-style:none }
.underline{ text-decoration:underline }

.truncate{
  max-width:100%;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

.list-reset{
  list-style:none;
  padding-left:0;
}

.inline{ display:inline }
.block{ display:block }
.inline-block{ display:inline-block }
.table{ display:table }
.table-cell{ display:table-cell }

.overflow-hidden{ overflow:hidden }
.overflow-scroll{ overflow:scroll }
.overflow-auto{ overflow:auto }

.clearfix:before,
.clearfix:after{
  content:" ";
  display:table
}
.clearfix:after{ clear:both }

.left{ float:left }
.right{ float:right }

.fit{ max-width:100% }

.max-width-1{ max-width: 24rem }
.max-width-2{ max-width: 32rem }
.max-width-3{ max-width: 48rem }
.max-width-4{ max-width: 64rem }

.border-box{ box-sizing:border-box }

.align-baseline{ vertical-align:baseline }
.align-top{ vertical-align:top }
.align-middle{ vertical-align:middle }
.align-bottom{ vertical-align:bottom }

.m0{ margin:0 }
.mt0{ margin-top:0 }
.mr0{ margin-right:0 }
.mb0{ margin-bottom:0 }
.ml0{ margin-left:0 }
.mx0{ margin-left:0; margin-right:0 }
.my0{ margin-top:0; margin-bottom:0 }

.m1{ margin: .5rem }
.mt1{ margin-top: .5rem }
.mr1{ margin-right: .5rem }
.mb1{ margin-bottom: .5rem }
.ml1{ margin-left: .5rem }
.mx1{ margin-left: .5rem; margin-right: .5rem }
.my1{ margin-top: .5rem; margin-bottom: .5rem }

.m2{ margin: 1rem }
.mt2{ margin-top: 1rem }
.mr2{ margin-right: 1rem }
.mb2{ margin-bottom: 1rem }
.ml2{ margin-left: 1rem }
.mx2{ margin-left: 1rem; margin-right: 1rem }
.my2{ margin-top: 1rem; margin-bottom: 1rem }

.m3{ margin: 2rem }
.mt3{ margin-top: 2rem }
.mr3{ margin-right: 2rem }
.mb3{ margin-bottom: 2rem }
.ml3{ margin-left: 2rem }
.mx3{ margin-left: 2rem; margin-right: 2rem }
.my3{ margin-top: 2rem; margin-bottom: 2rem }

.m4{ margin: 4rem }
.mt4{ margin-top: 4rem }
.mr4{ margin-right: 4rem }
.mb4{ margin-bottom: 4rem }
.ml4{ margin-left: 4rem }
.mx4{ margin-left: 4rem; margin-right: 4rem }
.my4{ margin-top: 4rem; margin-bottom: 4rem }

.m5{ margin: 5rem }
.mt5{ margin-top: 5rem }
.mr5{ margin-right: 5rem }
.mb5{ margin-bottom: 5rem }
.ml5{ margin-left: 5rem }
.mx5{ margin-left: 5rem; margin-right: 5rem }
.my5{ margin-top: 5rem; margin-bottom: 5rem }

.m6{ margin: 6rem }
.mt6{ margin-top: 6rem }
.mr6{ margin-right: 6rem }
.mb6{ margin-bottom: 6rem }
.ml6{ margin-left: 6rem }
.mx6{ margin-left: 6rem; margin-right: 6rem }
.my6{ margin-top: 6rem; margin-bottom: 6rem }

.mxn1{ margin-left: -.5rem; margin-right: -.5rem; }
.mxn2{ margin-left: -1rem; margin-right: -1rem; }
.mxn3{ margin-left: -2rem; margin-right: -2rem; }
.mxn4{ margin-left: -4rem; margin-right: -4rem; }

.ml-auto{ margin-left:auto }
.mr-auto{ margin-right:auto }
.mx-auto{ margin-left:auto; margin-right:auto; }

.p0{ padding:0 }
.pt0{ padding-top:0 }
.pr0{ padding-right:0 }
.pb0{ padding-bottom:0 }
.pl0{ padding-left:0 }
.px0{ padding-left:0; padding-right:0 }
.py0{ padding-top:0;  padding-bottom:0 }

.p1{ padding: .5rem }
.pt1{ padding-top: .5rem }
.pr1{ padding-right: .5rem }
.pb1{ padding-bottom: .5rem }
.pl1{ padding-left: .5rem }
.py1{ padding-top: .5rem; padding-bottom: .5rem }
.px1{ padding-left: .5rem; padding-right: .5rem }

.p2{ padding: 1rem }
.pt2{ padding-top: 1rem }
.pr2{ padding-right: 1rem }
.pb2{ padding-bottom: 1rem }
.pl2{ padding-left: 1rem }
.py2{ padding-top: 1rem; padding-bottom: 1rem }
.px2{ padding-left: 1rem; padding-right: 1rem }

.p3{ padding: 2rem }
.pt3{ padding-top: 2rem }
.pr3{ padding-right: 2rem }
.pb3{ padding-bottom: 2rem }
.pl3{ padding-left: 2rem }
.py3{ padding-top: 2rem; padding-bottom: 2rem }
.px3{ padding-left: 2rem; padding-right: 2rem }

.p4{ padding: 4rem }
.pt4{ padding-top: 4rem }
.pr4{ padding-right: 4rem }
.pb4{ padding-bottom: 4rem }
.pl4{ padding-left: 4rem }
.py4{ padding-top: 4rem; padding-bottom: 4rem }
.px4{ padding-left: 4rem; padding-right: 4rem }

.p5{ padding: 5rem }
.pt5{ padding-top: 5rem }
.pr5{ padding-right: 5rem }
.pb5{ padding-bottom: 5rem }
.pl5{ padding-left: 5rem }
.py5{ padding-top: 5rem; padding-bottom: 5rem }
.px5{ padding-left: 5rem; padding-right: 5rem }

.p6{ padding: 6rem }
.pt6{ padding-top: 6rem }
.pr6{ padding-right: 6rem }
.pb6{ padding-bottom: 6rem }
.pl6{ padding-left: 6rem }
.py6{ padding-top: 6rem; padding-bottom: 6rem }
.px6{ padding-left: 6rem; padding-right: 6rem }


.col{
  float:left;
  box-sizing:border-box;
}

.col-right{
  float:right;
  box-sizing:border-box;
}

.col-1{
  width:8.33333%;
}

.col-2{
  width:16.66667%;
}

.col-3{
  width:25%;
}

.col-4{
  width:33.33333%;
}

.col-5{
  width:41.66667%;
}

.col-6{
  width:50%;
}

.col-7{
  width:58.33333%;
}

.col-8{
  width:66.66667%;
}

.col-9{
  width:75%;
}

.col-10{
  width:83.33333%;
}

.col-11{
  width:91.66667%;
}

.col-12{
  width:100%;
}
@media (min-width: 40em){

  .sm-col{
    float:left;
    box-sizing:border-box;
  }

  .sm-col-right{
    float:right;
    box-sizing:border-box;
  }

  .sm-col-1{
    width:8.33333%;
  }

  .sm-col-2{
    width:16.66667%;
  }

  .sm-col-3{
    width:25%;
  }

  .sm-col-4{
    width:33.33333%;
  }

  .sm-col-5{
    width:41.66667%;
  }

  .sm-col-6{
    width:50%;
  }

  .sm-col-7{
    width:58.33333%;
  }

  .sm-col-8{
    width:66.66667%;
  }

  .sm-col-9{
    width:75%;
  }

  .sm-col-10{
    width:83.33333%;
  }

  .sm-col-11{
    width:91.66667%;
  }

  .sm-col-12{
    width:100%;
  }

}
@media (min-width: 52em){

  .md-col{
    float:left;
    box-sizing:border-box;
  }

  .md-col-right{
    float:right;
    box-sizing:border-box;
  }

  .md-col-1{
    width:8.33333%;
  }

  .md-col-2{
    width:16.66667%;
  }

  .md-col-3{
    width:25%;
  }

  .md-col-4{
    width:33.33333%;
  }

  .md-col-5{
    width:41.66667%;
  }

  .md-col-6{
    width:50%;
  }

  .md-col-7{
    width:58.33333%;
  }

  .md-col-8{
    width:66.66667%;
  }

  .md-col-9{
    width:75%;
  }

  .md-col-10{
    width:83.33333%;
  }

  .md-col-11{
    width:91.66667%;
  }

  .md-col-12{
    width:100%;
  }

}
@media (min-width: 64em){

  .lg-col{
    float:left;
    box-sizing:border-box;
  }

  .lg-col-right{
    float:right;
    box-sizing:border-box;
  }

  .lg-col-1{
    width:8.33333%;
  }

  .lg-col-2{
    width:16.66667%;
  }

  .lg-col-3{
    width:25%;
  }

  .lg-col-4{
    width:33.33333%;
  }

  .lg-col-5{
    width:41.66667%;
  }

  .lg-col-6{
    width:50%;
  }

  .lg-col-7{
    width:58.33333%;
  }

  .lg-col-8{
    width:66.66667%;
  }

  .lg-col-9{
    width:75%;
  }

  .lg-col-10{
    width:83.33333%;
  }

  .lg-col-11{
    width:91.66667%;
  }

  .lg-col-12{
    width:100%;
  }

}
.flex{ display:-webkit-box; display:-webkit-flex; display:-ms-flexbox; display:flex }

@media (min-width: 40em){
  .sm-flex{ display:-webkit-box; display:-webkit-flex; display:-ms-flexbox; display:flex }
}

@media (min-width: 52em){
  .md-flex{ display:-webkit-box; display:-webkit-flex; display:-ms-flexbox; display:flex }
}

@media (min-width: 64em){
  .lg-flex{ display:-webkit-box; display:-webkit-flex; display:-ms-flexbox; display:flex }
}

.flex-column{ -webkit-box-orient:vertical; -webkit-box-direction:normal; -webkit-flex-direction:column; -ms-flex-direction:column; flex-direction:column }
.flex-wrap{ -webkit-flex-wrap:wrap; -ms-flex-wrap:wrap; flex-wrap:wrap }

.items-start{ -webkit-box-align:start; -webkit-align-items:flex-start; -ms-flex-align:start; -ms-grid-row-align:flex-start; align-items:flex-start }
.items-end{ -webkit-box-align:end; -webkit-align-items:flex-end; -ms-flex-align:end; -ms-grid-row-align:flex-end; align-items:flex-end }
.items-center{ -webkit-box-align:center; -webkit-align-items:center; -ms-flex-align:center; -ms-grid-row-align:center; align-items:center }
.items-baseline{ -webkit-box-align:baseline; -webkit-align-items:baseline; -ms-flex-align:baseline; -ms-grid-row-align:baseline; align-items:baseline }
.items-stretch{ -webkit-box-align:stretch; -webkit-align-items:stretch; -ms-flex-align:stretch; -ms-grid-row-align:stretch; align-items:stretch }

.self-start{ -webkit-align-self:flex-start; -ms-flex-item-align:start; align-self:flex-start }
.self-end{ -webkit-align-self:flex-end; -ms-flex-item-align:end; align-self:flex-end }
.self-center{ -webkit-align-self:center; -ms-flex-item-align:center; align-self:center }
.self-baseline{ -webkit-align-self:baseline; -ms-flex-item-align:baseline; align-self:baseline }
.self-stretch{ -webkit-align-self:stretch; -ms-flex-item-align:stretch; align-self:stretch }

.justify-start{ -webkit-box-pack:start; -webkit-justify-content:flex-start; -ms-flex-pack:start; justify-content:flex-start }
.justify-end{ -webkit-box-pack:end; -webkit-justify-content:flex-end; -ms-flex-pack:end; justify-content:flex-end }
.justify-center{ -webkit-box-pack:center; -webkit-justify-content:center; -ms-flex-pack:center; justify-content:center }
.justify-between{ -webkit-box-pack:justify; -webkit-justify-content:space-between; -ms-flex-pack:justify; justify-content:space-between }
.justify-around{ -webkit-justify-content:space-around; -ms-flex-pack:distribute; justify-content:space-around }

.content-start{ -webkit-align-content:flex-start; -ms-flex-line-pack:start; align-content:flex-start }
.content-end{ -webkit-align-content:flex-end; -ms-flex-line-pack:end; align-content:flex-end }
.content-center{ -webkit-align-content:center; -ms-flex-line-pack:center; align-content:center }
.content-between{ -webkit-align-content:space-between; -ms-flex-line-pack:justify; align-content:space-between }
.content-around{ -webkit-align-content:space-around; -ms-flex-line-pack:distribute; align-content:space-around }
.content-stretch{ -webkit-align-content:stretch; -ms-flex-line-pack:stretch; align-content:stretch }
.flex-auto{
  -webkit-box-flex:1;
  -webkit-flex:1 1 auto;
      -ms-flex:1 1 auto;
          flex:1 1 auto;
  min-width:0;
  min-height:0;
}
.flex-none{ -webkit-box-flex:0; -webkit-flex:none; -ms-flex:none; flex:none }

.order-0{ -webkit-box-ordinal-group:1; -webkit-order:0; -ms-flex-order:0; order:0 }
.order-1{ -webkit-box-ordinal-group:2; -webkit-order:1; -ms-flex-order:1; order:1 }
.order-2{ -webkit-box-ordinal-group:3; -webkit-order:2; -ms-flex-order:2; order:2 }
.order-3{ -webkit-box-ordinal-group:4; -webkit-order:3; -ms-flex-order:3; order:3 }
.order-last{ -webkit-box-ordinal-group:100000; -webkit-order:99999; -ms-flex-order:99999; order:99999 }

.relative{ position:relative }
.absolute{ position:absolute }
.fixed{ position:fixed }

.top-0{ top:0 }
.right-0{ right:0 }
.bottom-0{ bottom:0 }
.left-0{ left:0 }

.z1{ z-index: 1 }
.z2{ z-index: 2 }
.z3{ z-index: 3 }
.z4{ z-index: 4 }

.border{
  border-style:solid;
  border-width: 1px;
}

.border-top{
  border-top-style:solid;
  border-top-width: 1px;
}

.border-right{
  border-right-style:solid;
  border-right-width: 1px;
}

.border-bottom{
  border-bottom-style:solid;
  border-bottom-width: 1px;
}

.border-left{
  border-left-style:solid;
  border-left-width: 1px;
}

.border-none{ border:0 }

.rounded{ border-radius: 20px }
.circle{ border-radius:50% }

.rounded-top{ border-radius: 20px 20px 0 0 }
.rounded-right{ border-radius: 0 20px 20px 0 }
.rounded-bottom{ border-radius: 0 0 20px 20px }
.rounded-left{ border-radius: 20px 0 0 20px }

.not-rounded{ border-radius:0 }

.hide{
  position:absolute !important;
  height:1px;
  width:1px;
  overflow:hidden;
  clip:rect(1px, 1px, 1px, 1px);
}

.dark-magenta{
  color: $dark-magenta;
}

.red {
 color: $red;
}

.black {
  color: #111!important
}

.white {
  color: #fff!important
}

.blue {
  color: #07c
}

.gray {
  color: #ccc
}

.darken {
  color: rgba(0,0,0,.0625)
}

.bg-black {
  background-color: #111
}

.bg-white {
  background-color: #fff
}

.bg-blue {
  background-color: #07c
}

.bg-gray {
  background-color: #ccc
}

.bg-darken {
  background-color: rgba(0,0,0,.0625)
}

.bg-dark-magenta{
  background-color: $dark-magenta;
}

@media (max-width: 40em){
  .xs-hide{ display:none !important }
}

@media (min-width: 40em) and (max-width: 52em){
  .sm-hide{ display:none !important }
}

@media (min-width: 52em) and (max-width: 64em){
  .md-hide{ display:none !important }
}

@media (min-width: 64em){
  .lg-hide{ display:none !important }
}

.display-none{ display:none !important }


// reset part 

input{
  outline: none;
  border: none;
}

button{
  background: none;
  border: none;
  outline: none;
}

th{
  font-weight: inherit;
}

.dashed{
    background-image: linear-gradient(to right, orange 75%, transparent 75%);
    background-position: 0 1.04em;
    background-repeat: repeat-x;
    background-size: 8px 5px;
    padding-right: 5px;
}