.activity{
    width: 215px;
    margin: 10px auto;
    display: flex;
    padding: 5px;
    flex-direction: column;
    background-color: #1b1b1b;
    color: $white-dark;
    &__subheader{
        font-size: 12px;
        text-align: left;
        margin-top: 4px;
        &--client{
            padding: 5px;
        }
    }
    &__show-donators{
        text-align: center;
        width: 100%;
        display: block;
        cursor: pointer;
        &:hover{
            color: $gold-dark;
        }
    }
    &__donators{
        margin: 5px;
        ul{
            margin-bottom: 10px;
        }
    }
    &__donator-row{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: left;
    }
    &__doit{
        width: 100%;
        display: flex;
        margin-top: 10px;
    }
    &__ok{
        width: 30px;
        height: 30px;
        background-image: url(../images/main/redesign/ok.png);
        background-size: 100%;
        margin-right: 10px;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    &__fail{
        width: 30px;
        height: 30px;
        background-image: url(../images/main/redesign/fail.png);
        background-size: 100%;
        margin-left: 10px;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    &__wrapper{
        background-image: linear-gradient(#1b1b1b, #1b1b1b), radial-gradient(circle at top left, $gold-light, $gold-dark);
        background-origin: border-box;
        background-clip: content-box, border-box;
        border-radius: 5%;
        padding: 5px;
        margin: 15px auto;
        width: 230px;
        &.hide{
            background: none;
        }
    }
    &__header{
        display: flex;
        justify-content: space-between;
        padding: 5px;
        position: relative;
        height: 30px;
    }
    &__underline{
        // display: block;
        // height: 1px;
        // width: 20%;
        // position: absolute;
        // border-bottom: 3px solid $gold-light;
        // top: 80%;
        // &_blue{
        //     border-bottom: 3px solid #2570ad; 
        // }
    }
    .time-to-accept{
        width: 58px;
        height: 20px;
        background: $background-grey;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        color: $white-dark;
        input {
          outline: none;
        }
        &__hh{
            width: 50%;
            height: 100%;
            background-color: transparent;
            border: none;
            border-right: 1px solid #fff;
            color: $white-dark;
            text-align: center;
            font-size: $super-small;
        }
        &__mm{
          width: 50%;
          height: 100%;
          background-color: transparent;
          border: none;
          color: $white-dark;
          text-align: center;
          font-size: $super-small;
        }
      }
    &__author{
        font-size: 10px;
        font-family: $ubuntu-medium;
        color: $white-dark;
        line-height: 1.33;
    }

    &__label{
        font-size: 18px;
        padding: 0 2px;
        font-family: $ubuntu-regular;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: $white-dark;
        &_show-more{
            width: 100%;
            text-align: center;
            cursor: pointer;
            padding: 5px 10px;
        }
    }
    &__label_underline::after{
        content: ' ';
        display: block;
        height: 1px;
        width: 20%;
        position: absolute;
        border-bottom: 3px solid $gold-light;
        top: 65%;
    }
    &__label_underline-cyan::after{
        content: ' ';
        display: block;
        height: 1px;
        width: 20%;
        position: absolute;
        border-bottom: 3px solid #4b7bec;
        top: 65%;
    }
    &__price{
        color: $white-dark;
        font-size: $smallest;
        display: flex;
        align-items: center;
        height: 20px;
    }
    &__alert{
        border: 2px solid #ffc837;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
    }
    &__voting-option{
        border: 1px solid $white-dark;
        border-radius: 15px;
        display: block;
        color: $white-dark;
        height: 30px;
        width: 100%;
        text-align: center;
        font-size: 10px;
    }
    &__button{
        cursor: pointer;
        position: relative;
        width: 60%;
        height: 30px;
        font-family: $ubuntu-medium;
        font-size: $smallest;
        line-height: 32px;
        letter-spacing: normal;
        text-align: center;
        color: #f7f7f7;
        background-image: linear-gradient($gold-light, $gold-dark);
        border-radius: 16px;
        z-index: 10;
        transition: all .3s ease;
        &:hover{
            box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
        }
        &_inline{
            cursor: pointer;
            position: absolute;
            width: 40%;
            height: 32px;
            top: 0;
            left: 25px;
            font-family: $ubuntu-medium;
            font-size: $smallest;
            line-height: 32px;
            letter-spacing: normal;
            text-align: center;
            color: #f7f7f7;
            border-radius: 16px;
            z-index: 10;
            transition: all .3s ease;
            &:hover{
                box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
            }
        }
        &_voting{
            background-color: $background-dark-grey;
            left: 5px; 
            width: 50%;
        }
        &_purple{
           background-color: #4891cc;  
        }
        &_cyan{
            background-color: #5eb1f5; 
            background-image: none;
        }
        &_gold{
            background-color: #ffc837;
        }

    }
    &__bank{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        height: 32px;
        border: 1px solid white;
        background: transparent;
        font-family: $ubuntu-medium;
        font-size: $smallest;
        line-height: 32px;
        letter-spacing: normal;
        text-align: right;
        color: #ffffff;
        border-radius: 16px;
        box-sizing: border-box;
        &_inline{
            position: absolute;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            top: 0px;
            left: 65px;
            width: 60%;
            height: 32px;
            border: 1px solid white;
            background: transparent;
            font-family: $ubuntu-medium;
            font-size: $smallest;
            line-height: 32px;
            letter-spacing: normal;
            text-align: right;
            padding-right: 20px;
            color: #ffffff;
            border-radius: 16px;
            box-sizing: border-box;
        }
        &_voting{
            background-color: transparent;
            left: calc(100% - 105px);
            padding-right: 10px;
        }
    }
    &__input{
        position: relative;
        border: none;
        line-height: 30px;
        width: 60px;
        left: 20px;
        text-align: right;
        outline: none;
    }
    &__join{
        display: flex;
        position: relative;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 72px;
        padding: 10px 5px;
        margin: 0 auto;
    }
    &__description{
        padding: 5px;
        font-family: $ubuntu-regular;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #f7f7f7;
        word-break: break-all;
        &_voting{
            background-color: $background-dark-grey;
            border-radius: 15px;
            font-size: 10px;
            text-align: center;
        }
    }
    &__options{
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0 5px;
    }
    &__option{
        position: relative;
        display: flex;
        flex-direction: column;
        height: 40px;
        box-sizing: border-box;
        &_voting{
            padding: 5px 0;
        }
    }
    &__orb{
        width: 12px;
        height: 12px;
        background-image: url('../images/main/redesign/orb.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 5px;
        margin-right: 5px;
    }

    &__cube{
        width: 12px;
        height: 12px;
        background-image: url('../images/main/redesign/cube.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 5px;
    }

    &__group{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    &__status{
        line-height: 15px;
        font-size: 12px;
        color: white;
        width: 125px;
        text-align: right;
    }
    &__bet-desc{
        // height: 20px;
        padding: 10px 5px 10px;
        display: flex;
        justify-content: space-between;
        color: $white-dark;
        font-size: $ultra-small;
        font-family: $ubuntu-regular;
    }
    &__bet-label{
        width: 80%;
        word-break: break-all;
    }
    &__close-icon{
        cursor: pointer;
        background-image: url('../images/main/activities/close.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 16px;
        height: 16px;
        &:hover{
            
        }
    }
    &__expected-win{
        color: $white-dark;
        font-size: $smallest;
        font-family: $ubuntu-regular;
        padding-left: 5px;
        margin-bottom: 7px;
    }
    &__place{
        display: flex;
        justify-content: space-evenly;
    }
    &__place-button{
        cursor: pointer;
        background-color: #5eb1f5;
        width: 35px;
        height: 35px;
        font-family: $ubuntu-medium;
        font-size: $smallest;
        color: $white-dark;
        border-radius: 50%;
        line-height: 35px;
        text-align: center;
        &:hover{
            box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
        }
        &_gold{
            background-color:$gold-light;
            &:hover{
                box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
            }
        }
    }
}

.timer{
    text-align: right;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    
    &__time{
        font-size: $smallest;
        font-family: $ubuntu-medium;
        color: $white-dark;
        margin-left: 10px;
    }
    &__desc{
        font-size: 10px;
        font-family: $ubuntu-medium;
        color: $white-dark;
        margin-left: 10px;
    }

}
.create-challenge{
    &__disclamer{
        font-size: 12px!important;
        width: 100%;
        padding: 0;
        opacity: 0.8;
    }
}