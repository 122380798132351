.profile-personalization{
    &__avatar-big{
        width: 207px;
        height: 207px;
    }
    &__avatar-small{
        width: 93px;
        height: 93px;
    }
    &__page-cover{
        width: 660px;
        height: 207px;
    }
}