.ds-widget-card{
    width: 300px;
    margin-right: 16px;
    position: relative;
    display: inline-block;
   
    &__info-box-tags{
        padding-top: 8px;
    }
    &__img{
        width: 100%;
    }
    &__desc{
        height: 128px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__action-area{
        // margin-top: 8px;
    }
    &__img-wrapper{
        overflow: hidden;
        width: 300px;
        height: 200px;
        position: relative;
    }

    &__hover-overlay{
        padding: 16px;
        box-sizing: border-box;
        background: grey;
        position: absolute;
        text-align: center;
        width: 100%;
        height: 200px;
        top: 100%;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
    }
    &__action-area{
        display: flex;
    }
}

.ds-widget-card__img-wrapper:hover .ds-widget-card__hover-overlay{
        top: 0%;
}