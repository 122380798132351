.post-editor{
    color: $white-dark;
    &__article-name{
        width: 100%;
    }
    &__row{
        display: flex;
    }
    &__langs{
        display: flex;
        justify-content: space-evenly;
        span{
            cursor: pointer;
            width: 100%;
            text-align: center;
        }
    }
    &__categories{
        display: flex;
        flex-direction: column;
    }
    &__add-category{
        display: flex;
        flex-direction: column;
    }
    &__editor-container{
        width: 70%;
        padding: 10px;
        height: 500px;
    }
    &__article-manage-container{
        width: 30%;
        padding: 10px;
        background-color: $background-grey;
    }
    &__close-button{
        cursor: pointer;
        position: relative;
        width: 15px;
        height: 15px;
        margin-left: 25px;
        opacity: 0.75;
        background-image: url(../images/main/help-center/delete.png);
        background-size: 100%;
        transition: all 0.3s;
        &:hover{
            opacity: 1;
        }
        @include mq('phone-wide', max){
        width: 20px;
        height: 20px;
        }
    }
    &__edit-button{
        cursor: pointer;
        position: relative;
        width: 15px;
        height: 15px;
        margin-left: 25px;
        opacity: 0.75;
        background-image: url(../images/main/help-center/edit.png);
        background-size: 100%;
        transition: all 0.3s;
        &:hover{
            opacity: 1;
        }
        @include mq('phone-wide', max){
        width: 20px;
        height: 20px;
        }
    }
    &__save-button{
        cursor: pointer;
        position: relative;
        width: 15px;
        height: 15px;
        margin-left: 25px;
        opacity: 0.75;
        background-image: url(../images/main/help-center/confirm.png);
        background-size: 100%;
        transition: all 0.3s;
        &:hover{
            opacity: 1;
        }
        @include mq('phone-wide', max){
        width: 20px;
        height: 20px;
        }
    }
}

i.mce-i-Button_gold:before {  
    content: "G";    
 }
 i.mce-i-Button_blue:before {  
    content: "B";    
 }