.logo{
   
    display: block;
    font-size: 0;
    &--size-small{
        width: 164px;
        height: 14px;
    }
    &--size-default{
        width: 280px;
        height: 24px;
    }
    &--size-large{
        width: 317px;
        height: 26px;
    }
    &__image{
        width: 100%;
        height: 100%;
    }
}