.custom-scroll {
  min-height: 0;
  min-width: 0; 
  width: 100%;
}
  .custom-scroll .outer-container {
    overflow: hidden; }
    .custom-scroll .outer-container .positioning {
      position: relative; }
    .custom-scroll .outer-container:hover .custom-scrollbar {
      opacity: 1;
      transition-duration: 0.2s; }
  .custom-scroll .inner-container {
    overflow-x: hidden;
    overflow-y: scroll;
    
  }

  .custom-scroll .inner-container::-webkit-scrollbar {
    width: 0px; 
    background: transparent;  
  }
  .custom-scroll .inner-container::-webkit-scrollbar-thumb {
    background: #FF0000;
  }   
    .custom-scroll .inner-container:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.05) 60%, transparent 100%);
      pointer-events: none;
      transition: height 0.1s ease-in;
      will-change: height; }
    .custom-scroll .inner-container.content-scrolled:after {
      height: 5px;
      transition: height 0.15s ease-out; }
  .custom-scroll.scroll-handle-dragged .inner-container {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .custom-scroll .custom-scrollbar {
    position: absolute;
    height: 100%;
    width: 6px;
    right: 3px;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.4s ease-out;
    padding: 6px 0;
    box-sizing: border-box;
    will-change: opacity;
    pointer-events: none; }
    .custom-scroll .custom-scrollbar.custom-scrollbar-rtl {
      right: auto;
      left: 3px; }
  .custom-scroll.scroll-handle-dragged .custom-scrollbar {
    opacity: 1; }
  .custom-scroll .custom-scroll-handle {
    position: absolute;
    width: 100%;
    top: 0; }
  .custom-scroll .inner-handle {
    height: calc(100% - 12px);
    margin-top: 6px;
    background-color: rgba(78, 183, 245, 0.7);
    border-radius: 3px; }
