.bettyTeamVersus {
  margin: 20px 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include mq('phone-wide', max){
    flex-direction: column;
  }
  .vs {
    display: flex;
    margin: 0px 17px;
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
  }

  .team {

    @include mq('phone-wide', max){
      width: 90%;
    } 

    width: 100%;
    display: flex;
    padding: 10px;
    border: 2px solid white;
    border-radius: 10px;

    &.radiant {
      background-color: rgba(21, 21, 45, 0.85);
    }

    &.dire {
      background-color: rgba(45, 26, 0, 0.85);
    }

    .img {
      float: left;
      width: 48px;
      height: 48px;
      margin-right: 5px;
      border: 2px solid white;
      border-radius: 4px;
      background-size: cover;
      background-position: center;
    }

    .title {
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #ffffff;
    }

    .rating {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      color: #ffffff;
    }
  }
}

.predictionColStream {
  .desc_text {
    opacity: 0.8;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #ffffff;
  }
}

.predictionTwitchStream {
  // wtf is this?
   .container{
     height: 480px;
     width: 100%;
     @include mq('phone-wide', max){
      height: auto;
     }
     @include mq('tablet-wide', max){
      height: auto;
     }
   }
  .top {
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    position: relative;

    .wrapper {
      position: relative;
      padding-bottom: 200%;
      transform: translateY(-35.95%);

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .hover {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: calc(100% - 80px);

    img {
      width: 100%;
      height: 100%;
    }

    .timer {
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .block {
        .timer_desc {
          font-size: 30px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.4px;
          text-align: center;
          color: #ffffff;
        }

        .timer_time {
          font-size: 48px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.7px;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }
}

.betty{
  &__team-one{
    height: auto;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  &__team-two{
    height: auto;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  &__overlay{
    z-index: 1001;
  }
  &__amount{
    z-index: 1001;
  }
  &__status{
    left: 110px;
  }
  &__event-preview{
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    display: flex;
    align-content: space-between;
    // flex-wrap: wrap;
    justify-content: flex-end;
    background: $background-grey;
  }
  &__event-preview-item{
    height: 60px;
    width: 80%;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    @include mq('phone-wide', max){
    background-size: 100%;
    }
  }
  &__title{
    margin: 10px;
  }
  &__show-results{
    background: none;
    border: none;
    outline: none;
    padding: 5px;
    color: $white-dark;
    line-height: 10px;
    height: 30px;
    background-color: green;
    align-items: center;
    border-radius: 15px;
    cursor: pointer;
    @include mq('phone-wide', max){
      margin-bottom: 20px;
    }
  }
  &__row{
    height: 73px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  background-color: #232323;
  overflow: hidden;
    @include mq('phone-wide', max){
      .prediction-stream .predictionColStream{
        display: block;
        padding: 20px 5px;
        box-sizing: border-box;
      }
      .prediction .predictionColStream .predictionTwitchStream{
        display: block;
        height: auto;
      }
      .predictionTwitchStream .hover {
      top: 0px;
      }
    }
    .gamesSlider__arrow-left{
      margin-top: 90px;
    }
    .gamesSlider__arrow-right{
      margin-top: 90px;
    }

  .streamSlider .slick-slide{
    margin-right: 0px;
  }
  #gamesSlider{
    height: 220px;
  }
  #gamesSlider .streamItem{
    width: 180px!important;
  }
  .custom-scroll{
    background-color: #232323;
  }
  .predictionTwitchStream{
    margin-top: 20px;
    min-height: 440px;
    background-color: #232323;
  }
  .prediction-stream{
    height: 640px;
  }
  .predictionContainer {
   margin: 0;
}
.predictionContainer:first-child {
  margin: 0;
}
.predictionColPred{
  padding: 0 20px;
  @include mq('phone-wide', max){
    box-sizing: border-box;
  }
}
.predictionTwitchStream .hover {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: calc(100% + 20px);
}
}

.dota{
  @include mq('phone-wide', max){
    background-size: 200% 100%;
    background-position: -20px;
  }
}