.stream-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.overlay-activities-container{
    position: absolute;
    right: -40px;
    transition: 0.3s;
    width: 100px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    border-radius: 0 5px 5px 0;
    height: 100vh;
    &:hover{
        right: 355px;
    }
    .activities{
        padding-left: 60px;
        width: 400px;
        background-color: transparent;
        position: relative;
        &:before{
            content: '';
            background-image: url('../images/main/slider/button-arrow-left-small.png');
            background-size: 100% 100%;
            width: 32px;
            height: 50px;
            margin-top: 50px;
            display: block;
            position: absolute;
            left: 21px;
            top: calc(50vh - 25px);
        }
    }
    .activity{
        background-color: transparent;
    }
    .predictionMainWindowWrapper {
      background-color: transparent !important;
    }
  }
  .stream {
    &__bottom {
      color: $white-dark;
      font-family: $ubuntu-regular;
      padding: 20px;
      background-color: $background-dark-grey;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
  
      @include mq('phone-wide', max) {
        flex-direction: column;
      }
    h3 {
      font-size: 24px;
      font-weight: normal;
      margin-bottom: 1rem;
    }

    h5 {
      font-size: 18px;
      font-weight: normal;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    width: 80%;

    @include mq('phone-wide', max) {
      width: 100%;
    }
  }

  &__right {
    display: flex;
    align-items: center;

    button {
      &:hover {
        box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
      }
    }

    @include mq('phone-wide', max) {
      width: 100%;
    }
  }

  &__report {
    color: #e74c3c;
    text-align: center;
  }

  &__create-challenge {
    padding: 7px 25px;
    margin: 0;
    margin-left: 25px;
    min-width: 150px;
    color: $white-dark;
    font-size: 14px;
    font-weight: bold;
    background-image: linear-gradient(#ffc837, #ff8008);

    &_send {
      min-width: 150px;
      background-image: linear-gradient(#f7f7f7, 30%, #2094fa);
      margin-right: 10px;
    }

    @include mq('phone-wide', max) {
      margin: 0;
      margin-top: 20px;
      font-size: 12px;
      margin-right: 10px;
    }
  }
}

.message-modal {
  width: 515px;
  height: 475px;
  background-color: $background-grey;
  margin: 40px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  border-radius: 0px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */

  }

  @include mq('phone-wide', max) {
    width: 90%;
    margin: 0px auto;
    margin-top: -40px;
    height: 560px;
  }

  .feedbackModalTitle {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../images/main/redesign/dota-2-launch-release@3x.png);
    border-bottom: 4px solid #ffc837;
    height: 105px;
  }

  .feedbackModal-title {
    font-family: $ubuntu-regular;
  }

  .feedbackModalDesc {
    margin-top: 0;
  }

  .feedbackModal-form {
    width: 100%;
    padding: 15px 30px;
    box-sizing: border-box;

    p {
      color: $white-dark;
      font-size: 18px;
      font-family: $ubuntu-regular;
      padding: 0px;

      @include mq('phone-wide', 'max') {
        font-size: 14px;
      }
    }

    input {
      color: #808080;
      height: 41px;
      background-color: #3d3d3d;
      border-radius: 0px;
    }

    textarea {
      color: #808080;
      background-color: #3d3d3d;
      border-radius: 0px;
      height: 133px;
      box-sizing: border-box;
    }
  }

  .feedbackModal-text {
    margin-top: 10px;
  }

  &__cubes-amount {
    background: transparent !important;
    font-size: 22px;
    margin-top: 10px !important;
    padding: 0px !important;
    color: $white-dark !important;
    width: 65px;
    text-align: left;
  }

  &__cubes {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-image: url(/assets/images/main/redesign/cube.png);
    margin-left: 5px;
    background-size: 100% 100%;
    cursor: pointer;
  }

  &__orbs {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-image: url(/assets/images/main/redesign/orb.png);
    margin-left: 5px;
    background-size: 100% 100%;
    cursor: pointer;
  }

  &__desc {
    text-align: center;
    color: $white-dark;
    padding: 10px;
    width: 80%;
  }

  &__send {
    border-radius: 15px !important;
    width: 180px !important;
    height: 32px !important;
    color: $white-dark !important;

    @include mq('phone-wide', 'max') {
      width: 120px !important;
    }
  }

  &__description {}

  &__donation-value {
    width: 120px;

  }

  &__username {
    width: 65%;
    margin: 0;
    height: 40px;
    box-sizing: border-box;

    @include mq('phone-wide', 'max') {
      width: 50%;
    }
  }

  &__playing {
    font-size: 16px;
    opacity: 0.6;
  }

  &__avatar {
    width: 75px;
    height: 75px;

    img {
      width: 75px;
      height: 75px;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;

    @include mq('phone-wide', max) {
      // flex-direction: column;
      // margin-top: 10px;
      // height: 120px;
    }
  }

  &__inline {
    width: 40%;

    @include mq('phone-wide', max) {
      width: 80%;
    }
  }

  &__currency-select {
    width: 100%;
    padding: 2px 10px;

    @include mq('phone-wide', max) {
      width: 40%;
      height: 30px;
      padding: 2px 10px;
    }
  }

  &__label {
    margin: 0;
    color: $white-dark;
  }

  &__wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0px;
    left: 0px;
    z-index: 1000;
  }

  &__button {
    padding: 5px 10px;
    color: $white-dark;
    font-family: $ubuntu-medium;
    font-size: $small;
    display: block;
    margin: 20px auto;
    width: auto !important;

    @include mq('phone-wide', max) {
      margin: 0 auto 20px;
      width: 100px;
      display: block;
    }
  }

  &__form {
    padding: 20px 0;
  }

  &__confirm {
    // height: 40px;
  }

  &__block {
    border-top: 1px solid white;
    display: flex;
    flex-direction: column;
    height: 110px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid white;
    justify-content: space-around;

    a {
      text-decoration: underline;
    }

    i {}

    &__form {
      padding: 20px 0;
    }

    &__head {
      height: 40px;

      @include mq('phone-wide', max) {
        height: 40px;
      }
    }
  }
}