/*
* Third Party
*/
//@import "../../bower_components/normalize.css/normalize.css";

/*
* Custom
*/
@import "partials/utils.scss";
@import "partials/variables.scss";
@import "partials/main-page.scss";
@import "partials/common-blocks.scss";
@import "partials/header.scss";
@import "partials/footer.scss";
@import "partials/about.scss";
@import "partials/leaderboard.scss";
@import "partials/admin.scss";
@import "partials/faq.scss";
@import "partials/profile.scss";
@import "partials/modal-view.scss";
@import "partials/sprite.scss";
@import "partials/dropdown.scss";
@import "partials/flags.scss";
@import "partials/controls.scss";
@import "partials/editor.scss";
@import "partials/admin-stats.scss";
@import "partials/buy-orbs.scss";
@import "partials/feedback-modal.scss";
@import "partials/chat.scss";
@import "partials/activities-list.scss";
@import "partials/activities.scss";
@import "partials/slider.scss";
@import "partials/game-slider.scss";
@import "partials/deposit-modal.scss";
@import "partials/register.scss";
@import "partials/create-challenge.scss";
@import "partials/profile-page.scss";
@import "partials/activities-admin.scss";
@import "partials/activities-create.scss";
@import "partials/social.scss";
@import "partials/stream-list.scss";
@import "partials/transactions.scss";
@import "partials/tooltip.scss";
@import "partials/stream-edit.scss";
@import "partials/stream-moderation.scss";
@import "partials/how-it-works.scss";
@import "partials/stream.scss";
@import "partials/subscribe-to-push.scss";
@import "partials/referals.scss";
@import "partials/betty.scss";
@import "partials/pagination.scss";
@import "partials/theme-ligth.scss";
@import "partials/admin-logs.scss";
@import "partials/post-editor.scss";
@import "partials/help-center.scss";
@import "partials/overlay-creator.scss";

// Admin New
@import "partials/admin-new-controls.scss";
@import "partials/admin-new-predictions.scss";
@import "partials/admin-new-challenges.scss";
@import "partials/admin-new-paid-actions.scss";
@import "partials/admin-new-voting.scss";

// Profile new
@import "partials/profile-new.scss";



// jivo widget must not overlap with cookies agreement row
#jvlabelWrap{
  z-index: 2000;
}
#test:hover .overlay-trigger{
  opacity: 1;
}
.overlay-trigger{
  opacity: 0;
  background: transparent;
  line-height: 55px;
  text-align: center;
  color: #f7f7f7;
  cursor: pointer;
  height: 55px;
  width: 140px;
  position: absolute;
  right: 10%;
  top: 0;
  transition: all 0.3s;
}
.overlay-trigger-overlay{
  cursor: pointer;
  background: transparent;
  height: 30px;
  width: 40px;
  position: relative;
  left: -5%;
  z-index: 1001;
  bottom: -95%;
  }

* {
    margin: 0;
    padding: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}

body, html {
    background-color: black;
    font-family: $montserrat;
}

a, p, h1, h2 {
    font-family: $montserrat;
   
}
a, a:visited, a:active {
  text-decoration: none;
  color: inherit;
}

.container {
    margin: 0 auto;
    width: calc(100% - 160px);
    padding: 0 80px;
    max-width: $desktop-container;
}

.root {
  width: 100vw;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.app__top{

}
.app__main {
  flex-grow: 1;
}
.app__bottom{
  height: 100px;
}

::-webkit-scrollbar {
  width: 15px;
  background-color: #F5F5F5;
}

/**  STYLE 11 */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0,0,0,0.1);
  border: 1px solid #ccc;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $background-grey;
  border: 1px solid #aaa;
}

::-webkit-scrollbar-thumb:hover {
  background: $background-grey;
}

::-webkit-scrollbar-thumb:active {
  background: $background-dark-grey;
}

@media (max-width: 1024px) {
	.container {
		width: auto;
	}
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  //CSS
}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  //CSS
  .container {
    padding: 0 30px;
    width: calc(100% - 60px);
  }
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  //CSS
  .root {
    padding-top: 80px;
  }
  .container {
    padding: 0 10px;
    width: calc(100% - 20px);
  }
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  //CSS
  .root {
    padding-top: 80px;
  }
  .container {
    padding: 0 10px;
  }
}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 823px) {
  //CSS

  .root {
    padding-top: 80px;
  }
  .container {
    padding: 0;
  }
}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  //CSS

  .container {
    padding: 0;
  }
}