
.how-it-works{
    width: 100%;
    background: transparent;
    // margin-top: 45px;
    // margin-left: 45px;
    cursor: default;
    &__join-predictoria{
        border: none;
        outline: none;
        background-image: linear-gradient(#f7f7f7, 30%, #2094fa);
        padding: 5px 20px;
        font-family: $ubuntu-regular;
        color: $white-dark;
        font-size: 18px;
        cursor: pointer;
        border-radius: 15px;
        margin-top: 20px;
        &:hover{
            background-image: linear-gradient(#f7f7f7, 30%, #025eaf); 
        }
    }
    &__overlay{
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
        z-index: 1002;
    }
    &__reg-button{
        outline: none;
        border: 1px solid grey;
        background-color: transparent;
        height: 30px;
        width: 200px;
        color: $white-dark;
        border-radius: 15px;
        padding: 0 10px;
        cursor: pointer;
        &:hover{
            font-weight: bold;
            background-color: rgba(104, 103, 103, 0.336)
        }
    }
    &__learn-more{
        text-decoration: underline; 
        font-size: 18px;
    }

    &__registration{
        height: 40px;
        width: 210px;
        color: #f7f7f7;
        cursor: pointer;
        font-size: 16px;
        &_gold{
            transition: all .3s ease;
            &:hover{
                background-image: linear-gradient(#fad355, #f77c0a);
            }  
        }        
        @include mq('phone-wide', 'max'){
            font-size: 12px;
            width: 180px;
        }
    }
    &__registration-block{
        padding: 0;
        justify-content: space-between;
        width: 50%;
        display: flex;
        @include mq('phone-wide', 'max'){
            width: 90%;
        }
    }
    &__wrapper{
        width: 882px;
        height: 100%;
        margin: 0 auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 1001;
        // background-color: white;
        @include mq('phone-wide', max){
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
        }
    }
    &__head, &__body{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $white-dark;
        // background-image: url('../images/main/redesign/background-dark.png');
    }
    &__bg{
        // background-image: url('../images/main/redesign/background-dark.png'); 
        // margin-top: 10%;
        background-color: $background-dark-grey;
    }
    &__body{
        margin-top: 45px;
    }
    &__heading{
        font-family: $ubuntu-regular;
        font-size: 25px;
        line-height: 1.5;
        text-align: center;
        margin-top: 20px;
        &_left{
            text-align: left;
        }
    }
    &__body-top{
        border-bottom: 9px solid $gold-dark;
        background-image: url(../images/main/redesign/section-two.png);
        background-size: 100%;
        height: 155px;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__bottom{
        border-top: 2px solid $white-dark;
        padding: 20px 0;
    }
    &__close{
        cursor: pointer;
        top: 20px;
        position: absolute;
    /* left: 20px; */
        right: 20px;
        width: 35px;
        height: 35px;
        opacity: 0.75;
        background-image: url('../images/main/redesign/close-icon.png');
        background-size: 100%;
        transition: all 0.3s;
        &:hover{
            opacity: 1;
        }
        @include mq('phone-wide', max){
        width: 20px;
        height: 20px;
        }
    }
    &__logo{
        width: 462px;
        height: 54px;
        background-image: url('../images/logo-predictoria.com.png');
        background-size: cover;
        @include mq('phone-wide', max){
            width: 80%;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    &__link{
        color: $gold-dark;
        text-decoration: underline;
    }
    &__description{
        width: 802px;
        font-family: $ubuntu-regular;
        font-size: 18px;
        text-align: justify;
        padding: 40px;
        padding-bottom: 0px;
        @include mq('phone-wide', max){
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
        }
        &_body{
            text-align: justify;
            font-size: 18px;
            font-weight: bold;
            padding: 0;
            line-height: 30px;
        }
        &_gold{
            font-size: 18px;
            font-weight: bold;
            color: #ff8008;
            text-align: justify;
            line-height: 30px;
        }
        &_center{
            font-size: 24px;
            font-weight: bold;
            padding: 25px 0;
            line-height: 35px;
            text-align: center;
            @include mq('phone-wide', max){
                font-size: 20px;
            }
        }
    }
    &__split-panel{
        width: 100%;
        height: 200px;
        position: relative;
        background-color: $white-dark;
    }
    &__split-panel-wrapper{
        width: 100%;
        margin: 0 auto;
    }
    &__icon{
        border: 10px solid $gold-dark;
        height: 120px;
        width: 120px;
        background-size: 50px 50px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: 50%;
        @include mq('phone-wide', max){
            width: 80px;
            height: 80px;
        }
        &_streamer{
            background-size: 60px 50px;
        }
    }
    &__line{
        display: flex;
        padding: 20px 0 0;
        &_between{
            justify-content: space-between;
        }
    }
    &__percent-blue{
        color: #358eff;
    }
    &__percent-gold{
        color: $gold-dark;
    }
    &__blue{
        background-color: #358eff;
        height: 40px;
        width: 95%;
    }
    &__gold{
        background-color: $gold-dark;
        height: 40px;
        width: 5%;
    }
    &__step{
        width: 140px;
        @include mq('phone-wide', max){
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    &__step:nth-child(1){
        div {
        background-image: url(../images/main/redesign/search.png);
        }
    }
    &__step:nth-child(2){
        div {
            background-image: url(../images/main/redesign/trophy.png);
        }
    }
    &__step:nth-child(3){
        div{
        background-image: url(../images/main/redesign/star.png);
        }
    }
    &__step_streamer:nth-child(1){
        div{
        background-image: url(../images/main/redesign/check.png);
        }
    }
    &__step_streamer:nth-child(2){
        div{
        background-image: url(../images/main/redesign/users.png);
        }
    }
    &__step_streamer:nth-child(3){
        div{
        background-image: url(../images/main/redesign/money.png);
        }
    }
    &__section{
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding: 20px;
        padding-bottom: 0;
        box-sizing: border-box;
        &_column{
            flex-direction: column;
            width: 100%;
        }
    }

    &__orbs{
        background-image: url('/assets/images/main/redesign/orb.png');
        width: 30px;
        height: 30px;
        display: inline-block;
        background-size: 100% 100%;
        position: relative;
        top: 10px;
    }
    &__cubes{
        background-image: url('/assets/images/main/redesign/cube.png');
        width: 30px;
        height: 30px;
        display: inline-block;
        background-size: 100% 100%;
        position: relative;
        top: 10px;
    }
    &__clear{
        width: 180px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin: 20px auto;
    }
    &__become-streamer{
        width: 160px;
        font-size: 14px;
        margin: 0 10px;
        height: 30px;
        line-height: 30px;
    
    }
    .content{
        display: block;
        position: relative;
        margin: 0 auto;
        width: 60%;
        top: 90px;
        font-size: 20px;
        text-align: center;
        @include mq('phone-wide', max){
            width: 60%;
            top: 90px;
            font-size: 20px;
        }
        &_left {
            right: 10px;
            @include mq('phone-wide', max){
            }
        }
        &_right{
            left: 25px;
            @include mq('phone-wide', max){
            }
        }
    }
    .one-is-active {
        & .section-one {
            width: 73%;
           
        }
        
        & .section-two {
            width: 45%;
            // .content{
            //     padding-top: 60%;
            // }
        }
      }
      
      .two-is-active {
        & .section-one {
            width: 45%;
            // .content{
            //     padding-top: 60%;
            // }
        }
        
        & .section-two {
            width: 73%;
        }
      }
      .section-one, .section-two {
        position: absolute;
        height: 200px;
        width: 70%;
        transition: all 0.5s;
        background-size: 800px 300px;
        cursor: pointer;
      }
      .section-one {
        left: 0;
        top: 0;
        width: 63%;

        background-image: url(../images/main/redesign/section-one.png);
        -webkit-clip-path: polygon(0 0, 100% 0%, calc(100% - 100px) 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0%, calc(100% - 100px) 100%, 0% 100%); 
        
        @include mq('phone-wide', max){
         clip-path: polygon(0 0, 100% 0%, calc(100% - 50px) 100%, 0% 100%); 
        }

      }
      .section-two {
        right: 0;
        top: 0;
        width: 55%;

        background-image: url(../images/main/redesign/section-two.png);
        -webkit-clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(180px 0, 100% 0%, 100% 100%, 80px 100%);

        @include mq('phone-wide', max){
            clip-path: polygon(85px 0, 100% 0%, 100% 100%, 35px 100%);
           }
      }
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}
