.profile-stream-management {
  div {
    box-sizing: border-box;
  }

  &__bg-image {
    background-image: url(../images/main/new-profile/dota-2-roshan-roshan-ulfsaar.jpg);
    background-size: cover;
    min-height: 700px;
    min-width: 600px;
  }

  &__settings-container {
    width: 100%;
    padding: 30px 80px;
  }

  &__settings-form {
    margin: 40px 0;

    &>.row-container {
      margin-bottom: 1rem;
    }
  }

  &__choose-image {
    margin-left: 60px;
  }

  &__save-button {
    display: block;
    margin-top: 60px;
  }

  &__modal {
    width: 40vw;
    height: 40vh;
  }

  &__modal-content {
    padding: 2rem;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  &__icon {
    margin-left: 15px;
  }

  &__ok-button {
    margin-top: 20px;
  }

  &__invite-button {
    margin-top: 20px;
  }

  &__choose-image-button {
    margin: 0 auto;
  }

  &__status-button {
    &:hover {
      transition: all 0.3s ease;
      box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7;
    }
  }

  &__admin-link {
    margin-left: 135px;
  }
}

.streaming-platform-selector {
  padding: 40px;

  &__header {
    &:after {
      content: '';
      position: relative;
      display: block;
      margin: 12px auto;
      border-bottom: 4px solid #ff8008;
      height: 1px;
      width: 460px;
    }
  }
}

.streaming-platform {
  margin-top: 40px;
  width: 380px;
  height: 380px;
  background-size: cover;
  position: relative;
  border-radius: 25px;

  &__button {
    position: absolute;
    bottom: 40px;
    left: calc(50% - 90px);
    width: 180px;
    height: 50px;
    margin: 0 auto;
  }

  &_twitch {
    background-image: url('../images/main/icons/twitch.png');
  }

  &_youtube {
    background-image: url('../images/main/icons/youtube.png');
  }

  &_goodgame {
    background-image: url('../images/main/icons/goodgame.png');
  }

  &_inactive {
    filter: grayscale(100%);

    &:hover {
      filter: none;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .profile-stream-management {
    div {
      display: block;
    }
  }
  .profile-stream-management__settings-container {
    padding: 30px 15px;
  }
  .profile-stream-management__choose-image {
    margin-left: 0;
  }
}