.create-challenge{
    width: 100%;
    position: absolute;
    height: 100%;
    top: 47px;
    border: none;
    background-color: #1b1b1b;
    box-sizing: border-box;
    padding: 10px 10px;
    &__link{
        margin-left: 10px;
    }
    &__input-block{
        margin-bottom: 0px;
    }
    &__submit{
        margin: 10px auto;
        &:hover{
            box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7;
        }
    }
    &__agreement{
        text-align: justify;
        display: block;
        width: 100%;
    }
    &__textarea{
        resize: none;
    }
    .feedbackModal-form {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        background: #1b1b1b;
        div{
            padding: 10px 0px;
        }
      }
}

.feedbackModal.feedbackModal_result {
    position: fixed;
    width: 350px;
    top: 130px;
    left: -2px;
    display: flex;
    height: 400px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 101;
    .feedbackModalTitle::after{
        content: '';
        border: none;
    }
    .feedbackModal-img {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: static;
    }
     
    .feedbackModal-result {
        font-family: $ubuntu-medium;
        font-size: $medium;
        padding: 20px 50px;
        font-weight: 500;
        letter-spacing: 0.4px;
        text-align: center;
        color: #000000;
      }
     
      
  }
  