@import '../../assets/style/partials/variables.scss';
.auth-modal{
    width: 502px;
    // height: 550px;
}

.login-mobile{
    // @REFACTOR
}

.social-icon{
    width: 40px;
    height: 40px;
}