.streamlist{
    &__header{
    display: flex;
    align-items: center;
    height: 44px;
    justify-content: space-between;
    padding: 0 5%;
    // @include mq('phone-wide', max){
    //     display: none; // Temp fix ???
    // }
    }
    &__see-all{
        line-height: 44px;
        height: 100%!important;
        width: auto!important;
    }
}