.admin-logs{
    background-color: $background-grey;
    color: $white-dark;
    padding: 20px;
    .table{
        width: 100%;
        margin-top: 40px;
    }
    td{
        // overflow: hidden;
        height: 40px;
        cursor: pointer;
    }
    &__modal{
        width: 400px;
        height: 400px;
        background: $background-grey;
        top: 15%;
        left: calc(50% - 200px);
        margin: 0 auto;
        position: absolute;
        white-space: pre-wrap;
        word-break: break-word;
        padding: 20px;
    }
    &__modal-overlay{
        top: 0;
        left: 0;
        z-index: 1004;
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }
}