.topGames{
    max-width: 1300px;
    margin: 0 auto;
}

.gamesSlider{
    height: 320px;
    width: 100%;
    display: flex;
    justify-content: center;
    &__header{
        display: flex;
        justify-content: space-between;
        padding: 0 5%;
    }
    &__see-all{
        line-height: 44px;
    }
    &__mobile{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .streamItem__game-info{
            background-color: #232323;
            height: 60px;
            padding: 10px;
        }
        .streamItem__title{
            font-family: $ubuntu-regular;
            font-size: 18px;
            text-align: left;
            color: #f7f7f7;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
            height: 20px;
            &:hover{
                color: $gold-dark;
            }
        }
        .streamItem__bank{
            font-family: $ubuntu-regular;
            font-size: 18px;
            font-weight: 300;
            line-height: 1.33;
            text-align: left;
            color: #9d9d9d;
        }
        .streamItem-overlay{
            background: none;
        }
        .streamItem{
            //width: 47%;
            max-width: 220px;
            height: 320px;
            margin: 5px;
            position: relative;
        }
        .streamItem-img{
            width: 100%;
            //height: 220px;
            height: auto;
        }
        .hidden{
            display: none;
        }
    }
    .slick-next{
        position: relative!important;
        right: 0px!important;
    }
    .slick-prev{
        position: relative!important;
        left: 0px!important;
    }
    .slick-arrow::before{
        content: '';
    }
    .slick-arrow{
        outline: none;
        border: none;
    }
    &__arrow-right{
        background-image: url('../images/main/slider/button-arrow-right-small.png');
        background-size: 100% 100%;
        width: 32px;
        height: 50px;
        margin-top: 50px;
    }
    &__arrow-left{
        background-image: url('../images/main/slider/button-arrow-left-small.png');
        background-size: 100% 100%;
        width: 32px;
        height: 50px;
        margin-top: 50px;
    }
    .slick-track{
        height: 220px;
        display: flex;
        justify-content: space-evenly;
        height: 100%;
    }
    .slick-slide{
        width: 190px!important;
        height: 100%;
        margin-right: 38px;
    }
    .slick-slider{
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
    }
    .slick-list{
        height: 100%;
        width: 100%;
    }
    .streamItem-img{
        width: 100%;
        height: 220px;
    }
    .streamItem__game-info{
        background-color: $background-dark-grey;
        height: 80px;
        padding: 10px;
    }
    .streamItem__streamers-number{
        text-align: center;
        font-size: 20px;
    }
    .streamItem__title{
        font-family: $ubuntu-regular;
        font-size: 22px;
        text-align: center;
        color: #f7f7f7;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 10px;
        &:hover{
            color: $gold-dark;
        }
    }
    .streamItem__bank{
        font-family: $ubuntu-regular;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.33;
        text-align: left;
        color: #9d9d9d;
    }
    .streamItem-Btn{
        box-shadow: none;
    }
    .streamItem-overlay{
        height: 100%;
    }
}
