.subscribe-to-push{
    width: 100%;
    height: 30px;
    line-height: 30px;
    background-image: linear-gradient($gold-dark, $gold-light);
    text-align: center;
    position: fixed;
    color: $white-dark;
    z-index: 1002;
    transition: all 0.5s;
    white-space: nowrap;

    @include mq('phone-wide', max){
        font-size: 10px;
    }

    a{
        text-decoration: underline;
        cursor: pointer;
    }
    &.open{
        transform: translateY(0px);
    }
    &.close{
        transform: translateY(-30px);
    }
    &__close{
        cursor: pointer;
        top: 5px;
        position: absolute;
    /* left: 20px; */
        right: 5px;
        width: 10px;
        height: 10px;
        opacity: 0.75;
        background-image: url('../images/main/redesign/close-icon.png');
        background-size: 100%;
        transition: all 0.3s;
        &:hover{
            opacity: 1;
        }
    }
}