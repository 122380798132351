@import url(https://fonts.googleapis.com/css?family=Open+Sans:300|Titillium+Web:700);

.travoltaPage {
	background:url(https://media.giphy.com/media/54rv8mQZtZgyY/giphy.gif);
	background-repeat:no-repeat;
	background-position:bottom center;
	background-size: cover;
	font-family: 'Titillium Web', sans-serif;
	color:#fff;
  background-color:rgba(0,0,0,0.65);
  
.travolta {
	display: flex;
	flex-direction:column;
  	justify-content: center;
	align-items:center;
	background-color:rgba(0,0,0,0.65);
	min-height:100vh;
  text-align:center;

  h1{
    font-size:25vw;
    font-weight:700;
  }
  
  p{
    font-family: 'Open Sans', sans-serif;
    font-weight:300;
    font-size:3vw;
    margin-bottom:30px;
  }
  
  a{
    border-radius:25px;
    background:none;
    color:#fff;
    font-size:18px;
    padding:10px 20px;
    border:2px solid #fff;
    text-decoration:none;
    &:hover{
      background:#fff;
      color:#212121;
    }
  }
}

}
