@import '../../../../assets/style/partials/variables.scss';

.ds-quick-donation{
    color: $white-dark;
    height: 100%;
    text-align: center;
    &__header{
        border-bottom: 1px solid $white-dark;
        padding: 16px;
    }
    &__message{
        padding: 16px;
        height: calc(100% - 96px);
        display: block;
        box-sizing: border-box;
    }
    &__action-area{
        padding: 16px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}