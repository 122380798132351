.modalView {
  background-color: $background;
}
.bonuscode{
 width: 400px!important;
}
.reset-password{
  width: 400px!important;
}
.modalViewContainer {
  min-height: calc(100vh - 285px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalViewBlock {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  background-color: $main-header-fill;
  padding: 60px 24px 50px;
  border-radius: 6px;
  border: solid 2px $border;
  margin: 20px 0;
  &.profileInput {
    width: 300px;
  }
}

#authInput-signIn.authInput {
  margin-top: 0px;
}

.modalViewBlock-title {
  padding-bottom: 10px;
  font-family: $ubuntu-medium;
  font-size: 30px;
  letter-spacing: 0.4px;
  text-align: center;
  color: $white;
}

.modalViewBlock-desc {
  font-family: $ubuntu-regular;
  font-size: 12px;
  letter-spacing: 0.1px;
  text-align: center;
  color: $white;
  width: 100%;
  margin-bottom: 30px;
}

.modalViewBlock-link {
  font-family: $ubuntu-regular;
  font-size: 10px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #ffffff;
}
@media (min-width: 1550px) {
  .modalViewContainer {
    min-height: calc(100vh - 251px);
  }
}

@media (min-width: 320px) and (max-width: 480px) {
 .modalViewBlock {
    margin: 20px;
    width: 85%;
    //padding: 60px 20px 55px;
    padding: 15px !important;
    .authInput {
      width: 100%;
    }
    .authInput[type="email"] {
      padding-left: 60px;
    }
    .inputBlock .authInput.authSendInput_modal {
      width: 100%;
    }
 }
 .modalViewBlock-title {
  font-size: 20px;
  letter-spacing: 0.3px;
 }
 .modalViewBlock-desc {
  font-size: 10px;
  padding: 0 22px;
  box-sizing: border-box;
 }
 .formContainerForm {
  width: 100%;
 }
 .g-recaptcha {
    transform:scale(0.78);
    -webkit-transform:scale(0.78);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
  }
}