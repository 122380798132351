header {
    position: fixed;
    display: flex;
    align-items: center;
    top: 0px;
    z-index: 1000;
    width: 100%;
    height: 100px;
    background-color: $background-dark-grey;
    // background-color: transparent;
    .container {
        padding: 0 20px;
        width: 100%;
    }
}
.header__wrapper{
    // ne kostil (a kostilisheeee)
    margin-bottom: 100px;
    @include mq('phone-wide', max){
        margin-bottom: 80px;
    }
    // @include mq('tablet-wide', max){
    //     margin-bottom: 0;
    // }
}
.become-streamer{
  &__button{
    @include font-source($ubuntu-medium, $small, $white-dark);
    height: 47px;
    width: 199px;
    line-height: 47px;
    border-radius: 23.5px;
    background-image: linear-gradient($gold-light, $gold-dark);
    text-align: center;
    padding: 0;
    display: block;
  } 
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__side{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &__group{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px;
        @include mq('phone-wide', max){
            display: none;
        }
    }
    
}

.profileBlock {
    // cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    &__tooltip{
        margin-left: 8px;
    }
    @include mq('phone-wide', max){
        width: 40px;
    }
    &__info{
        display: flex;
        flex-direction: column;
        width: 70%;
        height: 100%;
        padding-right: 20px;
        justify-content: center;
        @include mq('phone-wide', max){
            display: none;
        }
    }
    &__username{
        text-align: end;
        span{
        font-family: $ubuntu-regular;
        font-size: $medium;
        color: $white-dark;
        }
    }
    &__balance{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 30px;
        span{
            font-family: $ubuntu-regular;
            font-size: $small;
            text-align: center;
            color: $white-darker;
            height: 13px;
            line-height: 13px;
        }
    }
    &__cubes{
        padding-right: 10px;
        display: flex;
        align-items: center;
    }
    &__orbs{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    &__plus{
        width: 25px;
        height: 25px;
        border-radius: 13px;
        background-image: linear-gradient($gold-light, $gold-dark);
        font-size: 25px;
        text-align: center;
        line-height: 24px;
        margin-left: 10px;
        display: block;
        transition: all .3s ease;
        &:hover{
            // background-image: linear-gradient($gold-light-hover, $gold-dark-hover);
            box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
        }
        &_cubes{
            margin-left: 0;
            margin-right: 10px;
            background-image: linear-gradient($white-dark, 30%, $cube);
            color: $white-dark;
            cursor: pointer;
             &:hover{
                box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
            }
        }
    }
    &__cubes::after{
        content: " ";
        display: inline-block;
        width: 13px;
        height: 13px;
        background-image: url('/assets/images/main/redesign/cube.png');
        margin-left: 5px;
        background-size: 100% 100%;
    }
    &__orbs::after{
        display: inline-block; 
        width: 13px;
        height: 13px;
        content: " ";
        background-image: url('/assets/images/main/redesign/orb.png');
        margin-left: 5px;
        background-size: 100% 100%;
    }
    &__profile{
        cursor: pointer;
        height: 55px;
        width: 55px;
        border-radius: 50%;
        box-sizing: border-box;
        overflow: hidden;
        transition: all .3s ease;
        &:hover{
            box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
        }
        @include mq('phone-wide', max){
            height: 25px;
            width: 25px;
        }
        .react-gravatar {
			height: 100%;
			width: 100%;
		}
        .avatarImg {
        height: 55px;
        width: 55px;
        object-fit: cover;
       
        @include mq('phone-wide', max){
            height: 25px;
            width: 25px;
        }
        }
    }
    .coinsWrapper{
        display: block;
        .coin {
            display: flex;
            width: 100%;
            height: 50px;
            justify-content: center;
            border-radius: 0;
        }
    }
    .discordIcon {
        width: 50px;
        height: 44px;
        margin-right: 10px;
    }
}

.hamburgerMenu {
  display: none;
 	height: 17px;
 	width: 33px;
}

.coin {
    // cursor: pointer;	
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: $blue-fill;
    border: 2px solid $blue;
    border-radius: 23px;
    padding: 10px 0;
    height: 40px;
    box-sizing: border-box;
    margin-right: 9px;
    box-sizing: border-box;
    .coinImg {
        height: 20px;
        width: auto;
        padding-right: 14px;
        padding-left: 9px;
    }

    .coinAmount {
        font-family: $ubuntu-bold;
        line-height: 14px;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.2px;
        color: $white;
        padding-right: 20px;
    }
}
header .mainNav.mobile .coinsWrapper .coin {
    border: none;
    border-radius: 0;
}

.coinsWrapper {
	display: none;
    border: 2px solid $blue;
    border-radius: 10px;
    overflow: hidden;
    .coin {
        padding: 12px 0;
    }
    .depositButton {
      display: inline-block;
      font-family: $ubuntu-bold;
      width: 100%;
      font-size: 14px;
      background-color: $blue;
      text-align: center;
      height: 49px;
      line-height: 49px;
    }
}

.deposit {

     a {
        cursor: pointer;
        border: none;
        background: $blue;
        font-family: $ubuntu-bold;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.2px;
        color: $white;
    }

    span {
        font-family: $ubuntu-bold;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.2px;
        color: $white;
    }
}
.mainNav{
    display: flex;
    vertical-align: middle;
}
.mainMenu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style: none;
    vertical-align: middle;
    &__lang-switcher{
        display: flex;
        align-items: center;
        width: 110px;
        justify-content: space-between;
    }
    &__lang-trigger{
        display: block;
        width: 25px;
        height: 25px;
        background-image: url('../images/main/header/lang.png');
        background-size: 100% 100%;
    }
    .mainMenu-item {
        padding: 0 15px;
        color: white;
       
        a {
            color: $white-dark;
            font-size: $small;
            font-family: $ubuntu-medium;
            &:hover {
                color: $gold-dark;
            }
        }
    }
}

.notification {
    position: relative;
    background-color: $blue-fill;
    border-radius: 50%;
    border: 2px solid $blue;
    margin-right: 5px;
    height: 31px;
    width: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    a {
        display: flex;
        width: 100%;
        height: 100%;
    }

    #notificationMenu {
        top: 230%;
        top: 73px;
    }

    #notificationBlock-link {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .ringer {
        height: 15px;
        width: auto;
    }
}

.profile {
    // cursor: pointer;
    // height: 45px;
    // width: 45px;
    // border-radius: 50%;
    // box-sizing: border-box;
    // margin-top: 5px;
    // border: 2px solid white;
    // overflow: hidden;
    // display: flex;
    // flex-direction: row;
    // margin-right: 5px;
		.react-gravatar {
			height: 100%;
			width: 100%;
		}
    .arrow {
        height: 5px;
        width: auto;
        margin-left: 5px;
    }

    .avatarImg {
        height: 45px;
        width: 45px;
        object-fit: cover;
    }
}

.profileBlock {
    display: flex;
}

.Button {
    display: inline-block;
    height: 100%;
    width: 100%;
    padding: 15px 30px;
    color: white;
    background-color: transparent;
    border: 0;
    font-size: 12px;
    border-radius: 24px;
    font-family: $ubuntu-bold;
    outline: none;
    cursor: pointer;

    &.Button_blue {
        background-color: $blue;
    }
}

.registerBlock {
    display: flex;
    justify-content: flex-end;
    width: 40%;

    .signBlock {
        margin-left: 15px;
        border-radius: 24px;
        .Button {
          height: 40px;
			    width: 126px;
			    line-height: 40px;
			    padding: 0;
        }
    }
    .LoginBlock {
    	.Button {
    		font-size: 16px;
    	}
    }
}

.profileMenu {
    border-radius: 6px;

    .nameBlock {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        padding: 20px;

        //border: solid 2px #e5ebf1;
        background-color: $main-header-fill;

        span {
            color: $white;
            font-family: $ubuntu-bold;
            font-size: 20px;
        }
    }
}

.profileMenu {
    background-color: #1b1b1b;
    overflow: hidden;
    &__head{
        background-image: url('../images/main/header/profile-menu-head.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        justify-content: flex-end;
        height: 82px;
        padding: 0 20px;
        border-bottom: 1px solid white;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        .profileBlock{
            display: flex;
            width: 100%;
            align-items: center;
            &__info{
                display: block;
                /* width: 200px; */
                height: auto;
            }
        }
    }
    .deposit__button{
        cursor: pointer;
        display: block;
        border-radius: 23.5px;
        height: 45px;
        width: 173px;
        border: double 4px transparent;
        background-image: linear-gradient(#1b1b1b, #1b1b1b), radial-gradient(circle at top left, $gold-light, $gold-dark);
        background-origin: border-box;
        background-clip: content-box, border-box;
        font-family: $ubuntu-regular;
        font-size: $medium;
        line-height: 45px;
        text-align: center;
        color: $white-dark;
        margin-right: 40px;
    }
    z-index: 100;
    display: block;
    width: 450px;
    position: absolute;
    top: 0px;
    right: 0;
    border-radius: 6px;
    border: solid 1px #e5ebf1;
    &#depositMenu {
        width: 900px;
        cursor: default;
    }
}

.profileMenuList-item {
    padding: 10px 20px;
   
    &:first-child {
        padding-top: 20px;
    }

    &:last-child {
        padding-bottom: 20px;
    }

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    img, .img {
        padding-right: 10px;
    }

    a {
        font-family: $ubuntu-regular;
        font-size: $medium;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: right;
        color: $white-dark;
        &:hover {
            color: $gold-dark;
        }
    }
}

.notificationAllDesc {
    display: flex;
    flex-direction: column;
}

.notification-header {
    padding-bottom: 2px;
    font-family: $ubuntu-bold;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2px;
    text-align: left;
    color: #000000;
}

.notification-condition {
    padding-bottom: 5px;
    font-family: $ubuntu-regular;
    font-size: 12px;
    letter-spacing: 0.2px;
    text-align: left;
    color: #000000;
}

.notification-time {
    opacity: 0.6;
    font-family: $ubuntu-regular;
    font-size: 10px;
    letter-spacing: 0.1px;
    text-align: left;
    color: #000000;
}

//DEPOSIT MODAL VIEW

.depositMenuList {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    background-color: $background-dark-grey;
}

.depositMenuList-item {
    display: flex;
    border-top: 2px solid $border;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-collapse: collapse;
    height: 100%;
    color: $white-dark;
    // & + .depositMenuList-item {
    border-right: solid 2px $border;
    &_gold{
        background-image: linear-gradient( rgb(29, 4, 58), rgb(144, 79, 182));
        padding-bottom: 0px;
        border: 0px;
        height: 215px;
    }
    // }
    button, a {
        cursor: pointer;
        border: none;
        background: $blue;
        font-family: $ubuntu-bold;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.2px;
        color: $white;
    }

    a {
        width: 70px;
    }

    img, .img {
        margin-top: 20px;
        margin-bottom: 8px;
    }
    .depositMenuList-btn {
        width: 160px;
        box-sizing: border-box;
    }

    .depositMenuList-header {
        font-family: $ubuntu-bold;
        font-size: 14px;
        letter-spacing: 0.2px;
        text-align: center;
        margin-bottom: 5px;
        &_gold{
            color: white;
        }
    }

    .depositMenuList-desc {
        font-family: $ubuntu-regular;
        font-size: 12px;
        min-height: 39px;
        letter-spacing: 0.2px;
        text-align: center;
        &_gold{
            color: white;
        }
    }
}

//DEPOSIT MOBILE VIEW

.depositMobile {
    &>div {
        background-color: $background;
    }
    .profileMenu#depositMenu {
        width: 81%;
        margin: auto;
        position: static;
        display: block;
        border: none;
        box-shadow: none;
        background: transparent;
        padding-bottom: 40px;
    }
    .nameBlock {
        background-color: $background;
        padding: 40px 0 10px;
    } 
    .depositMenuList {
        display: block;
    }
    .depositMenuList-item {
        width: 100%;
        border-radius: 6px;
        border: none;
        margin-bottom: 10px;
        padding: 0;
        height: auto;
        background: $white;
        &_gold{
            background-image: linear-gradient( rgb(29, 4, 58), rgb(144, 79, 182));
        }
        .depositMenuList-desc {
            min-height: unset;
        }
        .pre-btn {
            margin-top: 10px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    //CSS
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
    //CSS
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px),
    (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .profileMenu#depositMenu {
        max-width: 800px;
        right: 0;
        left: unset;
        width: calc(100% - 24px);
    }
    .depositMobile {
        .profileMenu#depositMenu {
            width: 92%;
        }
        .nameBlock {
            text-align: center;
            padding-bottom: 20px;
            span {
                font-size: 24px;
            }
        }
        .depositMenuList-item {
            &_gold{
                background-image: linear-gradient( rgb(29, 4, 58), rgb(144, 79, 182));
            }
            margin-bottom: 20px;
            .depositMenuList-header {
                font-size: 20px;
                margin-bottom: 20px;
            }
            .depositMenuList-desc {
                font-size: 14px;
            }
            .img {
                margin-bottom: 20px;
            }
            .pre-btn {
                margin-top: 20px;
            }
        }
    }

    header {
        height: 80px;        

        .header {
				    padding: 0;
            height: 80px;
        }

        .hamburgerMenu {
          cursor: pointer;
          display: block;
          margin-left: 30px;
          margin-top: 10px;
          -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        }

      

      

        .profileBlock {    
            .coinsWrapper{
                display: block;
                .coin {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                }
            }
            
            .discordIcon {
                display: none;
            }
            .deposit {
                margin-right: 20px;
            }    
            .profile {
                width: 45px;
                height: 45px;
                margin-right: 5px;
                border: solid 2px #f2f4ff;
            }

            .profileMenu {
              position: fixed;
              top: 90px;
              margin: 0 10px;
            }
            #profileBlock {
	            margin-right: 30px;   
            }
        }

        .registerBlock {      
          .signBlock {
            margin: 0 20px 0 5px;
          }
          .Button {
            /*font-size: 10px;  */
            padding: 9px 10px;
            border-radius: 20px;
            width: max-content;
          }
          .modalDesc {
            display: block;
          }
          .modalDesc-img {
            width: 100%;
            background-image: url('/assets/images/pop-up/form-back.jpg');
            background-size: cover;
            background-position: center;
            padding: 30px 82px;
            height: auto;
          }
          .modalDesc-img .modalDesc-back {
            display: none;
          }
          .modalDesc-content {
            position: static;
          }
          .custom-modal {
            width: 600px;
          }
          .modalDesc-form {
            width: calc(100% - 160px);
            margin: auto;
          }
          .inputBlock {
            width: 100%;
          }
          .inputBlock .authInput {
            width: 100%;
            height: 50px;
            line-height: 50px;
          }
          .modal-content {
            border: none;
          }
          .modalDesc-form {
            width: 100%;
          }
          .AuthTabs {
            width: calc(100% - 160px);
            margin: auto;
          }
          .forgotPass {
            padding-bottom: 10px;
          }
          .authInput[type="submit"] {
            margin-top: 15px;
          }
          #in .inputBlock.submit {
            margin-top: 94px;
          }
          #up .socialContainer-desc {
            margin-top: 21px;
          }
        }
        .modalDesc-content .formLogo {
          padding: 30px 0;
        }
        .modalDesc-content h3 {
          font-size: 24px;
        }
        .modalDesc-content p {
          font-size: 16px;
        }
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px //812px iphone X //823px pixel 2x l
*/

@media (min-width: 481px) and (max-width: 823px) {
    //CSS
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

/*@media (min-width: 481px) and (max-width: 823px),*/
@media (min-width: 481px) and (max-width: 767px),
       (min-width: 320px) and (max-width: 480px) {
    //CSS
    .mainNav {
        display: none;

        &.mobile {
          position: fixed;
          /*top: 90px;*/
          top: 77px;
          left: 0;
          width: 100%;
          display: block;

          .coinsWrapper {
            display: block;
          }
          .coin {
            border-radius: 10px;
            margin-right: 0px;
            justify-content: center;
            -webkit-box-pack: center;
          }

          .mainMenu {
            display: block;
            text-align: center;
            border-radius: 10px;
            background-color: $background-grey;

            .mainMenu-item {
              display: flex;
              justify-content: center;
              color: $white-dark;
              padding: 20px 0;
              border-bottom: 1px solid #e5ebf1;
              background-color: $background-grey;
              margin: 0 10px;
              color: $white-dark;
              &.social-links{
                  border: none;
              }
              &_disabled{
                a{
                    color: grey;
                } 
              }
              &__lang-trigger{
                display: block;
                width: 25px;
                height: 25px;
                background-image: url('../images/main/header/lang.png');
                background-size: 100% 100%;
            }
              &:nth-last-child() {
                border-bottom: 0;
              }
              a {
                font-family: $ubuntu-regular;
                font-size: 14px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                //line-height: normal;
                letter-spacing: 0.2px;
              }
            }
          }
        }
    }
    .switcher{
        display: none;
    }
    header {
        height: 80px;
        // box-sizing: border-box;

        .header {
            //padding: 24px 0;
        }

        .hamburgerMenu {
          cursor: pointer;
          display: block;
          margin-left: 20px;
          margin-top: 5px;
          -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
        }

    

      

        .profileBlock {
            .discordIcon, .coin, .deposit, .notification, .profileBlock-link .arrow {
                display: none;
            }

            .profile {
                width: 29.2px;
                height: 29.2px;
                margin-right: 20px;
                border: solid 1.4px #f2f4ff;
            }

            .profileMenu {
              position: fixed;
              top: 90px;
              left: 0;
              width: calc(100% - 24px);
              margin: 0 10px;
            }
        }

        .registerBlock {
        	width: 50%;
          .signBlock {
            margin: 0 20px 0 5px;
            .Button {
            	width: 87px;
            	height: 38px;
            	padding: 0 23px;
            	line-height: normal;
            }
          }
          .Button {
            font-size: 10px;  
            padding: 10px 10px;
            border-radius: 20px;
          }
          .custom-modal {
            top: 10px;
            width: calc(100% - 20px);
            transform: none;

            &.modal-animate {
              animation: none;
            }

            .modalDesc-img {
              display: none;
            }
            .modalDesc-form {
              width: 100%;
            }
          }
        }
    }
}

.mainNav {
  
    &.mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        @include mq('phone-wide', min){
            display: none;
            width: 100%;
            margin: 0;
        }
      .coin {
        border-radius: 10px;
        margin-right: 0px;
        justify-content: center;
        -webkit-box-pack: center;
      }

      .mainMenu {
        display: block;
        text-align: center;
        border-radius: 10px;
        &__lang-trigger{
            display: block;
            width: 25px;
            height: 25px;
            background-image: url('../images/main/header/lang.png');
            background-size: 100% 100%;
        }
        .mainMenu-item {
          padding: 20px 0;
          border-bottom: 1px solid #e5ebf1;

          &:nth-last-child() {
            border-bottom: 0;
          }
          a {
            font-family: $ubuntu-regular;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.2px;
            color: $white-dark;
            &:hover {
                color: #ffffff; 
            }
          }
          span {
              color: #000000;
          }
        }
      }
    }
}