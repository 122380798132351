.ds-card{
    
}

.ds-message-card__body{
    border-bottom: 1px solid #dad8de;
    border-top: 1px solid #dad8de;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}