@import '../../assets/style/partials/variables.scss';

.profile{
    display: flex;
    height: 100%;
    &__sidebar{
        background-color: rebeccapurple;
        flex-basis: 375px;
        border-right: 1px solid $gray-77;
    }
    &__content{
        background-color: crimson;
        flex-grow: 1;
    }
    &__icon{
        width: 50px;
        height: 50px;
        background-color: lawngreen;
    }
}

.profile-menu{
    height: 100%;
}

.profile-header{
    &__avatar{
        width: 169px;
        height: 169px;
        background: cadetblue;
        position: relative;
        bottom: -100px; 
        border: solid 7px $white;
        z-index: 2;
    }
    .socials{
        position: absolute;
        margin-top: 42px;
    }
}

