.stream-edit{
   
    color: $white-dark;
    font-size: $small;
    &__content{
        a{
            &:hover{
                color: $gold-dark;
            }
        }
    }
    &__manage-activities{
        font-size: 18px;
        font-family: $ubuntu-regular;
        text-align: center;
    }
    &__row{
        display: flex;
        width: 100%;
        align-items: flex-end;
    }
    &__ask-for-partnership{
        width: 180px;
        padding: 5px 10px;
        color: $white-dark;
        margin: 20px auto;
    }
    &__streamer-help{
        width: 70%;
        margin: auto;
        text-align: justify;
    }
    &__content{
        display: flex;
        justify-content: center;
        @include mq('phone-wide', max){
            flex-direction: column;
            align-items: center;
        }
    }
    &__getting-started{
        width: 70%;
        margin: auto;
        text-align: justify;
        @include mq('phone-wide', max){
            width: 85%;
        }
    }
    &__help{
        border: 1px solid white;
        margin: 20px;
        width: 345px;
        box-sizing: border-box;
        padding: 20px;
        padding-top: 0;
        text-align: justify;
        img {
            display: block;
            margin: 0 auto;
        }
        @include mq('phone-wide', max){
            width: 85%;
            padding: 15px;
        }
    }
    &__help-text{
        font-size: 14px!important;
    }
    &__moderator-list{
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
    }
    &__desc{
        color: $white-dark;
        font-size: $small;
    }
    &__moderator{
        width: 210px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 20px 0px;
        height: 200px;
    }
    &__moderator-avatar{
        overflow: hidden;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        img{
        width: 30px;
        height: 30px;
        }
    }
    &__moderator-reputation{
        text-align: center;
    }
    &__label{
        text-align: center;
        margin-bottom: 10px;
        color: $white-dark;
        font-size: $small;
    }
    &__head{
        display: flex;
        justify-content: space-around;
        width: 100%;
    }
    &__invite{
        cursor: pointer;
        background-color: green;
        height: 30px;
        border-radius: 15px;
        color: $white-dark;
        width: 80px;
        line-height: 30px;
        text-align: center;
    }
    &__link{
        font-size: 18px;
        font-family: $ubuntu-medium;
    }
    &__button{
        width: 100px;
        margin: 20px auto 0;
        height: 30px;
        background-color: cadetblue;
        border-radius: 15px;
        line-height: 30px;
        color: $white-dark;
        text-align: center;
        cursor: pointer;
    }
}