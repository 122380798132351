.admin {
  &__content {
    padding-top: 2rem;
  }

  &__sidebar {
    h3.head {
      margin-bottom: 1rem;
      text-align: center;
    }
  }
}

.stream-list {
  width: 250px;
  background-color: $background-grey;
  margin-bottom: 1rem;

  &__item {
    width: 100%;
    height: 50px;
    line-height: 50px;
    list-style: none;
  }

  &__header {
    width: 100%;
  }

  &__filter {
    border-radius: 0px;
    height: 50px;
    background-color: $background-grey;
  }

  &__search-icon {
    right: 30px;
    width: 20px;
    height: 20px;
    background-image: url(../images/main/help-center/search-icon.png);
    background-size: cover;
    display: inline;
    position: relative;
    cursor: pointer;
  }
}

.acceptedChallenges,
.waitingForAcceptChallenges,
.waitingForApproveChallenges {
  &__challenges {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.active-paid-actions {
  &__paid-actions {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.demonstratedPredictions {
  &__predictions {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.in-progress-votings {}

.createPredictionBlock {

  .predictionContainer {

    width: 275px;

    // height: 380px;
    button {
      width: 30%;
    }

    input {
      height: 20px;
    }

    margin: 10px 5px;
    float: left;

    .predictionContainerBlock__top__headerRow_type {
      width: 30%;
      height: 20px;
      padding: 2px;
      line-height: 20px;
    }

    .predictionContainerBlock__top__footerRow {
      button {
        width: 40%;
      }
    }

    .predictionContainerBlock__bottom {
      button {
        width: 70%;
        height: 36px;
      }
    }
  }
}

.separator {
  width: 80%;
  margin: 1em auto;
  position: relative;
  height: 2px;
  display: block;
  background-color: #ffffff;
}

.blue {
  background-color: blue;
  margin: 2em auto;
  width: 90%;
}

.templateList {
  display: flex;
  flex-wrap: wrap;
}

.templates {
  color: $white-dark;

  &__header {
    color: $white-dark;
    margin: 20px 0;
  }

  &__label {
    font-family: $ubuntu-medium;
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 15px;
  }
}

.column {
  display: flex !important;
  flex-direction: column !important;
}

.predictionTab {
  color: white;

  h1 {
    margin: 1em;
  }
}

.profilePageForm {
  input {
    height: 30px;
    width: 40%;
  }

  button {
    width: 40%;

    @include mq('phone-wide', max) {
      width: 60%;
      display: block;
    }
  }
}

.tabContent {
  &__header {
    margin: 1em;
  }
}

.admin {
  &__nav {
    padding: 10px 25px;
  }
}

.admin-user {
  background-color: $background-grey;

  &__search {
    color: $white-dark;
  }
}

.adminPage {
  padding: 1rem 0;

  &__navigation-buttons {
    padding: 20px;

    @include mq('phone-wide', max) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.stream-edit {
  &__button {
    width: 130px;
  }
}

.bot {
  &__button {
    padding: 5px 0;
    width: 130px;
  }
}

.anchor {
  display: block;
  position: relative;
  top: -150px;
  visibility: hidden;
}