.overlay-creator{
    // todo make typographic
    h5{
        margin-bottom: 8px;
    }
    label{
        font-size: 14px;
    }
    &__settings{
          border-bottom: 6px solid #ed8c10;
          border-top: 6px solid #ed8c10;
          margin-bottom: 40px;
          padding: 20px 0;
          width: 100%;
    }
    &__row{
        display: flex;
        background-color: $background-dark-grey;
    }
    &__col{
        display: flex;
        flex-direction: column;
      
    }
    &__overlay-preview{
        width: calc(100% - 25%);
        background: rgba(0,109,255,0.96);
        background: -moz-linear-gradient(-45deg, rgba(0,109,255,0.96) 0%, rgba(255,120,0,1) 100%);
        background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(0,109,255,0.96)), color-stop(100%, rgba(255,120,0,1)));
        background: -webkit-linear-gradient(-45deg, rgba(0,109,255,0.96) 0%, rgba(255,120,0,1) 100%);
        background: -o-linear-gradient(-45deg, rgba(0,109,255,0.96) 0%, rgba(255,120,0,1) 100%);
        background: -ms-linear-gradient(-45deg, rgba(0,109,255,0.96) 0%, rgba(255,120,0,1) 100%);
        background: linear-gradient(135deg, rgba(0,109,255,0.96) 0%, rgba(255,120,0,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006dff', endColorstr='#ff7800', GradientType=1 );
        position: relative;
    }


    &__sidebar{
        width: 30%;
        color: $white-dark;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px;
        background-color: $background-dark-grey;
    }

    &__subheader{

    }
    &__tab{
        padding: 10px;
        text-align: center;
        background-color: $background-dark-grey;
        border: 1px solid $white-dark;
        width: 300px;
    }
    &__overlay-preview{
        position: relative;
        background-color: $white-dark;
        height: calc(100vh - 60px);
       
    }
    .overlay__message-text{
        display: inline-block;
        font-family: inherit;
    }
    .overlay__placeholder{
        width: inherit;
        height: inherit;
        border: 1px dashed orangered;
    }
    .overlay-preview__draggable-resizable{
        width: inherit;
        height: inherit;
        border: 1px dashed orangered;
        position: relative;
    }
    .overlay__container{
        border: 1px dotted rgb(243, 183, 143);
    }
    .overlay__crowdfunding-container{
        width: 100%;
        text-align: center;
        // display: none;
    }
    progress[value] {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 60px;
    }
    progress[value]::-webkit-progress-value {
        background-color: green;
        border-radius: 2px; 
        transition: all 1s;
    }
    .overlay__crowdfunding-description{
        font-size: 36px;
        /* position: absolute; */
        line-height: 60px;
        width: 100%;
        /* left: 10%; */
    }
    .overlay__crowdfunding-goal{
        font-size: 36px;
        position: absolute;
        line-height: 60px;
        right: 10%;
    }
    .overlay__crowdfunding-progress-inline{
        font-size: 36px;
        position: absolute;
        line-height: 60px;
        left: 10%;
    }
    .overlay__crowdfunding-progress{
        font-size: 36px;
        position: absolute; 
        line-height: 60px;
        width: 100%;
        display: block;
    }
}

// whats in use

.form-field{
    height: 25px;
    border-radius: 8px;
    width: 100%;
    background-color: #2d2d2d;
    border: none;
    outline: none;
    color: $white-dark;
}


.checkbox{
    display: inline;
    width: 18px !important;
    height: 18px !important;
    margin: 0 auto;
    border-radius: 20px;
    position: relative !important;
    top: 2px;
    left: 0 !important;
}

input:checked .checkbox:after{
    background-color: red;
}


.overlay-preview{
    &__controls{
        position: absolute;
        left: 0px;
        bottom: 0px;
        padding: 32px;
        margin-bottom: 60px;
    }
}

.activities__bottom{
    margin-top: 40px;
}

.donations__container{
    max-width: 110px;
}
.donations__top{
    margin-bottom: 24px;
}

.donations__middle{
    margin-bottom: 24px;
}