.adm-new-voting {
  width: 100%;
  box-sizing: border-box;

  &__option-bank {
    position: absolute;
    right: 0px;
  }

  &__option-value {
    margin-right: 10px;
  }

  &__winner {
    background-color: green;
  }

  &__option {
    position: relative;
  }

  &__input-time-container {
    border-bottom: 3px solid #ffad25;
    opacity: 0.5;
    display: flex;
    align-items: flex-end;
    max-width: 40px;
    transition: opacity 0.5s ease-in;

    &:focus-within {
      opacity: 1;
    }
  }
}

.adm-new-voting__cubes,
.adm-new-voting__orbs {
  &::before {
    content: ' ';
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    margin-bottom: -2px;
    background-size: 100% 100%;
  }
}

.adm-new-voting__orbs {
  &::before {
    background-image: url('/assets/images/main/redesign/orb.png');
  }
}

.adm-new-voting__cubes {
  &::before {
    background-image: url('/assets/images/main/redesign/cube.png');
  }
}

.adm-new-voting__option-group {
  margin-right: 15px;

  input {
    margin-top: -3px;
    padding-bottom: 0;
    width: 35%;
  }
}

.adm-new-voting__input-cube {
  background-image: url('/assets/images/main/redesign/cube.png');
  background-size: 100% 100%;
}

.adm-new-voting__input-orb {
  background-image: url('/assets/images/main/redesign/orb.png');
  background-size: 100% 100%;
}