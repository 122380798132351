.buy-orbs{
    // padding-bottom: 5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    // background-image: linear-gradient( rgb(10, 1, 20), rgb(88, 9, 134));
    background-image: url('../images/main/buy/1.png');
    background-size: 100%;
    @include mq('phone-wide', max){
    background-image: url(/assets/1.png);
    background-repeat: no-repeat;
    background-size: cover;
    }
    &__trigger{
        // margin-left: 10px;
    }
    &__option{
        cursor: pointer;
        text-align: center;
        &:hover{
            font-weight: bold;
        }
    }
    &__custom-amount{
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        padding: 20px;
        align-items: center;
        height: 30px;
        height: 100px;
        flex-wrap: wrap;
    }
    &__buy-button{
        margin-top: 40px;
        width: auto;
        padding: 0 10px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        color: #f7f7f7;
        border-radius: 30px;
        margin-right: 10px;
        background-image: linear-gradient(#ffc837, #ff8008);
        transition: all .3s ease;
        cursor: pointer;
        &:hover{
            box-shadow: 0 0 10px $white-dark, 0 0 20px $white-dark;
        }
    }
    &__label{
        height: 20px;
        line-height: 20px;
        margin-right: 10px;
        color: #7f7f7f;
        font-size: 20px;
        font-family: $ubuntu-regular;
    }
    &__payment{
        width: 200px;
        overflow: hidden;
        padding: 20px;
        height: 240px;
    }
    &__amount-input{
        outline: none;
        background: transparent;
        border: none;
        border-bottom: 1px solid #7f7f7f;
        height: 35px;
        /* border-radius: 5px; */
        margin-right: 10px;
        padding-bottom: 10px;
        color: #fefefe;
        font-size: 30px;
        margin-bottom: 20px;
    }
    &__card{
        height: 40px;
        width: 150px;
        background-repeat: no-repeat;
        background-position: center;
        &_small{
            width: 130px;
            background-size: 100%;
        }
    }
    &__summ{
        color: #ff9500;
        font-size: 30px;
        height: 35px;
        line-height: 35px;
    }
    &__visa{
        background-image: url('/assets/images/main/buy/payment-systems.svg');
    }
    &__pb24{
        background-image: url('/assets/images/main/buy/privat24.svg');
    }
    &__liqpay{
        background-image: url('/assets/images/main/buy/liqpay.svg');
    }
   &__pbs{
        background-image: url('/assets/images/main/buy/psb.svg');
   }
   &__alfa{
       background-image: url('/assets/images/main/buy/alfa.svg');
   }
   &__qiwi{
       background-image: url('/assets/images/main/buy/qiwi.svg');
   }
   &__yandex{
       background-image: url('/assets/images/main/buy/yandexmoney.svg');
   }
    &__supported-cards{
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        @include mq('phone', max){
            flex-wrap: wrap;
            a{
                margin-top: 10px;
            }
        }
    }
    &__row{
        display: flex;
        border-bottom: 2px solid #f6eaea;
        justify-content: space-evenly;
    }
    .selected{
        border-bottom: 5px solid #ff9500;
        position: relative;
        top: 2px;
    }
    &__card-block{
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: auto;
        padding: 20px 40px;
        cursor: pointer;
        // box-sizing: border-box;
    }
    &__currency-select{
        width: auto;
        background-color: transparent;
        border: none;
        font-family: $ubuntu-regular;
        outline: none;
        color: #ff9500;
        padding: 0px;
        font-size: 30px;
        margin-left: 10px;
        margin-top: 0;
        &:hover{
            border: none;
            // color: #ac6503;
        }
    }
    &__money-block{
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: auto;
        padding: 20px 40px;
        cursor: pointer;
        // box-sizing: border-box;
    }
    &__card-icon{
        background-image: url('../images/main/buy/005-card-1@3x.png');
        width: 45px;
        height: 33px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    &__money-icon{
        background-image: url('../images/main/buy/006-money-1@3x.png');
        width: 45px;
        height: 33px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    &__powered-by{
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        align-items: center;
        a {
            text-decoration: underline;
            padding-left: 10px;
            font-family: ubuntu-medium;
            font-size: 18px;
        }
    }
    &__footer{
        width: 100%;
        padding: 20px;
    }
    &__features{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        }
    &__buy{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        }
    .features__header{
        margin-top: 55px;
        font-size: 75px;
        font-weight: bold;
        color: #fefefe;
    }
    .features__subheader{
        margin-top: 45px;
        font-size: 41px;
        font-weight: 300;
        text-align: center;
    }
    .features-content{
        margin-top: 54px;
        display:flex;
        justify-content: space-evenly;
        width: 100%;
        margin-bottom: 42px;
    }
    .features-content__img-wrapper{
            border: 2px solid transparent;
            border-image: linear-gradient( rgb(122, 100, 247), rgb(88, 9, 134)) 30;
            width: 285px;
            height: 172px;
    }
    .features-content__column{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 290px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .features-content__header{
        margin-top: 34px;
        font-size: 34px;
        font-weight: bold;
    }
    .features-content__content{
        font-size: 20px;
        font-weight: 300;
        margin-top: 24px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fefefe;
    }
    .buy__header{
        margin-top: 42px;
        margin-bottom: 42px;
        font-size: 75px;
        font-weight: bold;
        text-align: center;
        &_small{
            font-size: 40px;
        }
    }
    .buy-content{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        @include mq('phone-wide', max){
           flex-direction: column;
        }
    }
    .buy-content__item{
        cursor: pointer;
        position: relative;
        top: 0%;
        width: 285px;
        height: 292px;
        display: block;
        // border: 1px solid rgb(88, 9, 134);
        background-size: 100% 100%;
        // @include mq('phone-wide', max){
        //     width: calc(80% / 4);
        //     height: auto;
        // }
        &_first{
            background-image: url('/assets/images/main/buy/6@2x.png');
        }
        &_second{
            background-image: url('/assets/images/main/buy/14@2x.png');
        }
        &_third{
            background-image: url('/assets/images/main/buy/12@2x.png');
        }
        &_forth{
            background-image: url('/assets/images/main/buy/10@2x.png');
        }
    }
    .buy-content__icon{
        position: absolute;
        display: flex;
        &_small{
            width: 51px;
            height: 51px;
            top: calc(50% - 25px);
            left: calc(50% - 25px);
            
            // @include mq('phone-wide', max){
            //     width: 25px;
            //     height: 25px;
            // }
        }
        &_medium{
            width: 67px;
            height: 67px;
            top: calc(50% - 33px);
            left: calc(50% - 33px);
            // @include mq('phone-wide', max){
            //     width: 30px;
            //     height: 30px;
            // }
        }
        &_large{
            width: 90px;
            height: 90px;
            top: calc(50% - 45px);
            left: calc(50% - 45px);
            // @include mq('phone-wide', max){
            //     width: 35px;
            //     height: 35px;
            // }
        }
        &_collosal{
            width: 130px;
            height: 130px;
            top: calc(50% - 65px);
            left: calc(50% - 65px);
            // @include mq('phone-wide', max){
            //     width: 40px;
            //     height: 40px;
            // }
        }
    }
       
    .buy-content__info{
        display: flex;
        position: absolute;
        bottom: 10px;
        left: 13px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        width: 100%;

    }
    .item__amount{
        display: block;
        font-size: 25px;
        span{
            font-size: 18px;
        }
    }
    .item__price{
        display: block;
        font-size: 15px;
        font-weight: 300;
        color: #ff8500;
    }
    .buy-content__value{
        font-size: 25px;
        font-weight: 300;
        color: #ff8500;
    }

}

@media (min-width: 320px) and (max-width: 480px) {
    .buy-orbs {
        padding-top: 50px;
    }
    .buy-orbs__select-payment-method{
        width: 100%;
    }
    .buy-orbs__container {
        width: 100%;
        overflow-x: scroll;
    }
    .buy-orbs__container::-webkit-scrollbar { 
        display: none;
    }
    .buy-orbs__row {
        width: 760px;
    }
  }