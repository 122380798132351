.become-streamer{
    &__wrapper{
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        top: 0px;
        left: 0px;
        z-index: 1;
    }
    &__modal{
      height: 200px;
    }
    &__set-up{
      margin: 20px auto;
      @include mq('phone-wide', max){
        margin-top: 12%;
      }
    }
    .feedbackModal-desc {
      font-family: $ubuntu-regular;
      font-size: 22px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      text-align: left;
      color: #000000;
    }
    .feedbackModal {
      height: 200px;
    }
    &__row{
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 100px;
    }
    &__twitch {
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
      img{
        width: 70px;
        height: 70px;
      }
    }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.feedbackModal {
    opacity: 1;
    position: absolute;
    margin: 0 auto;
    border-radius: 6px;
    width: 40%;
    height: 500px;
    left: 0;
    right: 0;
    top: 90px;
    bottom: 0;
    background-color: #ffffff;
    border: solid 2px #e5ebf1;
    z-index: 100;
    @include mq('phone-wide', max){
      width: 90%;
      //height: 370px;
      height: 45vh !important;
      display: flex;
      flex-direction: column;
    }
  }
  
  .feedbackModalTitle {
    width: 100%;
    box-sizing: border-box;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: #282828;
    padding: 20px;
  }
  
  .feedbackModal-title {
    font-size: 20px;
    font-family: $ubuntu-medium;
    letter-spacing: 0.2px;
    text-align: left;
    color: white;
  }
  
  .feedbackModal-text {
    margin-top: 20px;
    padding: 20px;
    font-size: 13px;
    letter-spacing: 0.2px;
    font-family: $ubuntu-regular;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.1);
    outline: none;
    border: none;
    width: 100%;
    height: 80px;
    resize: none;
    @include mq('phone-wide', max){
      height: 20px;
    }
  }
  

  .feedbackModal-submit {
    width: 100%;
    height: 60px;
    font-size: 20px;
    letter-spacing: 0.3px;
    color: white;
    border-radius: 30px;
    background-color: #2094fa;
    margin-bottom: 20px;
    text-align: center;
    border: none;
    outline: none;
    -webkit-appearance: none;
    margin-top: 20px;
    cursor: pointer;
    @include mq('phone-wide', max){
      width: 60px;
      height: 30px;
      margin: 20px auto;
      display: block;
    }
  }
  
  .feedbackModal-desc {
    font-family: $ubuntu-regular;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: left;
    padding: 0 20px;
    color: #000000;
  }
  
  .feedbackModal-create {
    position: relative;
    outline: none;
    cursor: pointer;
    padding-top: 9px;
    padding-bottom: 9px;
    margin: 0 auto;
    margin-bottom: 16px;
  }
  
  .feedbackModal-result {
    font-family: $ubuntu-medium;
    font-size: 30px;
    padding: 32px 50px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: #000000;
  }
  
  