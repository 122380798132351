.mainNav.mobile .b-dropdown__wrapper {
  right: 0;
}
.b-dropdown {
  display: inline-block;
  /*margin-right: 4em;*/
  position: relative;
  &_active {
    display: inline-block;

    .b-dropdown__wrapper {
      visibility: visible;
      transform: scale(1) translateY(10px);
      opacity: 1;
    }
  }
  &__trigger {
    width: 80px;
    cursor: pointer;
    color: #fff;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    border: none;
    margin: 0;
    padding-top: 0.5em;
    width: auto;
    overflow: visible;
    background: transparent;
    color: #fff;
    align-items: center;
    cursor: pointer;
   
   &:hover {
      transform: translateX(5px);
    }
  }
  &__arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    top: -10px;
    left: 50%;
    margin-left: -15px;
    z-index: 101;
    display: block;

    &::before {
      display: block;
      position: absolute;
      transform: rotate(45deg);
      border-radius: 2px;
      content: '';
      box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2);
      margin: 5px;
      width: 20px;
      height: 20px;
    }

    svg {
      transform: rotate(90deg);

      path {
        fill: #1B1B1B;
      }
    }
  }

  &__wrapper {
    transform: scale(0.8) translateY(-20px) translateZ(0);
    opacity: 0;
    transition: all 0.2s ease;
    visibility: hidden;
    position: absolute;
    z-index: 100;
    margin-top: 5px;
    right: 10px;

    &_right {
      right: 0;
    }
  }

  &__block {
    padding: 0 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 0.5rem;
    box-shadow: 0 8px 16px rgba(#000, 0.1);
    border-radius: 5px;
    background: {
      color: #1B1B1B;
    }
    width: 100px;
    z-index: 102;

  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .mainNav.mobile .menuLangSwitcher {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .b-dropdown {
    margin-right: 0.5em;
       &__arrow {
      position: absolute;
      width: 30px;
      height: 30px;
      top: -10px;
      left: 50%;
      margin-left: -15px;
      z-index: 101;
      display: block;
  
      &::before {
        visibility: hidden;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        border-radius: 2px;
        content: '';
        box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2);
        margin: 5px;
        width: 20px;
        height: 20px;
      }
  
      svg {
        visibility: hidden;
        transform: rotate(90deg);
  
        path {
          fill: #1B1B1B;
        }
      }
    }

    &__wrapper {
     
  
      &_right {
        right: 0;
      }
    }
  
    &__block {
      padding: 0 1rem;
    }
  
    &__content {
      top: -200px;
      background: {
        color: #1B1B1B;
      }
      width: 100%;
      z-index: 102;
  
    }
  }
}