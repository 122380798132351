
.ds-placeholder-component{
    background-color: #ddd;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 1300px;
    animation: shine-lines 2.6s infinite linear;
}

@keyframes shine-lines {
    0% {
      background-position: -10%;
    }
    40%, 100% {
      background-position: 100%;
    }
  }