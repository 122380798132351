@charset "UTF-8";
/*
* Third Party
*/
/*
* Custom
*/
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
.pointer {
  cursor: pointer; }

.disabled {
  filter: grayscale(100%);
  pointer-events: none;
  opacity: 0.5; }

html {
  font-size: 10px; }

.h1 {
  font-size: 4.2rem;
  line-height: 4.6rem; }

.h2 {
  font-size: 1.5rem; }

.h3 {
  font-size: 1.25rem; }

.h4 {
  font-size: 1rem; }

.h5 {
  font-size: .875rem; }

.h6 {
  font-size: .75rem; }

.t1 {
  font-size: 1.8rem;
  line-height: 2.4rem; }

.t2 {
  font-size: 1.6rem;
  line-height: 2.2rem; }

.t3 {
  font-size: 1.4rem;
  line-height: 1.8rem; }

.t4 {
  font-size: 1.2rem;
  line-height: 1.4rem; }

.t5 {
  font-size: 1rem;
  line-height: 1.2rem; }

.t6 {
  font-size: 0.8rem;
  line-height: 1rem; }

.font-family-inherit {
  font-family: inherit; }

.font-size-inherit {
  font-size: inherit; }

.text-decoration-none {
  text-decoration: none; }

.bold {
  font-weight: bold;
  font-weight: bold; }

.regular {
  font-weight: normal; }

.italic {
  font-style: italic; }

.caps {
  text-transform: uppercase;
  letter-spacing: .2em; }

.left-align {
  text-align: left; }

.center {
  text-align: center; }

.right-align {
  text-align: right; }

.justify {
  text-align: justify; }

.nowrap {
  white-space: nowrap; }

.break-word {
  word-wrap: break-word; }

.line-height-1 {
  line-height: 1; }

.line-height-2 {
  line-height: 1.125; }

.line-height-3 {
  line-height: 1.25; }

.line-height-4 {
  line-height: 1.5; }

.list-style-none {
  list-style: none; }

.underline {
  text-decoration: underline; }

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.list-reset {
  list-style: none;
  padding-left: 0; }

.inline {
  display: inline; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.table {
  display: table; }

.table-cell {
  display: table-cell; }

.overflow-hidden {
  overflow: hidden; }

.overflow-scroll {
  overflow: scroll; }

.overflow-auto {
  overflow: auto; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.left {
  float: left; }

.right {
  float: right; }

.fit {
  max-width: 100%; }

.max-width-1 {
  max-width: 24rem; }

.max-width-2 {
  max-width: 32rem; }

.max-width-3 {
  max-width: 48rem; }

.max-width-4 {
  max-width: 64rem; }

.border-box {
  box-sizing: border-box; }

.align-baseline {
  vertical-align: baseline; }

.align-top {
  vertical-align: top; }

.align-middle {
  vertical-align: middle; }

.align-bottom {
  vertical-align: bottom; }

.m0 {
  margin: 0; }

.mt0 {
  margin-top: 0; }

.mr0 {
  margin-right: 0; }

.mb0 {
  margin-bottom: 0; }

.ml0 {
  margin-left: 0; }

.mx0 {
  margin-left: 0;
  margin-right: 0; }

.my0 {
  margin-top: 0;
  margin-bottom: 0; }

.m1 {
  margin: .5rem; }

.mt1 {
  margin-top: .5rem; }

.mr1 {
  margin-right: .5rem; }

.mb1 {
  margin-bottom: .5rem; }

.ml1 {
  margin-left: .5rem; }

.mx1 {
  margin-left: .5rem;
  margin-right: .5rem; }

.my1 {
  margin-top: .5rem;
  margin-bottom: .5rem; }

.m2 {
  margin: 1rem; }

.mt2 {
  margin-top: 1rem; }

.mr2 {
  margin-right: 1rem; }

.mb2 {
  margin-bottom: 1rem; }

.ml2 {
  margin-left: 1rem; }

.mx2 {
  margin-left: 1rem;
  margin-right: 1rem; }

.my2 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.m3 {
  margin: 2rem; }

.mt3 {
  margin-top: 2rem; }

.mr3 {
  margin-right: 2rem; }

.mb3 {
  margin-bottom: 2rem; }

.ml3 {
  margin-left: 2rem; }

.mx3 {
  margin-left: 2rem;
  margin-right: 2rem; }

.my3 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.m4 {
  margin: 4rem; }

.mt4 {
  margin-top: 4rem; }

.mr4 {
  margin-right: 4rem; }

.mb4 {
  margin-bottom: 4rem; }

.ml4 {
  margin-left: 4rem; }

.mx4 {
  margin-left: 4rem;
  margin-right: 4rem; }

.my4 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.m5 {
  margin: 5rem; }

.mt5 {
  margin-top: 5rem; }

.mr5 {
  margin-right: 5rem; }

.mb5 {
  margin-bottom: 5rem; }

.ml5 {
  margin-left: 5rem; }

.mx5 {
  margin-left: 5rem;
  margin-right: 5rem; }

.my5 {
  margin-top: 5rem;
  margin-bottom: 5rem; }

.m6 {
  margin: 6rem; }

.mt6 {
  margin-top: 6rem; }

.mr6 {
  margin-right: 6rem; }

.mb6 {
  margin-bottom: 6rem; }

.ml6 {
  margin-left: 6rem; }

.mx6 {
  margin-left: 6rem;
  margin-right: 6rem; }

.my6 {
  margin-top: 6rem;
  margin-bottom: 6rem; }

.mxn1 {
  margin-left: -.5rem;
  margin-right: -.5rem; }

.mxn2 {
  margin-left: -1rem;
  margin-right: -1rem; }

.mxn3 {
  margin-left: -2rem;
  margin-right: -2rem; }

.mxn4 {
  margin-left: -4rem;
  margin-right: -4rem; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.p0 {
  padding: 0; }

.pt0 {
  padding-top: 0; }

.pr0 {
  padding-right: 0; }

.pb0 {
  padding-bottom: 0; }

.pl0 {
  padding-left: 0; }

.px0 {
  padding-left: 0;
  padding-right: 0; }

.py0 {
  padding-top: 0;
  padding-bottom: 0; }

.p1 {
  padding: .5rem; }

.pt1 {
  padding-top: .5rem; }

.pr1 {
  padding-right: .5rem; }

.pb1 {
  padding-bottom: .5rem; }

.pl1 {
  padding-left: .5rem; }

.py1 {
  padding-top: .5rem;
  padding-bottom: .5rem; }

.px1 {
  padding-left: .5rem;
  padding-right: .5rem; }

.p2 {
  padding: 1rem; }

.pt2 {
  padding-top: 1rem; }

.pr2 {
  padding-right: 1rem; }

.pb2 {
  padding-bottom: 1rem; }

.pl2 {
  padding-left: 1rem; }

.py2 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.px2 {
  padding-left: 1rem;
  padding-right: 1rem; }

.p3 {
  padding: 2rem; }

.pt3 {
  padding-top: 2rem; }

.pr3 {
  padding-right: 2rem; }

.pb3 {
  padding-bottom: 2rem; }

.pl3 {
  padding-left: 2rem; }

.py3 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.px3 {
  padding-left: 2rem;
  padding-right: 2rem; }

.p4 {
  padding: 4rem; }

.pt4 {
  padding-top: 4rem; }

.pr4 {
  padding-right: 4rem; }

.pb4 {
  padding-bottom: 4rem; }

.pl4 {
  padding-left: 4rem; }

.py4 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.px4 {
  padding-left: 4rem;
  padding-right: 4rem; }

.p5 {
  padding: 5rem; }

.pt5 {
  padding-top: 5rem; }

.pr5 {
  padding-right: 5rem; }

.pb5 {
  padding-bottom: 5rem; }

.pl5 {
  padding-left: 5rem; }

.py5 {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.px5 {
  padding-left: 5rem;
  padding-right: 5rem; }

.p6 {
  padding: 6rem; }

.pt6 {
  padding-top: 6rem; }

.pr6 {
  padding-right: 6rem; }

.pb6 {
  padding-bottom: 6rem; }

.pl6 {
  padding-left: 6rem; }

.py6 {
  padding-top: 6rem;
  padding-bottom: 6rem; }

.px6 {
  padding-left: 6rem;
  padding-right: 6rem; }

.col {
  float: left;
  box-sizing: border-box; }

.col-right {
  float: right;
  box-sizing: border-box; }

.col-1 {
  width: 8.33333%; }

.col-2 {
  width: 16.66667%; }

.col-3 {
  width: 25%; }

.col-4 {
  width: 33.33333%; }

.col-5 {
  width: 41.66667%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33333%; }

.col-8 {
  width: 66.66667%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33333%; }

.col-11 {
  width: 91.66667%; }

.col-12 {
  width: 100%; }

@media (min-width: 40em) {
  .sm-col {
    float: left;
    box-sizing: border-box; }
  .sm-col-right {
    float: right;
    box-sizing: border-box; }
  .sm-col-1 {
    width: 8.33333%; }
  .sm-col-2 {
    width: 16.66667%; }
  .sm-col-3 {
    width: 25%; }
  .sm-col-4 {
    width: 33.33333%; }
  .sm-col-5 {
    width: 41.66667%; }
  .sm-col-6 {
    width: 50%; }
  .sm-col-7 {
    width: 58.33333%; }
  .sm-col-8 {
    width: 66.66667%; }
  .sm-col-9 {
    width: 75%; }
  .sm-col-10 {
    width: 83.33333%; }
  .sm-col-11 {
    width: 91.66667%; }
  .sm-col-12 {
    width: 100%; } }

@media (min-width: 52em) {
  .md-col {
    float: left;
    box-sizing: border-box; }
  .md-col-right {
    float: right;
    box-sizing: border-box; }
  .md-col-1 {
    width: 8.33333%; }
  .md-col-2 {
    width: 16.66667%; }
  .md-col-3 {
    width: 25%; }
  .md-col-4 {
    width: 33.33333%; }
  .md-col-5 {
    width: 41.66667%; }
  .md-col-6 {
    width: 50%; }
  .md-col-7 {
    width: 58.33333%; }
  .md-col-8 {
    width: 66.66667%; }
  .md-col-9 {
    width: 75%; }
  .md-col-10 {
    width: 83.33333%; }
  .md-col-11 {
    width: 91.66667%; }
  .md-col-12 {
    width: 100%; } }

@media (min-width: 64em) {
  .lg-col {
    float: left;
    box-sizing: border-box; }
  .lg-col-right {
    float: right;
    box-sizing: border-box; }
  .lg-col-1 {
    width: 8.33333%; }
  .lg-col-2 {
    width: 16.66667%; }
  .lg-col-3 {
    width: 25%; }
  .lg-col-4 {
    width: 33.33333%; }
  .lg-col-5 {
    width: 41.66667%; }
  .lg-col-6 {
    width: 50%; }
  .lg-col-7 {
    width: 58.33333%; }
  .lg-col-8 {
    width: 66.66667%; }
  .lg-col-9 {
    width: 75%; }
  .lg-col-10 {
    width: 83.33333%; }
  .lg-col-11 {
    width: 91.66667%; }
  .lg-col-12 {
    width: 100%; } }

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

@media (min-width: 40em) {
  .sm-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; } }

@media (min-width: 52em) {
  .md-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; } }

@media (min-width: 64em) {
  .lg-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; } }

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.flex-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.items-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start; }

.items-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  -ms-grid-row-align: flex-end;
  align-items: flex-end; }

.items-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center; }

.items-baseline {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  -ms-grid-row-align: baseline;
  align-items: baseline; }

.items-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  -ms-grid-row-align: stretch;
  align-items: stretch; }

.self-start {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start; }

.self-end {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end; }

.self-center {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.self-baseline {
  -webkit-align-self: baseline;
  -ms-flex-item-align: baseline;
  align-self: baseline; }

.self-stretch {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch; }

.justify-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.justify-end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.justify-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.justify-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.justify-around {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.content-start {
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start; }

.content-end {
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end; }

.content-center {
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center; }

.content-between {
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between; }

.content-around {
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around; }

.content-stretch {
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch; }

.flex-auto {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0; }

.flex-none {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none; }

.order-0 {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3; }

.order-last {
  -webkit-box-ordinal-group: 100000;
  -webkit-order: 99999;
  -ms-flex-order: 99999;
  order: 99999; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.fixed {
  position: fixed; }

.top-0 {
  top: 0; }

.right-0 {
  right: 0; }

.bottom-0 {
  bottom: 0; }

.left-0 {
  left: 0; }

.z1 {
  z-index: 1; }

.z2 {
  z-index: 2; }

.z3 {
  z-index: 3; }

.z4 {
  z-index: 4; }

.border {
  border-style: solid;
  border-width: 1px; }

.border-top {
  border-top-style: solid;
  border-top-width: 1px; }

.border-right {
  border-right-style: solid;
  border-right-width: 1px; }

.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px; }

.border-left {
  border-left-style: solid;
  border-left-width: 1px; }

.border-none {
  border: 0; }

.rounded {
  border-radius: 20px; }

.circle {
  border-radius: 50%; }

.rounded-top {
  border-radius: 20px 20px 0 0; }

.rounded-right {
  border-radius: 0 20px 20px 0; }

.rounded-bottom {
  border-radius: 0 0 20px 20px; }

.rounded-left {
  border-radius: 20px 0 0 20px; }

.not-rounded {
  border-radius: 0; }

.hide {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.dark-magenta {
  color: #8e0470; }

.red {
  color: #FF4040; }

.black {
  color: #111 !important; }

.white {
  color: #fff !important; }

.blue {
  color: #07c; }

.gray {
  color: #ccc; }

.darken {
  color: rgba(0, 0, 0, 0.0625); }

.bg-black {
  background-color: #111; }

.bg-white {
  background-color: #fff; }

.bg-blue {
  background-color: #07c; }

.bg-gray {
  background-color: #ccc; }

.bg-darken {
  background-color: rgba(0, 0, 0, 0.0625); }

.bg-dark-magenta {
  background-color: #8e0470; }

@media (max-width: 40em) {
  .xs-hide {
    display: none !important; } }

@media (min-width: 40em) and (max-width: 52em) {
  .sm-hide {
    display: none !important; } }

@media (min-width: 52em) and (max-width: 64em) {
  .md-hide {
    display: none !important; } }

@media (min-width: 64em) {
  .lg-hide {
    display: none !important; } }

.display-none {
  display: none !important; }

input {
  outline: none;
  border: none; }

button {
  background: none;
  border: none;
  outline: none; }

th {
  font-weight: inherit; }

.dashed {
  background-image: linear-gradient(to right, orange 75%, transparent 75%);
  background-position: 0 1.04em;
  background-repeat: repeat-x;
  background-size: 8px 5px;
  padding-right: 5px; }

.main__container_wide {
  align-items: center;
  padding: 60px 20px;
  background-color: #232323; }

.predictionHeader {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center; }

.predictionParagraph {
  color: #ffffff;
  font-family: Ubuntu-Medium, sans-serif;
  font-size: 30px;
  letter-spacing: 0.4px;
  padding: 9px 0; }
  .predictionParagraph_small {
    font-size: 24px;
    font-family: Ubuntu-Bold, sans-serif; }
  .predictionParagraph_smaller {
    font-family: Ubuntu-Regular, sans-serif;
    padding-bottom: 40px;
    color: #F2F4FF; }
  .predictionParagraph_big {
    font-size: 48px;
    color: #F2F4FF;
    font-family: Ubuntu-Bold, sans-serif; }

.predictionParagraphWrapper {
  overflow: hidden; }

.predictionParagraph.featured {
  margin-bottom: -13.5px; }

.predictionParagraphWrapper .predictionParagraph {
  float: left; }

.liveStream {
  margin-bottom: 42px; }

.liveStream.live {
  margin-bottom: 33px; }

.chatButton {
  position: absolute;
  bottom: 0;
  right: 0;
  float: right;
  display: block;
  margin: 0px 10px 10px 0;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #fff;
  font-size: 24px; }

.twitchSlider {
  position: relative;
  background-size: 30%; }
  @media only screen and (max-device-width: 479px) {
    .twitchSlider {
      background-image: none;
      background-color: #1b1b1b; } }

.twitchStream {
  height: 100px;
  overflow: hidden; }

.prediction {
  margin-bottom: 50px;
  padding-top: 32px; }

.prediction-stream {
  height: 610px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center; }
  @media only screen and (max-device-width: 479px) {
    .prediction-stream {
      height: auto !important;
      margin-bottom: 40px; } }
  .prediction-stream .predictionColStream {
    width: calc(100% - 350px);
    height: 100%; }
  .prediction-stream .predictionColPred {
    position: relative;
    width: 350px;
    height: 100%; }
  .prediction-stream .predictionTwitchStream {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 440px;
    border-radius: 6px;
    background-color: #232323;
    /*margin: 10px;*/
    margin-left: 0;
    box-sizing: border-box; }
    .prediction-stream .predictionTwitchStream html {
      overflow: hidden; }
  .prediction-stream .predictionMainWindow {
    height: 100%; }
  .prediction-stream .predictionMainWindowWrapper {
    position: relative;
    /*margin: 10px; // padding: 0 20px;*/
    margin-right: 0;
    height: 100%;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: #000000; }
  .prediction-stream .predictionMainWindowEmpty {
    height: calc(100% - 44px);
    margin: 20px;
    padding: 0 50px;
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
    text-align: center;
    color: #ffffff;
    border-radius: 6px;
    background-color: #1b1b1b;
    border: solid 2px #ffffff;
    display: -webkit-inline-box;
    line-height: 500px; }
    .prediction-stream .predictionMainWindowEmpty span {
      line-height: 28px;
      display: inline-block;
      vertical-align: middle; }

.mainFakePrediction {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-user-select: none;
  -moz-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.btn-blue {
  font-family: Ubuntu-Regular, sans-serif;
  /*margin: 5px;*/
  margin-top: 8px;
  padding: 11px 32px;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  border: 2px solid #4b7bec;
  background-color: #4b7bec;
  box-sizing: border-box;
  border-radius: 20px; }
  .btn-blue_dark {
    background-color: #05192B;
    border: 2px solid #4b7bec; }

.interaction {
  position: relative;
  background: #4c6077; }
  .interaction .containerWrapper {
    padding: 0; }

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%; }

.interactionBackground {
  display: block;
  width: 100%; }

.interactionContent {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; }

.linkWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .linkWrapper a.btn-blue_dark:hover {
    transition: all .3s ease;
    box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }

.infoBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box; }
  .infoBlock:nth-child(even) {
    flex-direction: row-reverse; }
  .infoBlock .infoBlockImg,
  .infoBlock .infoBlockContent {
    width: 50%;
    box-sizing: border-box;
    background-color: #1B1B1B; }
  .infoBlock .infoBlockImg-img {
    display: block;
    width: 100%;
    height: auto; }
  .infoBlock .infoBlockContent {
    padding: 40px;
    display: flex;
    flex-direction: column;
    background-color: #1B1B1B;
    justify-content: center;
    align-items: flex-start; }
  .infoBlock .infoblockContent-icon {
    display: none; }

.infoBlockContent-header,
.infoBlockContent-paragraph {
  color: #ffffff; }
  .infoBlockContent-header a,
  .infoBlockContent-paragraph a {
    text-decoration: underline; }

.infoBlockContent-paragraph {
  opacity: 0.8;
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 18px; }

.infoBlockContent-header {
  padding-bottom: 21px;
  font-size: 36px;
  font-family: Ubuntu-Bold, sans-serif; }

.predictoria-header {
  font-family: Ubuntu-Bold, sans-serif;
  font-size: 52px;
  padding-top: 112px;
  padding-bottom: 75px;
  color: #f7f7f7;
  text-align: center;
  letter-spacing: -2px; }

.AdvantagesContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start; }

.AdvantagesContent-item {
  width: 33%;
  padding: 0 7%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 57px; }

.AdvantagesContentIcon-img {
  height: 32px;
  width: auto;
  display: block;
  padding-bottom: 21px; }

.AdvantagesContentTitle-header {
  font-family: Ubuntu-Bold, sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  color: #f7f7f7;
  opacity: 0.8;
  padding-bottom: 15px;
  text-transform: uppercase; }

.AdvantagesContentContent-paragraph {
  font-family: Ubuntu-Regular, sans-serif;
  line-height: 27px;
  font-size: 18px;
  text-align: center;
  color: #f7f7f7;
  opacity: 0.95; }

.AdvantagesContent {
  margin-bottom: 0; }

.streamSlider,
.streamList {
  width: calc(100% - 95px);
  position: relative;
  max-width: 1300px;
  margin: 0 auto; }
  .streamSlider a,
  .streamList a {
    display: flex;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    transition: all 0.2s ease;
    display: block;
    width: 142px;
    height: 90px; }
  .streamSlider .activestreamItemList .streamItem-overlay,
  .streamList .activestreamItemList .streamItem-overlay {
    background: transparent; }
  .streamSlider .slick-next:before,
  .streamSlider .slick-prev:before,
  .streamList .slick-next:before,
  .streamList .slick-prev:before {
    content: none; }
  .streamSlider .slick-next,
  .streamSlider .slick-prev,
  .streamList .slick-next,
  .streamList .slick-prev {
    font-size: initial;
    line-height: initial;
    top: 0;
    width: initial;
    height: initial;
    -webkit-transform: none;
    -ms-transform: none; }
  .streamSlider .slick-prev,
  .streamList .slick-prev {
    margin-left: 0; }
  .streamSlider .slick-next,
  .streamList .slick-next {
    margin-right: 0; }
  .streamSlider .streamItem,
  .streamList .streamItem {
    width: 160px !important;
    height: 90px;
    margin: 0 5px;
    box-sizing: border-box;
    background: black;
    overflow: hidden; }
    .streamSlider .streamItem_empty,
    .streamList .streamItem_empty {
      visibility: hidden;
      opacity: 0; }
    .streamSlider .streamItem:hover,
    .streamList .streamItem:hover {
      transition: all 0.2s ease; }
      .streamSlider .streamItem:hover .streamItem-overlay,
      .streamList .streamItem:hover .streamItem-overlay {
        background: transparent; }
        .streamSlider .streamItem:hover .streamItem-overlay .streamSlider__info,
        .streamList .streamItem:hover .streamItem-overlay .streamSlider__info {
          transition: all 0.5s;
          opacity: 1;
          transform: translateY(-20px); }
    .streamSlider .streamItem .streamItem-img,
    .streamList .streamItem .streamItem-img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.streamItem-Btn {
  width: 39px !important;
  height: 86px !important;
  margin: 0 5px;
  display: flex !important;
  align-items: center;
  text-align: center;
  cursor: pointer; }
  .streamItem-Btn.slick-disabled {
    opacity: .25;
    cursor: default; }
  .streamItem-Btn .streamItem-img {
    margin: 0 auto;
    opacity: 1;
    cursor: pointer; }

.gamesSlider {
  height: 160px;
  overflow: hidden; }

#gamesSlider.streamSlider {
  width: calc(100% - 100px);
  left: 55px; }

#gamesSlider .streamItem {
  position: relative;
  width: 120px;
  height: 160px; }
  #gamesSlider .streamItem span {
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 1;
    width: 100%;
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: #ffffff; }
    #gamesSlider .streamItem span.hidden {
      display: none; }

#gamesSlider .streamItem-Btn {
  height: 156px !important; }

#gamesSlider .streamItem-overlay {
  background: none; }

.slick-disabled .streamItem-img {
  opacity: 0.3;
  cursor: default; }

.streamItem-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5); }

.streamItem-amount {
  cursor: pointer;
  position: absolute;
  bottom: 9px;
  left: 25px;
  font-size: 14px;
  color: white; }
  .streamItem-amount:before {
    position: absolute;
    content: '';
    display: block;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    left: -15px;
    height: 10px;
    width: 10px;
    background: #FF4040; }

.streamList {
  overflow: hidden; }
  .streamList .streamItem {
    width: 31% !important;
    height: 170px;
    margin: 13.5px;
    float: left; }
  .streamList .streamItem-amount {
    font-size: 20px;
    bottom: 10px;
    left: 35px;
    letter-spacing: 0.3px; }
  .streamList .streamItem-amount:before {
    width: 20px;
    height: 20px;
    background: 0;
    left: -25px;
    content: url("/assets/images/yellow-cup.svg"); }

.predictionMainWindow {
  padding-top: 0px !important; }

.predictionContainer {
  position: relative;
  margin: 0 20px;
  margin-top: 10px; }

.predictionContainer:first-child {
  margin-top: 20px; }

.predictionContainer:last-child {
  margin-bottom: 20px; }

.predictionContainer button,
.predictionContainer input[type="button"] {
  -webkit-appearance: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  opacity: 1; }

.predictionContainer button:hover,
.predictionContainer input[type="button"]:hover {
  opacity: 0.85; }

.predictionContainer button:focus,
.predictionContainer input[type="button"]:focus {
  outline: 0; }

.predictionContainerBlock {
  border: 2px solid #ffffff;
  border-radius: 6px;
  background-color: #1B1B1B;
  overflow: hidden; }

.predictionContainerBlock__top {
  background-color: #282828;
  padding: 20px; }

.predictionContainerBlock__top__headerRow {
  overflow: hidden; }

.predictionContainerBlock__top__headerRow_timer {
  overflow: hidden;
  float: left; }

.predictionContainerBlock__top__headerRow_timer_time {
  font-size: 12px;
  color: #000000;
  float: left;
  letter-spacing: 0.14px;
  line-height: 23px;
  border-radius: 13px;
  background-color: #ffffff;
  padding: 0 13px; }

.predictionContainerBlock__top__headerRow_timer_desc {
  font-size: 12px;
  margin-left: 5px;
  float: left;
  line-height: 25px;
  color: #ffffff;
  letter-spacing: 0.14px; }

.predictionContainerBlock__top__headerRow_type {
  float: right;
  color: #ffffff;
  line-height: 25px;
  border: 1px solid;
  border-radius: 13px;
  padding: 0 13px;
  font-size: 12px;
  font-weight: 100;
  cursor: pointer; }

.predictionContainerBlock__top__headerRow_type:after {
  width: 11px;
  height: 11px;
  object-fit: contain;
  margin: 0 0 0 5px; }

.predictionContainerBlock__top__headerRow_type.live_event {
  border-color: #FF3B30;
  background-color: #2B0A08; }

.predictionContainerBlock__top__headerRow_type.live_event:after {
  content: url("../images/oval.svg");
  background-color: #ff3b30;
  background-color: var(--lose); }

.predictionContainerBlock__top__headerRow_type.next_event {
  background-color: #0E1E26;
  border-color: #5AC8FA; }

.predictionContainerBlock__top__headerRow_type.next_event:after {
  content: url("../images/arrow.svg");
  width: 11px;
  height: 7px;
  object-fit: contain; }

.predictionContainerBlock__top__headerRow_type.waiting_for_result {
  background-color: #242004;
  border-color: #ffe620; }

.predictionContainerBlock__top__headerRow_type.waiting_for_result:after {
  content: url("../images/timer.svg"); }

.predictionContainerBlock__top__headerRow_type.challenge_event {
  border-color: green;
  background-color: #1c3c24; }

.predictionContainerBlock__top__headerRow_type.challenge_event.not_accepted {
  border-color: #ffe620;
  background-color: #7d5824; }

.predictionContainerBlock__top__headerRow_type.challenge_event:after {
  content: '🏆'; }

.predictionContainerBlock__top__headerRow_type.done, .predictionContainerBlock__top__headerRow_type.win, .predictionContainerBlock__top__headerRow_type.lose {
  display: none; }

.predictionContainerBlock__top__footerRow {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.17px;
  margin: 10px 0 0 0;
  color: #ffffff; }

.predictionContainerBlock__bottom {
  padding: 20px 15px; }
  .predictionContainerBlock__bottom .team_one.tip span,
  .predictionContainerBlock__bottom .team_two.tip span {
    cursor: pointer; }

.predictionContainerBlock__bottom table {
  text-align: center;
  width: 100%; }

.predictionContainerBlock__bottom thead th {
  font-size: 10px;
  font-weight: lighter;
  letter-spacing: 0.14px;
  color: #ffffff; }

.predictionContainerBlock__bottom tbody tr td {
  line-height: 40px;
  text-align: center;
  padding: 5px; }

.predictionContainerBlock__bottom tbody tr td > span {
  font-size: 12px;
  font-weight: lighter;
  letter-spacing: 0.2px;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 20px;
  width: 100%;
  line-height: 38px;
  display: block;
  /* padding: 12px 10px; */
  margin: 0 auto; }

.predictionContainerBlock__bottom tbody tr td button {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.17px;
  color: #ffffff;
  border-radius: 20px;
  width: 100%;
  line-height: 38px;
  border: 1px solid;
  cursor: pointer; }

.predictionContainerBlock__bottom tbody tr.team_one td button {
  background-color: #787AFF;
  border-color: #787Aff; }

.predictionContainerBlock__bottom tbody tr.team_one td button:hover {
  opacity: 0.85; }

.predictionContainerBlock__bottom tbody tr.team_one.prediction td button {
  cursor: initial; }
  .predictionContainerBlock__bottom tbody tr.team_one.prediction td button:hover {
    opacity: 1; }

.predictionContainerBlock__bottom tbody tr.team_two.prediction td button {
  cursor: initial; }
  .predictionContainerBlock__bottom tbody tr.team_two.prediction td button:hover {
    opacity: 1; }

.predictionContainerBlock__bottom tbody tr.team_two td button {
  background-color: #FF9500;
  border-color: #FF9500; }

.predictionContainerBlock__bottom tbody tr.team_two td button:hover {
  opacity: 0.85; }

.predictionContainerBlock__bottom tbody tr.win td span {
  background-color: #011f10;
  border-color: #04de71; }

.predictionContainerBlock__bottom tbody tr.win td button {
  background-color: #04de71;
  border-color: #04de71; }

.predictionContainerBlock__bottom tbody tr.win.first td button {
  background-color: #9300ff;
  border-color: #9300ff; }

.predictionContainerBlock__bottom tbody tr.win.first td span {
  background-color: #0e011f;
  border-color: #9300ff; }

.predictionContainerBlock__bottom tbody tr.lose td span {
  background-color: #2b0a08;
  border-color: #ff3b30; }

.predictionContainerBlock__bottom tbody tr.lose td button {
  background-color: #ff3b30;
  border-color: #ff3b30; }

.predictionContainerBlock__bottom tbody tr.disabled td span {
  opacity: 0.3; }

.predictionContainerBlock__bottom tbody tr.disabled td button {
  background-color: #f2f4ff;
  border-color: #f2f4ff;
  color: #000000;
  opacity: 0.3;
  cursor: auto; }

.predictionContainerBlock__bottom tbody tr.disabled td button:hover {
  opacity: 0.3; }

/** set prediction value block **/
.predictionContainerAdd {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #1b1b1b;
  overflow: hidden; }

.predictionContainerAdd__back {
  cursor: pointer;
  position: relative;
  width: 42px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #1b1b1b;
  border: solid 2px #f2f4ff;
  margin-right: 10px;
  float: left;
  overflow: hidden; }
  .predictionContainerAdd__back .predictionContainerAdd__back-img {
    position: absolute;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    right: 0;
    left: 0;
    margin: auto; }

.predictionContainerAdd__body {
  height: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #1b1b1b;
  border: solid 2px #f2f4ff;
  overflow: hidden; }

.predictionContainerAdd__body__header {
  padding: 20px;
  background-color: #282828; }

.predictionContainerAdd__body__header h3 {
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #ffffff; }

.predictionContainerAdd__body__header h5 {
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  margin-top: 8px;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: left;
  color: #ffffff; }

.predictionContainerAdd__body__footer {
  padding: 40px 20px 20px; }
  .predictionContainerAdd__body__footer.challenge {
    padding-top: 20px; }

.predictionContainerAdd__body__footer__controlls {
  overflow: hidden; }

.predictionContainerAdd__body__footer__controlls input {
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
  text-align: center;
  width: calc(33% - 10px);
  float: left;
  color: #ffffff;
  -webkit-appearance: none; }

.predictionContainerAdd__body__footer__controlls__value {
  line-height: 36px;
  border-radius: 20px;
  border: solid 2px #f2f4ff;
  background-color: #1b1b1b;
  margin-right: 10px; }

.predictionContainerAdd__body__footer__controlls__value:focus {
  outline: 0; }

.predictionContainerAdd__body__footer__controlls__place {
  font-weight: bold;
  border-radius: 23px;
  background-color: #2094fa;
  border: 2px solid #2094fa;
  line-height: 36px;
  cursor: pointer;
  -webkit-appearance: none; }

.predictionContainerAdd__body__footer__buttons {
  overflow: hidden;
  margin-top: 10px; }

.predictionContainerAdd__body__footer__buttons__button {
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  width: calc(25% - 10px);
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  float: left;
  line-height: 40px;
  cursor: pointer;
  border: none;
  margin-right: 10px; }

.predictionContainerAdd__body__footer__buttons__button:last-child {
  margin-right: 0px; }

/* The slider itself */
.predictionContainerAdd__body__footer__controlls__slider {
  -webkit-appearance: none;
  appearance: none;
  width: calc(33% - 10px);
  height: 4px;
  border-radius: 2px;
  background-color: #ffffff;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin: 18px 10px 18px 0px; }

/* Mouse-over effects */
.predictionContainerAdd__body__footer__controlls__slider:hover {
  opacity: 1;
  /* Fully shown on mouse-over */ }

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.predictionContainerAdd__body__footer__controlls__slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  background-color: #2d87bf;
  border: solid 2px #f7f7f7;
  cursor: pointer;
  /* Cursor on hover */
  border-radius: 50%; }

.predictionContainerAdd__body__footer__controlls__slider::-moz-range-thumb {
  width: 13px;
  height: 13px;
  box-sizing: border-box;
  background-color: #2d87bf;
  border: solid 2px #f7f7f7;
  border-radius: 50%;
  cursor: pointer;
  /* Cursor on hover */ }

/** set prediction value block END **/
[data-simplebar] {
  position: relative;
  z-index: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  /* Trigger native scrolling for mobile, if not supported, plugin is used. */ }

[data-simplebar="init"] {
  display: flex; }

.simplebar-scroll-content {
  overflow-x: hidden;
  overflow-y: scroll;
  min-width: 100%;
  box-sizing: content-box; }

.simplebar-content {
  overflow-x: scroll;
  overflow-y: hidden;
  box-sizing: border-box;
  min-height: 100%; }

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 11px; }

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  border-radius: 7px;
  min-height: 10px;
  width: 7px;
  opacity: 0;
  transition: opacity 0.2s linear;
  background: black;
  background-clip: padding-box; }

.simplebar-track:hover .simplebar-scrollbar {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0 linear; }

.simplebar-track .simplebar-scrollbar.visible {
  opacity: 0.5; }

.simplebar-track.horizontal {
  left: 0;
  width: auto;
  height: 11px; }

.simplebar-track.vertical {
  top: 0; }

.horizontal.simplebar-track .simplebar-scrollbar {
  right: auto;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto; }

.hoverOverlayPrediction {
  position: absolute;
  width: calc(100% - 4px);
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in;
  z-index: 1000;
  border: 2px solid white;
  border-radius: 6px; }
  .hoverOverlayPrediction.hoverOverlayPrediction_active {
    visibility: visible;
    opacity: 1; }
  .hoverOverlayPrediction p {
    padding: 20px;
    text-align: justify; }
  .hoverOverlayPrediction .hideButtonTip {
    cursor: pointer;
    font-size: 16px;
    line-height: 1;
    position: absolute;
    top: 25px;
    left: 25px;
    color: white; }

.hoverButtonOverlay {
  position: absolute;
  padding: 12px 12px;
  left: 0;
  right: 0;
  bottom: calc(100% + 2px);
  height: auto;
  max-width: 200%;
  width: auto;
  border-radius: 6px;
  margin: auto;
  background: #282828;
  color: white;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  letter-spacing: 0.1px;
  box-shadow: 0px 0px 10px black; }
  .hoverButtonOverlay p {
    font-size: 12px;
    line-height: 1; }

.hoverTipWrapper {
  position: relative; }
  .hoverTipWrapper button + div {
    z-index: auto;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease; }
  .hoverTipWrapper button:hover {
    cursor: pointer !important; }
    .hoverTipWrapper button:hover + div {
      visibility: visible;
      opacity: 1; }

#g-recaptcha {
  margin: 10px auto 20px; }

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/
/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/
@media (min-width: 1025px) and (max-width: 1439px) {
  .streamList .streamItem {
    margin: 1%;
    width: 31% !important; }
  .predictionParagraph.featured {
    margin-bottom: -6px; } }

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), (min-width: 768px) and (max-width: 1024px) {
  .twitchSlider {
    padding-bottom: 1px; }
  .predictionParagraph {
    font-size: 24px;
    letter-spacing: 0.3px;
    padding-top: 0; }
  .predictionParagraph.featured {
    margin-bottom: -6px; }
  .prediction {
    margin-bottom: 20px; }
  .prediction-stream .predictionColStream {
    width: 61.5%; }
  .prediction-stream .predictionMainWindowEmpty {
    width: calc(100% - 20px);
    box-sizing: border-box;
    margin: 10px;
    padding: 0 20px;
    height: calc(100% - 20px); }
  .streamList {
    width: 101%;
    left: -0.5%; }
  .streamList .streamItem {
    margin: 0.5%;
    width: 32.3% !important;
    height: 130px; }
  .interactionBackground {
    height: 300px; }
  .interactionContent {
    padding-left: 30px; }
  .infoBlockContent-paragraph {
    font-size: 12px;
    line-height: 23px; }
  .interaction .predictionParagraph {
    font-size: 12px; }
  .predictionParagraph_small {
    font-size: 13px; }
  .interaction .predictionParagraph_big,
  .infoBlockContent-header,
  .predictoria-header {
    font-size: 24px; }
  .predictoria-header {
    padding-top: 60px;
    padding-bottom: 50px; }
  .AdvantagesContentTitle-header {
    font-size: 10px; }
  .AdvantagesContentContent-paragraph {
    font-size: 14px;
    line-height: 26px; }
  .AdvantagesContent-item {
    margin-bottom: 40px; }
  .AdvantagesContent-item {
    padding: 0 4%; }
  .AdvantagesContent {
    padding-bottom: 50px; }
  .feedbackModal-create {
    margin-top: -5px; } }

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px //812px iphone X //823px pixel 2x l
*/
@media (min-width: 481px) and (max-width: 823px) {
  .prediction .prediction-stream .predictionColStream .predictionTwitchStream > div {
    /*height: 110vh !important;*/ } }

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/
/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px //812px iphone X //823px pixel 2x l
*/
@media (min-width: 481px) and (max-width: 767px), (min-width: 320px) and (max-width: 480px) {
  .twitchSlider {
    padding: 0 3px; }
    .twitchSlider .slider-mobile-hidder {
      display: none !important; }
    .twitchSlider .predictionParagraph {
      font-family: Ubuntu-Regular, sans-serif;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      padding: 10px 0; }
    .twitchSlider .prediction {
      padding-top: 10px;
      margin-bottom: 0; }
      .twitchSlider .prediction .prediction-stream {
        flex-direction: column; }
        .twitchSlider .prediction .prediction-stream .predictionColStream {
          width: 100%; }
          .twitchSlider .prediction .prediction-stream .predictionColStream .predictionTwitchStream {
            margin: 0;
            padding: 0; }
            .twitchSlider .prediction .prediction-stream .predictionColStream .predictionTwitchStream .chatButton {
              top: -35px;
              bottom: auto;
              font-family: Ubuntu-Regular, sans-serif;
              font-size: 12px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: 0.75;
              letter-spacing: normal;
              margin: 0;
              padding: 7px 10px;
              border-radius: 8px;
              border: solid 1px #ffffff; }
        .twitchSlider .prediction .prediction-stream .predictionColPred {
          width: 100%; }
          .twitchSlider .prediction .prediction-stream .predictionColPred .feedbackModal-create,
          .twitchSlider .prediction .prediction-stream .predictionColPred .predictionParagraph {
            display: none; }
          .twitchSlider .prediction .prediction-stream .predictionColPred .predictionMainWindowWrapper {
            margin: 0;
            background-color: initial;
            max-height: initial;
            height: initial; }
            .twitchSlider .prediction .prediction-stream .predictionColPred .predictionMainWindowWrapper .predictionContainer {
              margin-left: 0;
              margin-right: 0; }
            .twitchSlider .prediction .prediction-stream .predictionColPred .predictionMainWindowWrapper .predictionContainer:first-child {
              margin-top: 0; }
            .twitchSlider .prediction .prediction-stream .predictionColPred .predictionMainWindowWrapper .predictionContainer.predictionContainerBlock.btn-blue {
              width: 100% !important; }
          .twitchSlider .prediction .prediction-stream .predictionColPred .predictionMainWindowEmpty {
            height: calc(100% - 4px);
            margin: 0px;
            padding: 0 45px;
            width: 100%;
            display: block;
            box-sizing: border-box; }
            .twitchSlider .prediction .prediction-stream .predictionColPred .predictionMainWindowEmpty button {
              margin: 0; }
    .twitchSlider .streamList {
      left: 0;
      width: 100%; }
      .twitchSlider .streamList .streamItem {
        width: 100% !important;
        margin: 0;
        margin-bottom: 10px; }
        .twitchSlider .streamList .streamItem:first-child .streamItem-img {
          width: 100%; }
  .interaction {
    height: 50vh;
    overflow: hidden; }
    .interaction img {
      width: initial;
      height: 100%; }
    .interaction .interactionContent {
      width: calc(100% - 40px);
      padding: 0 20px; }
      .interaction .interactionContent .predictionParagraph.predictionParagraph_small {
        font-family: Ubuntu-Regular, sans-serif;
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.1px;
        color: #f2f4ff; }
        .interaction .interactionContent .predictionParagraph.predictionParagraph_small.predictionParagraph_smaller {
          font-family: Ubuntu-Regular, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.1px;
          color: #f2f4ff; }
      .interaction .interactionContent .predictionParagraph.predictionParagraph_big {
        font-family: Ubuntu-Regular, sans-serif;
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #f2f4ff; }
  .info .infoBlock {
    flex-direction: column; }
    .info .infoBlock .infoBlockImg,
    .info .infoBlock .infoBlockContent {
      width: 100%; }
  .Advantages .Advantages-header {
    display: none; }
  .Advantages .AdvantagesContent {
    padding: 40px 20px;
    flex-direction: column; }
    .Advantages .AdvantagesContent .AdvantagesContent-item {
      width: 100%;
      margin-bottom: 20px;
      padding: 0 20px; }
      .Advantages .AdvantagesContent .AdvantagesContent-item:nth-child(n+3) {
        display: none; }
  .predictionParagraph.featured {
    margin-bottom: 0; } }

@media (min-width: 320px) and (max-width: 480px) {
  .predictionParagraph.featured {
    margin-bottom: 0; }
  .twitchSlider {
    padding: 10px 3px 0px; }
    .twitchSlider .slider-mobile-hidder {
      display: none !important; }
    .twitchSlider .twitchStream {
      height: 60px; }
    .twitchSlider .predictionColStream .predictionParagraph {
      width: calc(100% - 90px); }
    .twitchSlider .streamItem-Btn {
      height: 60px !important;
      width: 28px !important;
      box-sizing: border-box; }
    .twitchSlider #gamesSlider {
      height: 100px; }
    .twitchSlider #gamesSlider .streamItem-Btn {
      height: 100px !important; }
    .twitchSlider #gamesSlider .streamItem {
      height: 100px;
      width: 75px;
      margin: 0;
      margin-right: 5px;
      box-sizing: border-box; }
    .twitchSlider #gamesSlider.streamSlider,
    .twitchSlider .streamSlider {
      left: 33px;
      width: calc(100% - 66px); }
    .twitchSlider .streamItem-Btn .streamItem-img {
      transform: scale(0.6); }
    .twitchSlider .streamSlider .streamItem-amount:before {
      height: 6.7px;
      width: 6.7px;
      left: -11px; }
    .twitchSlider .streamSlider .streamItem-amount {
      bottom: 5px;
      left: 18px;
      font-size: 9.3px; }
    .twitchSlider .prediction-stream .predictionMainWindowEmpty span {
      font-size: 16px;
      line-height: 16px; }
    .twitchSlider .predictionContainer.predictionContainerBlock.btn-blue {
      padding-left: 20px;
      padding-right: 20px; }
    .twitchSlider .liveStream {
      margin-bottom: 10px; }
      .twitchSlider .liveStream .streamList .streamItem {
        height: 159px; } }
      @media only screen and (min-width: 320px) and (max-width: 480px) and (max-device-width: 479px) {
        .twitchSlider .liveStream .streamList .streamItem {
          width: calc(100% - 30px) !important;
          height: auto;
          padding: 0 15px;
          margin: 15px auto;
          float: none; } }

@media (min-width: 320px) and (max-width: 480px) {
    .twitchSlider .twitchSlider {
      padding-bottom: 1px; }
  .interaction {
    height: calc(50vh + 80px); }
  .infoBlock:first-child .infoBlockContent {
    background-color: #282828; }
  .info .infoBlock .infoBlockContent {
    text-align: center;
    padding: 40px 20px; }
  .info .infoBlockContent-header {
    font-size: 20px;
    width: 100%;
    padding-bottom: 10px; }
  .info .infoBlockContent-paragraph {
    font-size: 16px; }
  .info .infoBlockImg {
    display: none; }
  .info .infoblockContent-icon {
    display: inline-block;
    margin: 0 auto 20px; }
  .AdvantagesContentTitle-header {
    font-size: 16px;
    padding-bottom: 16px;
    letter-spacing: normal; }
  .AdvantagesContentIcon-img {
    padding-bottom: 12px; }
  .AdvantagesContentContent-paragraph {
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: normal; }
  .Advantages .AdvantagesContent {
    padding-bottom: 22px; }
  .Advantages .AdvantagesContent .AdvantagesContent-item {
    padding: 0 7%; }
  .footer-social, .footer-content {
    margin-bottom: 4px; } }

.adminPage {
  background-color: transparent;
  background-image: url("/assets/images/main/redesign/background-dark1.png"); }
  .adminPage__navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px; }

.streamItem-live {
  width: 37px;
  height: 20px;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #ffffff;
  background: #ff3b30;
  border-radius: 3px;
  position: absolute;
  right: 10px;
  bottom: 10px; }

.text {
  color: #f7f7f7;
  font-family: Ubuntu-Regular, sans-serif; }
  .text--small {
    font-size: 10px; }
  .text_small {
    font-size: 10px; }
  .text_medium {
    font-size: 12px; }
  .text_default {
    font-size: 14px; }

.greyscale {
  filter: grayscale(100%); }

.row-container {
  display: flex; }
  .row-container_center {
    justify-content: center; }
  .row-container_space {
    justify-content: space-between; }
  .row-container_v-center {
    align-items: center; }
  .row-container_around {
    justify-content: space-around; }
  .row-container_wrap {
    flex-wrap: wrap; }

.col-container {
  display: flex;
  flex-direction: column; }
  .col-container_center {
    align-items: center; }

.col-3 {
  column-count: 3; }

.head {
  width: 100%;
  color: #f7f7f7; }
  .head_center {
    text-align: center; }
  .head_small {
    font-size: 20px; }
  .head_medium {
    font-size: 22px; }
  .head_large {
    font-size: 36px; }

.button {
  text-align: center;
  height: 50px;
  border-radius: 25px;
  padding: 15px 30px;
  border: none;
  outline: none;
  color: #f7f7f7;
  cursor: pointer; }
  .button_grey {
    background-color: #4b4b4b; }
  .button_blue {
    background-color: #2095fe; }
  .button_gold {
    background-image: linear-gradient(#ffc837, #ff8008); }
  .button_gold-b {
    border: 4px solid #ff8008;
    background-color: transparent; }
  .button_glow:hover {
    box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }

.form__input-block {
  margin-bottom: 20px; }

.form__label {
  display: block;
  margin-bottom: 20px; }

.form__input {
  display: block;
  outline: none;
  border-radius: 25px;
  padding: 5px 10px;
  background: #4b4b4b;
  border: none;
  box-sizing: border-box;
  width: 100%;
  color: #f7f7f7; }

.link {
  cursor: pointer; }
  .link_gold {
    color: #ff8008; }
    .link_gold:hover {
      color: #f7f7f7; }
  .link_underline {
    text-decoration: underline; }

.icon_exclamation {
  background-image: url("../images/main/icons/1@3x.png"); }

.icon_questionmark {
  background-image: url("../images/main/icons/questionmark.svg"); }

.icon_clock {
  background-image: url("../images/main/icons/clock.png"); }

.icon_close {
  background-image: url("../images/main/activities/close.png"); }

header {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0px;
  z-index: 1000;
  width: 100%;
  height: 100px;
  background-color: #1b1b1b; }
  header .container {
    padding: 0 20px;
    width: 100%; }

.header__wrapper {
  margin-bottom: 100px; }
  @media only screen and (max-device-width: 479px) {
    .header__wrapper {
      margin-bottom: 80px; } }

.become-streamer__button {
  font-family: Ubuntu-Medium, sans-serif;
  font-size: 18px;
  color: #f7f7f7;
  height: 47px;
  width: 199px;
  line-height: 47px;
  border-radius: 23.5px;
  background-image: linear-gradient(#ffc837, #ff8008);
  text-align: center;
  padding: 0;
  display: block; }

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%; }
  .header__side {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .header__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px; }
    @media only screen and (max-device-width: 479px) {
      .header__group {
        display: none; } }

.profileBlock {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 500px; }
  .profileBlock__tooltip {
    margin-left: 8px; }
  @media only screen and (max-device-width: 479px) {
    .profileBlock {
      width: 40px; } }
  .profileBlock__info {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    padding-right: 20px;
    justify-content: center; }
    @media only screen and (max-device-width: 479px) {
      .profileBlock__info {
        display: none; } }
  .profileBlock__username {
    text-align: end; }
    .profileBlock__username span {
      font-family: Ubuntu-Regular, sans-serif;
      font-size: 20px;
      color: #f7f7f7; }
  .profileBlock__balance {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px; }
    .profileBlock__balance span {
      font-family: Ubuntu-Regular, sans-serif;
      font-size: 18px;
      text-align: center;
      color: rgba(247, 247, 247, 0.59);
      height: 13px;
      line-height: 13px; }
  .profileBlock__cubes {
    padding-right: 10px;
    display: flex;
    align-items: center; }
  .profileBlock__orbs {
    display: flex;
    align-items: center;
    cursor: pointer; }
  .profileBlock__plus {
    width: 25px;
    height: 25px;
    border-radius: 13px;
    background-image: linear-gradient(#ffc837, #ff8008);
    font-size: 25px;
    text-align: center;
    line-height: 24px;
    margin-left: 10px;
    display: block;
    transition: all .3s ease; }
    .profileBlock__plus:hover {
      box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
    .profileBlock__plus_cubes {
      margin-left: 0;
      margin-right: 10px;
      background-image: linear-gradient(#f7f7f7, 30%, #2094fa);
      color: #f7f7f7;
      cursor: pointer; }
      .profileBlock__plus_cubes:hover {
        box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
  .profileBlock__cubes::after {
    content: " ";
    display: inline-block;
    width: 13px;
    height: 13px;
    background-image: url("/assets/images/main/redesign/cube.png");
    margin-left: 5px;
    background-size: 100% 100%; }
  .profileBlock__orbs::after {
    display: inline-block;
    width: 13px;
    height: 13px;
    content: " ";
    background-image: url("/assets/images/main/redesign/orb.png");
    margin-left: 5px;
    background-size: 100% 100%; }
  .profileBlock__profile {
    cursor: pointer;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    box-sizing: border-box;
    overflow: hidden;
    transition: all .3s ease; }
    .profileBlock__profile:hover {
      box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
    @media only screen and (max-device-width: 479px) {
      .profileBlock__profile {
        height: 25px;
        width: 25px; } }
    .profileBlock__profile .react-gravatar {
      height: 100%;
      width: 100%; }
    .profileBlock__profile .avatarImg {
      height: 55px;
      width: 55px;
      object-fit: cover; }
      @media only screen and (max-device-width: 479px) {
        .profileBlock__profile .avatarImg {
          height: 25px;
          width: 25px; } }
  .profileBlock .coinsWrapper {
    display: block; }
    .profileBlock .coinsWrapper .coin {
      display: flex;
      width: 100%;
      height: 50px;
      justify-content: center;
      border-radius: 0; }
  .profileBlock .discordIcon {
    width: 50px;
    height: 44px;
    margin-right: 10px; }

.hamburgerMenu {
  display: none;
  height: 17px;
  width: 33px; }

.coin {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #05192B;
  border: 2px solid #4b7bec;
  border-radius: 23px;
  padding: 10px 0;
  height: 40px;
  box-sizing: border-box;
  margin-right: 9px;
  box-sizing: border-box; }
  .coin .coinImg {
    height: 20px;
    width: auto;
    padding-right: 14px;
    padding-left: 9px; }
  .coin .coinAmount {
    font-family: Ubuntu-Bold, sans-serif;
    line-height: 14px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #ffffff;
    padding-right: 20px; }

header .mainNav.mobile .coinsWrapper .coin {
  border: none;
  border-radius: 0; }

.coinsWrapper {
  display: none;
  border: 2px solid #4b7bec;
  border-radius: 10px;
  overflow: hidden; }
  .coinsWrapper .coin {
    padding: 12px 0; }
  .coinsWrapper .depositButton {
    display: inline-block;
    font-family: Ubuntu-Bold, sans-serif;
    width: 100%;
    font-size: 14px;
    background-color: #4b7bec;
    text-align: center;
    height: 49px;
    line-height: 49px; }

.deposit a {
  cursor: pointer;
  border: none;
  background: #4b7bec;
  font-family: Ubuntu-Bold, sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff; }

.deposit span {
  font-family: Ubuntu-Bold, sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff; }

.mainNav {
  display: flex;
  vertical-align: middle; }

.mainMenu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  vertical-align: middle; }
  .mainMenu__lang-switcher {
    display: flex;
    align-items: center;
    width: 110px;
    justify-content: space-between; }
  .mainMenu__lang-trigger {
    display: block;
    width: 25px;
    height: 25px;
    background-image: url("../images/main/header/lang.png");
    background-size: 100% 100%; }
  .mainMenu .mainMenu-item {
    padding: 0 15px;
    color: white; }
    .mainMenu .mainMenu-item a {
      color: #f7f7f7;
      font-size: 18px;
      font-family: Ubuntu-Medium, sans-serif; }
      .mainMenu .mainMenu-item a:hover {
        color: #ff8008; }

.notification {
  position: relative;
  background-color: #05192B;
  border-radius: 50%;
  border: 2px solid #4b7bec;
  margin-right: 5px;
  height: 31px;
  width: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; }
  .notification a {
    display: flex;
    width: 100%;
    height: 100%; }
  .notification #notificationMenu {
    top: 230%;
    top: 73px; }
  .notification #notificationBlock-link {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
  .notification .ringer {
    height: 15px;
    width: auto; }

.profile .react-gravatar {
  height: 100%;
  width: 100%; }

.profile .arrow {
  height: 5px;
  width: auto;
  margin-left: 5px; }

.profile .avatarImg {
  height: 45px;
  width: 45px;
  object-fit: cover; }

.profileBlock {
  display: flex; }

.Button {
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 15px 30px;
  color: white;
  background-color: transparent;
  border: 0;
  font-size: 12px;
  border-radius: 24px;
  font-family: Ubuntu-Bold, sans-serif;
  outline: none;
  cursor: pointer; }
  .Button.Button_blue {
    background-color: #4b7bec; }

.registerBlock {
  display: flex;
  justify-content: flex-end;
  width: 40%; }
  .registerBlock .signBlock {
    margin-left: 15px;
    border-radius: 24px; }
    .registerBlock .signBlock .Button {
      height: 40px;
      width: 126px;
      line-height: 40px;
      padding: 0; }
  .registerBlock .LoginBlock .Button {
    font-size: 16px; }

.profileMenu {
  border-radius: 6px; }
  .profileMenu .nameBlock {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    padding: 20px;
    background-color: #282828; }
    .profileMenu .nameBlock span {
      color: #ffffff;
      font-family: Ubuntu-Bold, sans-serif;
      font-size: 20px; }

.profileMenu {
  background-color: #1b1b1b;
  overflow: hidden;
  z-index: 100;
  display: block;
  width: 450px;
  position: absolute;
  top: 0px;
  right: 0;
  border-radius: 6px;
  border: solid 1px #e5ebf1; }
  .profileMenu__head {
    background-image: url("../images/main/header/profile-menu-head.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-end;
    height: 82px;
    padding: 0 20px;
    border-bottom: 1px solid white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px; }
    .profileMenu__head .profileBlock {
      display: flex;
      width: 100%;
      align-items: center; }
      .profileMenu__head .profileBlock__info {
        display: block;
        /* width: 200px; */
        height: auto; }
  .profileMenu .deposit__button {
    cursor: pointer;
    display: block;
    border-radius: 23.5px;
    height: 45px;
    width: 173px;
    border: double 4px transparent;
    background-image: linear-gradient(#1b1b1b, #1b1b1b), radial-gradient(circle at top left, #ffc837, #ff8008);
    background-origin: border-box;
    background-clip: content-box, border-box;
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    color: #f7f7f7;
    margin-right: 40px; }
  .profileMenu#depositMenu {
    width: 900px;
    cursor: default; }

.profileMenuList-item {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center; }
  .profileMenuList-item:first-child {
    padding-top: 20px; }
  .profileMenuList-item:last-child {
    padding-bottom: 20px; }
  .profileMenuList-item img, .profileMenuList-item .img {
    padding-right: 10px; }
  .profileMenuList-item a {
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: right;
    color: #f7f7f7; }
    .profileMenuList-item a:hover {
      color: #ff8008; }

.notificationAllDesc {
  display: flex;
  flex-direction: column; }

.notification-header {
  padding-bottom: 2px;
  font-family: Ubuntu-Bold, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.2px;
  text-align: left;
  color: #000000; }

.notification-condition {
  padding-bottom: 5px;
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 12px;
  letter-spacing: 0.2px;
  text-align: left;
  color: #000000; }

.notification-time {
  opacity: 0.6;
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 10px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #000000; }

.depositMenuList {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  background-color: #1b1b1b; }

.depositMenuList-item {
  display: flex;
  border-top: 2px solid #e5ebf1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-collapse: collapse;
  height: 100%;
  color: #f7f7f7;
  border-right: solid 2px #e5ebf1; }
  .depositMenuList-item_gold {
    background-image: linear-gradient(#1d043a, #904fb6);
    padding-bottom: 0px;
    border: 0px;
    height: 215px; }
  .depositMenuList-item button, .depositMenuList-item a {
    cursor: pointer;
    border: none;
    background: #4b7bec;
    font-family: Ubuntu-Bold, sans-serif;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #ffffff; }
  .depositMenuList-item a {
    width: 70px; }
  .depositMenuList-item img, .depositMenuList-item .img {
    margin-top: 20px;
    margin-bottom: 8px; }
  .depositMenuList-item .depositMenuList-btn {
    width: 160px;
    box-sizing: border-box; }
  .depositMenuList-item .depositMenuList-header {
    font-family: Ubuntu-Bold, sans-serif;
    font-size: 14px;
    letter-spacing: 0.2px;
    text-align: center;
    margin-bottom: 5px; }
    .depositMenuList-item .depositMenuList-header_gold {
      color: white; }
  .depositMenuList-item .depositMenuList-desc {
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 12px;
    min-height: 39px;
    letter-spacing: 0.2px;
    text-align: center; }
    .depositMenuList-item .depositMenuList-desc_gold {
      color: white; }

.depositMobile > div {
  background-color: #1b1b1b; }

.depositMobile .profileMenu#depositMenu {
  width: 81%;
  margin: auto;
  position: static;
  display: block;
  border: none;
  box-shadow: none;
  background: transparent;
  padding-bottom: 40px; }

.depositMobile .nameBlock {
  background-color: #1b1b1b;
  padding: 40px 0 10px; }

.depositMobile .depositMenuList {
  display: block; }

.depositMobile .depositMenuList-item {
  width: 100%;
  border-radius: 6px;
  border: none;
  margin-bottom: 10px;
  padding: 0;
  height: auto;
  background: #ffffff; }
  .depositMobile .depositMenuList-item_gold {
    background-image: linear-gradient(#1d043a, #904fb6); }
  .depositMobile .depositMenuList-item .depositMenuList-desc {
    min-height: unset; }
  .depositMobile .depositMenuList-item .pre-btn {
    margin-top: 10px; }
  .depositMobile .depositMenuList-item:last-child {
    margin-bottom: 0; }

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px), (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .profileMenu#depositMenu {
    max-width: 800px;
    right: 0;
    left: unset;
    width: calc(100% - 24px); }
  .depositMobile .profileMenu#depositMenu {
    width: 92%; }
  .depositMobile .nameBlock {
    text-align: center;
    padding-bottom: 20px; }
    .depositMobile .nameBlock span {
      font-size: 24px; }
  .depositMobile .depositMenuList-item {
    margin-bottom: 20px; }
    .depositMobile .depositMenuList-item_gold {
      background-image: linear-gradient(#1d043a, #904fb6); }
    .depositMobile .depositMenuList-item .depositMenuList-header {
      font-size: 20px;
      margin-bottom: 20px; }
    .depositMobile .depositMenuList-item .depositMenuList-desc {
      font-size: 14px; }
    .depositMobile .depositMenuList-item .img {
      margin-bottom: 20px; }
    .depositMobile .depositMenuList-item .pre-btn {
      margin-top: 20px; }
  header {
    height: 80px; }
    header .header {
      padding: 0;
      height: 80px; }
    header .hamburgerMenu {
      cursor: pointer;
      display: block;
      margin-left: 30px;
      margin-top: 10px;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }
    header .profileBlock .coinsWrapper {
      display: block; }
      header .profileBlock .coinsWrapper .coin {
        display: flex;
        width: 100%;
        justify-content: center; }
    header .profileBlock .discordIcon {
      display: none; }
    header .profileBlock .deposit {
      margin-right: 20px; }
    header .profileBlock .profile {
      width: 45px;
      height: 45px;
      margin-right: 5px;
      border: solid 2px #f2f4ff; }
    header .profileBlock .profileMenu {
      position: fixed;
      top: 90px;
      margin: 0 10px; }
    header .profileBlock #profileBlock {
      margin-right: 30px; }
    header .registerBlock .signBlock {
      margin: 0 20px 0 5px; }
    header .registerBlock .Button {
      /*font-size: 10px;  */
      padding: 9px 10px;
      border-radius: 20px;
      width: max-content; }
    header .registerBlock .modalDesc {
      display: block; }
    header .registerBlock .modalDesc-img {
      width: 100%;
      background-image: url("/assets/images/pop-up/form-back.jpg");
      background-size: cover;
      background-position: center;
      padding: 30px 82px;
      height: auto; }
    header .registerBlock .modalDesc-img .modalDesc-back {
      display: none; }
    header .registerBlock .modalDesc-content {
      position: static; }
    header .registerBlock .custom-modal {
      width: 600px; }
    header .registerBlock .modalDesc-form {
      width: calc(100% - 160px);
      margin: auto; }
    header .registerBlock .inputBlock {
      width: 100%; }
    header .registerBlock .inputBlock .authInput {
      width: 100%;
      height: 50px;
      line-height: 50px; }
    header .registerBlock .modal-content {
      border: none; }
    header .registerBlock .modalDesc-form {
      width: 100%; }
    header .registerBlock .AuthTabs {
      width: calc(100% - 160px);
      margin: auto; }
    header .registerBlock .forgotPass {
      padding-bottom: 10px; }
    header .registerBlock .authInput[type="submit"] {
      margin-top: 15px; }
    header .registerBlock #in .inputBlock.submit {
      margin-top: 94px; }
    header .registerBlock #up .socialContainer-desc {
      margin-top: 21px; }
    header .modalDesc-content .formLogo {
      padding: 30px 0; }
    header .modalDesc-content h3 {
      font-size: 24px; }
    header .modalDesc-content p {
      font-size: 16px; } }

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px //812px iphone X //823px pixel 2x l
*/
/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
/*@media (min-width: 481px) and (max-width: 823px),*/
@media (min-width: 481px) and (max-width: 767px), (min-width: 320px) and (max-width: 480px) {
  .mainNav {
    display: none; }
    .mainNav.mobile {
      position: fixed;
      /*top: 90px;*/
      top: 77px;
      left: 0;
      width: 100%;
      display: block; }
      .mainNav.mobile .coinsWrapper {
        display: block; }
      .mainNav.mobile .coin {
        border-radius: 10px;
        margin-right: 0px;
        justify-content: center;
        -webkit-box-pack: center; }
      .mainNav.mobile .mainMenu {
        display: block;
        text-align: center;
        border-radius: 10px;
        background-color: #232323; }
        .mainNav.mobile .mainMenu .mainMenu-item {
          display: flex;
          justify-content: center;
          color: #f7f7f7;
          padding: 20px 0;
          border-bottom: 1px solid #e5ebf1;
          background-color: #232323;
          margin: 0 10px;
          color: #f7f7f7; }
          .mainNav.mobile .mainMenu .mainMenu-item.social-links {
            border: none; }
          .mainNav.mobile .mainMenu .mainMenu-item_disabled a {
            color: grey; }
          .mainNav.mobile .mainMenu .mainMenu-item__lang-trigger {
            display: block;
            width: 25px;
            height: 25px;
            background-image: url("../images/main/header/lang.png");
            background-size: 100% 100%; }
          .mainNav.mobile .mainMenu .mainMenu-item:nth-last-child {
            border-bottom: 0; }
          .mainNav.mobile .mainMenu .mainMenu-item a {
            font-family: Ubuntu-Regular, sans-serif;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: 0.2px; }
  .switcher {
    display: none; }
  header {
    height: 80px; }
    header .hamburgerMenu {
      cursor: pointer;
      display: block;
      margin-left: 20px;
      margin-top: 5px;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }
    header .profileBlock .discordIcon, header .profileBlock .coin, header .profileBlock .deposit, header .profileBlock .notification, header .profileBlock .profileBlock-link .arrow {
      display: none; }
    header .profileBlock .profile {
      width: 29.2px;
      height: 29.2px;
      margin-right: 20px;
      border: solid 1.4px #f2f4ff; }
    header .profileBlock .profileMenu {
      position: fixed;
      top: 90px;
      left: 0;
      width: calc(100% - 24px);
      margin: 0 10px; }
    header .registerBlock {
      width: 50%; }
      header .registerBlock .signBlock {
        margin: 0 20px 0 5px; }
        header .registerBlock .signBlock .Button {
          width: 87px;
          height: 38px;
          padding: 0 23px;
          line-height: normal; }
      header .registerBlock .Button {
        font-size: 10px;
        padding: 10px 10px;
        border-radius: 20px; }
      header .registerBlock .custom-modal {
        top: 10px;
        width: calc(100% - 20px);
        transform: none; }
        header .registerBlock .custom-modal.modal-animate {
          animation: none; }
        header .registerBlock .custom-modal .modalDesc-img {
          display: none; }
        header .registerBlock .custom-modal .modalDesc-form {
          width: 100%; } }

.mainNav.mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block; }
  @media only screen and (min-device-width: 480px) {
    .mainNav.mobile {
      display: none;
      width: 100%;
      margin: 0; } }
  .mainNav.mobile .coin {
    border-radius: 10px;
    margin-right: 0px;
    justify-content: center;
    -webkit-box-pack: center; }
  .mainNav.mobile .mainMenu {
    display: block;
    text-align: center;
    border-radius: 10px; }
    .mainNav.mobile .mainMenu__lang-trigger {
      display: block;
      width: 25px;
      height: 25px;
      background-image: url("../images/main/header/lang.png");
      background-size: 100% 100%; }
    .mainNav.mobile .mainMenu .mainMenu-item {
      padding: 20px 0;
      border-bottom: 1px solid #e5ebf1; }
      .mainNav.mobile .mainMenu .mainMenu-item:nth-last-child {
        border-bottom: 0; }
      .mainNav.mobile .mainMenu .mainMenu-item a {
        font-family: Ubuntu-Regular, sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #f7f7f7; }
        .mainNav.mobile .mainMenu .mainMenu-item a:hover {
          color: #ffffff; }
      .mainNav.mobile .mainMenu .mainMenu-item span {
        color: #000000; }

.modalDesc {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch; }

.modalImgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5; }

.modalDesc-content {
  position: absolute;
  top: 0;
  left: 40px;
  right: 35px; }
  .modalDesc-content .formLogo {
    padding-top: 80px;
    height: 30px;
    width: auto;
    padding-bottom: 30px; }
  .modalDesc-content h3 {
    font-family: Ubuntu-Bold, sans-serif;
    font-size: 36px;
    letter-spacing: 0.1px;
    text-align: left;
    color: #f2f4ff;
    padding-bottom: 20px; }
  .modalDesc-content p {
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 24px;
    letter-spacing: 0.1px;
    text-align: left;
    color: #f2f4ff; }

.modalDesc-img, .modalDesc-form {
  border: 1px solid #f2f4ff;
  width: 50%;
  box-sizing: border-box; }
  @media only screen and (max-device-width: 479px) {
    .modalDesc-img, .modalDesc-form {
      width: 100%;
      padding-top: 20px; } }

.modalDesc-img {
  position: relative; }
  .modalDesc-img .modalDesc-back {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover; }

.custom-modal .closeButton {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 24px;
  right: 24px;
  border: none;
  cursor: pointer;
  z-index: 9999; }

footer {
  background-color: #282828;
  color: white;
  padding: 16px; }

@media (max-width: 1550px) {
  footer {
    padding-bottom: 20px; } }

.footerContent {
  display: flex;
  justify-content: space-around;
  align-items: start; }
  .footerContent.footerContent_additional {
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .footerContent.footerContent_additional .footer-content {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center; }

.predictoria {
  margin: 0;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.57px; }

.socialText {
  font-size: 14px;
  letter-spacing: 0.228571px;
  color: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.8;
  padding: 5px 0; }

.footer-social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .footer-social a {
    cursor: pointer;
    display: flex; }
  .footer-social .socialImg {
    display: block;
    height: 40px;
    width: auto;
    padding: 5px; }

.contact-us {
  padding-top: 32px;
  opacity: 0.5; }

.footer-social_additional {
  display: flex; }
  .footer-social_additional .socialImg {
    padding-right: 18px;
    padding-left: 18px;
    padding-top: 20px;
    height: 20px;
    width: auto; }

.modalForm-links {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  .modalForm-links button {
    border: 0;
    color: white;
    outline: 0;
    display: block;
    height: 100%;
    width: 100%;
    background-color: transparent;
    font-size: 20px;
    font-family: Ubuntu-Bold, sans-serif;
    padding: 20px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.5;
    cursor: pointer; }
    .modalForm-links button.activeFormLink {
      background-color: transparent;
      opacity: 1; }
    .modalForm-links button:hover {
      background-color: transparent;
      opacity: 1; }
  .modalForm-links div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%; }

.formTab {
  display: none;
  flex-direction: column; }
  .formTab.formTab_active {
    display: flex; }

.formContainerForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; }

.inputBlock {
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%; }
  .inputBlock .checkBox {
    display: flex;
    width: 340px;
    margin: 0 auto; }
  .inputBlock .authInput {
    box-sizing: border-box;
    outline: none;
    padding: 20px 61px;
    display: block;
    -webkit-appearance: none;
    background-color: #222224;
    border: 2px solid #e5ebf1;
    border-radius: 30px;
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 13px;
    letter-spacing: 0.2px;
    text-align: left;
    color: #ffffff;
    opacity: 0.6;
    width: 360px;
    margin: 0 auto 10px auto; }
    .inputBlock .authInput.authInput_modal {
      width: 450px;
      margin: 20px auto; }
    .inputBlock .authInput.authSendInput_modal {
      width: 200px;
      font-size: 12px;
      margin-top: 20px; }
    .inputBlock .authInput.authInput_error {
      transition: all 0.3s ease-in;
      background-color: #2b0a08;
      border: 2px solid #4069ca; }
    .inputBlock .authInput:active {
      opacity: 1; }
    .inputBlock .authInput:focus {
      opacity: 1; }

.inputBlock-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-75%);
  left: 20px; }

.inputBlock-img {
  display: block;
  height: auto;
  width: auto; }

.authInput[type="submit"] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 60px;
  padding: 0;
  cursor: pointer;
  background-color: #4b7bec;
  margin-top: 30px;
  border: #4b7bec;
  opacity: 1;
  text-align: center;
  font-family: Ubuntu-Bold, sans-serif;
  font-size: 20px;
  height: 60px;
  transition: background-color 0.3s ease-in; }
  .authInput[type="submit"]:hover {
    transition: background-color 0.3s ease-in;
    background-color: rgba(32, 128, 250, 0.8); }

/* hover style just for information */
.socialContainer-desc {
  display: block;
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 13px;
  letter-spacing: 0.2px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5); }

.socialContainer {
  width: 100%;
  margin-bottom: 40px; }

.socialContainerList {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .socialContainerList .socialContainer-img {
    padding: 5px;
    display: block;
    height: 45px;
    width: auto;
    border-radius: 10px;
    transition: filter 0.2s ease;
    opacity: 0.8; }
    .socialContainerList .socialContainer-img:hover {
      opacity: 1; }

.forgotPass {
  padding-bottom: 20px;
  display: block;
  margin: 0 auto;
  font-size: 10px;
  letter-spacing: 0.1px;
  text-align: center; }

#in .inputBlock.submit {
  margin-top: 40px; }

#up .socialContainer-desc {
  margin-top: 31px; }

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px //812px iphone X //823px pixel 2x l
*/
@media (min-width: 481px) and (max-width: 823px), (min-width: 320px) and (max-width: 480px) {
  .inputBlock .authInput {
    width: calc(100vw - 44px); } }

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .registerBlock .custom-modal {
    top: 50%;
    height: 100%; }
    .registerBlock .custom-modal.modal-animate {
      animation: none; }
    .registerBlock .custom-modal .modal-content {
      height: initial; }
  .aboutContent-header {
    font-size: 20px; }
  .aboutContent-description {
    font-size: 14px; }
  footer {
    padding: 24px 30px; } }

@media (min-width: 481px) and (max-width: 767px) {
  .predictoria {
    font-size: 16px; }
  .socialText {
    font-size: 12px; }
  .aboutContent {
    width: calc(100% - 40px); }
  .aboutContent-header {
    padding: 30px 0 20px; } }

@media (min-width: 320px) and (max-width: 480px) {
  header .registerBlock .custom-modal {
    width: calc(100% - 40px);
    top: 20px; }
  .footerContent {
    display: flex;
    flex-direction: column-reverse;
    text-align: center; }
  .footer-social, .footer-content {
    margin: auto; }
  .inputBlock {
    width: 100%; }
  .inputBlock .checkBox {
    width: calc(100% - 20px);
    margin: auto; }
  .inputBlock .authInput {
    padding: 13px 25px 13px 50px;
    width: calc(100% - 20px);
    font-size: 12px; }
  .authInput[type="submit"] {
    height: 44px;
    line-height: 44px;
    font-size: 12px;
    padding: 0;
    margin-top: 54px; }
  #up .authInput[type="submit"] {
    margin-top: 57px; }
  .authInput[name="referal"] {
    font-size: 10px; }
  .socialContainer-desc {
    margin-top: 0;
    font-size: 10px;
    margin-bottom: 5px; }
  #up .socialContainer-desc {
    margin-top: 0; }
  .socialContainer {
    margin-bottom: 20px; }
  .forgotPass {
    padding-bottom: 11px;
    font-size: 12px; }
  .inputBlock-icon {
    left: 35px; }
  header .registerBlock .custom-modal.modal-animate {
    height: auto; }
  .custom-modal .closeButton {
    display: block; } }

@media only screen and (max-device-width: 479px) {
  .footer {
    padding: 30px 15px; } }

.footer__links {
  display: flex;
  justify-content: center; }
  @media only screen and (max-device-width: 479px) {
    .footer__links {
      flex-wrap: wrap; } }

.footer__col {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  max-width: calc(100% /6);
  min-width: 150px; }
  @media only screen and (max-device-width: 479px) {
    .footer__col {
      margin-bottom: 30px;
      max-width: 100%;
      padding: 0; } }
  @media only screen and (max-device-width: 399px) {
    .footer__col {
      padding: 0 10px; } }

.footer__col-body {
  display: flex;
  flex-direction: column; }
  .footer__col-body a {
    padding: 5px 0px; }
    .footer__col-body a:hover {
      color: #ff8008; }

.footer__label {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.57px;
  padding-bottom: 5px; }

.footer__col-head {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.57px;
  padding-bottom: 5px; }

.about {
  padding: 0;
  margin: 0;
  background: transparent; }

.about.aboutUs {
  min-height: calc(100vh - 285px);
  padding-bottom: 105px;
  box-sizing: border-box; }

.aboutTeam {
  padding-top: 12px;
  background-color: black;
  width: 1060px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 70px; }

.aboutTeamCol {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 33%;
  padding: 24px;
  box-sizing: border-box; }

.aboutTeam-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px; }

.aboutTeamContent-name {
  font-size: 14px;
  font-family: Ubuntu-Bold, sans-serif;
  text-transform: uppercase;
  color: #ffffff;
  padding-bottom: 10px; }

.aboutTeamContent-post {
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.8; }

.aboutContent {
  max-width: 800px;
  width: calc(100% - 192px);
  margin: 0 auto;
  padding-bottom: 80px; }
  @media only screen and (max-device-width: 479px) {
    .aboutContent {
      padding: 80px 15px; } }
  .aboutContent .tabContent-header {
    text-align: center;
    font-family: Ubuntu-Bold, sans-serif;
    color: #ffffff !important;
    font-size: 34px;
    letter-spacing: 0.3px;
    padding-bottom: 28px;
    padding-top: 28px; }
    @media only screen and (max-device-width: 399px) {
      .aboutContent .tabContent-header {
        font-size: 24px;
        padding: 15px 0; } }
    .aboutContent .tabContent-header .blockExpandButton {
      float: right;
      display: none; }
  .aboutContent .tabContent-paragraph {
    text-align: justify;
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 24px;
    letter-spacing: 0.3px;
    padding-bottom: 0.5em;
    color: #FFFFFF !important; }
    @media only screen and (max-device-width: 479px) {
      .aboutContent .tabContent-paragraph {
        font-size: 20px; } }
    @media only screen and (max-device-width: 399px) {
      .aboutContent .tabContent-paragraph {
        font-size: 16px; } }
    .aboutContent .tabContent-paragraph a {
      text-decoration: underline; }
    .aboutContent .tabContent-paragraph button {
      width: initial;
      height: initial;
      padding: initial;
      font-size: 24px;
      text-decoration: underline; }
  .aboutContent:after {
    display: block;
    content: '';
    height: 2px;
    background-color: #4b7bec;
    width: 200px;
    margin: 0 auto; }

.aboutContent.aboutContent_faq:after {
  display: none; }

.aboutContentDescription {
  width: 100%; }

.aboutContent-header {
  font-family: Ubuntu-Bold, sans-serif;
  font-size: 64px;
  letter-spacing: 0.91px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  padding: 105px 0 36px 0; }

.aboutContent-description {
  font-family: Ubuntu-Regular, sans-serif;
  color: #ffffff;
  padding-bottom: 24px;
  font-size: 24px;
  line-height: 1.4;
  text-align: left; }

@media (min-width: 1550px) {
  .about.aboutUs {
    min-height: calc(100vh - 251px); } }

@media (min-width: 768px) and (max-width: 1024px), (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .aboutContent-header {
    font-size: 32px; }
  .aboutContent-description {
    font-size: 20px;
    text-align: center; }
  .aboutContent-header {
    padding: 70px 0 30px; } }

@media (min-width: 320px) and (max-width: 767px) {
  .aboutContent {
    width: calc(100% - 40px); }
  .aboutContent-header {
    font-size: 20px; }
  .aboutContent-description {
    font-size: 14px;
    text-align: center; }
  .aboutContent-header {
    padding: 30px 0 20px; }
  .about.aboutUs {
    padding-bottom: 0; }
  .about {
    width: 100%;
    overflow-x: scroll; }
  .about > div {
    width: 1200px; }
  .activity-admin__toggler {
    display: none; } }

.containerWrapper_absolute {
  top: 0px;
  width: 100%; }

.leaderboard {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between; }
  .leaderboard .b-dropdown__wrapper {
    top: 20px;
    left: -10px;
    background-color: transparent;
    width: 120px;
    display: flex;
    align-items: center; }
  .leaderboard .b-dropdown__content {
    background-color: transparent;
    align-items: center;
    width: 120px; }
  .leaderboard .b-dropdown__arrow svg {
    visibility: hidden !important; }
  .leaderboard .b-dropdown__trigger {
    width: 150px; }
  .leaderboard__avatar {
    position: relative;
    top: 15px;
    width: 40px;
    height: 40px;
    border: 3px solid white;
    border-radius: 50%;
    margin: 0 20px; }
    @media only screen and (max-device-width: 479px) {
      .leaderboard__avatar {
        display: none; } }
  .leaderboard .react-gravatar {
    position: relative;
    top: 15px;
    width: 50px;
    height: 50px;
    border: 3px solid white;
    border-radius: 50%;
    margin: 0 20px; }
  .leaderboard__table {
    padding: 40px;
    background-color: #1b1b1b; }
  .leaderboard__head {
    background-color: #1b1b1b;
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: lighter;
    line-height: 0.96;
    letter-spacing: normal;
    text-align: center;
    color: #cccaca; }
    .leaderboard__head th {
      height: 40px;
      text-align: left; }
  .leaderboard__body {
    background-color: #1b1b1b; }
    .leaderboard__body tr td, .leaderboard__body tr th {
      font-family: Ubuntu;
      font-size: 25px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 80px;
      letter-spacing: normal;
      color: #f7f7f7;
      text-align: left;
      border-bottom: 3px solid #484848;
      text-align: left;
      vertical-align: center; }
    .leaderboard__body tr:last-child td {
      border: none; }
  .leaderboard__section {
    background: linear-gradient(to bottom, rgba(27, 27, 27, 0) 20%, #1b1b1b 90%), url("../images/main/leaderboard/pirate-dark-min.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 280px 80px;
    padding-bottom: 120px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    @media only screen and (max-device-width: 479px) {
      .leaderboard__section {
        padding: 80px 20px 0px;
        background-size: cover;
        flex-direction: column;
        align-items: center;
        height: 180px; } }
  .leaderboard__header {
    width: 100%; }
  .leaderboard__page-title {
    font-family: Ubuntu;
    font-size: 84px;
    font-weight: bold;
    letter-spacing: normal;
    text-align: left;
    color: #f7f7f7; }
    @media only screen and (max-device-width: 479px) {
      .leaderboard__page-title {
        font-size: 40px;
        text-align: center; } }
  .leaderboard__page-desc {
    font-family: Ubuntu;
    font-size: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #f7f7f7; }
    @media only screen and (max-device-width: 479px) {
      .leaderboard__page-desc {
        text-align: center; } }
  .leaderboard__trigger {
    display: block;
    width: 150px;
    font-family: Ubuntu;
    font-size: 34px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.71;
    letter-spacing: normal;
    text-align: left;
    color: #f7f7f7; }
    @media only screen and (max-device-width: 479px) {
      .leaderboard__trigger {
        text-align: center; } }
  @media only screen and (max-device-width: 479px) {
    .leaderboard__table {
      padding: 0 15px; } }
  .leaderboard__trigger::after {
    display: block;
    position: absolute;
    width: 100%;
    border-bottom: 4px solid gold;
    margin: 10px 0 5px 0;
    content: " "; }
    @media only screen and (max-device-width: 479px) {
      .leaderboard__trigger::after {
        display: none; } }
  .leaderboard__option {
    cursor: pointer;
    width: 120px;
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.71;
    letter-spacing: normal;
    text-align: left;
    color: #d1cdcd;
    padding: 10px 0; }
    @media only screen and (max-device-width: 479px) {
      .leaderboard__option {
        text-align: center; } }
  .leaderboard .leaderboardUsers {
    width: 100%;
    margin-right: 20px; }
    .leaderboard .leaderboardUsers table {
      width: 100%; }
      .leaderboard .leaderboardUsers table .userPosition {
        width: 40px;
        height: 40px;
        display: block;
        margin: 0 auto;
        line-height: 40px;
        border-radius: 20px;
        background-color: rgba(255, 255, 255, 0.1);
        text-align: center; }
      .leaderboard .leaderboardUsers table .userName {
        text-align: left;
        line-height: 70px;
        margin-left: 15px;
        display: inline-block; }
  .leaderboard .sortByGames {
    width: 442px; }
    .leaderboard .sortByGames .gameSelector button {
      position: relative;
      margin-right: 10px;
      margin-bottom: 10px;
      outline: none;
      cursor: pointer;
      border: 0;
      font-family: Ubuntu-Regular, sans-serif;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      text-align: center;
      -webkit-transition: .3s;
      transition: opacity .3s; }
      .leaderboard .sortByGames .gameSelector button:nth-child(3n) {
        margin-right: 0px; }
      .leaderboard .sortByGames .gameSelector button .hover {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background: black; }
      .leaderboard .sortByGames .gameSelector button.active .hover, .leaderboard .sortByGames .gameSelector button:hover .hover {
        opacity: 0; }
      .leaderboard .sortByGames .gameSelector button span {
        position: absolute;
        top: 110px;
        left: 0;
        z-index: 1;
        width: 100%;
        font-family: Ubuntu-Regular, sans-serif;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        text-align: center;
        color: #ffffff; }
        .leaderboard .sortByGames .gameSelector button span.hidden {
          display: none; }
      .leaderboard .sortByGames .gameSelector button img {
        width: 134px;
        height: 178px;
        float: left; }

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), (min-width: 768px) and (max-width: 1024px) {
  .leaderboard {
    padding-left: 30px;
    padding-right: 30px; } }

@media (min-width: 320px) and (max-width: 480px) {
  .leaderboard .predictionParagraph {
    font-size: 20px; }
  .leaderboard .leaderboardUsers table thead th {
    font-size: 10px; }
  .leaderboard .leaderboardUsers table td {
    font-size: 14px;
    padding: 15px 5px;
    text-align: center; }
  .leaderboard .sortByGames {
    width: 100%; }
  .leaderboard .leaderboardUsers {
    width: 100%;
    margin-right: 0; }
  .leaderboard .leaderboardUsers table .userName {
    line-height: 14px;
    margin-left: 0; }
  .leaderboard .leaderboardUsers table .userPosition {
    width: 28px;
    height: 28px;
    line-height: 28px; } }

.admin__content {
  padding-top: 2rem; }

.admin__sidebar h3.head {
  margin-bottom: 1rem;
  text-align: center; }

.stream-list {
  width: 250px;
  background-color: #232323;
  margin-bottom: 1rem; }
  .stream-list__item {
    width: 100%;
    height: 50px;
    line-height: 50px;
    list-style: none; }
  .stream-list__header {
    width: 100%; }
  .stream-list__filter {
    border-radius: 0px;
    height: 50px;
    background-color: #232323; }
  .stream-list__search-icon {
    right: 30px;
    width: 20px;
    height: 20px;
    background-image: url(../images/main/help-center/search-icon.png);
    background-size: cover;
    display: inline;
    position: relative;
    cursor: pointer; }

.acceptedChallenges__challenges,
.waitingForAcceptChallenges__challenges,
.waitingForApproveChallenges__challenges {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }

.active-paid-actions__paid-actions {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start; }

.demonstratedPredictions__predictions {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start; }

.createPredictionBlock .predictionContainer {
  width: 275px;
  margin: 10px 5px;
  float: left; }
  .createPredictionBlock .predictionContainer button {
    width: 30%; }
  .createPredictionBlock .predictionContainer input {
    height: 20px; }
  .createPredictionBlock .predictionContainer .predictionContainerBlock__top__headerRow_type {
    width: 30%;
    height: 20px;
    padding: 2px;
    line-height: 20px; }
  .createPredictionBlock .predictionContainer .predictionContainerBlock__top__footerRow button {
    width: 40%; }
  .createPredictionBlock .predictionContainer .predictionContainerBlock__bottom button {
    width: 70%;
    height: 36px; }

.separator {
  width: 80%;
  margin: 1em auto;
  position: relative;
  height: 2px;
  display: block;
  background-color: #ffffff; }

.blue {
  background-color: blue;
  margin: 2em auto;
  width: 90%; }

.templateList {
  display: flex;
  flex-wrap: wrap; }

.templates {
  color: #f7f7f7; }
  .templates__header {
    color: #f7f7f7;
    margin: 20px 0; }
  .templates__label {
    font-family: Ubuntu-Medium, sans-serif;
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 15px; }

.column {
  display: flex !important;
  flex-direction: column !important; }

.predictionTab {
  color: white; }
  .predictionTab h1 {
    margin: 1em; }

.profilePageForm input {
  height: 30px;
  width: 40%; }

.profilePageForm button {
  width: 40%; }
  @media only screen and (max-device-width: 479px) {
    .profilePageForm button {
      width: 60%;
      display: block; } }

.tabContent__header {
  margin: 1em; }

.admin__nav {
  padding: 10px 25px; }

.admin-user {
  background-color: #232323; }
  .admin-user__search {
    color: #f7f7f7; }

.adminPage {
  padding: 1rem 0; }
  .adminPage__navigation-buttons {
    padding: 20px; }
    @media only screen and (max-device-width: 479px) {
      .adminPage__navigation-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; } }

.stream-edit__button {
  width: 130px; }

.bot__button {
  padding: 5px 0;
  width: 130px; }

.anchor {
  display: block;
  position: relative;
  top: -150px;
  visibility: hidden; }

.terms .tabContent-paragraph {
  font-size: 20px; }

.terms .tabContent-header {
  font-size: 36;
  font-weight: bold; }

.terms img {
  width: 300px;
  margin: 20px auto;
  display: block; }

.terms__buy-orbs-wrapper {
  display: inline-block;
  height: 40px;
  width: 100px; }

.terms .become-streamer__button {
  display: inline-block !important;
  width: 120px !important;
  margin: 0;
  margin-left: 10px; }

.streamer-help img {
  margin: 20px auto;
  display: block; }

.faqPage {
  background-color: #1b1b1b;
  background-size: 30%;
  min-height: calc(100vh - 285px); }
  .faqPage .about {
    background-color: transparent; }
  .faqPage .aboutContent {
    padding-bottom: 0; }

.faqContainer {
  margin-top: -10px; }

.faqMenuBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 105px; }

.faqMenu {
  display: inline-block;
  width: 28%;
  box-sizing: border-box;
  height: fit-content;
  border-radius: 6px;
  border: solid 2px #e5ebf1;
  background-color: #282828; }
  .faqMenu .faqMenu-item {
    cursor: pointer;
    background-color: #282828;
    display: block; }
    .faqMenu .faqMenu-item:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      margin-top: 15px; }
    .faqMenu .faqMenu-item:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      margin-bottom: 15px; }
    .faqMenu .faqMenu-item.activeItem {
      font-family: Ubuntu-Bold, sans-serif;
      background-color: #1b1b1b;
      background-color: #1b1b1b; }
      .faqMenu .faqMenu-item.activeItem a.activeItem {
        display: block;
        font-family: Ubuntu-Bold, sans-serif;
        background-color: #1b1b1b; }
    .faqMenu .faqMenu-item:hover {
      background-color: #1b1b1b; }
      .faqMenu .faqMenu-item:hover a {
        font-family: Ubuntu-Bold, sans-serif; }
    .faqMenu .faqMenu-item a {
      padding: 15px 0 15px 30px;
      display: block;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      font-family: Ubuntu-Regular, sans-serif;
      font-size: 20px;
      letter-spacing: 0.19px; }

.activeItem {
  display: block;
  font-family: Ubuntu-Bold, sans-serif;
  background-color: #1b1b1b;
  box-sizing: border-box; }

.faqContent {
  width: 80%; }
  .faqContent .tabContent {
    display: block;
    padding-left: 40px; }
    .faqContent .tabContent.activeTab {
      display: block; }
  .faqContent .tabContent-block:first-child .tabContent-header {
    padding-top: 0; }
  .faqContent .tabContent-header {
    font-family: Ubuntu-Bold, sans-serif;
    color: #ffffff !important;
    font-size: 34px;
    letter-spacing: 0.3px;
    padding-bottom: 28px;
    padding-top: 28px; }
    .faqContent .tabContent-header .blockExpandButton {
      float: right;
      display: none; }
  .faqContent .tabContent-paragraph {
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 24px;
    letter-spacing: 0.3px;
    color: #FFFFFF !important; }
    .faqContent .tabContent-paragraph a {
      text-decoration: underline; }
    .faqContent .tabContent-paragraph button {
      width: initial;
      height: initial;
      padding: initial;
      font-size: 24px;
      text-decoration: underline; }

@media (min-width: 1550px) {
  .faqPage {
    min-height: calc(100vh - 251px); } }

@media (max-width: 1024px) {
  .faqPage {
    padding: 0 30px; }
    .faqPage .aboutContent-header {
      padding: 50px 0 48px; }
    .faqPage .aboutContent {
      margin: auto; }
  .faqMenu .faqMenu-item a {
    font-size: 14px; }
  .faqContent .tabContent-header {
    font-size: 16px;
    padding: 15px 0;
    font-family: ubuntu, arial, sans-serif;
    font-weight: normal; }
    .faqContent .tabContent-header .blockExpandButton {
      display: block; }
  .faqContent .tabContent-paragraph {
    font-size: 12px;
    padding: 0 0 15px; }
  .faqContent .tabContent-block {
    border-bottom: 2px solid white; } }

@media (min-width: 481px) and (max-width: 767px) {
  .faqPage {
    padding: 0 20px; }
  .faqMenu .faqMenu-item a {
    font-size: 14px; }
  .faqContent .tabContent-header {
    font-size: 16px; }
  .faqContent .tabContent-paragraph {
    font-size: 12px; } }

@media (min-width: 320px) and (max-width: 480px) {
  .faqPage {
    padding-top: 24px; }
  .faqContainer {
    margin-top: 0; }
  .faqMenuBlock {
    display: block;
    padding-bottom: 40px; }
  .faqMenu {
    display: block;
    width: 100%;
    margin-bottom: 20px; }
  .faqContent {
    width: 100%; }
  .faqContent .tabContent {
    padding-left: 0; }
  .faqPage .aboutContent-header {
    display: none; }
  .faqMenu .faqMenu-item a {
    font-size: 18px; } }

.profile-stream {
  width: 80%;
  padding: 20px 0;
  margin: 0px auto; }
  @media only screen and (max-device-width: 479px) {
    .profile-stream {
      width: 100%; } }

.profileContainer {
  width: 720px;
  margin: 0 auto; }

.profilePage {
  min-height: calc(100vh - 285px);
  box-sizing: border-box;
  background-color: #1b1b1b;
  padding: 70px 0 40px 0; }

.profilePageBlock {
  display: flex;
  justify-content: center;
  align-items: flex-start; }
  .profilePageBlock_wide {
    align-items: center;
    width: 100%;
    margin-bottom: 20px; }

.overlayEditPhoto {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center; }
  .overlayEditPhoto p {
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-align: left;
    color: #ffffff; }

.profilePageBlock-avatar {
  cursor: pointer;
  margin-right: 50px;
  position: relative;
  overflow: hidden;
  height: 160px;
  width: 160px;
  border-radius: 50%;
  border: solid 2px #f2f4ff;
  transition: all 0.3s ease;
  box-sizing: border-box; }
  .profilePageBlock-avatar:hover {
    border: 2px solid #46474F; }
    .profilePageBlock-avatar:hover .overlayEditPhoto {
      transition: all 0.3s ease;
      opacity: 1;
      visibility: visible;
      top: -1px;
      left: -1px;
      width: 101%;
      height: 101%; }
  .profilePageBlock-avatar img {
    position: absolute;
    top: -9999px;
    right: -9999px;
    bottom: -9999px;
    left: -9999px;
    margin: auto;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover; }

.profilePageInputBlock {
  display: flex;
  flex-direction: column; }
  .profilePageInputBlock:first-child .profilePageInput-label {
    margin-top: 0; }

.profilePageInput-label {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 14px;
  letter-spacing: 0.2px;
  text-align: left;
  color: #ffffff; }

.profileInput {
  font-family: Ubuntu-Regular, sans-serif;
  margin-top: 10px;
  -webkit-appearance: none;
  width: 425px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #222224;
  border: solid 2px #e5ebf1;
  padding: 12px;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  color: #ffffff;
  transition: all .3s ease; }
  .profileInput_narrow {
    width: 300px; }
  .profileInput_pass {
    opacity: 0.6; }
  .profileInput:hover {
    transition: all .3s ease;
    border: solid 2px white; }

select.profileInput.profileInput_narrow {
  background: url("//predictoria.com/assets/favicon-white.png") 95%/10% no-repeat #222224; }

.connectedServicesSocial {
  display: flex; }
  @media only screen and (max-device-width: 479px) {
    .connectedServicesSocial {
      flex-direction: column;
      height: 120px;
      justify-content: space-around;
      align-items: center; } }

.connectedServices-title {
  padding-top: 20px;
  padding-bottom: 5px;
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: left;
  color: #ffffff; }

.depositMenuList-desc {
  padding: 0 19px; }

.connectedServicesSocial-img {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border-radius: 6px;
  cursor: pointer; }

.last {
  margin-right: 0px; }

.pre-btn {
  cursor: pointer;
  outline: none;
  margin-top: 20px;
  border: none;
  border-radius: 20px;
  background-color: #2d87bf;
  transition: all .3s ease;
  margin-bottom: 20px; }
  .pre-btn:hover {
    box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
  .pre-btn.pre-btn_gold {
    background-image: linear-gradient(#ffc400, #f5851c); }
  .pre-btn.pre-btn_red {
    background-color: #4069ca; }
    .pre-btn.pre-btn_red:hover {
      background-color: #FF4040; }
  .pre-btn.pre-btn_blue {
    background-color: #4b7bec; }
    .pre-btn.pre-btn_blue:hover {
      background-color: rgba(32, 148, 250, 0.8); }
  .pre-btn.pre-btn_promo {
    width: 70px;
    border: 2px solid #4b7bec;
    background-color: #05192B;
    text-align: center;
    cursor: text;
    box-sizing: unset;
    color: #f7f7f7; }
    .pre-btn.pre-btn_promo:hover {
      background-color: rgba(5, 25, 43, 0.8); }
  .pre-btn.pre-btn_modal {
    text-align: center; }
  .pre-btn.disabled {
    background-color: #5a5a5a;
    cursor: default;
    color: gray; }
    .pre-btn.disabled:hover {
      background-color: #5a5a5a; }
  .pre-btn:active {
    transition: all .3s ease; }
  .pre-btn:hover {
    transition: all .3s ease;
    background-color: rgba(4, 222, 113, 0.8); }

@media (min-width: 1550px) {
  .profilePage {
    min-height: calc(100vh - 251px); } }

@media (min-width: 320px) and (max-width: 480px) {
  .profile-page {
    padding-top: 80px; }
  .profilePageBlock-avatar {
    height: 140px;
    width: 140px; }
  .profileContainer {
    width: 100%; }
  .profileInput {
    width: 100%;
    font-size: 14px;
    margin-top: 7px;
    padding: 10px 12px; }
  .profilePageInput-label {
    font-size: 12px;
    margin-top: 15px; }
  .connectedServices-title {
    font-size: 12px; }
  .profilePageBlock {
    display: block; }
  .profilePage {
    padding: 30px 15px 0px 15px; }
  .profilePageBlock-avatar {
    margin: 0 auto 25px; }
  .addServices .pre-btn.pre-btn_blue {
    margin: 20px auto; } }

.modalView {
  background-color: #1b1b1b; }

.bonuscode {
  width: 400px !important; }

.reset-password {
  width: 400px !important; }

.modalViewContainer {
  min-height: calc(100vh - 285px);
  display: flex;
  justify-content: center;
  align-items: center; }

.modalViewBlock {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  background-color: #282828;
  padding: 60px 24px 50px;
  border-radius: 6px;
  border: solid 2px #e5ebf1;
  margin: 20px 0; }
  .modalViewBlock.profileInput {
    width: 300px; }

#authInput-signIn.authInput {
  margin-top: 0px; }

.modalViewBlock-title {
  padding-bottom: 10px;
  font-family: Ubuntu-Medium, sans-serif;
  font-size: 30px;
  letter-spacing: 0.4px;
  text-align: center;
  color: #ffffff; }

.modalViewBlock-desc {
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 12px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  margin-bottom: 30px; }

.modalViewBlock-link {
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 10px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #ffffff; }

@media (min-width: 1550px) {
  .modalViewContainer {
    min-height: calc(100vh - 251px); } }

@media (min-width: 320px) and (max-width: 480px) {
  .modalViewBlock {
    margin: 20px;
    width: 85%;
    padding: 15px !important; }
    .modalViewBlock .authInput {
      width: 100%; }
    .modalViewBlock .authInput[type="email"] {
      padding-left: 60px; }
    .modalViewBlock .inputBlock .authInput.authSendInput_modal {
      width: 100%; }
  .modalViewBlock-title {
    font-size: 20px;
    letter-spacing: 0.3px; }
  .modalViewBlock-desc {
    font-size: 10px;
    padding: 0 22px;
    box-sizing: border-box; }
  .formContainerForm {
    width: 100%; }
  .g-recaptcha {
    transform: scale(0.78);
    -webkit-transform: scale(0.78);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

.svgIcon-button-arrow-right, .svgIcon-Facebook,
.svgIcon-Facebook\:regular, .svgIcon-Instagram,
.svgIcon-Instagram\:regular, .svgIcon-Oval,
.svgIcon-Oval\:regular, .svgIcon-Twitter,
.svgIcon-Twitter\:regular, .svgIcon-Youtube,
.svgIcon-Youtube\:regular, .svgIcon-arrow-down,
.svgIcon-arrow-down\:regular, .svgIcon-arrow,
.svgIcon-arrow\:regular, .svgIcon-button-arrow-left,
.svgIcon-button-arrow-left\:regular,
.svgIcon-button-arrow-right\:regular, .svgIcon-chat_icon,
.svgIcon-chat_icon\:regular, .svgIcon-chat_icon_active,
.svgIcon-chat_icon_active\:regular, .svgIcon-clock,
.svgIcon-clock\:regular, .svgIcon-cubes,
.svgIcon-cubes\:regular, .svgIcon-edit-profile,
.svgIcon-edit-profile\:regular, .svgIcon-email-icon,
.svgIcon-email-icon\:regular, .svgIcon-fb,
.svgIcon-fb\:regular, .svgIcon-google,
.svgIcon-google\:regular, .svgIcon-green,
.svgIcon-green\:regular, .svgIcon-icon1,
.svgIcon-icon1\:regular, .svgIcon-icon2,
.svgIcon-icon2\:regular, .svgIcon-icon2_,
.svgIcon-icon2_\:regular, .svgIcon-icon3,
.svgIcon-icon3\:regular, .svgIcon-icon4,
.svgIcon-icon4\:regular, .svgIcon-icon5,
.svgIcon-icon5\:regular, .svgIcon-icon5_,
.svgIcon-icon5_\:regular, .svgIcon-icon6,
.svgIcon-icon6\:regular, .svgIcon-inst,
.svgIcon-inst\:regular, .svgIcon-loading-spin,
.svgIcon-loading-spin\:regular, .svgIcon-log-out,
.svgIcon-log-out\:regular, .svgIcon-logo,
.svgIcon-logo\:regular, .svgIcon-oval,
.svgIcon-oval\:regular, .svgIcon-password-icon,
.svgIcon-password-icon\:regular, .svgIcon-promocode,
.svgIcon-promocode\:regular, .svgIcon-recharge-balance,
.svgIcon-recharge-balance\:regular, .svgIcon-red,
.svgIcon-red\:regular, .svgIcon-referral,
.svgIcon-referral\:regular, .svgIcon-ringer,
.svgIcon-ringer\:regular, .svgIcon-share,
.svgIcon-share\:regular, .svgIcon-steam,
.svgIcon-steam\:regular, .svgIcon-support,
.svgIcon-support\:regular, .svgIcon-timer,
.svgIcon-timer\:regular, .svgIcon-twitter,
.svgIcon-twitter\:regular, .svgIcon-vk,
.svgIcon-vk\:regular, .svgIcon-yellow,
.svgIcon-yellow\:regular {
  background-repeat: no-repeat; }

.svgIcon-button-arrow-right {
  background-image: url("../images/sprite.svg"); }

.svgIcon-Facebook,
.svgIcon-Facebook\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 0; }

.svgIcon-Facebook-dims {
  width: 8px;
  height: 16px; }

.svgIcon-Instagram,
.svgIcon-Instagram\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -16px; }

.svgIcon-Instagram-dims {
  width: 16px;
  height: 16px; }

.svgIcon-Oval,
.svgIcon-Oval\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -32px; }

.svgIcon-Oval-dims {
  width: 25px;
  height: 25px; }

.svgIcon-Twitter,
.svgIcon-Twitter\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -57px; }

.svgIcon-Twitter-dims {
  width: 19px;
  height: 14px; }

.svgIcon-Youtube,
.svgIcon-Youtube\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -71px; }

.svgIcon-Youtube-dims {
  width: 40px;
  height: 40px; }

.svgIcon-arrow-down,
.svgIcon-arrow-down\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -111px; }

.svgIcon-arrow-down-dims {
  width: 8px;
  height: 5px; }

.svgIcon-arrow,
.svgIcon-arrow\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -116px; }

.svgIcon-arrow-dims {
  width: 13px;
  height: 11px;
  margin: 0 auto; }

.svgIcon-button-arrow-left,
.svgIcon-button-arrow-left\:regular {
  background-image: url("../images/sprite.svg");
  background-position: -18px -164px; }

.svgIcon-button-arrow-left-dims {
  width: 10px;
  height: 16px;
  margin: 0 auto; }

.svgIcon-button-arrow-right,
.svgIcon-button-arrow-right\:regular {
  background-image: url("../images/sprite.svg");
  background-position: -17px -254px; }

.svgIcon-button-arrow-right-dims {
  width: 10px;
  height: 16px; }

.svgIcon-chat_icon,
.svgIcon-chat_icon\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -307px; }

.svgIcon-chat_icon-dims {
  width: 25px;
  height: 16px; }

.svgIcon-chat_icon_active,
.svgIcon-chat_icon_active\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -323px; }

.svgIcon-chat_icon_active-dims {
  width: 25px;
  height: 16px; }

.svgIcon-clock,
.svgIcon-clock\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -339px; }

.svgIcon-clock-dims {
  width: 40px;
  height: 40px; }

.svgIcon-cubes,
.svgIcon-cubes\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -379px; }

.svgIcon-cubes-dims {
  width: 20px;
  height: 20px; }

.svgIcon-edit-profile,
.svgIcon-edit-profile\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -399px; }

.svgIcon-edit-profile-dims {
  width: 30px;
  height: 30px; }

.svgIcon-email-icon,
.svgIcon-email-icon\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -429px; }

.svgIcon-email-icon-dims {
  width: 19px;
  height: 13px; }

.svgIcon-fb,
.svgIcon-fb\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -441.65px; }

.svgIcon-fb-dims {
  width: 45px;
  height: 45px; }

.svgIcon-google,
.svgIcon-google\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -487px; }

.svgIcon-google-dims {
  width: 45px;
  height: 45px; }

.svgIcon-green,
.svgIcon-green\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -532px; }

.svgIcon-green-dims {
  width: 40px;
  height: 40px; }

.svgIcon-icon1,
.svgIcon-icon1\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -572px;
  background-size: auto; }

.svgIcon-icon1-dims {
  width: 32px;
  height: 32px; }

.svgIcon-icon2,
.svgIcon-icon2\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -604px;
  background-size: auto; }

.svgIcon-icon2-dims {
  width: 32px;
  height: 32px; }

.svgIcon-icon2_,
.svgIcon-icon2_\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -636px;
  background-size: auto; }

.svgIcon-icon2_-dims {
  width: 28px;
  height: 32px; }

.svgIcon-icon3,
.svgIcon-icon3\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -668px;
  background-size: auto; }

.svgIcon-icon3-dims {
  width: 26px;
  height: 32px; }

.svgIcon-icon4,
.svgIcon-icon4\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -700px;
  background-size: auto; }

.svgIcon-icon4-dims {
  width: 28px;
  height: 32px; }

.svgIcon-icon5,
.svgIcon-icon5\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -732px;
  background-size: auto; }

.svgIcon-icon5-dims {
  width: 38px;
  height: 32px; }

.svgIcon-icon5_,
.svgIcon-icon5_\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -764px;
  background-size: auto; }

.svgIcon-icon5_-dims {
  width: 32px;
  height: 32px; }

.svgIcon-icon6,
.svgIcon-icon6\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -796px;
  background-size: auto; }

.svgIcon-icon6-dims {
  width: 32px;
  height: 28px; }

.svgIcon-inst,
.svgIcon-inst\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -824px; }

.svgIcon-inst-dims {
  width: 40px;
  height: 40px; }

.svgIcon-loading-spin,
.svgIcon-loading-spin\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -864px; }

.svgIcon-loading-spin-dims {
  width: 32px;
  height: 32px; }

.svgIcon-log-out,
.svgIcon-log-out\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -896px; }

.svgIcon-log-out-dims {
  width: 30px;
  height: 30px; }

.svgIcon-logo,
.svgIcon-logo\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -926px; }

.svgIcon-logo-dims {
  width: 182px;
  height: 30px; }

.svgIcon-oval,
.svgIcon-oval\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -956px; }

.svgIcon-oval-dims {
  width: 11px;
  height: 11px; }

.svgIcon-password-icon,
.svgIcon-password-icon\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -967px; }

.svgIcon-password-icon-dims {
  width: 15px;
  height: 19px; }

.svgIcon-promocode,
.svgIcon-promocode\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -986px; }

.svgIcon-promocode-dims {
  width: 40px;
  height: 40px; }

.svgIcon-recharge-balance,
.svgIcon-recharge-balance\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -1026px; }

.svgIcon-recharge-balance-dims {
  width: 30px;
  height: 30px; }

.svgIcon-red,
.svgIcon-red\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -1056px; }

.svgIcon-red-dims {
  width: 40px;
  height: 40px; }

.svgIcon-referral,
.svgIcon-referral\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -1096px; }

.svgIcon-referral-dims {
  width: 18px;
  height: 20px; }

.svgIcon-ringer,
.svgIcon-ringer\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -1116px; }

.svgIcon-ringer-dims {
  width: 13px;
  height: 15px; }

.svgIcon-share,
.svgIcon-share\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -1131px; }

.svgIcon-share-dims {
  width: 40px;
  height: 40px; }

.svgIcon-steam,
.svgIcon-steam\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -1171px; }

.svgIcon-steam-dims {
  width: 45px;
  height: 45px; }

.svgIcon-support,
.svgIcon-support\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -1216px; }

.svgIcon-support-dims {
  width: 30px;
  height: 30px; }

.svgIcon-timer,
.svgIcon-timer\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -1246px; }

.svgIcon-timer-dims {
  width: 11px;
  height: 11px; }

.svgIcon-twitter,
.svgIcon-twitter\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -1257px; }

.svgIcon-twitter-dims {
  width: 45px;
  height: 45px; }

.svgIcon-vk,
.svgIcon-vk\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -1302px; }

.svgIcon-vk-dims {
  width: 45px;
  height: 45px; }

.svgIcon-yellow,
.svgIcon-yellow\:regular {
  background-image: url("../images/sprite.svg");
  background-position: 0 -1347px; }

.svgIcon-yellow-dims {
  width: 40px;
  height: 40px; }

.mainNav.mobile .b-dropdown__wrapper {
  right: 0; }

.b-dropdown {
  display: inline-block;
  /*margin-right: 4em;*/
  position: relative; }
  .b-dropdown_active {
    display: inline-block; }
    .b-dropdown_active .b-dropdown__wrapper {
      visibility: visible;
      transform: scale(1) translateY(10px);
      opacity: 1; }
  .b-dropdown__trigger {
    width: 80px;
    cursor: pointer;
    color: #fff; }
  .b-dropdown__link {
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    border: none;
    margin: 0;
    padding-top: 0.5em;
    width: auto;
    overflow: visible;
    background: transparent;
    color: #fff;
    align-items: center;
    cursor: pointer; }
    .b-dropdown__link:hover {
      transform: translateX(5px); }
  .b-dropdown__arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    top: -10px;
    left: 50%;
    margin-left: -15px;
    z-index: 101;
    display: block; }
    .b-dropdown__arrow::before {
      display: block;
      position: absolute;
      transform: rotate(45deg);
      border-radius: 2px;
      content: '';
      box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2);
      margin: 5px;
      width: 20px;
      height: 20px; }
    .b-dropdown__arrow svg {
      transform: rotate(90deg); }
      .b-dropdown__arrow svg path {
        fill: #1B1B1B; }
  .b-dropdown__wrapper {
    transform: scale(0.8) translateY(-20px) translateZ(0);
    opacity: 0;
    transition: all 0.2s ease;
    visibility: hidden;
    position: absolute;
    z-index: 100;
    margin-top: 5px;
    right: 10px; }
    .b-dropdown__wrapper_right {
      right: 0; }
  .b-dropdown__block {
    padding: 0 1rem; }
  .b-dropdown__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 0.5rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #1B1B1B;
    width: 100px;
    z-index: 102; }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .mainNav.mobile .menuLangSwitcher {
    display: none; } }

@media only screen and (max-width: 600px) {
  .b-dropdown {
    margin-right: 0.5em; }
    .b-dropdown__arrow {
      position: absolute;
      width: 30px;
      height: 30px;
      top: -10px;
      left: 50%;
      margin-left: -15px;
      z-index: 101;
      display: block; }
      .b-dropdown__arrow::before {
        visibility: hidden;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        border-radius: 2px;
        content: '';
        box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2);
        margin: 5px;
        width: 20px;
        height: 20px; }
      .b-dropdown__arrow svg {
        visibility: hidden;
        transform: rotate(90deg); }
        .b-dropdown__arrow svg path {
          fill: #1B1B1B; }
    .b-dropdown__wrapper_right {
      right: 0; }
    .b-dropdown__block {
      padding: 0 1rem; }
    .b-dropdown__content {
      top: -200px;
      background-color: #1B1B1B;
      width: 100%;
      z-index: 102; } }

.b-flag {
  width: 25px;
  height: 20px;
  display: inline-block;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 100%;
  vertical-align: middle;
  font-style: normal;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  margin-right: 0.4rem; }
  .b-flag_en {
    background-image: url("../images/flags/en.svg"); }
  .b-flag_ru {
    background-image: url("../images/flags/ru.svg"); }
  .b-flag_kr {
    background-image: url("../images/flags/kr.svg"); }
  .b-flag_ch {
    background-image: url("../images/flags/ch.svg"); }
  .b-flag_es {
    background-image: url("../images/flags/es.svg"); }

.controls {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 300px; }
  @media only screen and (max-device-width: 479px) {
    .controls {
      height: 500px; } }
  .controls .hr {
    width: 80%; }
  .controls .title {
    margin-top: 1em; }
  @media only screen and (max-device-width: 479px) {
    .controls .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 1em; } }
  .controls .row .pre-btn {
    margin: 1em;
    width: 100px;
    display: inline-block;
    text-align: center; }

.editor {
  position: relative;
  width: 100%;
  background-color: transparent;
  margin: 0 auto;
  overflow: auto; }
  .editor__window {
    width: 100%;
    height: 600px; }
  .editor__key {
    width: 30%; }
  .editor__save {
    width: 20%;
    margin: 20px auto;
    text-align: center; }
  .editor .key {
    position: sticky;
    top: 0px;
    right: 0px;
    width: 100px;
    height: 30px;
    margin-left: 90%;
    background-color: lightgrey;
    color: white;
    border: 1px solid #2094fa; }
  .editor .row {
    display: flex;
    justify-content: space-between;
    margin: 1em; }
  .editor .text-area {
    width: 70%; }

.RichEditor-root {
  background: transparent;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px; }

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px; }

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px; }

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px; }

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none; }

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px; }

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px; }

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none; }

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block; }

.RichEditor-activeButton {
  color: #5890ff; }

.admin-stats__filter-wrap {
  border: 2px solid white;
  border-radius: 5px;
  padding: 5px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; }

.admin-stats__filter-item-wrap {
  color: white;
  display: flex;
  flex-direction: column; }

.admin-stats__table-wrap {
  color: white; }

.admin-stats__table {
  width: 100%;
  border: 2px solid white;
  borderRadius: 5px; }

.admin-stats__table-item {
  border-right: 2px solid white;
  border-top: 2px solid white;
  padding: 4px; }

.admin-stats__table-item-last {
  border-top: 2px solid white;
  padding: 4px; }

.admin-stats__table-control-top {
  border-bottom: none;
  margin-bottom: none; }

.admin-stats__table-control-bottom {
  border-top: none;
  margin-bottom: none; }

.admin-stats__table-control-item {
  display: flex;
  flex-direction: row; }

.admin-stats__bank-title {
  font-weight: 900;
  background: black;
  border-radius: 5px;
  padding: 5px; }

.admin-stats__pagination-wrap {
  display: flex; }

.admin-stats__pagination-button {
  background: black;
  border-radius: 5px;
  padding: 5px;
  margin: 5px; }
  .admin-stats__pagination-button:hover {
    cursor: pointer; }

.admin-stats__current-page {
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  background: white;
  color: black; }

.admin-stats__pagination-field-wrap {
  width: 30px;
  display: flex;
  align-items: center;
  margin: 5px; }

.admin-stats__pagination-field {
  width: 30px; }

.admin-stats__search-button {
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px; }
  .admin-stats__search-button:hover {
    cursor: pointer; }

.buy-orbs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-image: url("../images/main/buy/1.png");
  background-size: 100%; }
  @media only screen and (max-device-width: 479px) {
    .buy-orbs {
      background-image: url(/assets/1.png);
      background-repeat: no-repeat;
      background-size: cover; } }
  .buy-orbs__option {
    cursor: pointer;
    text-align: center; }
    .buy-orbs__option:hover {
      font-weight: bold; }
  .buy-orbs__custom-amount {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    padding: 20px;
    align-items: center;
    height: 30px;
    height: 100px;
    flex-wrap: wrap; }
  .buy-orbs__buy-button {
    margin-top: 40px;
    width: auto;
    padding: 0 10px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: #f7f7f7;
    border-radius: 30px;
    margin-right: 10px;
    background-image: linear-gradient(#ffc837, #ff8008);
    transition: all .3s ease;
    cursor: pointer; }
    .buy-orbs__buy-button:hover {
      box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
  .buy-orbs__label {
    height: 20px;
    line-height: 20px;
    margin-right: 10px;
    color: #7f7f7f;
    font-size: 20px;
    font-family: Ubuntu-Regular, sans-serif; }
  .buy-orbs__payment {
    width: 200px;
    overflow: hidden;
    padding: 20px;
    height: 240px; }
  .buy-orbs__amount-input {
    outline: none;
    background: transparent;
    border: none;
    border-bottom: 1px solid #7f7f7f;
    height: 35px;
    /* border-radius: 5px; */
    margin-right: 10px;
    padding-bottom: 10px;
    color: #fefefe;
    font-size: 30px;
    margin-bottom: 20px; }
  .buy-orbs__card {
    height: 40px;
    width: 150px;
    background-repeat: no-repeat;
    background-position: center; }
    .buy-orbs__card_small {
      width: 130px;
      background-size: 100%; }
  .buy-orbs__summ {
    color: #ff9500;
    font-size: 30px;
    height: 35px;
    line-height: 35px; }
  .buy-orbs__visa {
    background-image: url("/assets/images/main/buy/payment-systems.svg"); }
  .buy-orbs__pb24 {
    background-image: url("/assets/images/main/buy/privat24.svg"); }
  .buy-orbs__liqpay {
    background-image: url("/assets/images/main/buy/liqpay.svg"); }
  .buy-orbs__pbs {
    background-image: url("/assets/images/main/buy/psb.svg"); }
  .buy-orbs__alfa {
    background-image: url("/assets/images/main/buy/alfa.svg"); }
  .buy-orbs__qiwi {
    background-image: url("/assets/images/main/buy/qiwi.svg"); }
  .buy-orbs__yandex {
    background-image: url("/assets/images/main/buy/yandexmoney.svg"); }
  .buy-orbs__supported-cards {
    display: flex;
    justify-content: center;
    margin-bottom: 10px; }
    @media only screen and (max-device-width: 399px) {
      .buy-orbs__supported-cards {
        flex-wrap: wrap; }
        .buy-orbs__supported-cards a {
          margin-top: 10px; } }
  .buy-orbs__row {
    display: flex;
    border-bottom: 2px solid #f6eaea;
    justify-content: space-evenly; }
  .buy-orbs .selected {
    border-bottom: 5px solid #ff9500;
    position: relative;
    top: 2px; }
  .buy-orbs__card-block {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: auto;
    padding: 20px 40px;
    cursor: pointer; }
  .buy-orbs__currency-select {
    width: auto;
    background-color: transparent;
    border: none;
    font-family: Ubuntu-Regular, sans-serif;
    outline: none;
    color: #ff9500;
    padding: 0px;
    font-size: 30px;
    margin-left: 10px;
    margin-top: 0; }
    .buy-orbs__currency-select:hover {
      border: none; }
  .buy-orbs__money-block {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: auto;
    padding: 20px 40px;
    cursor: pointer; }
  .buy-orbs__card-icon {
    background-image: url("../images/main/buy/005-card-1@3x.png");
    width: 45px;
    height: 33px;
    background-size: contain;
    background-repeat: no-repeat; }
  .buy-orbs__money-icon {
    background-image: url("../images/main/buy/006-money-1@3x.png");
    width: 45px;
    height: 33px;
    background-size: contain;
    background-repeat: no-repeat; }
  .buy-orbs__powered-by {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    align-items: center; }
    .buy-orbs__powered-by a {
      text-decoration: underline;
      padding-left: 10px;
      font-family: ubuntu-medium;
      font-size: 18px; }
  .buy-orbs__footer {
    width: 100%;
    padding: 20px; }
  .buy-orbs__features {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
  .buy-orbs__buy {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
  .buy-orbs .features__header {
    margin-top: 55px;
    font-size: 75px;
    font-weight: bold;
    color: #fefefe; }
  .buy-orbs .features__subheader {
    margin-top: 45px;
    font-size: 41px;
    font-weight: 300;
    text-align: center; }
  .buy-orbs .features-content {
    margin-top: 54px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 42px; }
  .buy-orbs .features-content__img-wrapper {
    border: 2px solid transparent;
    border-image: linear-gradient(#7a64f7, #580986) 30;
    width: 285px;
    height: 172px; }
  .buy-orbs .features-content__column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 290px; }
    .buy-orbs .features-content__column img {
      width: 100%;
      height: 100%; }
  .buy-orbs .features-content__header {
    margin-top: 34px;
    font-size: 34px;
    font-weight: bold; }
  .buy-orbs .features-content__content {
    font-size: 20px;
    font-weight: 300;
    margin-top: 24px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fefefe; }
  .buy-orbs .buy__header {
    margin-top: 42px;
    margin-bottom: 42px;
    font-size: 75px;
    font-weight: bold;
    text-align: center; }
    .buy-orbs .buy__header_small {
      font-size: 40px; }
  .buy-orbs .buy-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
    @media only screen and (max-device-width: 479px) {
      .buy-orbs .buy-content {
        flex-direction: column; } }
  .buy-orbs .buy-content__item {
    cursor: pointer;
    position: relative;
    top: 0%;
    width: 285px;
    height: 292px;
    display: block;
    background-size: 100% 100%; }
    .buy-orbs .buy-content__item_first {
      background-image: url("/assets/images/main/buy/6@2x.png"); }
    .buy-orbs .buy-content__item_second {
      background-image: url("/assets/images/main/buy/14@2x.png"); }
    .buy-orbs .buy-content__item_third {
      background-image: url("/assets/images/main/buy/12@2x.png"); }
    .buy-orbs .buy-content__item_forth {
      background-image: url("/assets/images/main/buy/10@2x.png"); }
  .buy-orbs .buy-content__icon {
    position: absolute;
    display: flex; }
    .buy-orbs .buy-content__icon_small {
      width: 51px;
      height: 51px;
      top: calc(50% - 25px);
      left: calc(50% - 25px); }
    .buy-orbs .buy-content__icon_medium {
      width: 67px;
      height: 67px;
      top: calc(50% - 33px);
      left: calc(50% - 33px); }
    .buy-orbs .buy-content__icon_large {
      width: 90px;
      height: 90px;
      top: calc(50% - 45px);
      left: calc(50% - 45px); }
    .buy-orbs .buy-content__icon_collosal {
      width: 130px;
      height: 130px;
      top: calc(50% - 65px);
      left: calc(50% - 65px); }
  .buy-orbs .buy-content__info {
    display: flex;
    position: absolute;
    bottom: 10px;
    left: 13px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%; }
  .buy-orbs .item__amount {
    display: block;
    font-size: 25px; }
    .buy-orbs .item__amount span {
      font-size: 18px; }
  .buy-orbs .item__price {
    display: block;
    font-size: 15px;
    font-weight: 300;
    color: #ff8500; }
  .buy-orbs .buy-content__value {
    font-size: 25px;
    font-weight: 300;
    color: #ff8500; }

@media (min-width: 320px) and (max-width: 480px) {
  .buy-orbs {
    padding-top: 50px; }
  .buy-orbs__select-payment-method {
    width: 100%; }
  .buy-orbs__container {
    width: 100%;
    overflow-x: scroll; }
  .buy-orbs__container::-webkit-scrollbar {
    display: none; }
  .buy-orbs__row {
    width: 760px; } }

.become-streamer__wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0px;
  left: 0px;
  z-index: 1; }

.become-streamer__modal {
  height: 200px; }

.become-streamer__set-up {
  margin: 20px auto; }
  @media only screen and (max-device-width: 479px) {
    .become-streamer__set-up {
      margin-top: 12%; } }

.become-streamer .feedbackModal-desc {
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #000000; }

.become-streamer .feedbackModal {
  height: 200px; }

.become-streamer__row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px; }

.become-streamer__twitch {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px; }
  .become-streamer__twitch img {
    width: 70px;
    height: 70px; }

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    transform: translate3d(4px, 0, 0); } }

.feedbackModal {
  opacity: 1;
  position: absolute;
  margin: 0 auto;
  border-radius: 6px;
  width: 40%;
  height: 500px;
  left: 0;
  right: 0;
  top: 90px;
  bottom: 0;
  background-color: #ffffff;
  border: solid 2px #e5ebf1;
  z-index: 100; }
  @media only screen and (max-device-width: 479px) {
    .feedbackModal {
      width: 90%;
      height: 45vh !important;
      display: flex;
      flex-direction: column; } }

.feedbackModalTitle {
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #282828;
  padding: 20px; }

.feedbackModal-title {
  font-size: 20px;
  font-family: Ubuntu-Medium, sans-serif;
  letter-spacing: 0.2px;
  text-align: left;
  color: white; }

.feedbackModal-text {
  margin-top: 20px;
  padding: 20px;
  font-size: 13px;
  letter-spacing: 0.2px;
  font-family: Ubuntu-Regular, sans-serif;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
  width: 100%;
  height: 80px;
  resize: none; }
  @media only screen and (max-device-width: 479px) {
    .feedbackModal-text {
      height: 20px; } }

.feedbackModal-submit {
  width: 100%;
  height: 60px;
  font-size: 20px;
  letter-spacing: 0.3px;
  color: white;
  border-radius: 30px;
  background-color: #2094fa;
  margin-bottom: 20px;
  text-align: center;
  border: none;
  outline: none;
  -webkit-appearance: none;
  margin-top: 20px;
  cursor: pointer; }
  @media only screen and (max-device-width: 479px) {
    .feedbackModal-submit {
      width: 60px;
      height: 30px;
      margin: 20px auto;
      display: block; } }

.feedbackModal-desc {
  font-family: Ubuntu-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  padding: 0 20px;
  color: #000000; }

.feedbackModal-create {
  position: relative;
  outline: none;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  margin: 0 auto;
  margin-bottom: 16px; }

.feedbackModal-result {
  font-family: Ubuntu-Medium, sans-serif;
  font-size: 30px;
  padding: 32px 50px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: #000000; }

.chat {
  height: 560px;
  width: 350px;
  border-radius: 6px;
  background: #000000; }
  @media only screen and (max-device-width: 479px) {
    .chat {
      background-color: transparent;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center; } }
  .chat__tab-name {
    color: #f7f7f7;
    font-size: 18px;
    font-family: Ubuntu-Regular, sans-serif; }
  .chat__tabs {
    display: flex;
    height: 53px;
    justify-content: space-evenly;
    align-items: center; }
    @media only screen and (max-device-width: 479px) {
      .chat__tabs {
        display: flex;
        flex-direction: column;
        background-color: #6441a5;
        border-radius: 16px;
        padding: 1px;
        height: 70px;
        width: 100%; } }
    .chat__tabs.TWITCH {
      background: #17111e; }
    .chat__tabs.DISCORD {
      background: #7289da; }
  .chat__sub-tab {
    width: 90%;
    height: 30px;
    border-radius: 15px;
    background-color: transparent;
    display: flex;
    justify-content: space-evenly; }
  .chat__main-tab {
    width: 100%;
    height: 40px;
    border-radius: 15px;
    background-color: #1b1b1b;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    box-sizing: border-box; }
  .chat__label {
    line-height: 36px;
    padding-left: 1em;
    width: 35%; }
  .chat__aside {
    width: 60%;
    display: flex; }
  .chat__twitch-tab {
    width: 25%;
    height: 100%;
    background-image: url("../images/main/chat/twitch-disabled.png");
    background-size: 37px 37px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer; }
    .chat__twitch-tab div {
      width: 37px;
      height: 37px;
      margin: auto;
      margin-top: 9%;
      border-radius: 50%;
      transition: all .3s ease; }
      .chat__twitch-tab div:hover {
        box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
    @media only screen and (max-device-width: 479px) {
      .chat__twitch-tab {
        background: none;
        color: #f7f7f7;
        font-size: 18px;
        text-align: center;
        line-height: 30px; } }
    .chat__twitch-tab.active {
      background-image: url("../images/main/chat/twitch-enabled.png"); }
  .chat__youtube-tab {
    width: 25%;
    height: 100%;
    background-color: black;
    background-size: 37px 37px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer; }
    .chat__youtube-tab div {
      width: 37px;
      height: 37px;
      margin: auto;
      margin-top: 9%;
      border-radius: 50%; }
      .chat__youtube-tab div:hover {
        box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
    @media only screen and (max-device-width: 479px) {
      .chat__youtube-tab {
        background: none;
        color: #f7f7f7;
        font-size: 18px;
        text-align: center;
        line-height: 30px; } }
    .chat__youtube-tab.active {
      background-color: red; }
  .chat__discord-tab {
    cursor: pointer;
    height: 100%;
    width: 25%;
    background-image: url("../images/main/chat/discord-disabled.png");
    background-size: 37px 37px;
    background-repeat: no-repeat;
    background-position: center; }
    .chat__discord-tab div {
      width: 37px;
      height: 37px;
      margin: auto;
      margin-top: 9%;
      border-radius: 50%;
      transition: all .3s ease; }
      .chat__discord-tab div:hover {
        box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
    @media only screen and (max-device-width: 479px) {
      .chat__discord-tab {
        background: none;
        color: #f7f7f7;
        font-size: 18px;
        text-align: center;
        line-height: 30px; } }
    .chat__discord-tab.active {
      background-image: url("../images/main/chat/discord-enabled.png"); }
  .chat__activities-tab-icon {
    background-image: url("../images/main/chat/cube-white.png");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 25px;
    height: 28px;
    margin-right: 5px; }
  .chat__activities-tab {
    cursor: pointer;
    width: 154px;
    height: 39px;
    border-radius: 18.5px;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    background-position-y: center;
    box-sizing: border-box;
    font-size: 18px;
    color: #f7f7f7;
    line-height: 37px;
    text-align: center;
    background-position-x: 27px; }
    .chat__activities-tab:hover {
      box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
    @media only screen and (max-device-width: 479px) {
      .chat__activities-tab {
        width: 30px;
        border: none;
        height: 30px;
        background-position-x: center;
        padding-left: 0; } }
    .chat__activities-tab.TWITCH {
      background-color: #6441a5; }
    .chat__activities-tab.DISCORD {
      background-color: #7c8ce4; }
  .chat__chat-tab {
    cursor: pointer;
    width: 154px;
    height: 39px;
    box-sizing: border-box;
    background-color: #1b1b1b;
    border-radius: 18.5px;
    border: 4px solid #ffc837;
    background-image: url("../images/main/activities/chat.png");
    background-size: 26px;
    background-position: center;
    background-repeat: no-repeat; }
    @media only screen and (max-device-width: 479px) {
      .chat__chat-tab {
        width: 30px;
        border: none;
        background-color: transparent;
        height: 30px;
        color: transparent; } }

.activities {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  background-color: #1b1b1b;
  height: 100%; }
  @media only screen and (max-device-width: 479px) {
    .activities {
      padding: 0 15px; } }
  .activities__tab-name {
    color: #f7f7f7;
    font-size: 18px;
    font-family: Ubuntu-Regular, sans-serif; }
  .activities__dropdown {
    width: 97px;
    position: relative;
    display: flex;
    justify-content: center; }
  .activities .b-dropdown {
    margin: 0; }
  .activities__selector {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 30px;
    justify-content: space-around;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    width: 97%; }
    .activities__selector div:hover {
      color: #ff8008; }
  .activities__selector-btn {
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 16px;
    color: #f7f7f7;
    line-height: 30px;
    opacity: 0.75;
    cursor: pointer; }
    .activities__selector-btn_selected {
      background: linear-gradient(#ffc837, #ff8008);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      opacity: 1;
      font-weight: bolder; }
  .activities__tabs {
    height: 53px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #232323;
    justify-content: center; }
    @media only screen and (max-device-width: 479px) {
      .activities__tabs {
        display: flex;
        border-radius: 16px;
        padding: 0 10px;
        height: 40px;
        width: 100%;
        border: 1px solid #ff8008;
        justify-content: space-between; } }
  .activities__activities-tab {
    width: 70%;
    height: 37px;
    border-radius: 18.5px;
    border: 1px solid white; }
    @media only screen and (max-device-width: 479px) {
      .activities__activities-tab {
        width: 30px;
        border: none;
        height: 30px; } }
    .activities__activities-tab.TWITCH {
      background-color: #6441a5; }
    .activities__activities-tab.DISCORD {
      background-color: #7c8ce4; }
  .activities__chat-tab {
    cursor: pointer;
    width: 154px;
    height: 39px;
    box-sizing: border-box;
    background-color: #1b1b1b;
    border-radius: 18.5px;
    border: 4px solid #ffc837;
    background-image: url("../images/main/redesign/chat.png");
    background-size: 26px;
    background-position-y: center;
    background-position-x: 30px;
    background-repeat: no-repeat;
    font-size: 18px;
    color: #f7f7f7;
    font-family: Ubuntu-Regular, sans-serif;
    text-align: center;
    line-height: 30px;
    padding-left: 15px;
    transition: all .3s ease; }
    .activities__chat-tab:hover {
      box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
    @media only screen and (max-device-width: 479px) {
      .activities__chat-tab {
        width: 30px;
        border: none;
        background-color: transparent;
        height: 30px;
        color: transparent;
        background-position-x: center; } }
  .activities__empty {
    width: 40px;
    height: 40px; }
  .activities__create {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-image: linear-gradient(#ffc837, #ff8008);
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 28px;
    line-height: 35px;
    font-size: 40px;
    color: #232323;
    text-align: center; }
  .activities__trigger {
    display: block;
    font-family: Ubuntu-Medium, sans-serif;
    font-size: 12px;
    line-height: 0.71;
    letter-spacing: normal;
    text-align: right;
    color: #f7f7f7; }
  .activities__trigger::after {
    display: block;
    position: absolute;
    width: 30%;
    right: 0px;
    border-bottom: 4px solid gold;
    margin: 3px 0 5px 0;
    content: " "; }
  .activities__option {
    text-align: right;
    width: 100%;
    color: #f7f7f7;
    cursor: pointer; }
  .activities .b-dropdown__wrapper {
    top: 15px;
    left: -7px;
    background-color: transparent;
    background-color: #232323;
    width: 115px;
    display: flex;
    align-items: center; }
  .activities .b-dropdown__content {
    background-color: transparent;
    align-items: center;
    width: 115px; }
  .activities .b-dropdown__arrow svg {
    visibility: hidden !important; }
  .activities .b-dropdown__trigger {
    width: 60px; }

.activities-list__be-first {
  margin-top: 20px;
  color: #f7f7f7;
  font-size: 14px;
  font-weight: bold;
  padding: 7px 25px;
  background-image: linear-gradient(#ffc837, #ff8008); }

.activities-list__no-challenges {
  color: #f7f7f7;
  width: 100%;
  text-align: center;
  padding: 20px;
  box-sizing: border-box; }
  .activities-list__no-challenges strong {
    cursor: pointer; }
    .activities-list__no-challenges strong:hover {
      color: #ff8008; }

.activity {
  width: 215px;
  margin: 10px auto;
  display: flex;
  padding: 5px;
  flex-direction: column;
  background-color: #1b1b1b;
  color: #f7f7f7; }
  .activity__subheader {
    font-size: 12px;
    text-align: left;
    margin-top: 4px; }
    .activity__subheader--client {
      padding: 5px; }
  .activity__show-donators {
    text-align: center;
    width: 100%;
    display: block;
    cursor: pointer; }
    .activity__show-donators:hover {
      color: #ff8008; }
  .activity__donators {
    margin: 5px; }
    .activity__donators ul {
      margin-bottom: 10px; }
  .activity__donator-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: left; }
  .activity__doit {
    width: 100%;
    display: flex;
    margin-top: 10px; }
  .activity__ok {
    width: 30px;
    height: 30px;
    background-image: url(../images/main/redesign/ok.png);
    background-size: 100%;
    margin-right: 10px;
    background-repeat: no-repeat;
    cursor: pointer; }
  .activity__fail {
    width: 30px;
    height: 30px;
    background-image: url(../images/main/redesign/fail.png);
    background-size: 100%;
    margin-left: 10px;
    background-repeat: no-repeat;
    cursor: pointer; }
  .activity__wrapper {
    background-image: linear-gradient(#1b1b1b, #1b1b1b), radial-gradient(circle at top left, #ffc837, #ff8008);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 5%;
    padding: 5px;
    margin: 15px auto;
    width: 230px; }
    .activity__wrapper.hide {
      background: none; }
  .activity__header {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 30px; }
  .activity .time-to-accept {
    width: 58px;
    height: 20px;
    background: #232323;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    color: #f7f7f7; }
    .activity .time-to-accept input {
      outline: none; }
    .activity .time-to-accept__hh {
      width: 50%;
      height: 100%;
      background-color: transparent;
      border: none;
      border-right: 1px solid #fff;
      color: #f7f7f7;
      text-align: center;
      font-size: 14px; }
    .activity .time-to-accept__mm {
      width: 50%;
      height: 100%;
      background-color: transparent;
      border: none;
      color: #f7f7f7;
      text-align: center;
      font-size: 14px; }
  .activity__author {
    font-size: 10px;
    font-family: Ubuntu-Medium, sans-serif;
    color: #f7f7f7;
    line-height: 1.33; }
  .activity__label {
    font-size: 18px;
    padding: 0 2px;
    font-family: Ubuntu-Regular, sans-serif;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #f7f7f7; }
    .activity__label_show-more {
      width: 100%;
      text-align: center;
      cursor: pointer;
      padding: 5px 10px; }
  .activity__label_underline::after {
    content: ' ';
    display: block;
    height: 1px;
    width: 20%;
    position: absolute;
    border-bottom: 3px solid #ffc837;
    top: 65%; }
  .activity__label_underline-cyan::after {
    content: ' ';
    display: block;
    height: 1px;
    width: 20%;
    position: absolute;
    border-bottom: 3px solid #4b7bec;
    top: 65%; }
  .activity__price {
    color: #f7f7f7;
    font-size: 12px;
    display: flex;
    align-items: center;
    height: 20px; }
  .activity__alert {
    border: 2px solid #ffc837;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff; }
  .activity__voting-option {
    border: 1px solid #f7f7f7;
    border-radius: 15px;
    display: block;
    color: #f7f7f7;
    height: 30px;
    width: 100%;
    text-align: center;
    font-size: 10px; }
  .activity__button {
    cursor: pointer;
    position: relative;
    width: 60%;
    height: 30px;
    font-family: Ubuntu-Medium, sans-serif;
    font-size: 12px;
    line-height: 32px;
    letter-spacing: normal;
    text-align: center;
    color: #f7f7f7;
    background-image: linear-gradient(#ffc837, #ff8008);
    border-radius: 16px;
    z-index: 10;
    transition: all .3s ease; }
    .activity__button:hover {
      box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
    .activity__button_inline {
      cursor: pointer;
      position: absolute;
      width: 40%;
      height: 32px;
      top: 0;
      left: 25px;
      font-family: Ubuntu-Medium, sans-serif;
      font-size: 12px;
      line-height: 32px;
      letter-spacing: normal;
      text-align: center;
      color: #f7f7f7;
      border-radius: 16px;
      z-index: 10;
      transition: all .3s ease; }
      .activity__button_inline:hover {
        box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
    .activity__button_voting {
      background-color: #1b1b1b;
      left: 5px;
      width: 50%; }
    .activity__button_purple {
      background-color: #4891cc; }
    .activity__button_cyan {
      background-color: #5eb1f5;
      background-image: none; }
    .activity__button_gold {
      background-color: #ffc837; }
  .activity__bank {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 32px;
    border: 1px solid white;
    background: transparent;
    font-family: Ubuntu-Medium, sans-serif;
    font-size: 12px;
    line-height: 32px;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    border-radius: 16px;
    box-sizing: border-box; }
    .activity__bank_inline {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      top: 0px;
      left: 65px;
      width: 60%;
      height: 32px;
      border: 1px solid white;
      background: transparent;
      font-family: Ubuntu-Medium, sans-serif;
      font-size: 12px;
      line-height: 32px;
      letter-spacing: normal;
      text-align: right;
      padding-right: 20px;
      color: #ffffff;
      border-radius: 16px;
      box-sizing: border-box; }
    .activity__bank_voting {
      background-color: transparent;
      left: calc(100% - 105px);
      padding-right: 10px; }
  .activity__input {
    position: relative;
    border: none;
    line-height: 30px;
    width: 60px;
    left: 20px;
    text-align: right;
    outline: none; }
  .activity__join {
    display: flex;
    position: relative;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 72px;
    padding: 10px 5px;
    margin: 0 auto; }
  .activity__description {
    padding: 5px;
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #f7f7f7;
    word-break: break-all; }
    .activity__description_voting {
      background-color: #1b1b1b;
      border-radius: 15px;
      font-size: 10px;
      text-align: center; }
  .activity__options {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 5px; }
  .activity__option {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 40px;
    box-sizing: border-box; }
    .activity__option_voting {
      padding: 5px 0; }
  .activity__orb {
    width: 12px;
    height: 12px;
    background-image: url("../images/main/redesign/orb.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-left: 5px;
    margin-right: 5px; }
  .activity__cube {
    width: 12px;
    height: 12px;
    background-image: url("../images/main/redesign/cube.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-left: 5px; }
  .activity__group {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .activity__status {
    line-height: 15px;
    font-size: 12px;
    color: white;
    width: 125px;
    text-align: right; }
  .activity__bet-desc {
    padding: 10px 5px 10px;
    display: flex;
    justify-content: space-between;
    color: #f7f7f7;
    font-size: 16px;
    font-family: Ubuntu-Regular, sans-serif; }
  .activity__bet-label {
    width: 80%;
    word-break: break-all; }
  .activity__close-icon {
    cursor: pointer;
    background-image: url("../images/main/activities/close.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px; }
  .activity__expected-win {
    color: #f7f7f7;
    font-size: 12px;
    font-family: Ubuntu-Regular, sans-serif;
    padding-left: 5px;
    margin-bottom: 7px; }
  .activity__place {
    display: flex;
    justify-content: space-evenly; }
  .activity__place-button {
    cursor: pointer;
    background-color: #5eb1f5;
    width: 35px;
    height: 35px;
    font-family: Ubuntu-Medium, sans-serif;
    font-size: 12px;
    color: #f7f7f7;
    border-radius: 50%;
    line-height: 35px;
    text-align: center; }
    .activity__place-button:hover {
      box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
    .activity__place-button_gold {
      background-color: #ffc837; }
      .activity__place-button_gold:hover {
        box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }

.timer {
  text-align: right;
  display: inline-flex;
  justify-content: space-between;
  align-items: center; }
  .timer__time {
    font-size: 12px;
    font-family: Ubuntu-Medium, sans-serif;
    color: #f7f7f7;
    margin-left: 10px; }
  .timer__desc {
    font-size: 10px;
    font-family: Ubuntu-Medium, sans-serif;
    color: #f7f7f7;
    margin-left: 10px; }

.create-challenge__disclamer {
  font-size: 12px !important;
  width: 100%;
  padding: 0;
  opacity: 0.8; }

@media only screen and (max-device-width: 479px) {
  .live-stream-slider {
    display: none; } }

.streamSlider {
  height: 100%; }
  .streamSlider .slick-arrow {
    margin-top: 20px; }
  .streamSlider .slick-list {
    position: relative;
    left: 15px; }
  .streamSlider .streamItem-overlay {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 0px;
    box-sizing: border-box;
    height: 100%; }
  .streamSlider__info {
    width: 100%;
    top: 30px;
    position: relative;
    -webkit-box-pack: justify;
    justify-content: space-between;
    opacity: 0;
    width: 90%;
    /* margin: 0 auto; */
    padding: 5px 10px;
    background-color: rgba(128, 128, 128, 0.7);
    height: 20px;
    display: flex; }
  .streamSlider__actions {
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
    justify-content: flex-start; }
  .streamSlider__become-streamer {
    height: 100%;
    text-align: center;
    width: 50%;
    background-image: linear-gradient(#ffc837, #ff8008);
    border-radius: 15px;
    line-height: 30px;
    color: #f7f7f7; }
    @media only screen and (max-device-width: 479px) {
      .streamSlider__become-streamer {
        font-size: .8em; } }
  .streamSlider__header {
    display: flex;
    justify-content: flex-start;
    height: 30px;
    align-items: center;
    margin-top: 20px; }
    @media only screen and (max-device-width: 479px) {
      .streamSlider__header {
        margin-top: 90px;
        padding: 0 15px; } }
  .streamSlider__search {
    content: ' ';
    cursor: pointer;
    display: block;
    position: relative;
    background-image: url("../images/main/header/search.png");
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
    margin-left: 20px; }
  .streamSlider__search-block {
    width: 30%;
    height: 30px; }
  .streamSlider__search-suggestions {
    width: 100%;
    position: relative;
    top: -5px;
    background: #f7f7f7;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    text-align: center;
    font-size: 12px;
    padding: 3px;
    box-sizing: border-box; }
  .streamSlider__search-input {
    width: 40%;
    height: 30px;
    text-align: center;
    background-color: #1b1b1b;
    border-radius: 15px;
    border: none;
    outline: none;
    font-size: 12px;
    color: #f7f7f7;
    margin-left: 20px;
    border: 1px solid #f7f7f7; }
    @media only screen and (max-device-width: 479px) {
      .streamSlider__search-input {
        width: 70%; } }
  .streamSlider__mobile {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .streamSlider__stream-name {
    width: auto;
    height: 15px;
    line-height: 15px;
    position: relative; }
  .streamSlider__stream-spectators {
    width: auto;
    height: 15px;
    line-height: 15px;
    position: relative; }
  .streamSlider__stream-spectators::before {
    position: absolute;
    content: '';
    display: block;
    border-radius: 50%;
    transform: translateY(-50%);
    left: -15px;
    top: 7px;
    height: 10px;
    width: 10px;
    background: #FF4040; }
  .streamSlider__arrow-right {
    background-image: url("../images/main/slider/button-arrow-right-small.png");
    background-size: 100% 100%;
    width: 32px;
    height: 55px; }
  .streamSlider__arrow-left {
    background-image: url("../images/main/slider/button-arrow-left-small.png");
    background-size: 100% 100%;
    width: 32px;
    height: 55px; }
  .streamSlider__background {
    display: flex;
    height: 150px; }
    .streamSlider__background img {
      filter: blur(3px); }
  .streamSlider__image {
    width: 100%;
    height: 100%; }
  .streamSlider__stream {
    border: 2px solid #1d61c7;
    border-radius: 2px;
    height: 220px;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 90%;
    position: relative; }
    .streamSlider__stream .streamItem-overlay {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 10px 0px;
      box-sizing: border-box;
      height: 100%; }
      .streamSlider__stream .streamItem-overlay div {
        color: #f7f7f7; }
    .streamSlider__stream:hover .streamItem-overlay {
      background: transparent; }
      .streamSlider__stream:hover .streamItem-overlay .streamSlider__info {
        transition: all 0.5s;
        opacity: 1;
        transform: translateY(-20px); }
  .streamSlider__stream-container {
    position: absolute;
    display: flex;
    align-items: center;
    width: 95%;
    justify-content: space-between; }
  .streamSlider .slick-track {
    height: 120px;
    display: flex;
    justify-content: space-evenly; }
  .streamSlider .slick-slide {
    width: 200px !important;
    height: 100%;
    margin-right: 25px; }
  @media only screen and (max-device-width: 479px) {
    .streamSlider {
      width: 100%;
      left: 0px; }
      .streamSlider .slick-slide {
        width: 50% !important;
        padding: 0px;
        height: 100%;
        border: none;
        border-radius: 0px; }
      .streamSlider__arrow-right {
        background-image: url("../images/main/slider/button-arrow-right-small.png");
        background-size: 100% 100%;
        width: 15px;
        height: 20px; }
      .streamSlider__arrow-left {
        background-image: url("../images/main/slider/button-arrow-left-small.png");
        background-size: 100% 100%;
        width: 15px;
        height: 20px; } }

.twitchStream {
  height: 120px !important; }
  @media only screen and (max-device-width: 479px) {
    .twitchStream {
      height: 160px !important;
      position: relative; } }

.no-border-radius {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

#carousel {
  height: 220px;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  width: 100%; }

#carousel div {
  margin: auto;
  width: 75%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
  transition: transform 0.4s ease; }

#carousel div.prev {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transform: translate3d(-15%, 0, -100px); }

#carousel div.next {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transform: translate3d(15%, 0, -100px); }

#carousel div.prevLeftSecond {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  transform: translate3d(-30%, 0, -200px); }

#carousel div.nextRightSecond {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  transform: translate3d(30%, 0, -200px); }

#carousel div img {
  width: 100%;
  transition: width 1s;
  height: 100%; }

#carousel div.hideLeft {
  display: none; }

#carousel div.hideRight {
  display: none; }

#carousel div.selected {
  box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.3), 0 11px 7px 0 rgba(0, 0, 0, 0.19);
  transform: translate3d(0, 0, 0); }

[type=radio] {
  display: none; }

#slider {
  height: 35vw;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d; }

#slider div {
  margin: auto;
  width: 60%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
  transition: transform 0.4s ease; }

#s1:checked ~ #slide4, #s2:checked ~ #slide5,
#s3:checked ~ #slide1, #s4:checked ~ #slide2,
#s5:checked ~ #slide3 {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  transform: translate3d(-30%, 0, -200px); }

#s1:checked ~ #slide5, #s2:checked ~ #slide1,
#s3:checked ~ #slide2, #s4:checked ~ #slide3,
#s5:checked ~ #slide4 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transform: translate3d(-15%, 0, -100px); }

#s1:checked ~ #slide1, #s2:checked ~ #slide2,
#s3:checked ~ #slide3, #s4:checked ~ #slide4,
#s5:checked ~ #slide5 {
  box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.3), 0 11px 7px 0 rgba(0, 0, 0, 0.19);
  transform: translate3d(0, 0, 0); }

#s1:checked ~ #slide2, #s2:checked ~ #slide3,
#s3:checked ~ #slide4, #s4:checked ~ #slide5,
#s5:checked ~ #slide1 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transform: translate3d(15%, 0, -100px); }

#s1:checked ~ #slide3, #s2:checked ~ #slide4,
#s3:checked ~ #slide5, #s4:checked ~ #slide1,
#s5:checked ~ #slide2 {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  transform: translate3d(30%, 0, -200px); }

#slide1 {
  background: #00BCD4; }

#slide2 {
  background: #4CAF50; }

#slide3 {
  background: #CDDC39; }

#slide4 {
  background: #FFC107; }

#slide5 {
  background: #FF5722; }

.topGames {
  max-width: 1300px;
  margin: 0 auto; }

.gamesSlider {
  height: 320px;
  width: 100%;
  display: flex;
  justify-content: center; }
  .gamesSlider__header {
    display: flex;
    justify-content: space-between;
    padding: 0 5%; }
  .gamesSlider__see-all {
    line-height: 44px; }
  .gamesSlider__mobile {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly; }
    .gamesSlider__mobile .streamItem__game-info {
      background-color: #232323;
      height: 60px;
      padding: 10px; }
    .gamesSlider__mobile .streamItem__title {
      font-family: Ubuntu-Regular, sans-serif;
      font-size: 18px;
      text-align: left;
      color: #f7f7f7;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 20px; }
      .gamesSlider__mobile .streamItem__title:hover {
        color: #ff8008; }
    .gamesSlider__mobile .streamItem__bank {
      font-family: Ubuntu-Regular, sans-serif;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.33;
      text-align: left;
      color: #9d9d9d; }
    .gamesSlider__mobile .streamItem-overlay {
      background: none; }
    .gamesSlider__mobile .streamItem {
      max-width: 220px;
      height: 320px;
      margin: 5px;
      position: relative; }
    .gamesSlider__mobile .streamItem-img {
      width: 100%;
      height: auto; }
    .gamesSlider__mobile .hidden {
      display: none; }
  .gamesSlider .slick-next {
    position: relative !important;
    right: 0px !important; }
  .gamesSlider .slick-prev {
    position: relative !important;
    left: 0px !important; }
  .gamesSlider .slick-arrow::before {
    content: ''; }
  .gamesSlider .slick-arrow {
    outline: none;
    border: none; }
  .gamesSlider__arrow-right {
    background-image: url("../images/main/slider/button-arrow-right-small.png");
    background-size: 100% 100%;
    width: 32px;
    height: 50px;
    margin-top: 50px; }
  .gamesSlider__arrow-left {
    background-image: url("../images/main/slider/button-arrow-left-small.png");
    background-size: 100% 100%;
    width: 32px;
    height: 50px;
    margin-top: 50px; }
  .gamesSlider .slick-track {
    height: 220px;
    display: flex;
    justify-content: space-evenly;
    height: 100%; }
  .gamesSlider .slick-slide {
    width: 190px !important;
    height: 100%;
    margin-right: 38px; }
  .gamesSlider .slick-slider {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    max-width: 1300px;
    width: 100%;
    margin: 0 auto; }
  .gamesSlider .slick-list {
    height: 100%;
    width: 100%; }
  .gamesSlider .streamItem-img {
    width: 100%;
    height: 220px; }
  .gamesSlider .streamItem__game-info {
    background-color: #1b1b1b;
    height: 80px;
    padding: 10px; }
  .gamesSlider .streamItem__streamers-number {
    text-align: center;
    font-size: 20px; }
  .gamesSlider .streamItem__title {
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 22px;
    text-align: center;
    color: #f7f7f7;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px; }
    .gamesSlider .streamItem__title:hover {
      color: #ff8008; }
  .gamesSlider .streamItem__bank {
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.33;
    text-align: left;
    color: #9d9d9d; }
  .gamesSlider .streamItem-Btn {
    box-shadow: none; }
  .gamesSlider .streamItem-overlay {
    height: 100%; }

.depositMenu .nameBlock {
  height: 40px;
  background-color: #232323; }

.depositMenu .depositMenuList-item {
  background-color: #1b1b1b;
  border: none;
  height: calc(100% - 40px);
  padding: 0; }
  .depositMenu .depositMenuList-item .depositMenuList-btn {
    line-height: 30px;
    height: 30px;
    width: 120px;
    margin: 10px; }

.deposit__wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0px;
  left: 0px;
  z-index: 1; }

.register {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 200px; }
  @media only screen and (max-device-width: 399px) {
    .register {
      width: 120px; } }
  .register__login-button {
    cursor: pointer;
    background: none;
    font-size: 16px;
    color: #f7f7f7;
    font-family: Ubuntu-Medium, sans-serif;
    border: none; }
    .register__login-button:hover {
      color: #ff8008; }
    @media only screen and (max-device-width: 399px) {
      .register__login-button {
        font-size: 10px;
        width: 40px; } }
  .register__register-button {
    font-size: 16px;
    color: #f7f7f7;
    font-family: Ubuntu-Medium, sans-serif;
    width: 120px;
    height: 47px; }
    @media only screen and (max-device-width: 399px) {
      .register__register-button {
        width: 80px;
        font-size: 10px;
        height: 25px; } }

.create-challenge {
  width: 100%;
  position: absolute;
  height: 100%;
  top: 47px;
  border: none;
  background-color: #1b1b1b;
  box-sizing: border-box;
  padding: 10px 10px; }
  .create-challenge__link {
    margin-left: 10px; }
  .create-challenge__input-block {
    margin-bottom: 0px; }
  .create-challenge__submit {
    margin: 10px auto; }
    .create-challenge__submit:hover {
      box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
  .create-challenge__agreement {
    text-align: justify;
    display: block;
    width: 100%; }
  .create-challenge__textarea {
    resize: none; }
  .create-challenge .feedbackModal-form {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    background: #1b1b1b; }
    .create-challenge .feedbackModal-form div {
      padding: 10px 0px; }

.feedbackModal.feedbackModal_result {
  position: fixed;
  width: 350px;
  top: 130px;
  left: -2px;
  display: flex;
  height: 400px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 101; }
  .feedbackModal.feedbackModal_result .feedbackModalTitle::after {
    content: '';
    border: none; }
  .feedbackModal.feedbackModal_result .feedbackModal-img {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: static; }
  .feedbackModal.feedbackModal_result .feedbackModal-result {
    font-family: Ubuntu-Medium, sans-serif;
    font-size: 20px;
    padding: 20px 50px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-align: center;
    color: #000000; }

.profilePageForm {
  width: 100%; }
  .profilePageForm input {
    width: 100%;
    font-size: 16px; }

.profileContainer {
  width: 80%; }
  @media only screen and (max-device-width: 479px) {
    .profileContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column; } }

.profilePage {
  background-image: url("/assets/images/main/redesign/4.png"); }
  .profilePage__save {
    display: flex; }
  .profilePage__become-streamer {
    width: 140px;
    margin: 0;
    margin-left: 20px; }
  .profilePage__change-lang {
    padding: 20px 0; }
  .profilePage__cashbox {
    padding: 20px 0px 0px; }
  .profilePage__ask-partner {
    padding: 20px 0; }
  .profilePage__allow-donation {
    display: inline;
    width: 18px !important;
    height: 18px !important;
    margin-left: 10px;
    position: relative !important;
    top: 2px;
    left: 0 !important; }
  .profilePage__cashbox-button-group {
    display: flex; }
  .profilePage__cashbox-button {
    margin: 10px 0px;
    width: 100px;
    display: inline-block;
    margin-right: 10px;
    font-size: 16px;
    padding: 0 5px;
    color: #f7f7f7;
    font-family: Ubuntu-Medium, sans-serif;
    height: 47px;
    line-height: 47px;
    text-align: center; }
    @media only screen and (max-device-width: 479px) {
      .profilePage__cashbox-button {
        width: calc(100% / 3); } }
  .profilePage__label {
    color: #f7f7f7;
    font-size: 14px;
    font-family: Ubuntu-Regular, sans-serif; }
  .profilePage__switcher {
    padding: 10px 0 5px; }
  .profilePage .become-streamer__button {
    margin: 0;
    width: 150px;
    display: block;
    font-size: 16px;
    padding: 0 5px; }
  .profilePage .buy-orbs__button {
    margin: 0;
    width: 100px;
    display: block;
    font-size: 16px;
    padding: 0 5px;
    margin-left: 10px; }
    @media only screen and (max-device-width: 479px) {
      .profilePage .buy-orbs__button {
        margin-left: 0; } }
  .profilePage .profileSaveBtn {
    height: 37px;
    width: 120px;
    color: #f7f7f7; }
  .profilePage__buy-orbs {
    width: 130px;
    margin: 0;
    margin-left: 20px; }
    @media only screen and (max-device-width: 479px) {
      .profilePage__buy-orbs {
        margin: 0;
        width: auto; } }
  .profilePage .profilePageBlock-info {
    width: 600px; }
    @media only screen and (max-device-width: 479px) {
      .profilePage .profilePageBlock-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%; } }
  .profilePage .profilePageForm {
    width: 300px; }
    @media only screen and (max-device-width: 479px) {
      .profilePage .profilePageForm {
        width: 100%;
        margin: 0 auto; } }
  @media only screen and (max-device-width: 479px) {
    .profilePage .addServices {
      width: 90%;
      margin: 0 auto; } }

.profile-page__donations {
  padding: 0 30px; }
  @media only screen and (max-device-width: 479px) {
    .profile-page__donations {
      padding: 0 15px; } }
  .profile-page__donations table {
    margin-top: 30px; }
    @media only screen and (max-device-width: 479px) {
      .profile-page__donations table {
        margin-top: 15px; } }

.profile-page__tabs {
  display: flex;
  color: #f7f7f7;
  height: 30px;
  justify-content: space-around;
  align-items: center;
  background-color: #1b1b1b;
  position: absolute;
  width: 100%;
  left: 0; }
  @media only screen and (max-device-width: 479px) {
    .profile-page__tabs {
      flex-wrap: wrap;
      height: auto;
      padding: 0 15px; } }

.profile-page__tab {
  width: calc(100% / 3);
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  display: block;
  font-size: 18px;
  font-family: Ubuntu-Medium, sans-serif;
  background-color: #1b1b1b; }
  @media only screen and (max-device-width: 479px) {
    .profile-page__tab {
      width: calc(100% / 2);
      height: auto;
      line-height: normal;
      padding: 5px 0;
      margin-top: 10px; } }
  .profile-page__tab:hover {
    background-color: #232323 !important; }

.ask-modal__head {
  height: 60px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid white;
  text-align: center; }

.cashbox-modal {
  width: 500px;
  background-color: #232323;
  margin: 180px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #f7f7f7; }
  .cashbox-modal input::-webkit-outer-spin-button,
  .cashbox-modal input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */ }
  @media only screen and (max-device-width: 479px) {
    .cashbox-modal {
      width: 90%;
      margin: 150px auto; } }
  .cashbox-modal__row {
    display: flex;
    margin-top: 10px;
    justify-content: space-around; }
    @media only screen and (max-device-width: 479px) {
      .cashbox-modal__row {
        flex-direction: column;
        margin-top: 10px;
        height: 120px; } }
  .cashbox-modal__inline {
    width: 40%; }
    @media only screen and (max-device-width: 479px) {
      .cashbox-modal__inline {
        width: 80%; } }
  .cashbox-modal__currency-select {
    width: 100%;
    padding: 2px 10px; }
    @media only screen and (max-device-width: 479px) {
      .cashbox-modal__currency-select {
        width: 40%;
        height: 30px;
        padding: 2px 10px; } }
  .cashbox-modal__label {
    margin: 0;
    color: #f7f7f7; }
  .cashbox-modal__wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0px;
    left: 0px;
    z-index: 1; }
  .cashbox-modal__button {
    padding: 5px 10px;
    color: #f7f7f7;
    font-family: Ubuntu-Medium, sans-serif;
    font-size: 18px;
    display: block;
    margin: 20px auto;
    width: auto !important; }
    @media only screen and (max-device-width: 479px) {
      .cashbox-modal__button {
        margin: 0 auto 20px;
        width: 100px;
        display: block; } }
  .cashbox-modal__form {
    padding: 20px 0; }
  .cashbox-modal__block {
    border-top: 1px solid white;
    display: flex;
    flex-direction: column;
    height: 110px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid white;
    justify-content: space-around; }
    .cashbox-modal__block a {
      text-decoration: underline; }
    .cashbox-modal__block__form {
      padding: 20px 0; }
    .cashbox-modal__block__head {
      height: 40px; }
      @media only screen and (max-device-width: 479px) {
        .cashbox-modal__block__head {
          height: 40px; } }

.profileBlock__plus-mob {
  display: none; }

.partnership-modal {
  margin: auto;
  background-color: transparent;
  border: none; }

.transactions__header th {
  white-space: nowrap; }

@media (min-width: 320px) and (max-width: 480px) {
  .profile-page__donations {
    padding: 0; }
  .profile-page__donations::-webkit-scrollbar {
    display: none; }
  .profile-page__table-cnt {
    width: 100%;
    overflow-x: scroll; }
  .profile-page__table-cnt table {
    width: 445px !important; }
  .profileBlock__plus-mob {
    display: block; } }

.activity_admin {
  width: 186px;
  border: 3px solid #fff;
  border-radius: 5px;
  background-color: #000;
  margin: 10px;
  display: inline-block; }
  .activity_admin__header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 40px; }
  .activity_admin__row {
    display: flex;
    padding: 3px 10px;
    justify-content: space-between; }
    .activity_admin__row_controls {
      position: relative;
      width: calc(100% + 10px);
      padding: 0;
      left: -5px;
      top: 5px;
      height: 50px;
      justify-content: center; }
  .activity_admin__goal-price {
    background-color: #232323;
    border-radius: 7.5px;
    border: 1px solid #f7f7f7;
    width: 60px;
    height: 20px;
    padding: 0 5px;
    font-size: 12px;
    font-family: Ubuntu-Regular, sans-serif;
    color: #f7f7f7; }
  .activity_admin__goal-price::placeholder {
    font-size: 12px;
    font-family: Ubuntu-Regular, sans-serif;
    text-align: center; }
  .activity_admin__current-balance {
    width: 100%;
    height: 20px;
    border-radius: 9.5px;
    border: solid 1px #f7f7f7;
    text-align: center;
    font-size: 10px;
    color: #f7f7f7;
    font-family: Ubuntu-Regular, sans-serif;
    line-height: 20px; }
  .activity_admin__operator-actions {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .activity_admin__operator-actions_voting {
      flex-direction: row; }
  .activity_admin__description {
    padding: 3px 10px;
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 12px;
    text-align: left;
    color: #f7f7f7; }
  .activity_admin__overlay {
    display: flex;
    position: absolute;
    width: 50%;
    justify-content: center;
    height: 100%;
    transition: width 1s linear 1s, opacity 0s 1s; }
  .activity_admin__button {
    cursor: pointer;
    width: 65px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    font-family: Ubuntu-Regular, sans-serif; }
    .activity_admin__button_vertical {
      cursor: pointer;
      flex-shrink: 1;
      width: 25%;
      color: #f7f7f7;
      font-size: 12px;
      font-family: Ubuntu-Bold, sans-serif;
      padding: 3px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      transition: width 1s linear 1s, color 0.75s linear 0.25s;
      box-sizing: border-box; }
    .activity_admin__button_transparent {
      color: transparent; }
    .activity_admin__button_wide {
      width: 50%; }
    .activity_admin__button_hidden {
      position: absolute; }
    .activity_admin__button_approve {
      border: double 2px transparent;
      background-image: linear-gradient(#4a69bd, #4a69bd), radial-gradient(circle at top left, #ffc837, #ff8008);
      background-origin: border-box;
      background-clip: content-box, border-box;
      border-radius: 13px;
      box-sizing: border-box;
      line-height: 16px;
      padding: 0 1px; }
    .activity_admin__button_add-new {
      background-color: green;
      font-family: Ubuntu-Bold, sans-serif;
      margin: 0 10px;
      width: 100px;
      display: block; }
    .activity_admin__button_refuse {
      background-color: #60a3bc; }
    .activity_admin__button_accept {
      background-color: green; }
    .activity_admin__button_done {
      background-color: green; }
    .activity_admin__button_failed {
      background-color: red; }
    .activity_admin__button_close {
      background-color: #4a69bd; }
    .activity_admin__button_close-pink {
      background-color: rgba(248, 177, 149, 0.8); }
    .activity_admin__button_cancel-pink {
      background-color: rgba(246, 114, 128, 0.8); }
    .activity_admin__button_close-create-pink {
      background-color: rgba(192, 108, 132, 0.8); }
    .activity_admin__button_cancel-create-pink {
      background-color: rgba(246, 114, 128, 0.8); }
  .activity_admin .time-to-accept {
    width: 58px;
    height: 20px;
    background: #232323;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    color: #f7f7f7; }
    .activity_admin .time-to-accept input {
      outline: none; }
    .activity_admin .time-to-accept__hh {
      width: 50%;
      height: 100%;
      background-color: transparent;
      border: none;
      border-right: 1px solid #f7f7f7;
      color: #f7f7f7;
      text-align: center;
      font-size: 12px;
      font-family: Ubuntu-Regular, sans-serif; }
    .activity_admin .time-to-accept__mm {
      width: 50%;
      height: 100%;
      background-color: transparent;
      border: none;
      color: #f7f7f7;
      text-align: center;
      font-size: 12px;
      font-family: Ubuntu-Regular, sans-serif; }
  .activity_admin .color {
    transition: color 1s linear 2s; }
  .activity_admin .hidden {
    opacity: 0;
    color: 0;
    visibility: hidden; }
    .activity_admin .hidden div {
      color: transparent; }
  .activity_admin .hide div {
    color: transparent; }
  .activity_admin .wide {
    z-index: 100;
    width: 100%; }
    .activity_admin .wide div {
      color: #f7f7f7; }
  .activity_admin .transparent {
    color: transparent; }

.activity-admin__toggler {
  height: 40px;
  background-color: #232323;
  line-height: 40px;
  margin: 20px 0px;
  cursor: pointer; }

.activity-admin__activity-block {
  border-left: 1px solid #f7f7f7;
  width: calc(100% / 4);
  padding: 0 1rem; }
  .activity-admin__activity-block:first-child {
    border: none; }
  .activity-admin__activity-block_prd {
    width: 100% !important; }

.activity-admin__templates {
  margin-top: 1.5rem;
  justify-content: center; }
  .activity-admin__templates > form.adm-new-prediction {
    width: calc(100% / 4); }

.activity-admin__header {
  margin-bottom: 1.5rem;
  font-size: 20px;
  min-height: 60px; }

.activity-admin__container {
  padding: 20px 15px 0 30px;
  margin: 0 auto;
  min-height: 100vh; }

.activity-admin__tab-content {
  margin-top: 50px; }
  .activity-admin__tab-content > .row-container {
    justify-content: flex-start; }

.activity-admin__tab-content.paidactions-tab .row-container .activity-admin__activity-block {
  width: calc(100% / 2); }

.activity-admin__tab-content.predictions-tab .row-container .activity-admin__activity-block {
  width: calc(100% / 3); }

.plus__icon {
  cursor: pointer;
  position: relative;
  width: 30px;
  height: 30px;
  border: 3px solid #ff8008;
  border-radius: 50%;
  margin: 10px auto; }
  .plus__icon:before, .plus__icon:after {
    content: '';
    position: absolute;
    background-color: #ff8008;
    transition: transform 0.25s ease-out; }
  .plus__icon:before {
    top: 25%;
    left: 50%;
    width: 3px;
    height: 50%;
    margin-left: -2px; }
  .plus__icon:after {
    top: 50%;
    left: 25%;
    width: 50%;
    height: 3px;
    margin-top: -1px; }

.activity-create {
  background-color: #000;
  border: 3px solid #fff;
  border-radius: 5px;
  width: 200px;
  height: auto;
  box-sizing: border-box;
  margin: 5px;
  display: inline-block; }
  .activity-create__delete {
    cursor: pointer;
    top: 10px;
    position: absolute;
    right: 10px;
    width: 15px;
    height: 15px;
    opacity: 0.75;
    background-image: url(/assets/close-icon.png);
    background-repeat: no-repeat;
    background-size: 100%;
    transition: all 0.3s;
    background-color: #232323; }
    .activity-create__delete:hover {
      opacity: 1; }
    @media only screen and (max-device-width: 479px) {
      .activity-create__delete {
        width: 20px;
        height: 20px; } }
  .activity-create textarea {
    resize: none; }
  .activity-create_voting {
    width: 200px; }
  .activity-create__header {
    height: 30px;
    width: 100%;
    display: flex;
    padding: 5px 10px;
    justify-content: space-between;
    box-sizing: border-box; }
    .activity-create__header_prediction {
      height: 120%;
      min-height: 30px; }
  .activity-create__paid-action-mode {
    cursor: pointer;
    font-size: 10px;
    color: #f7f7f7;
    background-color: #232323;
    border-radius: 15px;
    height: 20px;
    line-height: 20px;
    padding: 0px 10px; }
  .activity-create .active::after {
    border-bottom: 3px solid blue;
    display: block;
    content: ' ';
    width: 100%;
    height: 1px; }
  .activity-create__stream-name {
    height: 100%;
    font-size: 12px;
    font-family: Ubuntu-Medium, sans-serif;
    line-height: 100%;
    letter-spacing: normal;
    text-align: left;
    color: #f7f7f7; }
  .activity-create__activity-name {
    margin-left: 10px; }
  .activity-create__trigger {
    display: block;
    width: 75px;
    font-family: Ubuntu-Medium, sans-serif;
    font-size: 12px;
    line-height: 10px;
    letter-spacing: normal;
    text-align: right;
    color: #f7f7f7;
    margin-bottom: 10px; }
  .activity-create__trigger::after {
    display: block;
    position: absolute;
    width: 30%;
    right: 5px;
    border-bottom: 4px solid gold;
    margin: 3px 0 5px 0;
    content: " "; }
  .activity-create__row {
    display: flex;
    justify-content: space-around;
    padding: 5px;
    text-align: center;
    align-items: center; }
    .activity-create__row_voting {
      justify-content: center;
      align-items: center; }
    .activity-create__row_wrap {
      flex-wrap: wrap; }
    .activity-create__row_between {
      justify-content: space-between; }
  .activity-create__option {
    cursor: pointer;
    width: 120px;
    font-family: Ubuntu-Medium, sans-serif;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: normal;
    text-align: left;
    color: #d1cdcd;
    padding: 3px 0; }
  .activity-create__description {
    line-height: 15px;
    font-size: 14px;
    color: #f7f7f7;
    background-color: #232323;
    overflow: hidden;
    outline: none;
    border-radius: 10px;
    width: 100%;
    padding: 5px;
    text-align: center; }
    .activity-create__description_voting {
      width: 100%; }
  .activity-create__settings {
    height: 40px; }
  .activity-create__mode {
    cursor: pointer;
    font-size: 10px;
    color: #f7f7f7;
    text-align: right;
    position: relative;
    width: 100%;
    padding-right: 5px;
    bottom: 10px; }
  .activity-create__aside {
    width: 80px;
    display: flex;
    justify-content: space-between; }
  .activity-create__orbs {
    font-size: 12px;
    cursor: pointer; }
  .activity-create__cubes {
    font-size: 12px;
    cursor: pointer; }
  .activity-create__label {
    padding: 0 10px;
    width: 100%;
    text-align: left;
    font-size: 12px; }
    .activity-create__label_inline {
      width: auto;
      padding: 0 5px; }
  .activity-create__outcome {
    width: 95%;
    background-color: #232323;
    border-radius: 10px;
    color: #f7f7f7;
    text-align: center;
    overflow: hidden;
    outline: none;
    font-size: 14px;
    padding: 5px;
    line-height: 15px; }
    .activity-create__outcome_voting {
      margin: 5px 0; }
    .activity-create__outcome_hover:hover {
      border: 1px solid green;
      cursor: pointer; }
  .activity-create__button {
    cursor: pointer;
    width: 62px;
    background-color: #232323;
    height: 20px;
    border-radius: 10px;
    color: #f7f7f7;
    font-size: 10px;
    line-height: 20px;
    text-align: center; }
    .activity-create__button_medium {
      width: 80px; }
  .activity-create .b-dropdown__wrapper {
    top: 5px;
    background-color: transparent;
    background-color: #232323;
    display: flex;
    align-items: center; }
  .activity-create .b-dropdown__content {
    background-color: transparent;
    align-items: center;
    width: 140px; }
  .activity-create .b-dropdown__arrow svg {
    visibility: hidden !important; }
  .activity-create .b-dropdown__trigger {
    width: 100px; }
  .activity-create .activity-create__trigger {
    width: 75px; }
  .activity-create .time-to-accept {
    width: 65px;
    height: 25px;
    background: #232323;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    color: #f7f7f7; }
    .activity-create .time-to-accept input {
      outline: none; }
    .activity-create .time-to-accept__hh {
      width: 50%;
      height: 100%;
      background-color: transparent;
      border: none;
      border-right: 1px solid #fff;
      color: #f7f7f7;
      text-align: center;
      font-size: 12px; }
    .activity-create .time-to-accept__mm {
      width: 50%;
      height: 100%;
      background-color: transparent;
      border: none;
      color: #f7f7f7;
      text-align: center;
      font-size: 12px; }

.social {
  display: flex;
  height: 30px;
  align-items: center;
  padding: 0 20px; }
  .social__button {
    width: 25px;
    height: 25px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 10px; }
  .social__discord {
    background-image: url("../images/main/header/discord.png"); }
  .social__twitter {
    background-image: url("../images/main/header/twitter.png"); }
  .social__facebook {
    background-image: url("../images/main/header/facebook.png"); }
  .social__instagram {
    background-image: url("../images/main/header/instagram.png"); }

.streamlist__header {
  display: flex;
  align-items: center;
  height: 44px;
  justify-content: space-between;
  padding: 0 5%; }

.streamlist__see-all {
  line-height: 44px;
  height: 100% !important;
  width: auto !important; }

.transactions {
  background-color: #1b1b1b; }
  .transactions__table {
    padding: 43px; }
    @media only screen and (max-device-width: 479px) {
      .transactions__table {
        padding: 0 15px; } }
  .transactions table {
    width: 100%; }
  .transactions__header {
    color: #f7f7f7;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center; }
    .transactions__header tr {
      width: 100%;
      display: flex;
      justify-content: space-around; }
    .transactions__header th {
      width: calc(100% / 5);
      text-align: center;
      height: 100%;
      line-height: 30px;
      opacity: 0.5;
      font-size: 25px;
      font-weight: 300;
      cursor: pointer; }
      @media only screen and (max-device-width: 479px) {
        .transactions__header th {
          font-size: 10px; } }
    .transactions__header th:first-child {
      width: 50px; }
  .transactions__circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #2094fa; }
    .transactions__circle.gold {
      background-image: linear-gradient(#ffc837, #ff8008); }
  .transactions__label {
    color: #f7f7f7;
    line-height: 30px;
    display: flex;
    align-items: center; }
  .transactions__checkbox {
    position: static !important;
    margin-left: 10px; }
  .transactions__row {
    color: #f7f7f7;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 4px solid #484848;
    height: 80px; }
    .transactions__row td {
      width: calc(100% / 5);
      text-align: center; }
      @media only screen and (max-device-width: 479px) {
        .transactions__row td {
          font-size: 10px;
          padding: 5px; } }
    .transactions__row td:first-child {
      width: 50px; }
  .transactions__row {
    background-color: #1b1b1b; }

[tooltip] {
  position: relative;
  /* opinion 1 */ }

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none;
  /* opinion 2 */
  font-size: 1em;
  /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0; }

[tooltip]::before {
  content: '';
  border: 5px solid transparent;
  border: 5px solid transparent;
  border: 5px solid transparent;
  border: 5px solid transparent;
  border: 5px solid transparent;
  z-index: 1001; }

[tooltip]::after {
  content: attr(tooltip);
  content: attr(tooltip);
  content: attr(tooltip);
  font-family: Ubuntu-Regular, sans-serif;
  color: #f7f7f7;
  text-align: center;
  min-width: 12em;
  max-width: 25em;
  white-space: pre-wrap;
  word-break: break-word;
  padding: 1ch 1.5ch;
  border-radius: 3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #232323;
  opacity: 0.99;
  color: #fff;
  z-index: 1000;
  /* absurdity 2 */ }

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block; }

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important; }

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333; }

[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px); }

[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -0.5em); }

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333; }

[tooltip][flow^="down"]::after {
  top: calc(100% + 5px); }

[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, 0.5em); }

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-0.5em, -50%); }

[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-0.5em, -50%); }

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(0.5em, -50%); }

[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(0.5em, -50%); }

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0); } }

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%); } }

/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards; }

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards; }

.stream-edit {
  color: #f7f7f7;
  font-size: 18px; }
  .stream-edit__content a:hover {
    color: #ff8008; }
  .stream-edit__manage-activities {
    font-size: 18px;
    font-family: Ubuntu-Regular, sans-serif;
    text-align: center; }
  .stream-edit__row {
    display: flex;
    width: 100%;
    align-items: flex-end; }
  .stream-edit__ask-for-partnership {
    width: 180px;
    padding: 5px 10px;
    color: #f7f7f7;
    margin: 20px auto; }
  .stream-edit__streamer-help {
    width: 70%;
    margin: auto;
    text-align: justify; }
  .stream-edit__content {
    display: flex;
    justify-content: center; }
    @media only screen and (max-device-width: 479px) {
      .stream-edit__content {
        flex-direction: column;
        align-items: center; } }
  .stream-edit__getting-started {
    width: 70%;
    margin: auto;
    text-align: justify; }
    @media only screen and (max-device-width: 479px) {
      .stream-edit__getting-started {
        width: 85%; } }
  .stream-edit__help {
    border: 1px solid white;
    margin: 20px;
    width: 345px;
    box-sizing: border-box;
    padding: 20px;
    padding-top: 0;
    text-align: justify; }
    .stream-edit__help img {
      display: block;
      margin: 0 auto; }
    @media only screen and (max-device-width: 479px) {
      .stream-edit__help {
        width: 85%;
        padding: 15px; } }
  .stream-edit__help-text {
    font-size: 14px !important; }
  .stream-edit__moderator-list {
    display: flex;
    flex-wrap: wrap;
    padding: 10px; }
  .stream-edit__desc {
    color: #f7f7f7;
    font-size: 18px; }
  .stream-edit__moderator {
    width: 210px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0px;
    height: 200px; }
  .stream-edit__moderator-avatar {
    overflow: hidden;
    border-radius: 50%;
    width: 30px;
    height: 30px; }
    .stream-edit__moderator-avatar img {
      width: 30px;
      height: 30px; }
  .stream-edit__moderator-reputation {
    text-align: center; }
  .stream-edit__label {
    text-align: center;
    margin-bottom: 10px;
    color: #f7f7f7;
    font-size: 18px; }
  .stream-edit__head {
    display: flex;
    justify-content: space-around;
    width: 100%; }
  .stream-edit__invite {
    cursor: pointer;
    background-color: green;
    height: 30px;
    border-radius: 15px;
    color: #f7f7f7;
    width: 80px;
    line-height: 30px;
    text-align: center; }
  .stream-edit__link {
    font-size: 18px;
    font-family: Ubuntu-Medium, sans-serif; }
  .stream-edit__button {
    width: 100px;
    margin: 20px auto 0;
    height: 30px;
    background-color: cadetblue;
    border-radius: 15px;
    line-height: 30px;
    color: #f7f7f7;
    text-align: center;
    cursor: pointer; }

.stream-moderation {
  width: 90%;
  margin: 0 auto; }
  .stream-moderation a:hover {
    color: #ff8008; }
  .stream-moderation input[type="checkbox"] {
    position: static; }
  @media only screen and (max-device-width: 479px) {
    .stream-moderation {
      width: 95%; } }
  .stream-moderation table {
    width: 100%; }
  .stream-moderation__input {
    height: auto !important; }
  .stream-moderation__header {
    color: #f7f7f7;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center; }
    .stream-moderation__header tr {
      width: 100%;
      display: flex;
      justify-content: space-around; }
    .stream-moderation__header th {
      width: calc(100% / 5);
      text-align: center;
      height: 100%;
      line-height: 30px;
      opacity: 0.5;
      font-size: 25px;
      font-weight: 300;
      cursor: pointer; }
      @media only screen and (max-device-width: 479px) {
        .stream-moderation__header th {
          font-size: 10px;
          line-height: 15px; } }
    .stream-moderation__header th:first-child {
      width: 50px; }
  .stream-moderation__row {
    color: #f7f7f7;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 4px solid #484848;
    height: 80px; }
    .stream-moderation__row td {
      width: calc(100% / 5);
      text-align: center; }
      @media only screen and (max-device-width: 479px) {
        .stream-moderation__row td {
          font-size: 10px;
          padding: 5px; } }
    .stream-moderation__row td:first-child {
      width: 50px; }
  .stream-moderation__row {
    background-color: #1b1b1b; }
  .stream-moderation__stream-list {
    margin: 0 auto; }
  .stream-moderation__stream {
    color: #f7f7f7;
    display: block;
    padding: 10px; }
  @media only screen and (max-device-width: 479px) {
    .stream-moderation__moderators {
      display: flex;
      flex-direction: column;
      justify-content: space-between; } }
  .stream-moderation__save-btn {
    padding: 7px 25px;
    color: #f7f7f7;
    position: relative;
    right: -90%; }
    @media only screen and (max-device-width: 479px) {
      .stream-moderation__save-btn {
        margin-right: 7px;
        position: static;
        align-self: flex-end; } }
  .stream-moderation__label {
    color: #f7f7f7;
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 12px; }
  .stream-moderation__header {
    font-size: 18px;
    font-family: Ubuntu-Regular, sans-serif;
    padding: 20px 10px; }
    @media only screen and (max-device-width: 479px) {
      .stream-moderation__header {
        padding: 10px 0; } }

.how-it-works {
  width: 100%;
  background: transparent;
  cursor: default; }
  .how-it-works__join-predictoria {
    border: none;
    outline: none;
    background-image: linear-gradient(#f7f7f7, 30%, #2094fa);
    padding: 5px 20px;
    font-family: Ubuntu-Regular, sans-serif;
    color: #f7f7f7;
    font-size: 18px;
    cursor: pointer;
    border-radius: 15px;
    margin-top: 20px; }
    .how-it-works__join-predictoria:hover {
      background-image: linear-gradient(#f7f7f7, 30%, #025eaf); }
  .how-it-works__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    z-index: 1002; }
  .how-it-works__reg-button {
    outline: none;
    border: 1px solid grey;
    background-color: transparent;
    height: 30px;
    width: 200px;
    color: #f7f7f7;
    border-radius: 15px;
    padding: 0 10px;
    cursor: pointer; }
    .how-it-works__reg-button:hover {
      font-weight: bold;
      background-color: rgba(104, 103, 103, 0.336); }
  .how-it-works__learn-more {
    text-decoration: underline;
    font-size: 18px; }
  .how-it-works__registration {
    height: 40px;
    width: 210px;
    color: #f7f7f7;
    cursor: pointer;
    font-size: 16px; }
    .how-it-works__registration_gold {
      transition: all .3s ease; }
      .how-it-works__registration_gold:hover {
        background-image: linear-gradient(#fad355, #f77c0a); }
    @media only screen and (max-device-width: 479px) {
      .how-it-works__registration {
        font-size: 12px;
        width: 180px; } }
  .how-it-works__registration-block {
    padding: 0;
    justify-content: space-between;
    width: 50%;
    display: flex; }
    @media only screen and (max-device-width: 479px) {
      .how-it-works__registration-block {
        width: 90%; } }
  .how-it-works__wrapper {
    width: 882px;
    height: 100%;
    margin: 0 auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 1001; }
    @media only screen and (max-device-width: 479px) {
      .how-it-works__wrapper {
        width: 100%;
        padding: 20px;
        box-sizing: border-box; } }
  .how-it-works__head, .how-it-works__body {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #f7f7f7; }
  .how-it-works__bg {
    background-color: #1b1b1b; }
  .how-it-works__body {
    margin-top: 45px; }
  .how-it-works__heading {
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 25px;
    line-height: 1.5;
    text-align: center;
    margin-top: 20px; }
    .how-it-works__heading_left {
      text-align: left; }
  .how-it-works__body-top {
    border-bottom: 9px solid #ff8008;
    background-image: url(../images/main/redesign/section-two.png);
    background-size: 100%;
    height: 155px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .how-it-works__bottom {
    border-top: 2px solid #f7f7f7;
    padding: 20px 0; }
  .how-it-works__close {
    cursor: pointer;
    top: 20px;
    position: absolute;
    /* left: 20px; */
    right: 20px;
    width: 35px;
    height: 35px;
    opacity: 0.75;
    background-image: url("../images/main/redesign/close-icon.png");
    background-size: 100%;
    transition: all 0.3s; }
    .how-it-works__close:hover {
      opacity: 1; }
    @media only screen and (max-device-width: 479px) {
      .how-it-works__close {
        width: 20px;
        height: 20px; } }
  .how-it-works__logo {
    width: 462px;
    height: 54px;
    background-image: url("../images/logo-predictoria.com.png");
    background-size: cover; }
    @media only screen and (max-device-width: 479px) {
      .how-it-works__logo {
        width: 80%;
        background-size: contain;
        background-repeat: no-repeat; } }
  .how-it-works__link {
    color: #ff8008;
    text-decoration: underline; }
  .how-it-works__description {
    width: 802px;
    font-family: Ubuntu-Regular, sans-serif;
    font-size: 18px;
    text-align: justify;
    padding: 40px;
    padding-bottom: 0px; }
    @media only screen and (max-device-width: 479px) {
      .how-it-works__description {
        width: 100%;
        padding: 20px;
        box-sizing: border-box; } }
    .how-it-works__description_body {
      text-align: justify;
      font-size: 18px;
      font-weight: bold;
      padding: 0;
      line-height: 30px; }
    .how-it-works__description_gold {
      font-size: 18px;
      font-weight: bold;
      color: #ff8008;
      text-align: justify;
      line-height: 30px; }
    .how-it-works__description_center {
      font-size: 24px;
      font-weight: bold;
      padding: 25px 0;
      line-height: 35px;
      text-align: center; }
      @media only screen and (max-device-width: 479px) {
        .how-it-works__description_center {
          font-size: 20px; } }
  .how-it-works__split-panel {
    width: 100%;
    height: 200px;
    position: relative;
    background-color: #f7f7f7; }
  .how-it-works__split-panel-wrapper {
    width: 100%;
    margin: 0 auto; }
  .how-it-works__icon {
    border: 10px solid #ff8008;
    height: 120px;
    width: 120px;
    background-size: 50px 50px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: 50%; }
    @media only screen and (max-device-width: 479px) {
      .how-it-works__icon {
        width: 80px;
        height: 80px; } }
    .how-it-works__icon_streamer {
      background-size: 60px 50px; }
  .how-it-works__line {
    display: flex;
    padding: 20px 0 0; }
    .how-it-works__line_between {
      justify-content: space-between; }
  .how-it-works__percent-blue {
    color: #358eff; }
  .how-it-works__percent-gold {
    color: #ff8008; }
  .how-it-works__blue {
    background-color: #358eff;
    height: 40px;
    width: 95%; }
  .how-it-works__gold {
    background-color: #ff8008;
    height: 40px;
    width: 5%; }
  .how-it-works__step {
    width: 140px; }
    @media only screen and (max-device-width: 479px) {
      .how-it-works__step {
        display: flex;
        flex-direction: column;
        align-items: center; } }
  .how-it-works__step:nth-child(1) div {
    background-image: url(../images/main/redesign/search.png); }
  .how-it-works__step:nth-child(2) div {
    background-image: url(../images/main/redesign/trophy.png); }
  .how-it-works__step:nth-child(3) div {
    background-image: url(../images/main/redesign/star.png); }
  .how-it-works__step_streamer:nth-child(1) div {
    background-image: url(../images/main/redesign/check.png); }
  .how-it-works__step_streamer:nth-child(2) div {
    background-image: url(../images/main/redesign/users.png); }
  .how-it-works__step_streamer:nth-child(3) div {
    background-image: url(../images/main/redesign/money.png); }
  .how-it-works__section {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 20px;
    padding-bottom: 0;
    box-sizing: border-box; }
    .how-it-works__section_column {
      flex-direction: column;
      width: 100%; }
  .how-it-works__orbs {
    background-image: url("/assets/images/main/redesign/orb.png");
    width: 30px;
    height: 30px;
    display: inline-block;
    background-size: 100% 100%;
    position: relative;
    top: 10px; }
  .how-it-works__cubes {
    background-image: url("/assets/images/main/redesign/cube.png");
    width: 30px;
    height: 30px;
    display: inline-block;
    background-size: 100% 100%;
    position: relative;
    top: 10px; }
  .how-it-works__clear {
    width: 180px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin: 20px auto; }
  .how-it-works__become-streamer {
    width: 160px;
    font-size: 14px;
    margin: 0 10px;
    height: 30px;
    line-height: 30px; }
  .how-it-works .content {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 60%;
    top: 90px;
    font-size: 20px;
    text-align: center; }
    @media only screen and (max-device-width: 479px) {
      .how-it-works .content {
        width: 60%;
        top: 90px;
        font-size: 20px; } }
    .how-it-works .content_left {
      right: 10px; }
    .how-it-works .content_right {
      left: 25px; }
  .how-it-works .one-is-active .section-one {
    width: 73%; }
  .how-it-works .one-is-active .section-two {
    width: 45%; }
  .how-it-works .two-is-active .section-one {
    width: 45%; }
  .how-it-works .two-is-active .section-two {
    width: 73%; }
  .how-it-works .section-one, .how-it-works .section-two {
    position: absolute;
    height: 200px;
    width: 70%;
    transition: all 0.5s;
    background-size: 800px 300px;
    cursor: pointer; }
  .how-it-works .section-one {
    left: 0;
    top: 0;
    width: 63%;
    background-image: url(../images/main/redesign/section-one.png);
    -webkit-clip-path: polygon(0 0, 100% 0%, calc(100% - 100px) 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, calc(100% - 100px) 100%, 0% 100%); }
    @media only screen and (max-device-width: 479px) {
      .how-it-works .section-one {
        clip-path: polygon(0 0, 100% 0%, calc(100% - 50px) 100%, 0% 100%); } }
  .how-it-works .section-two {
    right: 0;
    top: 0;
    width: 55%;
    background-image: url(../images/main/redesign/section-two.png);
    -webkit-clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(180px 0, 100% 0%, 100% 100%, 80px 100%); }
    @media only screen and (max-device-width: 479px) {
      .how-it-works .section-two {
        clip-path: polygon(85px 0, 100% 0%, 100% 100%, 35px 100%); } }

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2); }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0); } }

.stream-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end; }

.overlay-activities-container {
  position: absolute;
  right: -40px;
  transition: 0.3s;
  width: 100px;
  text-decoration: none;
  font-size: 20px;
  color: white;
  border-radius: 0 5px 5px 0;
  height: 100vh; }
  .overlay-activities-container:hover {
    right: 355px; }
  .overlay-activities-container .activities {
    padding-left: 60px;
    width: 400px;
    background-color: transparent;
    position: relative; }
    .overlay-activities-container .activities:before {
      content: '';
      background-image: url("../images/main/slider/button-arrow-left-small.png");
      background-size: 100% 100%;
      width: 32px;
      height: 50px;
      margin-top: 50px;
      display: block;
      position: absolute;
      left: 21px;
      top: calc(50vh - 25px); }
  .overlay-activities-container .activity {
    background-color: transparent; }
  .overlay-activities-container .predictionMainWindowWrapper {
    background-color: transparent !important; }

.stream__bottom {
  color: #f7f7f7;
  font-family: Ubuntu-Regular, sans-serif;
  padding: 20px;
  background-color: #1b1b1b;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box; }
  @media only screen and (max-device-width: 479px) {
    .stream__bottom {
      flex-direction: column; } }
  .stream__bottom h3 {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 1rem; }
  .stream__bottom h5 {
    font-size: 18px;
    font-weight: normal; }

.stream__left {
  display: flex;
  flex-direction: column;
  width: 80%; }
  @media only screen and (max-device-width: 479px) {
    .stream__left {
      width: 100%; } }

.stream__right {
  display: flex;
  align-items: center; }
  .stream__right button:hover {
    box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }
  @media only screen and (max-device-width: 479px) {
    .stream__right {
      width: 100%; } }

.stream__report {
  color: #e74c3c;
  text-align: center; }

.stream__create-challenge {
  padding: 7px 25px;
  margin: 0;
  margin-left: 25px;
  min-width: 150px;
  color: #f7f7f7;
  font-size: 14px;
  font-weight: bold;
  background-image: linear-gradient(#ffc837, #ff8008); }
  .stream__create-challenge_send {
    min-width: 150px;
    background-image: linear-gradient(#f7f7f7, 30%, #2094fa);
    margin-right: 10px; }
  @media only screen and (max-device-width: 479px) {
    .stream__create-challenge {
      margin: 0;
      margin-top: 20px;
      font-size: 12px;
      margin-right: 10px; } }

.message-modal {
  width: 515px;
  height: 475px;
  background-color: #232323;
  margin: 40px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  border-radius: 0px; }
  .message-modal input::-webkit-outer-spin-button,
  .message-modal input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */ }
  @media only screen and (max-device-width: 479px) {
    .message-modal {
      width: 90%;
      margin: 0px auto;
      margin-top: -40px;
      height: 560px; } }
  .message-modal .feedbackModalTitle {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../images/main/redesign/dota-2-launch-release@3x.png);
    border-bottom: 4px solid #ffc837;
    height: 105px; }
  .message-modal .feedbackModal-title {
    font-family: Ubuntu-Regular, sans-serif; }
  .message-modal .feedbackModalDesc {
    margin-top: 0; }
  .message-modal .feedbackModal-form {
    width: 100%;
    padding: 15px 30px;
    box-sizing: border-box; }
    .message-modal .feedbackModal-form p {
      color: #f7f7f7;
      font-size: 18px;
      font-family: Ubuntu-Regular, sans-serif;
      padding: 0px; }
      @media only screen and (max-device-width: 479px) {
        .message-modal .feedbackModal-form p {
          font-size: 14px; } }
    .message-modal .feedbackModal-form input {
      color: #808080;
      height: 41px;
      background-color: #3d3d3d;
      border-radius: 0px; }
    .message-modal .feedbackModal-form textarea {
      color: #808080;
      background-color: #3d3d3d;
      border-radius: 0px;
      height: 133px;
      box-sizing: border-box; }
  .message-modal .feedbackModal-text {
    margin-top: 10px; }
  .message-modal__cubes-amount {
    background: transparent !important;
    font-size: 22px;
    margin-top: 10px !important;
    padding: 0px !important;
    color: #f7f7f7 !important;
    width: 65px;
    text-align: left; }
  .message-modal__cubes {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-image: url(/assets/images/main/redesign/cube.png);
    margin-left: 5px;
    background-size: 100% 100%;
    cursor: pointer; }
  .message-modal__orbs {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-image: url(/assets/images/main/redesign/orb.png);
    margin-left: 5px;
    background-size: 100% 100%;
    cursor: pointer; }
  .message-modal__desc {
    text-align: center;
    color: #f7f7f7;
    padding: 10px;
    width: 80%; }
  .message-modal__send {
    border-radius: 15px !important;
    width: 180px !important;
    height: 32px !important;
    color: #f7f7f7 !important; }
    @media only screen and (max-device-width: 479px) {
      .message-modal__send {
        width: 120px !important; } }
  .message-modal__donation-value {
    width: 120px; }
  .message-modal__username {
    width: 65%;
    margin: 0;
    height: 40px;
    box-sizing: border-box; }
    @media only screen and (max-device-width: 479px) {
      .message-modal__username {
        width: 50%; } }
  .message-modal__playing {
    font-size: 16px;
    opacity: 0.6; }
  .message-modal__avatar {
    width: 75px;
    height: 75px; }
    .message-modal__avatar img {
      width: 75px;
      height: 75px; }
  .message-modal__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px; }
  .message-modal__inline {
    width: 40%; }
    @media only screen and (max-device-width: 479px) {
      .message-modal__inline {
        width: 80%; } }
  .message-modal__currency-select {
    width: 100%;
    padding: 2px 10px; }
    @media only screen and (max-device-width: 479px) {
      .message-modal__currency-select {
        width: 40%;
        height: 30px;
        padding: 2px 10px; } }
  .message-modal__label {
    margin: 0;
    color: #f7f7f7; }
  .message-modal__wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0px;
    left: 0px;
    z-index: 1000; }
  .message-modal__button {
    padding: 5px 10px;
    color: #f7f7f7;
    font-family: Ubuntu-Medium, sans-serif;
    font-size: 18px;
    display: block;
    margin: 20px auto;
    width: auto !important; }
    @media only screen and (max-device-width: 479px) {
      .message-modal__button {
        margin: 0 auto 20px;
        width: 100px;
        display: block; } }
  .message-modal__form {
    padding: 20px 0; }
  .message-modal__block {
    border-top: 1px solid white;
    display: flex;
    flex-direction: column;
    height: 110px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid white;
    justify-content: space-around; }
    .message-modal__block a {
      text-decoration: underline; }
    .message-modal__block__form {
      padding: 20px 0; }
    .message-modal__block__head {
      height: 40px; }
      @media only screen and (max-device-width: 479px) {
        .message-modal__block__head {
          height: 40px; } }

.subscribe-to-push {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background-image: linear-gradient(#ff8008, #ffc837);
  text-align: center;
  position: fixed;
  color: #f7f7f7;
  z-index: 1002;
  transition: all 0.5s;
  white-space: nowrap; }
  @media only screen and (max-device-width: 479px) {
    .subscribe-to-push {
      font-size: 10px; } }
  .subscribe-to-push a {
    text-decoration: underline;
    cursor: pointer; }
  .subscribe-to-push.open {
    transform: translateY(0px); }
  .subscribe-to-push.close {
    transform: translateY(-30px); }
  .subscribe-to-push__close {
    cursor: pointer;
    top: 5px;
    position: absolute;
    /* left: 20px; */
    right: 5px;
    width: 10px;
    height: 10px;
    opacity: 0.75;
    background-image: url("../images/main/redesign/close-icon.png");
    background-size: 100%;
    transition: all 0.3s; }
    .subscribe-to-push__close:hover {
      opacity: 1; }

@media only screen and (max-device-width: 479px) {
  .referals {
    padding-bottom: 15px; } }

.referals a:hover {
  color: #ff8008; }

.referals table {
  background-color: #232323; }

.referals p, .referals div {
  color: #f7f7f7; }

.referals__list-container {
  padding: 15px; }
  @media only screen and (max-device-width: 479px) {
    .referals__list-container {
      padding: 0 15px; } }

.referals__button {
  color: #f7f7f7;
  padding: 20px;
  cursor: pointer;
  opacity: 0.6; }
  @media only screen and (max-device-width: 479px) {
    .referals__button {
      padding: 10px 0; } }
  .referals__button_active {
    opacity: 1; }

.referals__username {
  text-align: center;
  width: calc(50% / 2); }

.referals__date {
  text-align: center;
  width: calc(50% / 2); }

.referals__invite-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px; }
  .referals__invite-container > * {
    padding: 0 10%; }
  @media only screen and (max-device-width: 479px) {
    .referals__invite-container {
      padding: 15px; }
      .referals__invite-container > * {
        padding: 0; } }

.referals__share-container {
  display: flex;
  justify-content: center; }

.referals__switch {
  display: flex;
  width: 40%;
  justify-content: space-around; }
  @media only screen and (max-device-width: 479px) {
    .referals__switch {
      width: calc(100% - 30px);
      padding: 10px 15px;
      justify-content: space-between; } }
  @media only screen and (max-device-width: 399px) {
    .referals__switch > * {
      flex-basis: calc(100% / 3 - 20px); } }

.referals__row {
  color: #f7f7f7;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 4px solid #484848;
  height: 80px; }

.referals__link {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-device-width: 479px) {
    .referals__link {
      width: 100%; } }

.referals__link-input {
  display: block;
  width: 100%;
  height: 30px;
  background-color: #232323;
  border: 1px solid #1b1b1b;
  outline: none;
  padding: 10px;
  color: #f7f7f7;
  margin-right: 15px; }
  @media only screen and (max-device-width: 479px) {
    .referals__link-input {
      flex-basis: 65%; } }
  @media only screen and (max-device-width: 399px) {
    .referals__link-input {
      margin-right: 10px; } }

.referals__copy-button {
  display: block;
  width: 120px;
  height: 40px;
  cursor: pointer;
  background-color: green;
  border: none;
  color: #f7f7f7;
  border-radius: 5px; }
  @media only screen and (max-device-width: 399px) {
    .referals__copy-button {
      width: 85px; } }

.referals__label {
  margin-bottom: 20px; }

.referals .fb-share-button {
  width: 300px;
  height: 30px; }

.referals__fb {
  background-image: url("../images/main/main-social/fb.svg");
  width: 45px;
  height: 45px; }

.referals table {
  width: 100%; }

.referals__header {
  color: #f7f7f7;
  height: 30px;
  display: -webkit-box;
  display: flex;
  justify-content: space-around;
  -webkit-box-align: center;
  align-items: center; }
  .referals__header tr {
    width: 100%;
    display: flex;
    justify-content: space-around; }
  .referals__header th {
    width: calc(100% / 2);
    text-align: center;
    height: 100%;
    line-height: 30px;
    opacity: 0.5;
    font-size: 25px;
    font-weight: 300;
    cursor: pointer; }
    @media only screen and (max-device-width: 479px) {
      .referals__header th {
        font-size: 10px; } }

.bettyTeamVersus {
  margin: 20px 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-device-width: 479px) {
    .bettyTeamVersus {
      flex-direction: column; } }
  .bettyTeamVersus .vs {
    display: flex;
    margin: 0px 17px;
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff; }
  .bettyTeamVersus .team {
    width: 100%;
    display: flex;
    padding: 10px;
    border: 2px solid white;
    border-radius: 10px; }
    @media only screen and (max-device-width: 479px) {
      .bettyTeamVersus .team {
        width: 90%; } }
    .bettyTeamVersus .team.radiant {
      background-color: rgba(21, 21, 45, 0.85); }
    .bettyTeamVersus .team.dire {
      background-color: rgba(45, 26, 0, 0.85); }
    .bettyTeamVersus .team .img {
      float: left;
      width: 48px;
      height: 48px;
      margin-right: 5px;
      border: 2px solid white;
      border-radius: 4px;
      background-size: cover;
      background-position: center; }
    .bettyTeamVersus .team .title {
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #ffffff; }
    .bettyTeamVersus .team .rating {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      color: #ffffff; }

.predictionColStream .desc_text {
  opacity: 0.8;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #ffffff; }

.predictionTwitchStream .container {
  height: 480px;
  width: 100%; }
  @media only screen and (max-device-width: 479px) {
    .predictionTwitchStream .container {
      height: auto; } }
  @media only screen and (max-device-width: 1023px) {
    .predictionTwitchStream .container {
      height: auto; } }

.predictionTwitchStream .top {
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  position: relative; }
  .predictionTwitchStream .top .wrapper {
    position: relative;
    padding-bottom: 200%;
    transform: translateY(-35.95%); }
    .predictionTwitchStream .top .wrapper iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.predictionTwitchStream .hover {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: calc(100% - 80px); }
  .predictionTwitchStream .hover img {
    width: 100%;
    height: 100%; }
  .predictionTwitchStream .hover .timer {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .predictionTwitchStream .hover .timer .block .timer_desc {
      font-size: 30px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-align: center;
      color: #ffffff; }
    .predictionTwitchStream .hover .timer .block .timer_time {
      font-size: 48px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.7px;
      text-align: center;
      color: #ffffff; }

.betty {
  background-color: #232323;
  overflow: hidden; }
  .betty__team-one {
    height: auto;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .betty__team-two {
    height: auto;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .betty__overlay {
    z-index: 1001; }
  .betty__amount {
    z-index: 1001; }
  .betty__status {
    left: 110px; }
  .betty__event-preview {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    display: flex;
    align-content: space-between;
    justify-content: flex-end;
    background: #232323; }
  .betty__event-preview-item {
    height: 60px;
    width: 80%;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat; }
    @media only screen and (max-device-width: 479px) {
      .betty__event-preview-item {
        background-size: 100%; } }
  .betty__title {
    margin: 10px; }
  .betty__show-results {
    background: none;
    border: none;
    outline: none;
    padding: 5px;
    color: #f7f7f7;
    line-height: 10px;
    height: 30px;
    background-color: green;
    align-items: center;
    border-radius: 15px;
    cursor: pointer; }
    @media only screen and (max-device-width: 479px) {
      .betty__show-results {
        margin-bottom: 20px; } }
  .betty__row {
    height: 73px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  @media only screen and (max-device-width: 479px) {
    .betty .prediction-stream .predictionColStream {
      display: block;
      padding: 20px 5px;
      box-sizing: border-box; }
    .betty .prediction .predictionColStream .predictionTwitchStream {
      display: block;
      height: auto; }
    .betty .predictionTwitchStream .hover {
      top: 0px; } }
  .betty .gamesSlider__arrow-left {
    margin-top: 90px; }
  .betty .gamesSlider__arrow-right {
    margin-top: 90px; }
  .betty .streamSlider .slick-slide {
    margin-right: 0px; }
  .betty #gamesSlider {
    height: 220px; }
  .betty #gamesSlider .streamItem {
    width: 180px !important; }
  .betty .custom-scroll {
    background-color: #232323; }
  .betty .predictionTwitchStream {
    margin-top: 20px;
    min-height: 440px;
    background-color: #232323; }
  .betty .prediction-stream {
    height: 640px; }
  .betty .predictionContainer {
    margin: 0; }
  .betty .predictionContainer:first-child {
    margin: 0; }
  .betty .predictionColPred {
    padding: 0 20px; }
    @media only screen and (max-device-width: 479px) {
      .betty .predictionColPred {
        box-sizing: border-box; } }
  .betty .predictionTwitchStream .hover {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: calc(100% + 20px); }

@media only screen and (max-device-width: 479px) {
  .dota {
    background-size: 200% 100%;
    background-position: -20px; } }

.pagination {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0 auto;
  list-style: none;
  color: #f7f7f7;
  padding: 20px;
  cursor: pointer; }
  @media only screen and (max-device-width: 399px) {
    .pagination {
      padding: 20px 0;
      width: 100%; } }
  .pagination .active {
    background-color: #232323; }

.pagination li {
  outline: 0;
  display: block;
  padding: 5px;
  min-width: 20px;
  text-align: center; }
  @media only screen and (max-device-width: 479px) {
    .pagination li {
      min-width: 10px;
      padding: 3px 5px; } }

.pagination li a {
  outline: 0; }

.gold .profilePage {
  background-image: url(../images/main/redesign/background-dark1.png); }

.gold .main__container_wide {
  background-color: transparent; }

.gold .container {
  background-color: transparent; }

.gold .twitchSlider {
  background-image: url(../images/main/redesign/background-dark1.png); }

.gold .help-center__main-content {
  background-image: url(../images/main/redesign/background-dark1.png); }

.gold .help-center .category-view {
  background-image: url(../images/main/redesign/background-dark1.png); }

.gold .help-center__article {
  background-image: url(../images/main/redesign/background-dark1.png); }

.gold .help-center__article * {
  background-color: transparent !important; }

.gold .help-center__category-icon {
  background-color: rgba(1, 1, 1, 0.5); }

.default .profilePage {
  background-image: none; }

.admin-logs {
  background-color: #232323;
  color: #f7f7f7;
  padding: 20px; }
  .admin-logs .table {
    width: 100%;
    margin-top: 40px; }
  .admin-logs td {
    height: 40px;
    cursor: pointer; }
  .admin-logs__modal {
    width: 400px;
    height: 400px;
    background: #232323;
    top: 15%;
    left: calc(50% - 200px);
    margin: 0 auto;
    position: absolute;
    white-space: pre-wrap;
    word-break: break-word;
    padding: 20px; }
  .admin-logs__modal-overlay {
    top: 0;
    left: 0;
    z-index: 1004;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); }

.post-editor {
  color: #f7f7f7; }
  .post-editor__article-name {
    width: 100%; }
  .post-editor__row {
    display: flex; }
  .post-editor__langs {
    display: flex;
    justify-content: space-evenly; }
    .post-editor__langs span {
      cursor: pointer;
      width: 100%;
      text-align: center; }
  .post-editor__categories {
    display: flex;
    flex-direction: column; }
  .post-editor__add-category {
    display: flex;
    flex-direction: column; }
  .post-editor__editor-container {
    width: 70%;
    padding: 10px;
    height: 500px; }
  .post-editor__article-manage-container {
    width: 30%;
    padding: 10px;
    background-color: #232323; }
  .post-editor__close-button {
    cursor: pointer;
    position: relative;
    width: 15px;
    height: 15px;
    margin-left: 25px;
    opacity: 0.75;
    background-image: url(../images/main/help-center/delete.png);
    background-size: 100%;
    transition: all 0.3s; }
    .post-editor__close-button:hover {
      opacity: 1; }
    @media only screen and (max-device-width: 479px) {
      .post-editor__close-button {
        width: 20px;
        height: 20px; } }
  .post-editor__edit-button {
    cursor: pointer;
    position: relative;
    width: 15px;
    height: 15px;
    margin-left: 25px;
    opacity: 0.75;
    background-image: url(../images/main/help-center/edit.png);
    background-size: 100%;
    transition: all 0.3s; }
    .post-editor__edit-button:hover {
      opacity: 1; }
    @media only screen and (max-device-width: 479px) {
      .post-editor__edit-button {
        width: 20px;
        height: 20px; } }
  .post-editor__save-button {
    cursor: pointer;
    position: relative;
    width: 15px;
    height: 15px;
    margin-left: 25px;
    opacity: 0.75;
    background-image: url(../images/main/help-center/confirm.png);
    background-size: 100%;
    transition: all 0.3s; }
    .post-editor__save-button:hover {
      opacity: 1; }
    @media only screen and (max-device-width: 479px) {
      .post-editor__save-button {
        width: 20px;
        height: 20px; } }

i.mce-i-Button_gold:before {
  content: "G"; }

i.mce-i-Button_blue:before {
  content: "B"; }

.overlay-creator h5 {
  margin-bottom: 8px; }

.overlay-creator label {
  font-size: 14px; }

.overlay-creator__settings {
  border-bottom: 6px solid #ed8c10;
  border-top: 6px solid #ed8c10;
  margin-bottom: 40px;
  padding: 20px 0;
  width: 100%; }

.overlay-creator__row {
  display: flex;
  background-color: #1b1b1b; }

.overlay-creator__col {
  display: flex;
  flex-direction: column; }

.overlay-creator__overlay-preview {
  width: calc(100% - 25%);
  background: rgba(0, 109, 255, 0.96);
  background: -moz-linear-gradient(-45deg, rgba(0, 109, 255, 0.96) 0%, #ff7800 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(0, 109, 255, 0.96)), color-stop(100%, #ff7800));
  background: -webkit-linear-gradient(-45deg, rgba(0, 109, 255, 0.96) 0%, #ff7800 100%);
  background: -o-linear-gradient(-45deg, rgba(0, 109, 255, 0.96) 0%, #ff7800 100%);
  background: -ms-linear-gradient(-45deg, rgba(0, 109, 255, 0.96) 0%, #ff7800 100%);
  background: linear-gradient(135deg, rgba(0, 109, 255, 0.96) 0%, #ff7800 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006dff', endColorstr='#ff7800', GradientType=1 );
  position: relative; }

.overlay-creator__sidebar {
  width: 30%;
  color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #1b1b1b; }

.overlay-creator__tab {
  padding: 10px;
  text-align: center;
  background-color: #1b1b1b;
  border: 1px solid #f7f7f7;
  width: 300px; }

.overlay-creator__overlay-preview {
  position: relative;
  background-color: #f7f7f7;
  height: calc(100vh - 60px); }

.overlay-creator .overlay__message-text {
  display: inline-block;
  font-family: inherit; }

.overlay-creator .overlay__placeholder {
  width: inherit;
  height: inherit;
  border: 1px dashed orangered; }

.overlay-creator .overlay-preview__draggable-resizable {
  width: inherit;
  height: inherit;
  border: 1px dashed orangered;
  position: relative; }

.overlay-creator .overlay__container {
  border: 1px dotted #f3b78f; }

.overlay-creator .overlay__crowdfunding-container {
  width: 100%;
  text-align: center; }

.overlay-creator progress[value] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 60px; }

.overlay-creator progress[value]::-webkit-progress-value {
  background-color: green;
  border-radius: 2px;
  transition: all 1s; }

.overlay-creator .overlay__crowdfunding-description {
  font-size: 36px;
  /* position: absolute; */
  line-height: 60px;
  width: 100%;
  /* left: 10%; */ }

.overlay-creator .overlay__crowdfunding-goal {
  font-size: 36px;
  position: absolute;
  line-height: 60px;
  right: 10%; }

.overlay-creator .overlay__crowdfunding-progress-inline {
  font-size: 36px;
  position: absolute;
  line-height: 60px;
  left: 10%; }

.overlay-creator .overlay__crowdfunding-progress {
  font-size: 36px;
  position: absolute;
  line-height: 60px;
  width: 100%;
  display: block; }

.form-field {
  height: 25px;
  border-radius: 8px;
  width: 100%;
  background-color: #2d2d2d;
  border: none;
  outline: none;
  color: #f7f7f7; }

.checkbox {
  display: inline;
  width: 18px !important;
  height: 18px !important;
  margin: 0 auto;
  border-radius: 20px;
  position: relative !important;
  top: 2px;
  left: 0 !important; }

input:checked .checkbox:after {
  background-color: red; }

.overlay-preview__controls {
  position: absolute;
  left: 0px;
  bottom: 0px;
  padding: 32px;
  margin-bottom: 60px; }

.activities__bottom {
  margin-top: 40px; }

.donations__container {
  max-width: 110px; }

.donations__top {
  margin-bottom: 24px; }

.donations__middle {
  margin-bottom: 24px; }

.adm-new-controls-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 0; }

.adm-new-control-card {
  width: calc(100% / 4 - 55px);
  border: 5px solid rgba(255, 173, 37, 0.25);
  color: #f7f7f7;
  margin: 0 0.5rem 1rem 0.5rem;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .adm-new-control-card:hover .adm-new-control-card__actions {
    display: block !important; }
  .adm-new-control-card--active {
    border: 5px solid #ffad25; }
  .adm-new-control-card__desc {
    margin: 20px 0;
    word-break: break-all; }
  .adm-new-control-card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px; }
  .adm-new-control-card__close {
    cursor: pointer; }
    .adm-new-control-card__close::before, .adm-new-control-card__close::after {
      background-color: #ffad25; }
  .adm-new-control-card__content {
    font-size: 13px; }
    .adm-new-control-card__content > input:last-child {
      margin-top: 5px; }
    .adm-new-control-card__content > textarea:focus {
      outline: none;
      opacity: 1; }
  .adm-new-control-card__description {
    width: 100%;
    margin-top: 10px;
    border: none;
    resize: none;
    color: #fff;
    background-color: transparent;
    margin-bottom: 10px;
    border-bottom: 3px solid #ffad25;
    opacity: 0.5;
    transition: opacity 0.3s ease-in;
    overflow: hidden; }
  .adm-new-control-card__input-group {
    display: flex;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end; }
    .adm-new-control-card__input-group i {
      width: 15px;
      min-width: 15px;
      height: 15px;
      margin-right: 5px;
      cursor: pointer; }
  .adm-new-control-card__input {
    width: 100%;
    padding: 5px 0px;
    color: #fff;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #ffad25;
    opacity: 0.5;
    transition: opacity 0.3s ease-in;
    margin: 0 !important; }
    .adm-new-control-card__input:focus {
      outline: none;
      opacity: 1; }
  .adm-new-control-card__actions {
    margin-top: 15px;
    display: none; }
  .adm-new-control-card__btn-group {
    display: flex;
    justify-content: space-between; }
    .adm-new-control-card__btn-group:last-child {
      margin-top: 15px; }
    .adm-new-control-card__btn-group > button:last-child {
      margin-top: 0; }
  .adm-new-control-card__btn {
    display: block;
    width: 100%;
    padding: 5px;
    color: #fff;
    font-weight: 600;
    border: none;
    outline: none;
    text-align: center;
    border-radius: 25px;
    cursor: pointer; }
    .adm-new-control-card__btn:hover {
      box-shadow: 0 0 10px #f7f7f7, 0 0 10px #f7f7f7; }
    .adm-new-control-card__btn:last-child {
      margin-top: 15px; }
    .adm-new-control-card__btn--accent {
      background-color: #ffad25;
      border: 2px solid #ffad25; }
    .adm-new-control-card__btn--blue {
      background-color: #4492ee;
      border: 2px solid #4492ee; }
    .adm-new-control-card__btn--create {
      margin-top: -5px; }
    .adm-new-control-card__btn--red {
      background-color: #d40707;
      border: 2px solid #d40707; }
    .adm-new-control-card__btn--bordered {
      background-color: transparent; }

.purecss-close {
  width: 12px;
  height: 12px;
  position: relative; }

.purecss-close:before,
.purecss-close:after {
  content: ' ';
  position: absolute;
  right: 0.4em;
  height: 12px;
  width: 1.7px; }

.purecss-close:before {
  transform: rotate(45deg); }

.purecss-close:after {
  transform: rotate(-45deg); }

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type='number'] {
  -moz-appearance: textfield; }

.adm-new-prediction-container {
  flex-wrap: wrap;
  justify-content: center; }

.adm-new-prediction {
  width: 100%;
  box-sizing: border-box;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid; }

.adm-new-prediction__btn-group {
  position: relative; }

.adm-new-control-card__option {
  font-size: 10px; }

.adm-new-prediction__btn:first-child {
  position: absolute;
  width: 50%; }

.adm-new-prediction__btn:last-child {
  padding-left: 45%; }

.adm-new-challenge-container {
  flex-wrap: wrap;
  justify-content: center; }

.adm-new-challenge {
  width: calc(100% - 2rem);
  height: auto; }

.adm-new-challenge__option-group {
  display: flex;
  justify-content: space-between;
  margin-right: 15px; }
  .adm-new-challenge__option-group input {
    margin-top: -3px;
    padding-bottom: 0; }

.adm-new-challenge__input-group {
  flex-basis: 45%; }

.adm-new-challenge__orbs {
  margin: 0 3px; }
  .adm-new-challenge__orbs::before {
    content: ' ';
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 3px;
    margin-bottom: -2px;
    background-size: 100% 100%; }

.adm-new-challenge__orbs::before {
  background-image: url("/assets/images/main/redesign/orb.png"); }

.adm-new-challenge__icon {
  width: 15px;
  min-width: 15px;
  height: 15px; }

.adm-new-challenge__input-time {
  background-color: #ffad25;
  background-size: 100% 100%; }

.adm-new-challenge__input-orb {
  background-image: url("/assets/images/main/redesign/orb.png");
  background-size: 100% 100%; }

.adm-new-challenge__content p {
  margin-top: 15px; }

.adm-new-challenge-state2 .adm-new-challenge__btn--status {
  display: flex;
  justify-content: center; }
  .adm-new-challenge-state2 .adm-new-challenge__btn--status span {
    padding: 0 3px; }

.adm-new-challenge__input-time-container {
  border-bottom: 3px solid #ffad25;
  opacity: 0.5;
  display: flex;
  align-items: flex-end;
  transition: opacity 0.5s ease-in; }
  .adm-new-challenge__input-time-container:focus-within {
    opacity: 1; }

.adm-new-challenge__current-balance {
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  border: 3px solid #ffad25;
  border-radius: 25px;
  display: flex;
  justify-content: space-around; }

.bank-input {
  border: none;
  width: 30%; }

.time-input {
  border: none;
  padding: 0px 2px; }
  .time-input_h {
    text-align: right; }
  .time-input_m {
    text-align: left; }

.adm-new-challenge__head-input-group {
  position: relative;
  display: flex;
  width: 45%; }

.adm-new-challenge__time {
  display: flex;
  align-items: flex-end; }

.adm-new-paid-action {
  width: 70%;
  box-sizing: border-box; }

.adm-new-paid-action__cubes::before,
.adm-new-paid-action__orbs::before {
  content: ' ';
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 10px;
  margin-bottom: -2px;
  background-size: 100% 100%; }

.adm-new-paid-action__orbs::before {
  background-image: url("/assets/images/main/redesign/orb.png"); }

.adm-new-paid-action__cubes::before {
  background-image: url("/assets/images/main/redesign/cube.png"); }

.adm-new-paid-action__option-group {
  margin-right: 15px; }
  .adm-new-paid-action__option-group input {
    margin-top: -3px;
    padding-bottom: 0;
    width: 50%; }

.adm-new-paid-action__input-cube {
  background-image: url("/assets/images/main/redesign/cube.png");
  background-size: 100% 100%; }

.adm-new-paid-action__input-orb {
  background-image: url("/assets/images/main/redesign/orb.png");
  background-size: 100% 100%; }

.adm-new-voting {
  width: 100%;
  box-sizing: border-box; }
  .adm-new-voting__option-bank {
    position: absolute;
    right: 0px; }
  .adm-new-voting__option-value {
    margin-right: 10px; }
  .adm-new-voting__winner {
    background-color: green; }
  .adm-new-voting__option {
    position: relative; }
  .adm-new-voting__input-time-container {
    border-bottom: 3px solid #ffad25;
    opacity: 0.5;
    display: flex;
    align-items: flex-end;
    max-width: 40px;
    transition: opacity 0.5s ease-in; }
    .adm-new-voting__input-time-container:focus-within {
      opacity: 1; }

.adm-new-voting__cubes::before,
.adm-new-voting__orbs::before {
  content: ' ';
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 10px;
  margin-bottom: -2px;
  background-size: 100% 100%; }

.adm-new-voting__orbs::before {
  background-image: url("/assets/images/main/redesign/orb.png"); }

.adm-new-voting__cubes::before {
  background-image: url("/assets/images/main/redesign/cube.png"); }

.adm-new-voting__option-group {
  margin-right: 15px; }
  .adm-new-voting__option-group input {
    margin-top: -3px;
    padding-bottom: 0;
    width: 35%; }

.adm-new-voting__input-cube {
  background-image: url("/assets/images/main/redesign/cube.png");
  background-size: 100% 100%; }

.adm-new-voting__input-orb {
  background-image: url("/assets/images/main/redesign/orb.png");
  background-size: 100% 100%; }

.profile-stream-management div {
  box-sizing: border-box; }

.profile-stream-management__bg-image {
  background-image: url(../images/main/new-profile/dota-2-roshan-roshan-ulfsaar.jpg);
  background-size: cover;
  min-height: 700px;
  min-width: 600px; }

.profile-stream-management__settings-container {
  width: 100%;
  padding: 30px 80px; }

.profile-stream-management__settings-form {
  margin: 40px 0; }
  .profile-stream-management__settings-form > .row-container {
    margin-bottom: 1rem; }

.profile-stream-management__choose-image {
  margin-left: 60px; }

.profile-stream-management__save-button {
  display: block;
  margin-top: 60px; }

.profile-stream-management__modal {
  width: 40vw;
  height: 40vh; }

.profile-stream-management__modal-content {
  padding: 2rem;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly; }

.profile-stream-management__icon {
  margin-left: 15px; }

.profile-stream-management__ok-button {
  margin-top: 20px; }

.profile-stream-management__invite-button {
  margin-top: 20px; }

.profile-stream-management__choose-image-button {
  margin: 0 auto; }

.profile-stream-management__status-button:hover {
  transition: all 0.3s ease;
  box-shadow: 0 0 10px #f7f7f7, 0 0 20px #f7f7f7; }

.profile-stream-management__admin-link {
  margin-left: 135px; }

.streaming-platform-selector {
  padding: 40px; }
  .streaming-platform-selector__header:after {
    content: '';
    position: relative;
    display: block;
    margin: 12px auto;
    border-bottom: 4px solid #ff8008;
    height: 1px;
    width: 460px; }

.streaming-platform {
  margin-top: 40px;
  width: 380px;
  height: 380px;
  background-size: cover;
  position: relative;
  border-radius: 25px; }
  .streaming-platform__button {
    position: absolute;
    bottom: 40px;
    left: calc(50% - 90px);
    width: 180px;
    height: 50px;
    margin: 0 auto; }
  .streaming-platform_twitch {
    background-image: url("../images/main/icons/twitch.png"); }
  .streaming-platform_youtube {
    background-image: url("../images/main/icons/youtube.png"); }
  .streaming-platform_goodgame {
    background-image: url("../images/main/icons/goodgame.png"); }
  .streaming-platform_inactive {
    filter: grayscale(100%); }
    .streaming-platform_inactive:hover {
      filter: none; }

@media (min-width: 320px) and (max-width: 480px) {
  .profile-stream-management div {
    display: block; }
  .profile-stream-management__settings-container {
    padding: 30px 15px; }
  .profile-stream-management__choose-image {
    margin-left: 0; } }

#jvlabelWrap {
  z-index: 2000; }

#test:hover .overlay-trigger {
  opacity: 1; }

.overlay-trigger {
  opacity: 0;
  background: transparent;
  line-height: 55px;
  text-align: center;
  color: #f7f7f7;
  cursor: pointer;
  height: 55px;
  width: 140px;
  position: absolute;
  right: 10%;
  top: 0;
  transition: all 0.3s; }

.overlay-trigger-overlay {
  cursor: pointer;
  background: transparent;
  height: 30px;
  width: 40px;
  position: relative;
  left: -5%;
  z-index: 1001;
  bottom: -95%; }

* {
  margin: 0;
  padding: 0; }

*, *::before, *::after {
  box-sizing: border-box; }

body, html {
  background-color: black;
  font-family: "Montserrat", sans-serif; }

a, p, h1, h2 {
  font-family: "Montserrat", sans-serif; }

a, a:visited, a:active {
  text-decoration: none;
  color: inherit; }

.container {
  margin: 0 auto;
  width: calc(100% - 160px);
  padding: 0 80px;
  max-width: 1280px; }

.root {
  width: 100vw;
  display: flex;
  flex-direction: column;
  box-sizing: border-box; }

.app__main {
  flex-grow: 1; }

.app__bottom {
  height: 100px; }

::-webkit-scrollbar {
  width: 15px;
  background-color: #F5F5F5; }

/**  STYLE 11 */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc; }

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #232323;
  border: 1px solid #aaa; }

::-webkit-scrollbar-thumb:hover {
  background: #232323; }

::-webkit-scrollbar-thumb:active {
  background: #1b1b1b; }

@media (max-width: 1024px) {
  .container {
    width: auto; } }

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
  .container {
    padding: 0 30px;
    width: calc(100% - 60px); } }

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .root {
    padding-top: 80px; }
  .container {
    padding: 0 10px;
    width: calc(100% - 20px); } }

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .root {
    padding-top: 80px; }
  .container {
    padding: 0 10px; } }

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 823px) {
  .root {
    padding-top: 80px; }
  .container {
    padding: 0; } }

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .container {
    padding: 0; } }
