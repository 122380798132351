@import '../../../../assets/style/partials/variables.scss';

.ds-tabs{
  &__tab-list{
    border-bottom: 1px solid #d6d6d6;
    position: relative;
  }
  &__list-item{
    // flex-grow: 1;
  }
  &__left-addon{
    position: absolute;
    right: 0;
  }
  &__button{
    width: 100%;
    cursor: pointer;
    &--selected{
      border-bottom: 4px solid $dark-magenta;
    }
  }
}