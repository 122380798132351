.footer-mobile{
    height: 150px;
}
.footer-desktop{
   
}
.navigation{
    a{
        margin-right: 3rem;
    }
}