.controls{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 300px;
    @include mq('phone-wide', max){
        height: 500px;
    }
    .hr{
        width: 80%;
    }
    .title{
        margin-top: 1em;
    }
    .row{
        @include mq('phone-wide', max){
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 1em;
        }
       
        .pre-btn{
            margin: 1em;
            width:100px;
            display: inline-block;
            text-align: center;
        }
    }
    }
    