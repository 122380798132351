@import '../../../../assets/style/partials/variables.scss';

.ds-widgets-explorer{
    color: $white-dark;
    font-family: $ubuntu-regular;
    h5{
        font-size: 18px;
    }
    &__category{
        width: 1250px;
        margin: 0 auto;
        padding-top: 16px;
    }
    &__arrow{
        &:before{
            color: grey;
        }
    }
    &__category-title{
        line-height: 1.5;
    }
    &__category-description{
        line-height: 1.5;
    }
    &__category-widgets{
        padding: 16px 0;
    }
}