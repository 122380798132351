@import '../../../assets/style/partials/variables.scss';


// => reset.css
li, ul{
 list-style: none;
}

.ds-tab-switcher {
    padding-bottom: 16px;
    display: inline-block;

    &__tab {
      color: $white-dark;
      text-align: center;
      cursor: pointer;
      font-size: 20px;
      margin-right: 16px;
      &:last-of-type{
        margin-right: 0;
      }
      &--active{
        font-size: 22px;
        font-weight: bold;
        color: $gold-dark;
        box-shadow: inset 0 -1px 0 $gold-dark;
      }
    }
  }
  
.ds-tab-switcher-inner{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: inset 0 -1px 0 #dad8de;
}